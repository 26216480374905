import { Component } from 'react'
import styled from 'styled-components'

class Email extends Component {
  state = {
    value: this.props.value || ''
  }

  changeHandler = event => {
    this.setState({ value: event.target.value })
    if (this.props.onChange) this.props.onChange(event)
  }

  render() {
    return (
      <StyledEmail
        type='email'
        id={this.props.id}
        value={this.state.value}
        placeholder={this.props.placeholder === 'none' ? null : this.props.placeholder || '@uj.ac.za or @jbs.ac.za'}
        required={this.props.required}
        smallDevice={this.props.smallDevice}
        onChange={this.changeHandler}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        pattern={this.props.pattern === 'none' ? null : this.props.pattern || '^.+@(uj|jbs).ac.za'}
        autoComplete={this.props.autoComplete || 'email'}
      />
    )
  }
}

const StyledEmail = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 26px;
  color: ${props => props.theme.dark};
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  padding: 4px 6px;
  font-size: inherit;

  :focus {
    border-width: 2px;
    outline: none;
  }
`

export default Email
