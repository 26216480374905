import { EXIF } from 'exif-js'

async function getDateTaken(photo) {
  const dateFromEXIF = await new Promise(function (resolve) {
    const img = new Image()
    if (typeof photo === 'string') img.src = photo
    if (typeof photo === 'object') img.src = URL.createObjectURL(photo)
    img.onload = () => {
      EXIF.getData(img, function () {
        const exifDateString = EXIF.getTag(this, 'DateTimeOriginal') || EXIF.getTag(this, 'DateTimeDigitized')
        resolve(exifDateString)
      })
    }
  })

  if (dateFromEXIF) {
    const dateArray = dateFromEXIF.split(" ")
    const dateString = dateArray[0].replace(/:/g, "-")
    const timeString = dateArray[1]
    const formattedDateString = dateString + " " + timeString
    const timestamp = new Date(formattedDateString)
    return timestamp
  } else return 'undefined'
  
}

export default getDateTaken