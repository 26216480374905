import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Tasks from 'pages/admin/Tasks'
import Users from 'pages/admin/Users'
import Bookings from 'pages/admin/Bookings'
import Photos from 'pages/admin/Photos'
import Searches from 'pages/admin/Searches'

class Admin extends Component {
  state = {
    view: 'Users'
  }

  renderViews = () => {
    return ['Users', 'Bookings', 'Photos', 'Searches', 'Tasks'].map(view => {
      return (
        <View
          id={view}
          key={view}
          onClick={() => this.setState({ view })}
          active={this.state.view === view}
        >
          {view}
        </View>
      )
    })
  }

  render() {
    return (
      <React.Fragment>
        <StyledAdmin>
          {this.state.view === 'Users' ? (
            <Users />
          ) : this.state.view === 'Bookings' ? (
            <Bookings />
          ) : this.state.view === 'Photos' ? (
            <Photos />
          ) : this.state.view === 'Searches' ? (
            <Searches />
          ) : (
            <Tasks />
          )}
        </StyledAdmin>
        <Footer>
          <Views>{this.renderViews()}</Views>
        </Footer>
      </React.Fragment>
    )
  }
}

const StyledAdmin = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const Footer = styled.div`
  grid-area: footer;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.light};
  color: ${props => props.theme.dark};
`

const Views = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
  right: 0px;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const View = styled.div`
  position: relative;
  height: 100%;
  width: calc(100% / 5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${props => props.theme.dark};
  font-weight: ${props => (props.active ? 'bold' : null)};
  background-color: ${props => (props.active ? props.theme.main : props.theme.light)};
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.main};
  }
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    config: state.config
  }
}

export default connect(mapStateToProps)(Admin)
