import * as actionTypes from 'store/actions/actionTypes'

export const updateProgress = progress => {
  return {
    type: actionTypes.UPDATE_PROGRESS,
    progress
  }
}

export const addFiles = files => {
  return {
    type: actionTypes.ADD_FILES,
    files
  }
}

export const clearFiles = () => {
  return {
    type: actionTypes.CLEAR_FILES
  }
}

export const removeFile = fileName => {
  return {
    type: actionTypes.REMOVE_FILE,
    fileName
  }
}
