import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { auth } from 'utils/firebase'
import * as actions from 'store/actions'
import Modal from 'components/Modal'
import Input from 'components/NewInput'
import Loader from 'components/loader/Loader'
import Alert from 'components/Alert'
import { getInputValue } from 'components/NewInput'

class ChangePasswordModal extends Component {
  state = {
    loading: false,
    passwordsEqual: false
  }

  backdropClicked = event => {
    if (event.target.id === 'modalBackdrop') {
      this.props.showChangePasswordModal()
    }
  }

  renderPasswordValidation = passwordValidation => {
    this.passwordValidation = passwordValidation
    this.forceUpdate()
  }

  setValues = () => {
    this.currentPassword = getInputValue('currentPassword')
    this.newPassword = getInputValue('newPassword')
    this.confirmNewPassword = getInputValue('confirmNewPassword')
    this.setState({ passwordsEqual: this.newPassword === this.confirmNewPassword})
  }
  
  changePasswordHandler = event => {
    event.preventDefault()
    this.setState({ loading: true })
    auth
      .signIn(this.props.currentUser.email, this.currentPassword)
      .then(() => {
        auth
          .updatePassword(this.newPassword)
          .then(() => {
            this.alert = <Alert alertType='Password changed successfully' width='400px' alertButtonClicked={this.continueHandler} />
            this.setState({ loading: false })
          })
          .catch(error => {
            if (error.code === 'auth/weak-password') {
              this.alert = <Alert alertType='Password too short' width='400px' alertButtonClicked={this.retryNewPasswordHandler} />
            }
            this.setState({ loading: false })
          })
      })
      .catch(error => {
        if (error.code === 'auth/wrong-password') {
          this.alert = <Alert alertType='Incorrect password' width='400px' alertButtonClicked={this.retryOldPasswordHandler} />
        } else {
          this.props.setError(error, false, 'Failed to change password via the changePasswordModal.')
        }
        this.setState({ loading: false })
      })
  }

  continueHandler = () => {
    this.props.showChangePasswordModal()
  }

  retryOldPasswordHandler = () => {
    this.alert = null
    this.forceUpdate()
    document.getElementById('currentPassword').focus()
    document.getElementById('currentPassword').value = ''
  }

  retryNewPasswordHandler = () => {
    this.alert = null
    const newPasswordInput = document.getElementById('newPassword')
    const confirmNewPasswordInput = document.getElementById('confirmNewPassword')
    newPasswordInput.value = ''
    confirmNewPasswordInput.value = ''
    newPasswordInput.focus()
  }

  render() {
    return (
      <React.Fragment>
        <Modal title='Change password' width='600px' backdropClicked={this.backdropClicked} smallDevice={this.props.smallDevice}>
          <form onSubmit={this.changePasswordHandler}>
            {this.state.loading ? (
              <Loader />
            ) : (
              <StyledChangePasswordModal>
                <InputLine>
                  <Input
                    type='password'
                    id='currentPassword'
                    label='Current password'
                    autoComplete='current-password'
                    onChange={this.setValues}
                    autoFocus
                    required
                  />
                </InputLine>

                <InputLine height='20px' />
                <InputLine>
                  <Input
                    type='password'
                    id='newPassword'
                    label='New password'
                    autoComplete='new-password'
                    renderPasswordValidation={this.renderPasswordValidation}
                    onChange={this.setValues}
                    required
                  />
                </InputLine>
                <InputLine>
                  <Input type='password' id='confirmNewPassword' label='Confirm new password' autoComplete='new-password' onChange={this.setValues} required />
                </InputLine>
                <InputLine height='10px' />
                {this.passwordValidation}
                <InputLine height='20px' />
                <InputLine>
                  <Input type='button' buttonType='submit' width='auto' id='submit' disabled={!this.state.passwordsEqual}>
                    Change password
                  </Input>
                </InputLine>
                <InputLine />
              </StyledChangePasswordModal>
            )}
          </form>
          {this.alert}
        </Modal>
      </React.Fragment>
    )
  }
}

const StyledChangePasswordModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`

const InputLine = styled.div`
  width: 100%;
  height: ${props => props.height || 'auto'};
  display: flex;
  justify-content: center;
  align-items: center;
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    smallDevice: state.ui.smallDevice
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showChangePasswordModal: () => dispatch(actions.showChangePasswordModal()),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal)
