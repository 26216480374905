import ReactDOMServer from 'react-dom/server'
import React from 'react'
import { functions } from 'utils/firebase'
import store from 'store/store'
import { setError } from 'store/actions'

var toRecipients, subject, messageContent

const sendEmail = async () => {
  toRecipients = createToRecipientList()
  subject = `Photo reported on the UJDML`
  messageContent = createMessageContent()

  if (toRecipients) return await sendMailViaCloudFunction()
}

function createMessageContent() {
  return ReactDOMServer.renderToStaticMarkup(
    <html
      style={{
        height: '100% !important',
        width: 'auto !important',
        margin: '0 auto !important',
        padding: '0 !important'
      }}
    >
      <head>
        <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet' />
      </head>
      <body
        style={{
          height: '100% !important',
          width: 'auto !important',
          margin: '0 auto !important',
          padding: '10px 10px !important',
          msoLineHeightRule: 'exactly',
          lineHeight: '90%',
          fontFamily: 'Roboto, Arial'
        }}
      >
        <table
          id='body'
          style={{
            height: '100%',
            width: '100%',
            tableLayout: 'fixed'
          }}
        >
          <tbody>
            <tr>
              <td>
                <table
                  id='container'
                  style={{
                    width: '100%',
                    maxWidth: '1000px',
                    backgroundColor: '#e0dede',
                    fontSize: '14px',
                    color: '#57575a',
                    border: '2px solid #57575a',
                    borderCollapse: 'collapse',
                    tableLayout: 'fixed'
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          id='title'
                          style={{
                            tableLayout: 'fixed',
                            width: '100%',
                            backgroundColor: '#e0dede',
                            border: '0',
                            borderCollapse: 'collapse',
                            fontSize: '22px',
                            color: '#57575a'
                          }}
                        >
                          <tbody>
                            <tr style={{ height: '40px' }}>
                              <td style={{ width: '20px' }}></td>
                              <td>
                                <strong>{`Photo reported on the UJDML`}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          style={{
                            backgroundColor: '#f4f4f4',
                            tableLayout: 'fixed',
                            border: '0',
                            borderCollapse: 'collapse',
                            width: '100%',
                            color: '#57575a'
                          }}
                          id='body'
                        >
                          <tbody>
                            <tr>{renderBody()}</tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          id='footer'
                          style={{
                            backgroundColor: '#e0dede',
                            width: '100%',
                            cellPadding: '0',
                            cellSpacing: '0',
                            border: '0',
                            borderCollapse: 'collapse',
                            tableLayout: 'fixed',
                            padding: '0',
                            margin: '0'
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ width: '40px' }}>
                                <a href='https://ujdml.co.za/' style={{ textDecoration: 'none' }}>
                                  <img
                                    src='https://ujdml.co.za/icon-72x72.png'
                                    height='40'
                                    width='40'
                                    style={{ height: '40px', padding: '0', margin: '0' }}
                                    alt='uj-logo'
                                  />
                                </a>
                              </td>
                              <td style={{ width: '6px' }}></td>
                              <td style={{ paddingTop: '6px' }}>
                                <a href='https://ujdml.co.za/' style={{ textDecoration: 'none' }}>
                                  <span
                                    style={{
                                      color: '#57575a',
                                      fontSize: '22px'
                                    }}
                                  >
                                    <strong>UJ Digital Media Library</strong>
                                  </span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  )
}

function renderBody() {
  return (
    <td style={{ fontSize: '14px', padding: '20px 20px 20px 20px' }}>
      <p>{`Dear UJDML Administrator,`}</p>
      <p>
        {`A photo was reported on the UJDML. Please visit the `}

        <span>
          <a href={`https://ujdml.co.za/admin`} style={{ color: '#57575a' }}>{`admin page`}</a>
        </span>
        {` to review the removal request. `}
      </p>
      <p>{`You need to respond to this request within one business day.`}</p>
    </td>
  )
}

function createToRecipientList() {
  const recipients = []
  for (const administrator of store.getState().config.admins) {
    recipients.push({
      emailAddress: {
        address: administrator.email
      }
    })
  }
  return recipients
}

var retries = 0
async function sendMailViaCloudFunction() {
  await functions
    .httpsCallable('sendMail')({
      toRecipients,
      ccRecipients: null,
      subject,
      messageContent
    })
    .then(() => 'SUCCESS')
    .catch(async error => {
      if (retries < 4) {
        retries++
        setTimeout(async () => {
          return await sendMailViaCloudFunction()
        }, 400)
      } else {
        store.dispatch(setError(error, false, 'Failed to send report photo confirmation in PhotoView component.'))
      }
    })
}

export default sendEmail
