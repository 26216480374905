import { Component } from 'react'
import styled from 'styled-components'

class Password extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ''
    }

    this.validateProps()
    if (this.props.renderPasswordValidation) this.props.renderPasswordValidation(this.renderPasswordValidation(''))
  }

  validateProps = () => {
    if (!this.props.autoComplete || !['current-password', 'new-password'].includes(this.props.autoComplete))
      throw new Error(
        "<Input type='password' /> must have an autoComplete property of either 'current-password' or 'new-password'"
      )
  }

  changeHandler = event => {
    if (this.lastCharacterIsSpace(event.target.value)) return false
    this.setState({ value: event.target.value }, () => {
      if (this.props.onChange) this.props.onChange(event)
      if (this.props.renderPasswordValidation)
        this.props.renderPasswordValidation(this.renderPasswordValidation(event.target.value))
    })
  }

  lastCharacterIsSpace = string => string.slice(string.length - 1) === ' '

  renderPasswordValidation = password => {
    const length = new RegExp('(.{8,})')
    const lowerCase = new RegExp('[a-z]')
    const upperCase = new RegExp('[A-Z]')
    const number = new RegExp('[0-9]')
    const special = new RegExp('[^a-zA-Z0-9s]')

    return (
      <PasswordValidation>
        <ValidationItem passed={lowerCase.test(password)}>Contain at least one lower case letter</ValidationItem>
        <ValidationItem passed={upperCase.test(password)}>Contain at least one upper case letter</ValidationItem>
        <ValidationItem passed={number.test(password)}>Contain at least one number</ValidationItem>
        <ValidationItem passed={special.test(password)}>Contain at least one special character</ValidationItem>
        <ValidationItem passed={length.test(password)}>At lease eight characters long</ValidationItem>
      </PasswordValidation>
    )
  }

  render() {
    return (
      <StyledPassword
        type="password"
        id={this.props.id}
        value={this.state.value}
        required={this.props.required}
        smallDevice={this.props.smallDevice}
        onChange={this.changeHandler}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        autoComplete={this.props.autoComplete || 'off'}
      />
    )
  }
}

// pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[\W]).{8,64}$"

const StyledPassword = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 26px;
  color: ${props => props.theme.dark};
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  padding: 4px 6px;

  :focus {
    border-width: 2px;
    outline: none;
  }
`
const PasswordValidation = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
`

const ValidationItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  color: ${props => (props.passed ? '#6cca67' : props.theme.danger)};
`

export default Password
