import React from 'react'
import styled from 'styled-components'

const TextLink = props => {
  return (
    <StyledTextLink
      onClick={props.disabled ? null : props.clicked}
      gridArea={props.gridArea}
      justifySelf={props.justifySelf}
      fontSize={props.fontSize}
      fontColor={props.fontColor}
      padding={props.padding}
      margin={props.margin}
      hoverMargin={props.hovermargin}
      id={props.id}
      dangerLink={props.dangerLink}
      fontWeight={props.fontWeight}
      disabled={props.disabled}
      touchDevice={props.touchDevice}
      tabIndex={props.tabIndex || '-1'}
      onKeyDown={props.onKeyDown}
      underline={props.underline}
      underlined={props.underlined}
    >
      {props.children}
    </StyledTextLink>
  )
}

const StyledTextLink = styled.span`
  grid-area: ${props => props.gridArea || null};
  box-sizing: border-box;
  width: auto;
  height: auto;
  position: relative;
  justify-self: ${props => props.justifySelf || null};
  font-size: ${props => props.fontSize || 'inherit'};
  color: ${props => props.fontColor || (props.disabled ? props.theme.light : props.theme.dark)};
  text-decoration: ${props => (props.underlined ? 'underline solid' : 'none')};
  padding: ${props => props.padding || null};
  margin: ${props => props.margin || null};
  font-weight: ${props => props.fontWeight || null};
  cursor: ${props => (props.disabled ? 'select' : 'pointer')};

  :hover {
    font-weight: ${props => (props.touchDevice ? 'initial' : 'bold')};
    margin: ${props => (props.touchDevice ? props.margin : props.hovermargin)};
    color: ${props => (props.dangerLink ? '#ff0000' : props.theme.disabled ? props.theme.light : props.theme.dark)};
    text-decoration: ${props => (props.underline ? 'underline solid' : null)};
  }

  :focus {
    outline: 1px dotted ${props => props.theme.main};
  }
`

export default TextLink
