// NTS: create progress window for downloads so that users can continue with work 

import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from 'store/actions'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import Modal from 'components/Modal'
import Button from 'components/input/Button'
import Loader from 'components/loader/Loader'

class Download extends Component {
  state = { showFormatSelector: true, type: null }

  downloadType = this.props.downloadType

  componentDidMount() {
    this.init()
  }

  componentDidUpdate() {
    if (this.downloadType !== this.props.downloadType) this.init()
  }

  init = () => {
    this.setState({ type: this.getDownloadType(), showFormatSelector: true })
  }

  getDownloadType = () => {
    if (typeof this.downloadType === 'string') {
      if (this.downloadType === 'all') return getSource()
      else return 'photo'
    } else return 'selection'

    function getSource() {
      let url = window.location.toString()
      if (url.includes('/albums/')) return 'album'
      if (url.includes('/search/')) return 'search results'
      if (url.includes('/collections/')) return 'collection'
    }
  }

  selectFormat = event => {
    this.format = event.target.id
    this.setState({ showFormatSelector: false, loading: true })
    this.initiateDownload()
  }

  initiateDownload = async () => {
    const files = this.compileFileList()
    if (files.length > 1) {
      this.zip = new JSZip()
      this.folderName = this.props.folderName.slice(0,60)
      this.counter = 0
    }
    for (const file of files) {
      let xhr = new XMLHttpRequest()
      xhr.open('GET', file.url)
      xhr.responseType = 'blob'
      xhr.onreadystatechange = event => {
        if (event.target.readyState === 4) {
          if (files.length > 1) {
            this.counter += 1
            this.zip.folder(this.folderName).file([`UJDML_${file.name}${this.format === 'web' ? '_small' : ''}.jpg`], event.target.response)
            if (files.length === this.counter) {
              this.zip.generateAsync({ type: 'blob' }).then(newZipFile => {
                saveAs(newZipFile, `${this.folderName}.zip`)
                this.props.closeComponent()
              })
            }
          } else {
            saveAs(event.target.response, `UJDML_${file.name}${this.format === 'web' ? '_small' : ''}.jpg`)
            this.props.closeComponent()
          }
        }
      }
      xhr.send()
    }
  }

  compileFileList = () => {
    let files = []
    if (this.state.type === 'photo')
      files.push(this.createDownloadObject(this.props.currentPhotoCollection.find(photo => photo.id === this.props.downloadType)))
    else
      this.props.currentPhotoCollection.forEach(photo => {
        if (this.state.type === 'selection' && this.props.downloadType.includes(photo.id)) files.push(this.createDownloadObject(photo))
        else if (['album', 'search results', 'collection'].includes(this.state.type)) files.push(this.createDownloadObject(photo))
      })

    return files
  }

  createDownloadObject = file => {
    return {
      name: file.id,
      url: file[this.format === 'print' ? 'printURL' : 'webURL']
    }
  }

  closeComponent = event => {
    if (event.target.id === 'modalBackdrop') this.props.closeComponent()
  }

  render() {
    return (
      <Backdrop onClick={this.closeComponent}>
        {this.state.showFormatSelector ? (
          <Modal title={`Download ${this.state.type}`} width={this.props.smallDevice ? '80%' : '300px'}>
            <FormatSelector>
              <Button id='web' onClick={this.selectFormat}>
                web
              </Button>
              <Button id='print' onClick={this.selectFormat} disabled={this.props.currentUser.anonymous}>
                print
              </Button>
            </FormatSelector>
          </Modal>
        ) : null}
        {this.state.loading ? <Loader /> : null}
      </Backdrop>
    )
  }
}

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`

const FormatSelector = styled.div`
  display: flex;
  justify-content: space-between;
`

const mapStateToProps = state => {
  return {
    currentPhotoCollection: state.browse.currentPhotoCollection,
    smallDevice: state.ui.smallDevice,
    downloadType: state.download.downloadType,
    folderName: state.download.folderName,
    currentUser: state.auth.currentUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeComponent: () => dispatch(actions.download(null, null))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Download)
