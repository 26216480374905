import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setError } from 'store/actions'
import styled from 'styled-components'
import { db, functions } from 'utils/firebase'
import Loader from 'components/loader/Loader'
import Modal from 'components/Modal'
import Input from 'components/Input'
import Button from 'components/Button.js'
import Alert from 'components/Alert'
import BookingCard from 'pages/bookings/BookingCard'
import SortInput from 'pages/bookings/BookingsSort'
import FilterInput from 'pages/bookings/BookingsFilter'
import BookingForm from './bookings/BookingForm'
import sendEmail from 'pages/bookings/sendEmail'
import { collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore'

class Bookings extends Component {
  state = {
    loading: true,
    filters: null,
    reasonDeclined: '',
    bookingBeingDeclined: null,
    reasonForCancellation: '',
    sortBy: 'dateOfEvent',
    sortDirection: 'descending',
    showFilters: false
  }

  componentDidMount() {
    if (this.props.admins) this.getBookings()
  }

  componentDidUpdate() {
    if (this.props.admins && !this.bookingsListener) this.getBookings()
  }

  componentWillUnmount() {
    this.removeBookingsListener()
  }

  getBookings = async () => {
    if (this.props.currentUser.admin) this.addBookingsListener('admin')
    else if (this.props.currentUser.photographer) this.addBookingsListener('photographer')
    else if (this.props.currentUser.videographer) this.addBookingsListener('videographer')
    else this.addBookingsListener('staff')
  }

  addBookingsListener = async user => {
    this.bookingsListener = onSnapshot(
      query(
        collection(db, 'bookings'),
        where(
          user === 'staff' ? 'clientEmail' : 'type',
          user === 'admin' ? 'in' : '==',
          user === 'admin'
            ? this.getAdminNotifications()
            : user === 'photographer'
            ? 'Photography'
            : user === 'videographer'
            ? 'Videography'
            : this.props.currentUser.email
        )
      ),
      bookings => {
        this.bookings = bookings.docs.map(booking => {
          return { ...booking.data(), id: booking.id }
        })
        this.processBookings()
      },
      error => this.props.setError(error, false, 'Failed to load bookings in Bookings component.')
    )
  }

  removeBookingsListener = () => {
    if (this.bookingsListener) this.bookingsListener()
  }

  getAdminNotifications = () => {
    let admin = this.props.admins.find(admin => admin.email === this.props.currentUser.email)
    return Object.keys(admin.notifications).map(option => {
      if (admin.notifications[option]) return `${option.slice(0, 1).toUpperCase()}${option.slice(1)}graphy`
      return null
    })
  }

  processBookings = () => {
    this.setFilters()
    this.applyFilters()
    this.sortBookings()
    if (this.state.loading) this.setState({ loading: false })
    else this.forceUpdate()
  }

  setFilters = () => {
    if (this.sessionIsActive) return
    this.sessionIsActive = true
    let filters = {
      photography: {
        name: 'Photography',
        isApplied: true,
        count: 0
      },
      videography: {
        name: 'Videography',
        isApplied: true,
        count: 0
      },
      pending: {
        name: 'Pending requests',
        isApplied: true,
        count: 0
      },
      completed: {
        name: 'Completed bookings',
        isApplied: false,
        count: 0
      },
      accepted: {
        name: 'Accepted bookings',
        isApplied: true,
        count: 0
      },
      declined: {
        name: 'Declined bookings',
        isApplied: false,
        count: 0
      },
      cancelled: {
        name: 'Cancelled bookings',
        isApplied: false,
        count: 0
      }
    }

    if (this.props.currentUser.admin) {
      if (!this.getAdminNotifications().includes('Photography')) delete filters.photography
      if (!this.getAdminNotifications().includes('Videography')) delete filters.videography
    } else {
      if (this.props.currentUser.photographer && !this.props.currentUser.videographer) delete filters.videography
      if (this.props.currentUser.videographer && !this.props.currentUser.photographer) delete filters.photography
    }

    this.setState({ filters })
  }

  applyFilters = () => {
    const filters = { ...this.state.filters }
    let shouldInclude
    let filterIsApplied

    Object.keys(filters).forEach(filter => {
      filters[filter].count = 0
    })

    this.filteredBookings = this.bookings.filter(booking => {
      shouldInclude = false
      if (booking.type === 'Photography') {
        filters.photography.count++
        shouldInclude = filters.photography.isApplied
      }
      if (booking.type === 'Videography') {
        filters.videography.count++
        shouldInclude = filters.videography.isApplied
      }
      return shouldInclude
    })

    this.filteredBookings = this.filteredBookings.filter(booking => {
      shouldInclude = false
      Object.keys(filters).forEach(filter => {
        filterIsApplied = filters[filter].isApplied
        switch (filter) {
          case 'pending':
            if (['Submitted', 'Updated'].includes(booking.status)) {
              filters[filter].count++
              shouldInclude = filterIsApplied
            }
            break
          case 'completed':
            if (booking.status === 'Completed') {
              filters[filter].count++
              shouldInclude = filterIsApplied
            }
            break
          case 'accepted':
            if (booking.status === 'Accepted') {
              filters[filter].count++
              shouldInclude = filterIsApplied
            }
            break
          case 'declined':
            if (booking.status === 'Declined') {
              filters[filter].count++
              shouldInclude = filterIsApplied
            }
            break
          case 'cancelled':
            if (booking.status === 'Cancelled') {
              filters[filter].count++
              shouldInclude = filterIsApplied
            }
            break
          default:
            break
        }
      })
      return shouldInclude
    })

    this.setState({ filters })
  }

  sortBookings = () => {
    this.filteredBookings.sort((a, b) => {
      let sortUp = this.state.sortDirection === 'descending'

      if (this.state.sortBy === 'name') {
        return a.name.toLowerCase().trim() < b.name.toLowerCase().trim() ? (sortUp ? 1 : -1) : sortUp ? -1 : 1
      } else if (this.state.sortBy === 'dateOfEvent') {
        let dateA = a.dateOfEvent || a.deadline
        let dateB = b.dateOfEvent || b.deadline
        return dateA < dateB ? (sortUp ? 1 : -1) : sortUp ? -1 : 1
      } else return 0
    })
    this.forceUpdate()
  }

  renderBookings = () => {
    return this.filteredBookings.map(booking => {
      return (
        <BookingCard
          booking={booking}
          key={booking.id}
          id={booking.id}
          cancelBooking={this.cancelBookingHandler}
          editBooking={this.editBooking}
          acceptBooking={this.acceptBooking}
          declineBooking={this.declineBookingHandler}
          markAsCompleted={this.markAsCompleted}
        />
      )
    })
  }

  showFilters = () => {
    this.setState({ showFilters: !this.state.showFilters })
  }

  filterClicked = filter => {
    let filters = { ...this.state.filters }
    filters[filter].isApplied = !filters[filter].isApplied
    this.setState({ filters }, () => this.processBookings())
  }

  sortOptionClicked = event => {
    let option = event.target.id.slice(11)
    if (option === this.state.sortBy)
      this.setState({ sortDirection: this.state.sortDirection === 'ascending' ? 'descending' : 'ascending' }, () => this.sortBookings())
    else this.setState({ sortBy: option }, () => this.sortBookings())
  }

  acceptBooking = id => {
    let agent = this.setAgent()
    updateDoc(doc(db, 'bookings', id), {
      status: 'Accepted',
      dateOfAcceptance: new Date(),
      [agent]: this.props.currentUser.fullName,
      [`${agent}Email`]: this.props.currentUser.email
    }).catch(error => this.props.setError(error, false, 'Failed to accept booking in Booking component.'))
    sendEmail({
      type: 'accepted',
      booking: this.findBooking(id),
      agentName: this.props.currentUser.fullName,
      agentEmail: this.props.currentUser.email
    }).catch(error => this.props.setError(error, false, 'Failed to send acceptance email in Booking component.'))
  }

  setAgent = () => {
    if (this.props.currentUser.videographer) return 'videographer'
    else return 'photographer'
  }

  findBooking = id => {
    return this.filteredBookings.find(booking => booking.id === id)
  }

  declineBookingHandler = id => {
    this.setState(
      {
        bookingBeingDeclined: this.findBooking(id)
      },
      () => {
        this.alert = this.reasonDeclinedModal()
        this.forceUpdate()
      }
    )
  }

  reasonDeclinedUpdated = event => {
    this.setState({ reasonDeclined: event.target.value }, () => {
      this.alert = this.reasonDeclinedModal()
      this.forceUpdate()
    })
  }

  reasonDeclinedModal = () => (
    <Modal modalId='reasonDeclinedModal' title='Provide reason' width='500px' backdropClicked={this.reasonDeclinedEscaped}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }}
      >
        <Input
          type='text'
          value={this.state.reasonDeclined}
          changed={this.reasonDeclinedUpdated}
          placeholder='Please provide a reason for declining this booking'
          required
        ></Input>
        <Button onMouseUp={this.declineBooking} width='100px'>
          Submit
        </Button>
      </div>
    </Modal>
  )

  reasonDeclinedEscaped = event => {
    if (event.target.id === 'modalBackdrop') {
      this.alert = null
      this.setState({ bookingBeingDeclined: null, reasonDeclined: '' })
    }
  }

  declineBooking = () => {
    this.alert = null
    let agent = this.setAgent()
    updateDoc(doc(db, 'bookings', this.state.bookingBeingDeclined.id), {
      status: 'Declined',
      dateOfDecline: new Date(),
      [agent]: 'N/A',
      reasonDeclined: this.state.reasonDeclined
    })
      .then(() => {
        this.retries = 0
        this.deleteCalendarItem(this.state.bookingBeingDeclined.calendarId)
        sendEmail({
          type: 'declined',
          booking: this.state.bookingBeingDeclined,
          agentName: this.props.currentUser.fullName,
          agentEmail: this.props.currentUser.email,
          reason: this.state.reasonDeclined
        }).catch(error => this.props.setError(error, false, 'Failed to send decline email in Booking component.'))

        this.setState({ bookingBeingDeclined: null, reasonDeclined: '' })
        this.renderBookings()
      })
      .catch(error => this.props.setError(error, false, 'Failed to decline booking in Booking component.'))
  }

  deleteCalendarItem = calendarId => {
    functions
      .httpsCallable('deleteCalendarItem')({
        calendarId
      })
      .catch(error => {
        this.retries++
        if (this.retries < 40)
          setTimeout(() => {
            this.deleteCalendarItem()
          }, 400)
        else this.props.setError(error, false, 'Failed to delete calndar item in Booking component.')
      })
  }

  cancelBookingHandler = event => {
    this.setState({
      bookingBeingCancelled: this.findBooking(event.target.id.slice(14, event.target.id.length))
    })
    this.alert = (
      <Alert alertType='Cancel booking' width='400px' alertButtonClicked={this.cancelBookingConfirmed} backdropClicked={this.cancelBookingCancelled} />
    )
    this.forceUpdate()
  }

  cancelBookingConfirmed = event => {
    if (event.target.id === 'continue') {
      this.alert = this.reasonCancelledModal()
      this.forceUpdate()
    } else {
      this.alert = null
      this.forceUpdate()
    }
  }

  reasonCancelledUpdated = event => {
    this.setState({ reasonForCancellation: event.target.value }, () => {
      this.alert = this.reasonCancelledModal()
      this.forceUpdate()
    })
  }

  reasonCancelledModal = () => (
    <Modal modalId='reasonCancelledModal' title='Provide reason' width='500px' backdropClicked={this.cancelBookingCancelled}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }}
      >
        <Input
          type='text'
          placeholder='Provide reason for cancellation'
          value={this.state.reasonForCancellation}
          changed={this.reasonCancelledUpdated}
          required
        ></Input>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'
          }}
        >
          <Button onMouseUp={this.cancelBookingCancelled} id='cancelButton' width='100px'>
            Cancel
          </Button>
          <Button onMouseUp={this.cancelBooking} width='100px'>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  )

  cancelBooking = () => {
    this.alert = null
    let booking = this.state.bookingBeingCancelled
    updateDoc(doc(db, 'bookings', booking.id), {
      status: 'Cancelled',
      reasonForCancellation: this.state.reasonForCancellation,
      [this.setAgent()]: 'N/A'
    }).catch(error => this.props.setError(error, false, 'Failed to cancel booking in Booking component.'))
    this.retries = 0
    this.deleteCalendarItem(booking.calendarId)
    sendEmail({
      type: 'cancelled',
      booking,
      reason: this.state.reasonForCancellation
    }).catch(error => this.props.setError(error, false, 'Failed to send cancellation email in Booking component.'))
  }

  cancelBookingCancelled = event => {
    if (event.target.id === 'modalBackdrop' || event.target.id === 'cancelButton') {
      this.alert = null
      this.forceUpdate()
    }
  }

  markAsCompleted = bookingId => {
    updateDoc(doc(db, 'bookings', bookingId), {
      status: 'Completed',
      completedBy: this.props.currentUser.email,
      completedOn: new Date()
    }).catch(error => this.props.setError(error, false, 'Failed to mark booking as completed in Booking component.'))
  }

  editBooking = event => {
    let bookingId = event.target.id.slice(12, event.target.id.length)
    let booking = this.findBooking(bookingId)
    this.bookingForm = <BookingForm type={booking.type} removeBookingForm={this.removeBookingForm} existingBooking={{ ...booking, id: bookingId }} />
    this.forceUpdate()
  }

  createBookingHandler = () => {
    this.alert = <Alert alertType='Create a booking' alertButtonClicked={this.createBookingForm} backdropClicked={this.removeAlert} escapable />
    this.forceUpdate()
  }

  createBookingForm = event => {
    this.bookingForm = <BookingForm type={event.target.id === 'photographer' ? 'Photography' : 'Videography'} removeBookingForm={this.removeBookingForm} />
    this.alert = null
    this.forceUpdate()
  }

  removeBookingForm = () => {
    this.bookingForm = null
    this.forceUpdate()
  }

  removeAlert = event => {
    if (event.target.id === 'modalBackdrop') {
      this.alert = null
      this.forceUpdate()
    }
  }

  render() {
    return this.state.loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        {this.bookings.length > 0 ? (
          this.filteredBookings.length > 0 ? (
            <StyledBookings id='bookingsDiv' hidden={this.bookingForm} smallDevice={this.props.smallDevice}>
              {this.renderBookings()}
            </StyledBookings>
          ) : (
            <NoBookings>Please select at least one filter.</NoBookings>
          )
        ) : (
          <NoBookings>You have not yet made any bookings.</NoBookings>
        )}

        <Footer>
          <CreateBooking smallDevice={this.props.smallDevice} onClick={this.createBookingHandler} disabled={this.state.loading}>
            Create booking
          </CreateBooking>
          <FilterInput
            filters={this.state.filters}
            filterClicked={this.filterClicked}
            inputMethod={this.props.inputMethod}
            showFilters={this.showFilters}
            disabled={this.state.loading}
            totalBookings={this.bookings ? this.bookings.length : 0}
          ></FilterInput>
          <SortInput
            sortBy={this.state.sortBy}
            sortDirection={this.state.sortDirection}
            sortOptionClicked={this.sortOptionClicked}
            inputMethod={this.props.inputMethod}
            showFilters={this.state.showFilters}
            disabled={this.state.loading}
          />
        </Footer>
        {this.alert}
        {this.bookingForm}
      </React.Fragment>
    )
  }
}

const StyledBookings = styled.div`
  position: relative;
  grid-area: main;
  box-sizing: border-box;
  width: ${props => (props.smallDevice ? '100%' : '800px')};
  height: auto;
  justify-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 26px;
  overflow-y: auto;
`

const NoBookings = styled.div`
  position: absolute;
  top: 30%;
  width: 100%;
  color: ${props => props.theme.dark};
  display: flex;
  justify-content: center;
`

const Footer = styled.div`
  grid-area: footer;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.light};
  color: ${props => props.theme.dark};
`

const CreateBooking = styled.div`
  position: absolute;
  bottom: 10px;
  right: ${props => (props.smallDevice ? '15px' : '20px')};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: ${props => (props.smallDevice ? '6px 6px' : '6px 10px')};
  border: ${props => (props.smallDevice ? '2px' : '3px')} solid ${props => props.theme[props.disabled ? 'main' : 'dark']};
  border-radius: 13px;
  font-size: ${props => (props.smallDevice ? '14px' : '18px')};
  color: ${props => props.theme[props.disabled ? 'main' : 'dark']};
  font-weight: bold;
  :hover {
    border-width: ${props => (props.disabled ? null : '4px')};
    padding: ${props => (props.disabled ? null : '5px 9px')};
    cursor: ${props => (props.disabled ? 'select' : 'pointer')};
  }
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    inputMethod: state.ui.inputMethod,
    smallDevice: state.ui.smallDevice,
    admins: state.config.admins
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setError: (error, breaking, customMessage) => dispatch(setError(error, breaking, customMessage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookings)
