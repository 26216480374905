import React, { Component } from 'react'
import styled from 'styled-components'
import Input from 'components/Input'

class DateInput extends Component {
  state = {
    day: 0,
    month: 0,
    year: 0
  }

  componentDidMount() {
    let date = undefined
    this.props.date ? (date = this.props.date) : (date = new Date())
    this.currentYear = date.getFullYear()
    this.currentMonth = date.getMonth()
    this.currentDate = date.getDate()

    this.createDaysArray()
    this.createMonthsArray()
    this.createYearsArray()

    this.setState(
      {
        year: this.currentYear,
        month: this.currentMonth,
        day: this.currentDate
      },
      () => {
        this.updateDate()
      }
    )
  }

  createDaysArray = () => {
    let numberOfDaysInMonth = 0
    switch (this.state.month) {
      case 0:
        numberOfDaysInMonth = 31
        break
      case 1:
        if (
          (this.state.year / 4) % 1 === 0 &&
          (this.state.year / 100) % 1 !== 0
        ) {
          numberOfDaysInMonth = 29
        } else if (
          (this.state.year / 4) % 1 === 0 &&
          (this.state.year / 100) % 1 === 0 &&
          (this.state.year / 400) % 1 === 0
        ) {
          numberOfDaysInMonth = 29
        } else numberOfDaysInMonth = 28
        break
      case 2:
        numberOfDaysInMonth = 31
        break
      case 3:
        numberOfDaysInMonth = 30
        break
      case 4:
        numberOfDaysInMonth = 31
        break
      case 5:
        numberOfDaysInMonth = 30
        break
      case 6:
        numberOfDaysInMonth = 31
        break
      case 7:
        numberOfDaysInMonth = 31
        break
      case 8:
        numberOfDaysInMonth = 30
        break
      case 9:
        numberOfDaysInMonth = 31
        break
      case 10:
        numberOfDaysInMonth = 30
        break
      case 11:
        numberOfDaysInMonth = 31
        break
      default:
        break
    }

    this.daysArray = []
    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      this.daysArray.push(
        <option key={i} value={i}>
          {i}
        </option>
      )
    }
    this.forceUpdate()
  }

  createMonthsArray = () => {
    this.monthsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(mm => {
      let monthString = ''
      switch (mm) {
        case 0:
          monthString = 'JAN'
          break
        case 1:
          monthString = 'FEB'
          break
        case 2:
          monthString = 'MAR'
          break
        case 3:
          monthString = 'APR'
          break
        case 4:
          monthString = 'MAY'
          break
        case 5:
          monthString = 'JUN'
          break
        case 6:
          monthString = 'JUL'
          break
        case 7:
          monthString = 'AUG'
          break
        case 8:
          monthString = 'SEP'
          break
        case 9:
          monthString = 'OCT'
          break
        case 10:
          monthString = 'NOV'
          break
        case 11:
          monthString = 'DEC'
          break
        default:
          break
      }
      return (
        <option key={mm} value={mm}>
          {monthString}
        </option>
      )
    })
  }

  createYearsArray = () => {
    this.yearsArray = []
    for (let i = this.currentYear - 1; i < this.currentYear + 5; i++) {
      this.yearsArray.push(
        <option key={i} value={i}>
          {i}
        </option>
      )
    }
  }

  dayChangedHandler = event => {
    this.setState({ day: parseInt(event.target.value) }, () => {
      this.updateDate()
    })
  }

  monthChangedHandler = event => {
    this.setState({ month: parseInt(event.target.value) }, () => {
      this.createDaysArray()
      this.updateDate()
    })
  }

  yearChangedHandler = event => {
    this.setState({ year: parseInt(event.target.value) }, () => {
      this.createDaysArray()
      this.updateDate()
    })
  }

  updateDate = () => {
    this.props.changed({
      target: {
        id: this.props.id,
        value: new Date(this.state.year, this.state.month, this.state.day)
      }
    })
  }

  render() {
    return (
      <StyledDateInput onFocus={this.props.onFocus} id={this.props.id}>
        <Input
          type="select"
          id="dayInput"
          inputLabel="Day"
          width="45px"
          height="28px"
          margin={this.props.margin || '14px 10px 0px 10px'}
          fontSize={this.props.fontSize || '14px'}
          value={this.state.day}
          changed={this.dayChangedHandler}
          required
        >
          {this.daysArray}
        </Input>
        <Input
          type="select"
          id="monthInput"
          inputLabel="Month"
          width="70px"
          height="28px"
          margin={this.props.margin || '14px 10px 0px 10px'}
          fontSize={this.props.fontSize || '14px'}
          value={this.state.month}
          changed={this.monthChangedHandler}
          required
        >
          {this.monthsArray}
        </Input>
        <Input
          type="select"
          id="yearInput"
          inputLabel="Year"
          width="120px"
          height="28px"
          margin={this.props.margin || '14px 10px 0px 10px'}
          fontSize={this.props.fontSize || '14px'}
          value={this.state.year}
          changed={this.yearChangedHandler}
          required
        >
          {this.yearsArray}
        </Input>
      </StyledDateInput>
    )
  }
}

const StyledDateInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 23%;
  margin-top: -14px;
`

export default DateInput
