import * as actionTypes from 'store/actions/actionTypes'

export const setExistingBooking = booking => {
  return {
    type: actionTypes.SET_EXISTING_BOOKING,
    booking
  }
}

export const setFiles = (id, files) => {
  return {
    type: actionTypes.SET_FILES,
    id,
    files
  }
}

export const clearBookingFiles = () => {
  return {
    type: actionTypes.CLEAR_BOOKING_FILES
  }
}
