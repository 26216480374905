import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Input from 'components/Input'
import Button from 'components/Button'

class Share extends Component {
  state = {
    emails: ''
  }

  componentDidMount() {
    this.createTitle()
  }

  pathname = window.location.pathname
  componentDidUpdate() {
    if (this.pathname !== window.location.pathname) {
      this.pathname = window.location.pathname
      this.createTitle()
    }
  }

  createTitle = () => {
    this.pathname.includes('albums')
      ? (this.title = 'Share album')
      : this.pathname.includes('photos')
      ? (this.title = 'Share photo')
      : (this.title = 'Share collection')
    this.forceUpdate()
  }

  copyUrlToClipboard = () => {
    document.getElementById('url').select()
    document.execCommand('copy')
    document.getElementById('url').setSelectionRange(0, 0)
    this.setState({ copiedToClipboard: true })
    setTimeout(() => {
      this.setState({ copiedToClipboard: false })
    }, 2000)
  }

  hideSharingWindow = (event) => {
    event.stopPropagation()
    this.sharingWindow = null
    this.forceUpdate()
  }

  render() {
    return (
      <React.Fragment>
        <Backdrop id='backdrop' onClick={this.props.hideShare} />
        <Container id='container' onClick={this.props.hideShare}>
          <SharingWindow id='sharingWindow' smallDevice={this.props.smallDevice}>
            <Title id='title'>{this.title}</Title>
            <Body id='shareBody'>
              <CopyLink id='copyLink'>
                <InputLine id='shareInputLine'>
                  Anybody with this link van view and download the shared
                  photos.
                </InputLine>
                <InputLine id='shareInputLine'>
                  <Input
                    type="text"
                    id="url"
                    value={window.location.href}
                    width={this.props.smallDevice ? '60%' : '400px'}
                    readOnly
                    selectable
                  />
                  <Button
                    clicked={this.copyUrlToClipboard}
                    id='shareButton'
                    width={this.props.smallDevice ? '90px' : '112px'}
                    touchInput={this.props.inputMethod === 'touch'}
                  >
                    Copy link
                  </Button>
                </InputLine>
                <ConfirmationText hidden={!this.state.copiedToClipboard}>
                  Text copied to clipboard
                </ConfirmationText>
              </CopyLink>
            </Body>
          </SharingWindow>
        </Container>
      </React.Fragment>
    )
  }
}

const Backdrop = styled.div`
  position: fixed;
  top: 48px;
  left: 0px;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 48px);
  background-color: black;
  opacity: 0.1;
  z-index: 1;
`

const Container = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SharingWindow = styled.div`
  position: absolute;
  justify-self: center;
  align-self: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${props => (props.smallDevice ? '90%' : '600px')};
  height: ${props => (props.smallDevice ? 'auto' : '170px')};
  font-size: ${props => (props.smallDevice ? '12px' : '14px')};
  background-color: ${props => props.theme.bg};
  border: 2px solid ${props => props.theme.dark};
  border-radius: 5px;
  overflow: hidden;
  z-index: 2;
`

const Title = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px 10px;
  background-color: ${props => props.theme.dark};
  color: ${props => props.theme.bg};
  font-size: 16px;
`

const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 40px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 20px;
  color: ${props => props.theme.dark};
`

const CopyLink = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const InputLine = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

const ConfirmationText = styled.div`
  display: block;
  opacity: ${props => (props.hidden ? 0 : 1)};
  transition: all 1s linear;
  font-size: 12px;
  padding-left: 15px;
`

const mapStateToProps = state => {
  return {
    smallDevice: state.ui.smallDevice,
    inputMethod: state.ui.inputMethod
  }
}

export default connect(mapStateToProps)(Share)
