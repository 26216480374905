import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from 'store/actions'

class DropArea extends Component {
  state = {
    dropAreaColor: null
  }

  dragOverHandler = event => {
    event.preventDefault()
    this.setState({ dropAreaColor: '#bbe6c0' })
  }

  dragLeaveHandler = event => {
    event.preventDefault()
    this.setState({ dropAreaColor: null })
  }

  dropHandler = event => {
    event.preventDefault()
    this.setState({ dropAreaColor: null })
    this.files = []
    let sizeLimitExceeded = false
    let numberOfFiles = event.dataTransfer.files.length
    let imagesChecked = 0
    Object.values(event.dataTransfer.files).forEach(file => {
      if (file.type === 'image/jpeg') {
        let img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
          if (img.width > 15000 || img.height > 15000) {
            sizeLimitExceeded = true
          } else {
            this.files.push(file)
          }
          imagesChecked += 1
          if (imagesChecked === numberOfFiles) {
            if (sizeLimitExceeded) {
              this.props.sizeLimitExceeded()
            }
            this.props.addFiles(this.files)
          }
        }
      }
    })
  }

  render() {
    return (
      <StyledDropArea
        onDragOver={this.dragOverHandler}
        onDragLeave={this.dragLeaveHandler}
        onDrop={this.dropHandler}
        dropAreaColor={this.state.dropAreaColor}
      >
        {this.state.dropAreaColor
          ? 'Drop to add files'
          : "Drop files here or use the 'Add'-button"}
      </StyledDropArea>
    )
  }
}

const StyledDropArea = styled.div`
  grid-area: explorer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${props =>
    props.dropAreaColor ? '#bbe6c0' : props.theme.bg};
  color: ${props => props.theme.dark};
`

const mapDispatchToProps = dispatch => {
  return {
    addFiles: files => dispatch(actions.addFiles(files))
  }
}

export default connect(null, mapDispatchToProps)(DropArea)
