import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from 'store/actions'
import Modal from 'components/Modal'
import Text from 'components/Text'
import Button from 'components/Button'

class VideoPoliciesModal extends Component {
  componentDidMount() {
    if (document.getElementById('policiesModal')) {
      document.getElementById('policiesModal').scrollTo(0, 0)
    }
  }

  backdropClicked = event => {
    if (event.target.id === 'modalBackdrop') {
      this.props.showVideoPoliciesModal()
    }
  }

  buttonClicked = () => {
    this.props.showVideoPoliciesModal()
  }

  render() {
    return (
      <Modal
        backdropClicked={this.backdropClicked}
        title="Service Policies"
        width="80%"
        height="70%"
        scroll="auto"
        modalId="policiesModal"
        smallDevice={this.props.smallDevice}
      >
        <Policies>
          <Text>
            Videography Services exist to provide high quality videographic
            support to all faculties and departments within the University. We
            strive to be professional, punctual and to adhere to strict
            turnaround times so that our clients receive quality video material
            within a limited waiting period in order to promote their content on
            any internal or external platform. To maintain an ongoing and
            acceptable quality of service, some policies are in place to ensure
            that a mutual understanding exists between ourselves and our
            clients.
          </Text>
          <Break />
          <Table smallDevice={this.props.smallDevice}>
            <tbody>
              <Row>
                <Number>1.</Number>
                <Policy colSpan="3" fontWeight="bold" alignLeft>
                  THE BOOKING PROCESS
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                <Number />
                <Policy colSpan="3">
                  No videos will be taken unless an online booking form has been
                  completed by the client and accepted by a videographer.
                </Policy>
              </Row>
              <BlankRow />

              <BlankRow />
              <BlankRow />
              <Row>
                <Number>2.</Number>
                <Policy colSpan="3" fontWeight="bold" alignLeft>
                  POLICIES
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>2.1</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  Videography Services operate on a strict 'first come, first
                  served' basis. It is the responsibility of the client to
                  ensure that booking requests are made as soon as events have
                  been planned in order to maximise the odds of a videographer
                  being available.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>2.2</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  For event bookings, we only produce news packaged videos of
                  less than five minutes to be used on the Web.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>2.3</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  Clients have to book the videographer an hour before the event
                  starts for setup purposes.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>2.4</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  Live streaming will be at Strategic Communications'
                  discretion.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>2.5</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  Videos of events that do not directly serve the purpose of
                  marketing the University or its activities will only be taken
                  at the discretion of the videographer and Strategic
                  Communications.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                <Number>3.</Number>
                <Policy colSpan="3" fontWeight="bold" alignLeft>
                  ACCESS TO VIDEOS
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                <Number />
                <Policy colSpan="3">
                  Upon the completion of a project, the video will be posted on
                  YouTube and/or shared with the client directly. The turnaround
                  time for the completion of any video project is 72 hours.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                <Number>4.</Number>
                <Policy colSpan="3" fontWeight="bold" alignLeft>
                  STORAGE OF VIDEOS
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                <Number />
                <Policy colSpan="3">
                  Videos will be stored on YouTube. Under no circumstances can
                  any videos be sold to any party without the written consent of
                  Videography Services.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                <Number>5.</Number>
                <Policy colSpan="3" fontWeight="bold" alignLeft>
                  COST OF SERVICE
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                <Number />
                <Policy colSpan="3">
                  Our services are free of charge to all UJ staff members as
                  long as their events take place on UJ property and during
                  business hours (08:00 to 16:30). Standard AA-rates will apply
                  for transport to off-campus events. For after hours events, an
                  overtime fee will be applicable and will be charged as 'time
                  away from home', thus including travel time to and from the
                  event.
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                <Number />
                <Policy colSpan="3">Overtime rates are as follows:</Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>5.1</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  Monday to Thursday:{' '}
                  <Text fontWeight="bold">R220 per hour</Text>
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>5.2</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  Fridays, Saturdays and eves of public holidays:{' '}
                  <Text fontWeight="bold">R320 per hour</Text>
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>5.3</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  Sundays and public holidays:{' '}
                  <Text fontWeight="bold">R420 per hour</Text>
                </Policy>
              </Row>
            </tbody>
          </Table>
          <Break />
          <Break />
          <ButtonDiv>
            <Button type="button" onMouseUp={this.buttonClicked} width="100px">
              OK
            </Button>
          </ButtonDiv>
        </Policies>
      </Modal>
    )
  }
}

const Policies = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: ${props => (props.smallDevice ? '10px 6px' : '10px 20px')};
  font-size: 20px;
  text-align: justify;
`

const Break = styled.br`
  margin: 14px 0px;
`

const Table = styled.table`
  margin: ${props => (props.smallDevice ? '4px 4px' : '4px 20px')};
  padding-right: 20px;
  width: 100%;
  font-size: 18px;
`

const Row = styled.tr`
  height: 30px;
`

const BlankRow = styled.tr`
  height: 12px;
`

const Number = styled.td`
  width: 50px;
  text-align: left;
  vertical-align: top;
`

const Policy = styled.td`
  text-align: ${props => (props.alignLeft ? 'left' : 'justify')};
  vertical-align: top;
  font-weight: ${props => props.fontWeight || null};
`

const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const mapStateToProps = state => {
  return {
    smallDevice: state.ui.smallDevice
  }
}
const mapDispatchToProps = dispatch => {
  return {
    showVideoPoliciesModal: () => dispatch(actions.showVideoPoliciesModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPoliciesModal)
