import * as actionTypes from 'store/actions/actionTypes'
import * as actions from 'store/actions'
import { db } from 'utils/firebase'
import { limit, orderBy, onSnapshot, startAfter, collection, getDocs, query } from 'firebase/firestore'
import store from 'store/store'

var lastVisible = null
var albumsArray
var allAlbumsRetrieved = false
var _limit = 20

export const getAlbums = () => {
  return dispatch => {
    onSnapshot(
      query(collection(db, 'albums'), orderBy('date', 'desc'), limit(_limit)),
      albums => {
        albumsArray = []
        for (const album of albums.docs) {
          if (album.data().photosConverted === album.data().totalPhotos) {
            lastVisible = album
            albumsArray.push({
              id: album.id,
              name: album.data().name,
              coverURL: album.data().coverURL
            })
          }
        }
        allAlbumsRetrieved = albums.size < _limit
        return dispatch(storeAlbums(albumsArray, lastVisible, allAlbumsRetrieved))
      },
      error => store.dispatch(actions.setError(error, true, 'Failed to get albums from database in the Home component.'))
    )
  }
}

export const showMore = () => {
  return dispatch => {
    if (allAlbumsRetrieved) return
    getDocs(query(collection(db, 'albums'), orderBy('date', 'desc'), startAfter(lastVisible), limit(_limit)))
      .then(albums => {
        for (const album of albums.docs) {
          if (album.data().photosConverted === album.data().totalPhotos) {
            lastVisible = album
            albumsArray.push({
              id: album.id,
              name: album.data().name,
              coverURL: album.data().coverURL
            })
          }
        }
        allAlbumsRetrieved = albums.size < _limit
        return dispatch(storeAlbums(albumsArray, lastVisible, allAlbumsRetrieved))
      })
      .catch(error => store.dispatch(actions.setError(error, false, 'Failed to show more albums in the Home component.')))
  }
}

export const storeAlbums = (albumsArray, lastVisible, allAlbumsRetrieved) => {
  return {
    type: actionTypes.STORE_ALBUMS,
    albumsArray,
    lastVisible,
    allAlbumsRetrieved
  }
}

export const saveScrollPosition = scrollPosition => {
  return {
    type: actionTypes.SAVE_SCROLL_POSITION,
    scrollPosition
  }
}
