import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import * as actions from 'store/actions'
import AlbumCard from 'components/AlbumCard'
import Loader from 'components/loader/Loader'

class Home extends Component {
  state = {
    loading: true
  }

  restoreScrollPositionOnNextLoad = true

  componentDidMount() {
    if (!this.props.albums) this.props.getAlbums()
    else this.populateAlbums()
  }

  albums = this.props.albums
  componentDidUpdate() {
    if (this.albums !== this.props.albums) {
      this.albums = this.props.albums
      this.populateAlbums()
    }
  }

  componentWillUnmount() {
    if (document.getElementById('explorer')) {
      this.props.saveScrollPosition(document.getElementById('explorer').scrollTop)
      this.restoreScrollPositionOnNextLoad = true
    }
  }

  scrollListener = event => {
    if (this.delayScrollListener) return
    const explorer = event.target
    if (Math.abs(explorer.scrollHeight - explorer.scrollTop - explorer.offsetHeight) <= 1) {
      this.delayScrollListener = true
      this.props.showMore()
      setTimeout(() => {
        this.delayScrollListener = false
      }, 200)
    }
  }

  populateAlbums = () => {
    this.recentAlbums = this.albums.map(album => {
      return (
        <Link to={`/albums/${album.id}`} key={album.id}>
          <AlbumCard albumName={album.name} key={album.id} id={album.id} src={album.coverURL} alt={album.name} inputMethod={this.props.inputMethod} />
        </Link>
      )
    })
    if (this.state.loading) {
      this.setState({ loading: false })
    } else {
      this.forceUpdate()
    }
  }

  restoreScrollPosition = () => {
    if (this.restoreScrollPositionOnNextLoad) {
      document.getElementById('explorer').scrollTo(0, this.props.scrollPosition)
      this.restoreScrollPositionOnNextLoad = false
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    } else {
      return (
        <React.Fragment>
          <StyledHome smallDevice={this.props.smallDevice}>
            <Title smallDevice={this.props.smallDevice}>Recent albums</Title>
            <Explorer smallDevice={this.props.smallDevice} id='explorer' onLoad={this.restoreScrollPosition} onScroll={this.scrollListener}>
              {this.recentAlbums}
            </Explorer>
            {this.alert}
          </StyledHome>
          <Footer smallDevice={this.props.smallDevice}>
            <FooterTab justify='left' smallDevice={this.props.smallDevice}>
              <span>Created by Strategic Communications</span>
            </FooterTab>

            <FooterTab justify='center' smallDevice={this.props.smallDevice}>
              <span>
                &copy;&nbsp;{new Date().getFullYear()}
                {this.props.smallDevice ? <br /> : <span>&nbsp;</span>}University of Johannesburg
              </span>
            </FooterTab>
            <FooterTab justify='right' smallDevice={this.props.smallDevice}>
              <a href={`mailto:${this.props.config.webAdmin && this.props.config.webAdmin[0].email}`}>Contact the webmaster</a>
            </FooterTab>
          </Footer>
        </React.Fragment>
      )
    }
  }
}

const StyledHome = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 100%;
  grid-area: main;
`

const Title = styled.div`
  align-self: flex-start;
  margin-top: ${props => (props.smallDevice ? '6px' : '0px')};
  margin-bottom: 20px;
  font-size: ${props => (props.smallDevice ? '20px' : '26px')};
  color: ${props => props.theme.dark};
`

const Explorer = styled.div`
  display: grid;
  box-sizing: border-box;
  height: calc(100% - 60px);
  max-height: 100%;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-around;
  grid-gap: 30px;
  overflow-y: scroll;
  background-color: ${props => props.theme.bg};
`

const Footer = styled.div`
  grid-area: footer;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: ${props => (props.smallDevice ? '0px 10px' : '0px 40px')};
  font-size: ${props => (props.smallDevice ? '10px' : '14px')};
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.light};
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  a {
    text-decoration: underline;
  }
`

const FooterTab = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: ${props => props.justify};
  text-align: ${props => (props.justify === 'center' ? 'center' : null)};
  align-items: center;
  height: 100%;
  min-width: 33.3333%;
`
const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    smallDevice: state.ui.smallDevice,
    inputMethod: state.ui.inputMethod,
    albums: state.home.albums,
    allAlbumsRetrieved: state.home.allAlbumsRetrieved,
    scrollPosition: state.home.scrollPosition,
    config: state.config
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAlbums: () => dispatch(actions.getAlbums()),
    showMore: () => dispatch(actions.showMore()),
    saveScrollPosition: scrollPosition => dispatch(actions.saveScrollPosition(scrollPosition)),
    clearSearchQuery: () => dispatch(actions.search(''))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
