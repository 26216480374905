import React from 'react'
import styled from 'styled-components'
import Thumbnail from 'components/Thumbnail'
import AutoCompleteInput from 'components/AutoCompleteInput'

const UploadPhotoCard = props => {
  return (
    <StyledPhotoCard>
      <Overlay onClick={props.albumCoverSelected} id={`${props.fileName}?albumCover`} albumCover={props.albumCover}>
        <Toolbar id='toolbar'>
          <DeleteIcon onClick={props.removePhoto}>
            <i className='material-icons' id={props.fileName}>
              delete
            </i>
          </DeleteIcon>
          <AutoCompleteInput
            id={`tagInput-${props.alt}`}
            optionsArray={props.tagOptions || <option />}
            placeholder='tags'
            width='100%'
            margin='0px 0px 10px 0px'
            addValueToAutoCompleteOptions={props.addValueToAutoCompleteOptions}
            multiple={true}
          />
        </Toolbar>
      </Overlay>
      <Thumbnail src={props.src} alt={props.alt} id={props.alt} />
    </StyledPhotoCard>
  )
}

const StyledPhotoCard = styled.div`
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 300px;
  width: 300px;
  padding: ${props => (props.albumCover ? '17px 12px 57px 12px' : '20px 15px 60px 15px')};
  color: ${props => props.theme.dark};
  background-color: ${props => props.theme.bg};
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: ${props => (props.albumCover ? '1px 1px' : '4px 4px')};
  border: ${props => (props.albumCover ? '4px' : '1px')} solid ${props => (props.albumCover ? props.theme.dark : props.theme.main)};
  border-radius: 5px;
  opacity: 1;

  :hover {
    padding: ${props => (props.albumCover ? '1px 1px' : '3px 3px')};
    border: ${props => (props.albumCover ? 'initial' : '2px solid')} ${props => props.theme.dark};
    color: ${props => props.theme.dark};
  }
`

const Toolbar = styled.div`
  width: 100%;
  height: 50px;
  padding-left: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 5;
`

const DeleteIcon = styled.div`
  margin: 6px 5px 0px 0px;
  font-size: 32px;
  color: ${props => props.theme.dark};

  :hover {
    cursor: pointer;
    margin: 6px 4px 0px -1px;
    font-size: 34px;
  }
`

export default UploadPhotoCard
