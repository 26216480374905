import React from 'react'
import styled from 'styled-components'

const Photo = props => {
  return (
    <React.Fragment>
      <StyledPhoto
        src={props.src}
        alt={props.alt}
        draggable={false}
        id={props.id}
        onLoad={() => positionOverlay(props.id, props.onLoad)}
        onClick={props.onClick}
        clickable={props.clickable}
      />
      <Overlay name={`overlay-${props.id}`} id={props.id} />
    </React.Fragment>
  )
}

function positionOverlay(id, onLoad) {
  if (onLoad) onLoad()
  let overlay = document.getElementsByName(`overlay-${id}`)[0]
  let img = document.getElementById(id)
  overlay.style.height = `${img.clientHeight}px`
  overlay.style.width = `${img.clientWidth}px`
}

const Overlay = styled.div`
  position: absolute;
`

const StyledPhoto = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: scale-down;

  :hover {
    cursor: ${props => props.clickable ? 'pointer' : 'default'};
  }
`

export default Photo
