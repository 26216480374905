import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Input, { getInputValue } from 'components/NewInput'
import Text from 'components/Text'
import * as actions from 'store/actions'
import ujLogo from 'pages/layout/ujLogo.png'

class Header extends Component {
  visitorsLandingPage = '/'
  componentDidMount() {
    this.determineVisitorsLandingPage()
    this.createMenu()
  }

  currentUser = null
  authModalShowing = this.props.authModalShowing
  currentPage = window.location.pathname
  componentDidUpdate() {
    if (this.props.currentUser !== this.currentUser) {
      this.currentUser = this.props.currentUser
      this.createMenu()
    }
    if (this.authModalShowing !== this.props.authModalShowing) {
      this.authModalShowing = this.props.authModalShowing
      this.forceUpdate()
    }
    if (window.location.pathname === '/' && window.location.pathname !== this.currentPage) {
      this.currentPage = window.location.pathname
      this.createMenu()
    }
    if (this.props.searchKeys && !this.searchKeyOptions) this.renderSearchKeyOptions()
  }

  menu = null
  createMenu = () => {
    if (this.props.currentUser && this.props.currentUser.staff) {
      this.menu = (
        <Menu>
          <MenuItem onClick={this.tabClicked} id='bookings'>
            <Text onClick={this.tabClicked}>Bookings</Text>
          </MenuItem>
          <MenuItem onClick={this.tabClicked} id='collections'>
            <Text onClick={this.tabClicked}>Collections</Text>
          </MenuItem>
          <MenuItem onClick={this.tabClicked} id='profile'>
            <Text onClick={this.tabClicked}>My Profile</Text>
          </MenuItem>
          {this.props.currentUser.admin ? (
            <MenuItem onClick={this.tabClicked} id='admin'>
              <Text onClick={this.tabClicked}>Admin</Text>
            </MenuItem>
          ) : null}
          {this.props.currentUser.photographer ? (
            <MenuItem onClick={this.tabClicked} id='upload'>
              <Text onClick={this.tabClicked}>Uploads</Text>
            </MenuItem>
          ) : null}
        </Menu>
      )
    } else this.menu = null
    this.forceUpdate()
  }

  searchQueryEnteredHandler = event => {
    if (event) event.preventDefault()
    // this.props.search(null)
    this.props.history.push(`/search/${getInputValue('searchbar')}`)
  }

  tabClicked = event => {
    this.props.history.push(`/${event.target.id ? event.target.id : event.target.parentElement.id}`)
    this.currentPage = window.location.pathname
    this.createMenu()
  }

  determineVisitorsLandingPage = () => {
    if (window.location.pathname.includes('/photos/') && !window.location.pathname.match(/(_\d\d\d)$/)) this.visitorsLandingPage = window.location.pathname
    else if (window.location.pathname.includes('/photos/'))
      this.visitorsLandingPage = `/albums/${window.location.pathname.slice(8, window.location.pathname.length - 4)}`
    else if (window.location.pathname.includes('/albums/')) this.visitorsLandingPage = window.location.pathname
    else if (window.location.pathname.includes('/collections/')) this.visitorsLandingPage = window.location.pathname
    else this.visitorsLandingPage = '/'
  }

  showAuthModal = () => {
    this.props.showAuthModal(true)
  }

  getSearchKeys = () => this.props.searchKeys ? null : this.props.getSearchKeys()

  searchKeyOptions = null
  renderSearchKeyOptions = () => {
    if (!this.props.searchKeys) return
    let searchKeys = []
    for (const keyArray of Object.values(this.props.searchKeys)) {
      searchKeys = searchKeys.concat(keyArray)
    }
    this.searchKeyOptions = searchKeys
    this.forceUpdate()
  }

  render() {
    return (
      <React.Fragment>
        <StyledHeader>
          <Link to={this.props.currentUser && this.props.currentUser.staff ? '/' : this.visitorsLandingPage}>
            <Title>
              <Logo>
                <img style={{ width: '48px', height: '48px' }} src={ujLogo} alt='' />
              </Logo>
              <Name>
                <Text>University of Johannesburg</Text>
                <Text>Digital Media Library</Text>
              </Name>
            </Title>
          </Link>

          {this.props.currentUser && this.props.currentUser.staff ? (
            <SearchBar>
              <form id='searchQuery' onSubmit={this.searchQueryEnteredHandler}>
                <Input
                  id='searchbar'
                  type='autocomplete'
                  placeholder='search'
                  width='300px'
                  onFocus={this.getSearchKeys}
                  value={getInputValue('searchbar') || ''}
                  optionsArray={this.searchKeyOptions}
                  submitOnEnter={this.searchQueryEnteredHandler}
                />
              </form>
            </SearchBar>
          ) : null}
          {this.props.currentUser && this.props.currentUser.anonymous && !this.props.authModalShowing ? (
            <StaffSignIn onClick={this.showAuthModal}>Staff Login</StaffSignIn>
          ) : null}
          {this.menu}
        </StyledHeader>
        {this.authModal}
      </React.Fragment>
    )
  }
}

const StyledHeader = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.light};
  border-bottom: 1px solid ${props => props.theme.main};
  color: ${props => props.theme.dark};
`

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;

  :hover {
    cursor: pointer;
  }
`

const Logo = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 4px;
`

const Name = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`

const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 10px;

  #searchQuery {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
  }
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  font-weight: bold;
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${props => props.theme.dark};
  height: 100%;
  min-width: 100px;
  background-color: ${props => (window.location.pathname.includes(props.id) ? props.theme.main : props.theme.light)};
  :hover {
    background-color: ${props => props.theme.main};
    cursor: pointer;
  }
`

const StaffSignIn = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 30px 10px 10px;

  :hover {
    font-size: 17px;
    padding-right: 28px;
    cursor: pointer;
  }
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    showMenu: state.ui.showMenu,
    authModalShowing: state.ui.showAuthModal,
    searchKeys: state.search.searchKeys
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showMenu: () => dispatch(actions.showMenu()),
    showAuthModal: value => dispatch(actions.showAuthModal(value)),
    search: searchQuery => dispatch(actions.search(searchQuery)),
    getSearchKeys: () => dispatch(actions.getSearchKeys())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))

/*

Update styling (12 Jul 2021)

import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Input, { getInputValue } from 'components/NewInput'
import Text from 'components/Text'
import * as actions from 'store/actions'
import ujLogo from 'pages/layout/ujLogo.png'

class Header extends Component {
  visitorsLandingPage = '/'
  componentDidMount() {
    this.props.setSearchQuery('')
    this.determineVisitorsLandingPage()
    this.createMenu()
  }

  currentUser = null
  authModalShowing = this.props.authModalShowing
  currentPage = window.location.pathname
  componentDidUpdate() {
    if (this.props.currentUser !== this.currentUser) {
      this.currentUser = this.props.currentUser
      this.createMenu()
    }
    if (this.authModalShowing !== this.props.authModalShowing) {
      this.authModalShowing = this.props.authModalShowing
      this.forceUpdate()
    }
    if (
      window.location.pathname === '/' &&
      window.location.pathname !== this.currentPage
    ) {
      this.currentPage = window.location.pathname
      this.createMenu()
    }
  }

  menu = null
  createMenu = () => {
    if (this.props.currentUser && this.props.currentUser.staff) {
      this.menu = (
        <Menu>
         {this.props.currentUser && this.props.currentUser.staff ? (
            <SearchBar>
              <form id="searchQuery" onSubmit={this.searchQueryEnteredHandler}>
                <Input
                  type="search"
                  id="searchBar"
                  placeholder="search"
                  width="100px"
                  value={this.props.searchQuery}
                  onChange={this.inputChangedHandler}
                />
              </form>
            </SearchBar>
          ) : null}
          <MenuItem onClick={this.tabClicked} id="bookings">
            <Text onClick={this.tabClicked}>Bookings</Text>
          </MenuItem>
          <MenuItem onClick={this.tabClicked} id="collections">
            <Text onClick={this.tabClicked}>Collections</Text>
          </MenuItem>
          <MenuItem onClick={this.tabClicked} id="profile">
            <Text onClick={this.tabClicked}>My Profile</Text>
          </MenuItem>
          {this.props.currentUser.admin ? (
            <MenuItem onClick={this.tabClicked} id="admin">
              <Text onClick={this.tabClicked}>Admin</Text>
            </MenuItem>
          ) : null}
          {this.props.currentUser.photographer ? (
            <MenuItem onClick={this.tabClicked} id="upload">
              <Text onClick={this.tabClicked}>Uploads</Text>
            </MenuItem>
          ) : null}
        </Menu>
      )
    } else this.menu = null
    this.forceUpdate()
  }

  inputChangedHandler = () => {
    this.props.setSearchQuery(getInputValue('searchBar'))
  }

  searchQueryEnteredHandler = event => {
    event.preventDefault()
    if (this.props.searchQuery.trim() !== '') {
      this.props.populatePhotoCollection(null)
      this.props.history.push('/search/' + this.props.searchQuery.toLowerCase().trim())
    } else return
  }

  tabClicked = event => {
    this.props.history.push(
      `/${event.target.id ? event.target.id : event.target.parentElement.id}`
    )
    this.currentPage = window.location.pathname
    this.createMenu()
  }

  determineVisitorsLandingPage = () => {
    if (
      window.location.pathname.includes('/photos/') &&
      !window.location.pathname.match(/(_\d\d\d)$/)
    )
      this.visitorsLandingPage = window.location.pathname
    else if (window.location.pathname.includes('/photos/'))
      this.visitorsLandingPage = `/albums/${window.location.pathname.slice(
        8,
        window.location.pathname.length - 4
      )}`
    else if (window.location.pathname.includes('/albums/'))
      this.visitorsLandingPage = window.location.pathname
    else if (window.location.pathname.includes('/collections/'))
      this.visitorsLandingPage = window.location.pathname
    else this.visitorsLandingPage = '/'
  }

  showAuthModal = () => {
    this.props.showAuthModal(true)
  }

  render() {
    return (
      <React.Fragment>
        <StyledHeader>
          <Link
            to={
              this.props.currentUser && this.props.currentUser.staff
                ? '/'
                : this.visitorsLandingPage
            }
          >
            <Title>
              <Logo>
                <img
                  style={{ width: '48px', height: '48px' }}
                  src={ujLogo}
                  alt=""
                />
              </Logo>
              <Name>
                <Text>University of Johannesburg</Text>
                <Text>Digital Media Library</Text>
              </Name>
            </Title>
          </Link>

         
          {this.props.currentUser &&
          this.props.currentUser.anonymous &&
          !this.props.authModalShowing ? (
            <StaffSignIn onClick={this.showAuthModal}>Staff Login</StaffSignIn>
          ) : null}
          {this.menu}
        </StyledHeader>
        {this.authModal}
      </React.Fragment>
    )
  }
}

const StyledHeader = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.light};
  border-bottom: 1px solid ${props => props.theme.main};
  color: ${props => props.theme.dark};
`

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;

  :hover {
    cursor: pointer;
  }
`

const Logo = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 4px;
`

const Name = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`

const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding-right: 40px;

  #searchQuery {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  font-weight: bold;
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${props => props.theme.dark};
  height: 100%;
  min-width: 100px;
  background-color: ${props =>
    window.location.pathname.includes(props.id)
      ? props.theme.main
      : props.theme.light};
  :hover {
    background-color: ${props => props.theme.main};
    cursor: pointer;
  }
`

const StaffSignIn = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 30px 10px 10px;

  :hover {
    font-size: 17px;
    padding-right: 28px;
    cursor: pointer;
  }
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    showMenu: state.ui.showMenu,
    authModalShowing: state.ui.showAuthModal,
    searchQuery: state.browse.searchQuery
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showMenu: () => dispatch(actions.showMenu()),
    showAuthModal: value => dispatch(actions.showAuthModal(value)),
    populatePhotoCollection: photoArray =>
      dispatch(actions.populatePhotoCollection(photoArray)),
    setSearchQuery: searchQuery => dispatch(actions.setSearchQuery(searchQuery))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))


*/
