import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled, { ThemeProvider } from 'styled-components'
import { auth, db } from 'utils/firebase'
import { doc, getDoc, onSnapshot, writeBatch, arrayUnion } from 'firebase/firestore'
import * as actions from 'store/actions'
import Layout from 'pages/Layout'
import Uploads from 'pages/Uploads'
import AlbumView from 'pages/AlbumView'
import Collections from 'pages/Collections'
import PhotoView from 'pages/PhotoView'
import Profile from 'pages/Profile'
import Search from 'pages/Search'
import Bookings from 'pages/Bookings'
import Admin from 'pages/Admin'
import Loader from 'components/loader/Loader'
import Home from 'pages/Home'

class App extends Component {
  state = {
    loading: true,
    underConstruction: false
  }

  componentDidMount() {
    this.listenForAuthChanges()
    this.setWindowStateAndListeners()
    this.props.requestApprovedFormItems()
  }

  listenForAuthChanges = async () => {
    if (this.tokenListener) return
    this.tokenListener = auth.onIdTokenChange(
      async () => {
        await this.getConfig()
        this.processUser()
      },
      error => {
        console.log(error)
      }
    )
  }

  getConfig = async () => {
    getDoc(doc(db, 'config', 'config'))
      .then(config => this.props.setConfig(config.data()))
      .catch(error => this.props.setError(error, true, 'Failed to get config from database in App component.'))
  }

  processUser = async () => {
    if (auth.currentUser()) {
      await auth.getIdTokenResult().then(async tokenResponse => {
        let user = tokenResponse.claims
        if (user.staff) {
          if (this.state.underConstruction) this.allowWebAdmin(user)
          this.tokenListener()
          await this.updateCurrentUser(user)
          if (!this.authListener) {
            this.addAuthListener(user)
            this.updateLastActive(user)
          }
        } else if (user.provider_id === 'anonymous') {
          this.tokenListener()
          this.props.updateCurrentUser({ anonymous: true, uid: user.user_id })
        } else {
          this.props.showAuthModal(true)
          this.setState({ loading: false })
        }
        return
      })
    } else {
      // sign out any existing user sessions
      if (this.authListener) this.authListener()
      this.props.updateCurrentUser(null)
      this.props.showAuthModal(true)
    }
    if (this.state.loading) this.setState({ loading: false })
    else this.forceUpdate()
  }

  allowWebAdmin = user => {
    const webAdmins = this.props.config.webAdmin.map(wa => wa.email)
    if ([...webAdmins, 'photos@uj.ac.za'].includes(user.email)) this.setState({ underConstruction: false })
  }

  updateCurrentUser = async user => {
    getDoc(doc(db, 'users', user.email))
      .then(userDoc => {
        const userData = userDoc.data()
        return this.props.updateCurrentUser({
          uid: user.user_id,
          email: user.email,
          fullName: user.name,
          name: userData.name,
          surname: userData.surname,
          department: userData.department,
          faculty: userData.faculty,
          officePhone: userData.officePhone,
          anonymous: false,
          staff: true,
          admin: userData.admin,
          photographer: userData.photographer,
          videographer: userData.videographer
        })
      })
      .catch(error => this.props.setError(error, true, 'Failed to update database with current user in App component.'))
  }

  updateLastActive = user => {
    let batch = writeBatch(db)
    batch.update(doc(db, 'users', user.email), { lastActive: new Date() })
    batch.set(
      doc(db, 'analytics', 'activeUsers'),
      {
        [new Date().toLocaleDateString('en-za')]: arrayUnion(user.email)
      },
      { merge: true }
    )
    try {
      batch.commit()
    } catch (error) {
      this.props.setError(error, false, 'Failed to update lastActive in App component.')
    }
  }

  addAuthListener = async user => {
    let initialSnapshot = null
    this.authListener = onSnapshot(
      doc(db, 'users', user.email),
      async snapshot => {
        if (!snapshot.data()) return
        if (!initialSnapshot) initialSnapshot = snapshot.data()
        let updatedSnapshot = snapshot.data()
        let tokens = ['admin', 'photographer', 'videographer']
        let tokensChanged = false
        tokens.forEach(token => {
          if (!updatedSnapshot) return
          if (initialSnapshot[token] !== updatedSnapshot[token]) tokensChanged = true
        })
        if (tokensChanged) {
          if (
            (this.props.location.pathname === '/upload' && !updatedSnapshot.photographer) ||
            (this.props.location.pathname === '/admin' && !updatedSnapshot.admin)
          )
            this.props.history.replace('/')
          await auth.getIdToken()
          initialSnapshot = snapshot.data()
          this.processUser()
        }
      },
      error => {
        this.props.setError(error, true, 'Auth listener failed in App component.')
      }
    )
  }

  setWindowStateAndListeners = () => {
    if (window.innerWidth <= 1024) this.props.setSmallDevice(true)
    if (window.innerHeight < window.innerWidth && window.innerHeight < 500) this.props.setSmallDeviceLandscape(true)
    window.addEventListener('resize', this.resizeListener)
    window.addEventListener('keydown', this.backspacePressed)
    window.addEventListener('mouseover', this.mouseListener)
    window.addEventListener('touchstart', this.touchListener)
    window.addEventListener('touchMove', this.touchListener)
    window.addEventListener('contextmenu', this.mobileContextMenuHandler)
  }

  theme = {
    bg: '#f4f4f4',
    main: '#c6c6c6',
    light: '#e0dede',
    dark: '#57575a',
    danger: '#e20909',
    fontSize: '14px'
  }

  backspacePressed = event => {
    if (event.key !== 'Backspace') return
    if (['text', 'search', 'datalist', 'textarea', 'time', 'date', 'password', 'email', 'tel', 'url'].includes(event.target.type)) return
    if (this.props.awaitingDownload) return
    this.props.history.goBack()
  }

  resizeListener = () => {
    if (window.innerWidth <= 1024 && !this.props.smallDevice) this.props.setSmallDevice(true)
    if (window.innerWidth > 1024 && this.props.smallDevice) this.props.setSmallDevice(false)
    if (window.innerHeight < window.innerWidth && window.innerHeight < 500 && !this.props.smallDeviceLandscape) this.props.setSmallDeviceLandscape(true)
    if (window.innerHeight > window.innerWidth && this.props.smallDeviceLandscape) this.props.setSmallDeviceLandscape(false)
  }

  recentlyTouched = false
  touchListener = () => {
    this.recentlyTouched = true
    if (this.touchTimeout) clearTimeout(this.touchTimeout)
    if (!this.touchTimeout)
      this.touchTimeout = setTimeout(() => {
        this.recentlyTouched = false
      }, 1100)
    if (this.props.inputMethod !== 'touch') this.props.setInputMethod('touch')
  }

  mouseListener = () => {
    if (this.props.inputMethod !== 'mouse' && this.recentlyTouched === false) this.props.setInputMethod('mouse')
  }

  mobileContextMenuHandler = event => {
    if (this.props.inputMethod === 'touch' && this.props.smallDevice) event.preventDefault()
  }

  render() {
    let routes = (
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route path='/albums/:albumName'>
          <AlbumView />
        </Route>
        <Route exact path='/collections'>
          <Collections />
        </Route>
        <Route path='/collections/:collectionId'>
          <Collections />
        </Route>
        <Route path='/photos/:photoId'>
          <PhotoView />
        </Route>
        <Route path='/search/:searchQuery'>{this.props.currentUser && this.props.currentUser.staff ? <Search /> : <Home />}</Route>
        <Route path='/profile'>{this.props.currentUser && this.props.currentUser.staff ? <Profile /> : <Home />}</Route>
        <Route exact path='/bookings'>
          {this.props.currentUser && this.props.currentUser.staff ? <Bookings /> : <Home />}
        </Route>
        <Route path='/admin'>{this.props.currentUser && this.props.currentUser.admin && !this.props.smallDevice ? <Admin /> : <Home />}</Route>
        <Route path='/upload'>{this.props.currentUser && this.props.currentUser.photographer && !this.props.smallDevice ? <Uploads /> : <Home />}</Route>
      </Switch>
    )
    if (this.state.underConstruction) {
      return <UnderConstruction>This website is currently under construction. Please check back later.</UnderConstruction>
    } else return <ThemeProvider theme={this.theme}>{this.state.loading ? <Loader /> : <Layout>{routes}</Layout>}</ThemeProvider>
  }
}

const mapStateToProps = state => {
  return {
    awaitingDownload: state.ui.awaitingDownload,
    smallDevice: state.ui.smallDevice,
    smallDeviceLandscape: state.ui.smallDeviceLandscape,
    showMenu: state.ui.showMenu,
    inputMethod: state.ui.inputMethod,
    currentUser: state.auth.currentUser,
    progress: state.upload.progress,
    config: state.config
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentUser: userObject => dispatch(actions.updateCurrentUser(userObject)),
    showAuthModal: value => dispatch(actions.showAuthModal(value)),
    setSmallDevice: status => dispatch(actions.setSmallDevice(status)),
    setSmallDeviceLandscape: status => dispatch(actions.setSmallDeviceLandscape(status)),
    setInputMethod: type => dispatch(actions.setInputMethod(type)),
    setConfig: config => dispatch(actions.setConfig(config)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage)),
    requestApprovedFormItems: () => dispatch(actions.requestApprovedFormItems())
  }
}

const UnderConstruction = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #c6c6c6;
`

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
