import { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from './store/actions'
import Error from 'pages/Error'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const customErrorObject = {
      code: 'ReactTypeError',
      message: error.message,
      name: error.name,
      stack: errorInfo.componentStack
    }
    this.props.setError(customErrorObject, true, 'This is a React error which caused a break in the user interface.')
  }

  render() {
    if (this.state.hasError) return <Error clearErrorBoundary={() => this.setState({ hasError: false })} />
    return this.props.children
  }
}

const connectDispatchWithProps = dispatch => {
  return {
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default connect(null, connectDispatchWithProps)(ErrorBoundary)
