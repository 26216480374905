import * as actions from 'store/actions/actionTypes'

const initialState = {
  searchQuery: null,
  searchKeys: null,
  albumResults: null,
  photoResults: null
}

const search = (state, action) => {
  return {
    ...state,
    searchQuery: action.searchQuery || null,
    albumResults: action.albumResults || null,
    photoResults: action.photoResults || null
  }
}

const updatePhotoResults = (state, action) => {
  const updatedPhotoResults = [...action.photoResults]
  return {
    ...state,   
    photoResults: updatedPhotoResults
  }
}

const setSearchKeys = (state, action) => {
  return {
    ...state,
    searchKeys: action.searchKeys
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SEARCH:
      return search(state, action)
    case actions.UPDATE_PHOTO_RESULTS:
      return updatePhotoResults(state, action)
    case actions.SET_SEARCH_KEYS:
      return setSearchKeys(state, action)
    default:
      return state
  }
}

export default reducer
