import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Text from 'components/Text'

class BookingsFilter extends Component {
  state = {
    showFilters: false,
    filters: null,
    enableTransition: false
  }

  componentDidMount() {
    this.setState({ filters: this.props.filters })
  }

  componentDidUpdate() {
    if (this.state.filters !== this.props.filters) {
      this.setState({ filters: this.props.filters })
    }
  }

  showFilters = event => {
    event.stopPropagation()
    if (event.target.id.slice(0, 7) !== 'filter-') {
      this.setState({ enableTransition: true, showFilters: !this.state.showFilters }, () => {
        if (this.state.showFilters) {
          document.addEventListener('click', this.showFilters)
        } else {
          document.removeEventListener('click', this.showFilters)
        }
      })
      this.props.showFilters()
    }
  }

  filterClicked = event => {
    let id = event.target.id
    let filter = id.slice(7)
    this.props.filterClicked(filter)
  }

  disableTransition = () => {
    if (this.state.showFilters) {
      this.setState({ enableTransition: false })
    }
  }

  render() {
    return (
      <React.Fragment>
        <StyledBookingsFilter
          onClick={this.props.disabled ? null : this.showFilters}
          smallDevice={this.props.smallDevice}
          smallDeviceLandscape={this.props.smallDeviceLandscape}
          showFilters={this.state.showFilters}
          disabled={this.props.disabled}
          enableTransition={this.state.enableTransition}
          onTransitionEnd={this.disableTransition}
        >
          <InputBox>
            <i className={this.props.smallDevice ? 'material-icons filter-small' : 'material-icons filter'}>filter_list</i>
            <Text>Filters</Text>
          </InputBox>
          {this.state.showFilters ? (
              <Filters>
                {Object.keys(this.state.filters).map(filter => {
                  if (true) {
                    return (
                      <Filter
                        key={filter}
                        id={`filter-${filter}`}
                        onClick={this.filterClicked}
                        isApplied={this.state.filters[filter].isApplied}
                        smallDevice={this.props.smallDevice}
                        topFilter={Object.keys(this.state.filters)[0] === filter}
                      >
                        <FilterData id={`filter-${filter}`}>
                          <Text id={`filter-${filter}`} ellipsis width='auto'>
                            {this.state.filters[filter].name}
                          </Text>
                          <Text id={`filter-${filter}`} padding='0px 2px 0px 10px'>{`(${this.state.filters[filter].count})`}</Text>
                        </FilterData>
                        {this.state.filters[filter].isApplied ? (
                          <i
                            className={
                              this.props.smallDevice ? 'material-icons-outlined filter-checkbox-false-small' : 'material-icons-outlined filter-checkbox-false'
                            }
                            id={`filter-${filter}`}
                          >
                            check_box
                          </i>
                        ) : (
                          <i
                            className={
                              this.props.smallDevice ? 'material-icons-outlined filter-checkbox-true-small' : 'material-icons-outlined filter-checkbox-true'
                            }
                            id={`filter-${filter}`}
                          >
                            check_box_outline_blank
                          </i>
                        )}
                      </Filter>
                    )
                  }
                  return null
                })}
              </Filters>
          ) : null}
        </StyledBookingsFilter>
      </React.Fragment>
    )
  }
}

const StyledBookingsFilter = styled.div`
  position: absolute;
  bottom: 12px;
  left: ${props => (props.smallDevice ? '15px' : '30px')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  width: ${props => (props.smallDevice ? (props.showFilters ? '190px' : '80px') : props.showFilters ? '230px' : '150px')};
  border: ${props => (props.smallDevice && props.showFilters ? '2px' : '1px')} solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  border-radius: 13px;
  font-size: ${props => (props.smallDevice ? '14px' : '15px')};
  background-color: ${props => props.theme.light};
  z-index: 2;
  transition: width ${props => (props.enableTransition ? '0.2s' : '0s')} linear;
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  :hover {
    border-width: ${props => (props.smallDevice ? null : props.disabled ? '1px' : '2px')};
    margin: ${props => (props.smallDevice ? null : '-1px -1px')};
    width: ${props => (props.smallDevice ? null : props.showFilters ? '232px' : '150px')};
  }
`

const InputBox = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  width: 100%;
  padding: 4px 4px;
  :hover {
    cursor: pointer;
  }
`

const Filters = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  border-top: ${props => (props.showFilters ? `1px solid ${props.theme.dark}` : null)};
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-color: ${props => props.theme.bg};
`

const Filter = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.dark};
  padding: ${props => (props.smallDevice ? '6px 10px' : '6px 10px')};
  color: ${props => (props.isApplied ? props.theme.dark : props.theme.main)};
  font-size: ${props => (props.smallDevice ? '12px' : '14px')};
  background-color: ${props => props.theme.bg};

  :hover {
    background-color: ${props => props.theme.main};
    color: ${props => (props.isApplied ? 'initial' : props.theme.bg)};
    cursor: pointer;
  }
`

const FilterData = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  padding-right: 6px;
`

const mapStateToProps = state => {
  return {
    smallDevice: state.ui.smallDevice,
    smallDeviceLandscape: state.ui.smallDeviceLandscape
  }
}

export default connect(mapStateToProps)(BookingsFilter)
