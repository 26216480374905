import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { auth, db, functions } from 'utils/firebase'
import * as actions from '../../store/actions'
import Input from 'components/NewInput'
import Alert from 'components/Alert'
import Loader from 'components/loader/Loader'
import sendVerificationCode from './sendVerificationCode'
import { getInputValue } from 'components/NewInput'
import { doc, getDoc, setDoc } from 'firebase/firestore'

class CreateAccountModal extends Component {
  state = {
    invalidPasswordPopup: false,
    verificationEmailSent: false,
    loading: false
  }

  renderPasswordValidation = passwordValidation => {
    this.passwordValidation = passwordValidation
    this.forceUpdate()
  }

  passwordIsValid = password => {
    const length = new RegExp('(.{8,})')
    const lowerCase = new RegExp('[a-z]')
    const upperCase = new RegExp('[A-Z]')
    const number = new RegExp('[0-9]')
    const special = new RegExp('[^a-zA-Z0-9s]')

    if (!length.test(password)) return false
    if (!lowerCase.test(password)) return false
    if (!upperCase.test(password)) return false
    if (!number.test(password)) return false
    if (!special.test(password)) return false

    return true
  }

  createAccountHandler = event => {
    if (this.uid) return false
    event.preventDefault()
    event.stopPropagation()

    this.email = getInputValue('email')
    this.name = getInputValue('name')
    this.surname = getInputValue('surname')
    this.extension = getInputValue('extension')
    this.faculty = getInputValue('faculty')
    this.department = getInputValue('department')
    this.password = getInputValue('password')
    this.confirmPassword = getInputValue('confirm-password')

    if (!this.passwordIsValid(this.password)) {
      this.alert = <Alert alertType='Invalid password' alertButtonClicked={this.alertButtonClickedHandler} />
      return this.forceUpdate()
    }

    this.setState({ loading: true })
    const emailDomain = this.email.slice(this.email.indexOf('@') + 1, this.email.length)
    if (this.password === this.confirmPassword && (['uj.ac.za', 'jbs.ac.za'].includes(emailDomain) || this.props.config.externalUsers.includes(this.email))) {
      auth
        .createUser(this.email, this.password)
        .then(async res => {
          this.uid = res.user.uid
          await sendVerificationCode(this.email)
          const createdOn = auth.currentUser().metadata.creationTime
          await auth
            .updateProfile({
              displayName: `${this.name} ${this.surname}`,
              email: this.email
            })
            .catch(error => this.props.setError(error, true, 'Failed to update auth.currentUser'))
          await setDoc(
            doc(db, 'users', this.email),
            {
              name: this.name,
              surname: this.surname,
              fullName: `${this.name} ${this.surname}`,
              email: this.email,
              officePhone: this.extension,
              department: this.department,
              faculty: this.faculty,
              uid: this.uid,
              createdOn
            },
            { merge: true }
          ).catch(error => this.props.setError(error, true, 'Failed to write new user data to database.'))
          this.props.addPendingFormItems({
            department: this.department,
            faculty: this.faculty
          })
          this.alert = (
            <Alert
              alertType='Verify account'
              emailValue={this.email}
              alertButtonClicked={this.alertButtonClickedHandler}
              resendVerificationEmail={() => sendVerificationCode(this.email)}
            />
          )
          this.setState({ loading: false })
        })
        .catch(error => {
          if (error.code === 'auth/email-already-in-use') {
            this.alert = <Alert alertType='Invalid email address' emailValue={this.email} alertButtonClicked={this.alertButtonClickedHandler} />
            this.setState({ loading: false })
          } else this.props.setError(error, true, 'Failed to create new user via Firebase Auth in CreateAccountModal.')
        })
    } else {
      if (emailDomain !== 'uj.ac.za' && emailDomain !== 'jbs.ac.za') {
        this.alert = <Alert alertType='Invalid email' emailValue={this.email} alertButtonClicked={this.alertButtonClickedHandler} />
        this.setState({ loading: false })
      }
      if (this.password !== this.confirmPassword) {
        this.alert = <Alert alertType='Password mismatch' alertButtonClicked={this.alertButtonClickedHandler} />
        this.setState({ loading: false })
      }
    }
  }

  goBack = event => {
    if (!event || event.type === 'click' || (event.type === 'keydown' && event.keyCode === 32))
      if (this.props.currentUser && this.props.currentUser.anonymous) {
        this.props.showAuthModal(false)
      } else this.props.changeWindowMode({ target: { id: 'staff' }, type: 'click' })
  }

  verifyAccount = async givenCode => {
    this.setState({ loading: true })
    let verificationCode = await getDoc(doc(db, 'users', this.email))
      .then(res => res.data().verificationCode)
      .catch(error => this.props.setError(error, false, 'Failed to get verification code from database in CreateAccountModal.'))
    if (givenCode === verificationCode) {
      await this.addUserType()
      await auth.currentUser().getIdToken(true)
      this.alert = null
      this.props.showAuthModal(false)
    } else {
      this.alert = <Alert alertType='Incorrect code' alertButtonClicked={this.alertButtonClickedHandler} />
      this.setState({ loading: false })
    }
    return
  }

  retries = 0
  addUserType = async () => {
    return await functions
      .httpsCallable('addUserType')({
        uid: this.uid,
        type: 'staff'
      })
      .then(() => 'SUCCESS')
      .catch(async error => {
        this.retries++
        if (this.retries < 5)
          setTimeout(async () => {
            return await this.addUserType()
          }, 400)
        else this.props.setError(error, true, 'Failed to set user type in CreateAccountModal.')
      })
  }

  alertButtonClickedHandler = async event => {
    switch (event.target.id) {
      case 'passwordMismatch':
        this.alert = null
        document.getElementById('confirm-password').value = ''
        document.getElementById('confirm-password').focus()
        this.setState({
          alertType: ''
        })
        break
      case 'invalidPassword':
        this.alert = null
        document.getElementById('password').value = ''
        document.getElementById('password').focus()
        this.setState({
          alertType: ''
        })
        break
      case 'verifyAccount':
        this.verifyAccount(getInputValue('verificationCodeInput'))
        break
      case 'invalidEmailContinue':
        this.alert = null
        this.setState({ alertType: '' })
        break
      case 'wrongCode':
        this.alert = (
          <Alert
            alertType='Verify account'
            emailValue={this.email}
            alertButtonClicked={this.alertButtonClickedHandler}
            resendVerificationEmail={() => sendVerificationCode(this.email)}
          />
        )
        this.forceUpdate()
        break
      default:
        break
    }
  }

  externalUserListener = event => {
    const externalUsers = this.props.config.externalUsers
    if (externalUsers.includes(event.target.value)) this.setState({ externalUser: true })
    else this.setState({ externalUser: false })
  }

  render() {
    return (
      <Container>
        {this.state.loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <CreateAccountForm onSubmit={this.createAccountHandler}>
              <InputLine>
                <Input type='text' id='name' label='Name' autoFocus required />
                <Input type='text' id='surname' label='Surname' required />
              </InputLine>

              <InputLine>
                <Input type='datalist' id='department' label='Department' required>
                  {this.props.approved.department || <option />}
                </Input>
                <Input type='datalist' id='faculty' label='Faculty/Division' required>
                  {this.props.approved.faculty || <option />}
                </Input>
              </InputLine>

              <InputLine>
                <Input
                  type='email'
                  id='email'
                  label='Valid UJ email address'
                  required
                  autoComplete='username'
                  onChange={this.externalUserListener}
                  pattern={this.state.externalUser ? 'none' : null}
                />

                <Input type='extension' id='extension' label='Office phone extension' required />
                <input type='text' style={{ display: 'none' }} />
              </InputLine>
              <InputLine>
                <Input
                  type='password'
                  id='password'
                  autoComplete='new-password'
                  label='Password'
                  required
                  renderPasswordValidation={this.renderPasswordValidation}
                />
                <Input type='password' id='confirm-password' autoComplete='new-password' label='Confirm password' required />
              </InputLine>
              <InputLine>{this.passwordValidation}</InputLine>
              <Toolbar>
                <Input type='button' buttonType='submit' id='submitButton'>
                  Submit
                </Input>
              </Toolbar>
              <GoBack onClick={this.goBack} onKeyDown={this.goBack} loading={this.state.loading.toString()} tabIndex='0'>
                GO BACK
              </GoBack>
            </CreateAccountForm>
            {this.alert}
          </React.Fragment>
        )}
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CreateAccountForm = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 20px;
`

const InputLine = styled.div`
  width: 100%;
  height: ${props => props.height || 'auto'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
`

const Toolbar = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GoBack = styled.div`
  display: ${props => (props.loading === 'true' ? 'none' : 'block')};
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.dark};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: pointer;
      font-size: 15px;
      left: 14px;
      bottom: 14px;
    }
  }
`

const mapStateToProps = state => {
  return {
    smallDevice: state.ui.smallDevice,
    approved: state.forms.approved,
    currentUser: state.auth.currentUser,
    config: state.config
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addPendingFormItems: newItems => dispatch(actions.addPendingFormItems(newItems)),
    showAuthModal: value => dispatch(actions.showAuthModal(value)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountModal)
