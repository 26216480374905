// base long description cut-off on div height, not amount of characters. figure out a way to make it consistently cut off at end of three lines

import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

class BookingCard extends Component {
  state = {
    expand: false,
    expanded: false,
    hover: false
  }

  booking = this.props.booking
  componentDidUpdate() {
    if (this.props.booking !== this.booking) {
      this.booking = this.props.booking
      this.setState({ expand: false, expanded: false })
    }
  }

  cardClickedHandler = event => {
    if (window.getSelection().toString() !== '') return // user selected text
    if (clickedOnButton(event)) return
    if (this.state.expand && event.target.id !== 'button-info') return
    this.getExpandedHeight()
    this.toggleExpand()

    function clickedOnButton(event) {
      return event.target.id.includes('button') && event.target.id !== 'button-info'
    }
  }

  toggleExpand = () => {
    this.setState(
      prevState => {
        return { expand: !prevState.expand, expanded: false }
      },
      () => {
        if (this.state.expand) document.addEventListener('click', this.outsideClickListener)
        else this.removeOutsideClickListener()
      }
    )
  }

  cardExpanded = () => {
    if (this.state.expand) this.setState({ expanded: true })
  }

  getExpandedHeight = () => {
    let height = 186
    height += document.getElementById(`mockTitle-${this.props.id}`).offsetHeight
    height += document.getElementById(`mockDescription-${this.props.id}`).offsetHeight
    this.expandedHeight = `${height}px`
    this.forceUpdate()
  }

  outsideClickListener = event => {
    let clickedElement = event.target
    let activeCard = document.getElementById(`bookingCard-${this.props.id}`)

    if (isOutsideClick(clickedElement, activeCard)) {
      this.removeOutsideClickListener()
      this.toggleExpand()
    }

    function isOutsideClick(clickedElement, activeCard) {
      if (activeCard) {
        return !activeCard.contains(clickedElement) && !document.getElementById('reasonDeclinedModal') && !document.getElementById('reasonCancelledModal')
      } else return true
    }
  }

  removeOutsideClickListener = () => {
    document.removeEventListener('click', this.outsideClickListener)
  }

  setHover = event => {
    if (event.type === 'mouseenter') this.setState({ hover: true })
    if (event.type === 'mouseleave') this.setState({ hover: false })
  }

  getBookingDate = () => {
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let dateOfEvent = this.booking.deadline
      ? this.booking.deadline.toDate()
      : this.booking.startDate
      ? this.booking.startDate.toDate()
      : this.booking.dateOfEvent.toDate()
    return `${days[dateOfEvent.getDay()]}, ${dateOfEvent.getDate()} ${months[dateOfEvent.getMonth()]} ${dateOfEvent.getFullYear()}`
  }

  isPastEvent = () => {
    let currentDate = new Date()
    if (this.booking.dateOfEvent) {
      let bookingDate = this.booking.dateOfEvent.toDate()
      bookingDate.setHours(this.booking.endTime.slice(0, 2))
      bookingDate.setMinutes(this.booking.endTime.slice(3))
      return this.booking.dateOfEvent.toDate() < currentDate
    }
    if (this.booking.deadline) return this.booking.deadline.toDate() < currentDate
    if (this.booking.startDate) return this.booking.startDate.toDate() < currentDate
  }

  render() {
    let booking = this.booking

    return (
      <StyledBookingCard
        id={`bookingCard-${this.props.id}`}
        onMouseEnter={this.setHover}
        onMouseLeave={this.setHover}
        onMouseOver={this.setHover}
        onTransitionEnd={this.cardExpanded}
        expand={this.state.expand}
        expandedHeight={this.expandedHeight}
        onClick={this.cardClickedHandler}
        smallDevice={this.props.smallDevice}
      >
        <Title id={`title-${this.props.id}`} smallDevice={this.props.smallDevice} expand={this.state.expand} title={booking.name}>
          {booking.name}
        </Title>

        <DateTime>
          <i className='material-icons'>schedule</i>
          {`${this.getBookingDate()} ${booking.startTime ? `\u00A0\u00A0(${booking.startTime} - ${booking.endTime})` : ''}`}
        </DateTime>

        <Venue>
          <i className='material-icons'>place</i>
          <p title={booking.venue ? `${booking.venue}, ${booking.campus}` : 'N/A'}>{booking.venue ? `${booking.venue}, ${booking.campus}` : 'N/A'}</p>
        </Venue>

        <Description expand={this.state.expand} expanded={this.state.expanded} id={`description-${this.props.id}`}>
          {booking.description}
        </Description>

        {booking.clientEmail === this.props.currentUser.email ? (
          <Agent expanded={this.state.expanded}>
            <p>
              {booking.type === 'Photography' ? 'Photographer' : 'Videographer'}:{' '}
              {![booking.photographer, booking.videographer].includes('Pending') ? (
                <a href={`mailto:${booking.photographer ? booking.photographerEmail : booking.videographerEmail}`}>
                  {booking.photographer || booking.videographer}
                </a>
              ) : (
                'Pending'
              )}
            </p>
          </Agent>
        ) : (
          <Client expanded={this.state.expanded}>
            <p>
              Booked by <a href={`mailto:${booking.clientEmail}`}>{booking.clientName}</a>
            </p>
            <p>{`${booking.department}, ${booking.faculty}`}</p>
          </Client>
        )}

        <MockTitle id={`mockTitle-${this.props.id}`}>{booking.name}</MockTitle>
        <MockDescription id={`mockDescription-${this.props.id}`}>{booking.description}</MockDescription>

        <Controls hover={this.state.hover}>
          <Status>
            <i className='material-icons'>{this.props.booking.type === 'Photography' ? 'camera_alt' : 'videocam'}</i>
            <span>{`${booking.status.toUpperCase()}`}</span>
            {booking.status === 'Accepted' && this.props.currentUser.videographer ? (
              <span style={{ fontSize: '15px', paddingLeft: '6px' }}>{`by ${booking.photographer || booking.videographer}`}</span>
            ) : null}
          </Status>

          <Buttons>
            {(this.props.currentUser.photographer && booking.type === 'Photography') ||
            (this.props.currentUser.videographer && booking.type === 'Videography') ? (
              ['Submitted', 'Updated'].includes(booking.status) ? (
                <AcceptDecline expanded={this.state.expanded}>
                  <i
                    className='material-icons'
                    id={`button-accept-${this.props.id}`}
                    title='Accept booking'
                    onClick={
                      this.state.expanded
                        ? () => {
                            this.props.acceptBooking(this.props.id)
                          }
                        : null
                    }
                  >
                    check
                  </i>
                  <i
                    className='material-icons'
                    id={`button-decline-${this.props.id}`}
                    title='Decline booking'
                    onClick={
                      this.state.expanded
                        ? () => {
                            this.props.declineBooking(this.props.id)
                          }
                        : null
                    }
                  >
                    clear
                  </i>
                </AcceptDecline>
              ) : booking.type === 'Videography' &&
                this.props.currentUser.videographer &&
                this.props.currentUser.email === booking.videographerEmail &&
                booking.status === 'Accepted' &&
                this.isPastEvent() ? (
                <AcceptDecline expanded={this.state.expanded}>
                  <i
                    className='material-icons'
                    id={`button-markAsComplete-${this.props.id}`}
                    title='Mark as completed'
                    onClick={
                      this.state.expanded
                        ? () => {
                            this.props.markAsCompleted(this.props.id)
                          }
                        : null
                    }
                  >
                    task_alt
                  </i>
                </AcceptDecline>
              ) : null
            ) : null}

            {this.state.expanded && ['Submitted', 'Updated', 'Accepted'].includes(booking.status) && booking.clientEmail === this.props.currentUser.email ? (
              <i className='material-icons' id={`button-cancel-${this.props.id}`} title='Cancel booking' onClick={this.props.cancelBooking}>
                delete
              </i>
            ) : null}

            {this.state.expanded &&
            ['Submitted', 'Updated', 'Accepted'].includes(booking.status) &&
            (booking.clientEmail === this.props.currentUser.email || this.props.currentUser.videographer || this.props.currentUser.photographer) ? (
              <i className='material-icons' id={`button-edit-${this.props.id}`} title='Edit booking' onClick={this.props.editBooking}>
                edit
              </i>
            ) : null}

            <i
              className={this.state.expand ? 'material-icons' : 'material-icons-outlined'}
              id='button-info'
              title={`${this.state.expand ? 'Hide' : 'Show'} info`}
            >
              info
            </i>
          </Buttons>
        </Controls>
      </StyledBookingCard>
    )
  }
}

const StyledBookingCard = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${props => (props.smallDevice ? '100%' : '700px')};
  height: ${props => (props.expand ? props.expandedHeight : '140px')};
  overflow-y: hidden;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 15px;
  padding: 15px;
  transition: height 0.3s linear;
  color: ${props => props.theme.dark};
  flex-shrink: 0;
  :hover {
    border: 2px solid ${props => props.theme.dark};
    padding: 14px;
  }
`

const Title = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  white-space: ${props => (props.expand ? 'normal' : 'nowrap')};
  text-overflow: ellipsis;
  overflow-x: hidden;
  flex-shrink: 0;
`

const MockTitle = styled(Title)`
  height: auto;
  visibility: hidden;
  white-space: normal;
`

const DateTime = styled.div`
  width: 100%;
  margin-bottom: 4px;
  padding-left: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;

  i {
    font-size: 20px;
    margin-right: 8px;
  }
`

const Venue = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;

  i {
    font-size: 20px;
    margin-right: 8px;
    overflow-y: hidden;
  }

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 16px;
    margin: 0;
  }
`

const Description = styled.div`
  width: 100%;
  height: ${props => (props.expanded ? 'auto' : '0px')};
  padding: 0px 20px;
  margin-bottom: ${props => (props.expanded ? '20px' : '0px')};
  font-size: 14px;
  word-wrap: break-word;
  text-align: justify;
  opacity: ${props => (props.expand ? 1 : 0)};
  transition: opacity ${props => (props.expand ? '0.3s' : '0s')} linear ${props => (props.expand ? '0.3s' : '0s')};
`

const MockDescription = styled(Description)`
  height: auto;
  transition: none;
  visibility: hidden;
`

const Client = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  opacity: ${props => (props.expanded ? 1 : 0)};
  transition: opacity ${props => (props.expand ? '0.3s' : '0s')} linear ${props => (props.expand ? '0.3s' : '0s')};

  p {
    height: 16px;
    margin: 0;
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  a:hover {
    text-decoration: underline;
  }
`

const Agent = styled(Client)``

const Controls = styled.div`
  position: absolute;
  bottom: ${props => (props.hover ? '0px' : '1px')};
  left: ${props => (props.hover ? '0px' : '1px')};
  right: ${props => (props.hover ? '0px' : '1px')};
  height: 40px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Status = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;

  i {
    font-size: 20px;
    margin-right: 4px;
  }
`

const Buttons = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 8px;
  margin-left: 30px;

  i {
    font-size: 26px;
    margin: 0px 1px;
  }

  i:hover {
    font-size: 28px;
    margin: 0;
    cursor: pointer;
  }
`

const AcceptDecline = styled.div`
  margin-right: 20px;
  display: ${props => (props.expanded ? 'flex' : 'none')};
  align-items: center;
`

const matchStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    smallDevice: state.ui.smallDevice,
    smallDeviceLandscape: state.ui.smallDeviceLandscape
  }
}

export default connect(matchStateToProps)(BookingCard)
