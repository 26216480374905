import { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import store from 'store/store'
import Button from 'components/input/Button'
import Checkbox from 'components/input/Checkbox'
import Datalist from 'components/input/Datalist'
import DateInput from 'components/input/Date'
import Email from 'components/input/Email'
import Extension from 'components/input/Extension'
import File from 'components/input/File'
import Mobile from 'components/input/Mobile'
import Number from 'components/input/Number'
import Password from 'components/input/Password'
import Radio from 'components/input/Radio'
import Search from 'components/input/Search'
import Select from 'components/input/Select'
import Text from 'components/input/Text'
import TextArea from 'components/input/TextArea'
import Time from 'components/input/Time'
import URL from 'components/input/URL'
import AutoComplete from 'components/input/AutoComplete'

class Input extends Component {
  constructor(props) {
    super(props)

    this.validateProps()
    this.createLabel()
    this.createInput()
  }

  oldProps = this.props
  componentDidUpdate() {
    if (this.oldProps !== this.props) {
      this.oldProps = this.props
      this.createLabel()
      this.forceUpdate()
    }
  }

  validateProps = () => {
    if (!this.props.id) throw new Error('<Input /> must have a unique id')
    if (!this.props.type) throw new Error('<Input /> must have a type property')
  }

  createLabel = () => {
    this.label = <Label htmlFor={this.props.id}>{this.props.label}</Label>
  }

  createInput = () => {
    switch (this.props.type) {
      case 'autocomplete':
        this.input = <AutoComplete {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'button':
        this.input = <Button {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'checkbox':
        this.input = <Checkbox {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'datalist':
        this.input = (
          <Datalist {...this.props} smallDevice={this.props.smallDevice}>
            {this.props.children}
          </Datalist>
        )
        break

      case 'date':
        this.input = <DateInput {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'email':
        this.input = <Email {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'extension':
        this.input = <Extension {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'file':
        this.input = <File {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'mobile':
        this.input = <Mobile {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'number':
        this.input = <Number {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'password':
        this.input = <Password {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'radio':
        this.input = <Radio {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'search':
        this.input = <Search {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'select':
        this.input = (
          <Select {...this.props} smallDevice={this.props.smallDevice}>
            {this.props.children}
          </Select>
        )
        break

      case 'text':
        this.input = <Text {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'textarea':
        this.input = <TextArea {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'time':
        this.input = <Time {...this.props} smallDevice={this.props.smallDevice} />
        break

      case 'url':
        this.input = <URL {...this.props} smallDevice={this.props.smallDevice} />
        break
      default:
        break
    }
  }

  render() {
    this.createInput()

    return (
      <StyledInputBox
        hasLabel={this.props.label}
        width={this.props.width}
        margin={this.props.margin}
        smallDevice={this.props.smallDevice}
      >
        {this.props.label ? this.label : null}
        {this.input}
      </StyledInputBox>
    )
  }
}

const StyledInputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.hasLabel ? 'flex-start' : 'center')};
  align-items: flex-start;
  margin: ${props => props.margin || '0px 0px 10px 0px'};
  height: auto;
  width: ${props => props.width || '100%'};
  font-family: 'Roboto';
  font-size: ${props => (props.smallDevice ? '12px' : '14px')};

  input {
    font-family: 'Roboto'
  }

  option {
    font-family: 'Roboto'
  }
`

const Label = styled.label`
  font-size: 12px;
  padding: 0px 0px;
  margin: 0px 0px 4px 0px;
  color: ${props => props.theme.dark};
`

const mapStateToProps = state => {
  return {
    smallDevice: state.ui.smallDevice
  }
}

export function getInputValue(id) {
  const input = document.getElementById(id)
  if (input) {
    const value = input.value || input.getAttribute('value') || ''
    if (input.type === 'file') return store.getState().bookings.files[id] || null
    if (input.type === 'search') return value.toLowerCase().trim()
    if (input.placeholder === 'x0000') return value.slice(1)
    if (input.getAttribute('type') === 'checkbox') return input.value
    if (input.getAttribute('time_input_type') === 'safari') return convertTo24Hour(value)
    return typeof value === 'string' && value.length === 0
      ? ''
      : input.getAttribute('type') === 'date'
      ? new Date(value)
      : value
  } else return undefined
}

function convertTo24Hour(time) {
  let indicator = time.slice(6, 8)
  let hours = time.slice(0, 2)
  let minutes = time.slice(3, 5)
  if (indicator === 'AM') return `${hours}:${minutes}`
  if (indicator === 'PM') return `${parseInt(hours) + 12}:${minutes}`
  return time
}

export default connect(mapStateToProps)(Input)
