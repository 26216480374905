import React, { Component } from 'react'
import styled from 'styled-components'

class Datalist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value || ''
    }

    this.validateProps()
  }

  validateProps = () => {
    if (!this.props.children)
      throw new Error(`<Input type='datalist' /> must have at least one <option /> element as its child.`)
  }

  inputChangedHandler = event => {
    this.setState({ value: event.target.value }, () => {
      if (this.props.onChange) this.props.onChange(event)
    })
  }

  render() {
    return (
      <React.Fragment>
        <Input
          type='text'
          id={this.props.id}
          list={`datalist-${this.props.id}`}
          placeholder={this.props.placeholder}
          value={this.state.value}
          autoComplete='off'
          autoFocus={this.props.autoFocus}
          onChange={this.inputChangedHandler}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          required={this.props.required}
        />
        <DataList id={`datalist-${this.props.id}`}>{this.props.children}</DataList>
      </React.Fragment>
    )
  }
}

const Input = styled.input`
  box-sizing: border-box;
  height: 26px;
  width: 100%;
  padding: 4px 6px;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  outline: none;
  background-color: white;
  color: ${props => props.theme.dark};
  font-size: inherit;

  :focus {
    border: 2px solid ${props => props.theme.dark};
  }
`

const DataList = styled.datalist``

export default Datalist
