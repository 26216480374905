import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Text from 'components/Text'

class SearchFilter extends Component {
  state = {
    showFilters: false,
    filters: null,
    enableTransition: false
  }

  componentDidMount() {
    this.setState({ filters: this.props.filters })
    this.availableWidth = `${window.innerWidth - 30}px`
  }

  smallDeviceLandscape = this.props.smallDeviceLandscape
  componentDidUpdate() {
    if (this.state.filters !== this.props.filters) {
      this.setState({ filters: this.props.filters })
    }
    if (this.smallDeviceLandscape !== this.props.smallDeviceLandscape) {
      this.smallDeviceLandscape = this.props.smallDeviceLandscape
      this.availableWidth = `${window.innerWidth - 15}px`
    }
  }

  showFilters = event => {
    event.stopPropagation()
    if (!['option-', 'filter-'].includes(event.target.id.slice(0, 7))) {
      this.setState(
        { enableTransition: true, showFilters: !this.state.showFilters },
        () => {
          if (this.state.showFilters) {
            document.addEventListener('click', this.showFilters)
          } else {
            document.removeEventListener('click', this.showFilters)
          }
        }
      )
      this.props.showFilters()
    }
  }

  expandFilter = event => {
    if (event.target.id.slice(0, 7) === 'option-') return
    let clickedFilter = event.target.id.slice(7)
    let filters = { ...this.state.filters }
    if (filters[clickedFilter]) {
      filters[clickedFilter].expand = !filters[clickedFilter].expand
      if (filters[clickedFilter].expand) {
        Object.keys(filters).forEach(filter => {
          if (clickedFilter !== filter) {
            filters[filter].expand = false
          }
        })
      }
      this.setState({ filters })
    }
  }

  optionClicked = event => {
    let id = event.target.id
    let filter = id.slice(7, id.indexOf('/'))
    let option = id.slice(id.indexOf('/') + 1)
    this.props.filterOptionClicked(filter, option)
  }

  disableTransition = () => {
    if (this.state.showFilters) {
      this.setState({ enableTransition: false })
    }
  }

  render() {
    return (
      <React.Fragment>
        <StyledSearchFilter
          onClick={this.props.disabled ? null : this.showFilters}
          smallDevice={this.props.smallDevice}
          showFilters={this.state.showFilters}
          enableTransition={this.state.enableTransition}
          onTransitionEnd={this.disableTransition}
          availableWidth={this.availableWidth}
          disabled={this.props.disabled}
        >
          <InputBox>
            <i
              className={
                this.props.smallDevice
                  ? 'material-icons filter-small'
                  : 'material-icons filter'
              }
            >
              filter_list
            </i>
            <Text>Filters</Text>
          </InputBox>
          {this.state.showFilters ? (
            <React.Fragment>
              <Filter
                id="filter-filterOptions"
                filterOptions={true}
                smallDevice={this.props.smallDevice}
              >
                <Controls id="filter-controls">
                  <Clear
                    id="filter-clearFilters"
                    onClick={this.props.clearFilters}
                    showFilterControls={!this.state.enableTransition}
                    smallDevice={this.props.smallDevice}
                  >
                    Clear results
                  </Clear>
                  <Reset
                    id="filter-resetFilters"
                    onClick={this.props.resetFilters}
                    showFilterControls={!this.state.enableTransition}
                    smallDevice={this.props.smallDevice}
                  >
                    Reset filters
                  </Reset>
                </Controls>
              </Filter>
              {Object.keys(this.state.filters).map(filter => {
                if (
                  Object.keys(this.state.filters[filter].options).length > 0
                ) {
                  return (
                    <Filter
                      key={filter}
                      id={`filter-${filter}`}
                      onClick={this.expandFilter}
                      lastOption={
                        filter ===
                        Object.keys(this.state.filters)[
                          Object.keys(this.state.filters).length - 1
                        ]
                      }
                      expand={this.state.filters[filter].expand}
                      smallDevice={this.props.smallDevice}
                    >
                      <Title
                        id={`filter-${filter}`}
                        expand={this.state.filters[filter].expand}
                      >
                        {this.state.filters[filter].name}
                        <i
                          className="material-icons"
                          id={`filter-${filter}`}
                          style={{ fontSize: '22px' }}
                        >
                          {this.state.filters[filter].expand
                            ? 'arrow_drop_down'
                            : 'arrow_drop_up'}
                        </i>
                      </Title>

                      {this.state.filters[filter].expand ? (
                        <Options expand={this.state.filters[filter].expand}>
                          {Object.keys(this.state.filters[filter].options).map(
                            option => {
                              if (
                                !['Stock'].includes(option) &&
                                !this.state.filters[filter].options[option]
                                  .universal
                              ) {
                                return (
                                  <Option
                                    key={option}
                                    id={`option-${filter}/${option}`}
                                    onClick={this.optionClicked}
                                    inactive={
                                      this.state.filters[filter].options[option]
                                        .inactive
                                    }
                                    applied={
                                      this.state.filters[filter].options[option]
                                        .applied
                                    }
                                    smallDevice={this.props.smallDevice}
                                  >
                                    <OptionData
                                      id={`option-${filter}/${option}`}
                                    >
                                      <Text
                                        id={`option-${filter}/${option}`}
                                        ellipsis
                                        width="auto"
                                      >
                                        {option}
                                      </Text>
                                      <Text
                                        id={`option-${filter}/${option}`}
                                        padding="0px 2px 0px 10px"
                                      >{`(${this.state.filters[filter].options[option].totalCount})`}</Text>
                                    </OptionData>
                                    {this.state.filters[filter].options[option]
                                      .applied ? (
                                      <i
                                        className={
                                          this.props.smallDevice
                                            ? 'material-icons-outlined filter-checkbox-true-small'
                                            : 'material-icons-outlined filter-checkbox-true'
                                        }
                                        id={`option-${filter}/${option}`}
                                      >
                                        check_box_outline_blank
                                      </i>
                                    ) : (
                                      <i
                                        className={
                                          this.props.smallDevice
                                            ? 'material-icons-outlined filter-checkbox-false-small'
                                            : 'material-icons-outlined filter-checkbox-false'
                                        }
                                        id={`option-${filter}/${option}`}
                                      >
                                        check_box
                                      </i>
                                    )}
                                  </Option>
                                )
                              }
                              return null
                            }
                          )}
                        </Options>
                      ) : null}
                    </Filter>
                  )
                } else return null
              })}
            </React.Fragment>
          ) : null}
        </StyledSearchFilter>
      </React.Fragment>
    )
  }
}

const StyledSearchFilter = styled.div`
  position: absolute;
  bottom: ${props => (props.smallDevice ? '10px' : '12px')};
  left: ${props => (props.smallDevice ? '15px' : '30px')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  width: ${props =>
    props.smallDevice
      ? props.showFilters
        ? props.availableWidth
        : '100px'
      : props.showFilters
      ? '300px'
      : '150px'};
  border: ${props => (props.smallDevice && props.showFilters ? '2px' : '1px')}
    solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  border-radius: 13px;
  font-size: ${props => (props.smallDevice ? '14px' : '15px')};
  background-color: ${props => props.theme.light};
  z-index: 10;
  transition: width ${props => (props.enableTransition ? '0.2s' : '0s')} linear;
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  :hover {
    border-width: ${props => (props.smallDevice ? null : '2px')};
    margin: ${props => (props.smallDevice ? '0px 0px' : '-1px -1px')};
    width: ${props =>
      props.smallDevice ? null : props.showFilters ? '302px' : '152px'};
  }
`
const InputBox = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  width: 100%;
  padding: 4px 4px;
  :hover {
    cursor: pointer;
  }
`

const Filter = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${props => (props.selectAll ? 'center' : 'space-between')};
  width: 100%;
  height: ${props =>
    props.expand ? 'auto' : props.filterOptions ? '30px' : '40px'};
  max-height: 300px;
  border-bottom: 1px solid ${props => props.theme.dark};
  border-top: ${props =>
    props.expand && !props.lastOption ? `1px solid ${props.theme.dark}` : null};
  border-top-left-radius: ${props => (props.lastOption ? '13px' : null)};
  border-top-right-radius: ${props => (props.lastOption ? '13px' : null)};
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  font-size: ${props => (props.smallDevice ? '11px' : '14px')};

  :hover {
    background-color: ${props =>
      props.smallDevice
        ? null
        : !props.expand && !props.filterOptions
        ? props.theme.main
        : props.theme.light};
    cursor: ${props =>
      props.disabled || props.filterOptions ? 'select' : 'pointer'};
  }
`

const Title = styled.div`
  position: sticky;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: ${props => (props.smallDevice ? '6px 10px' : '6px 11px')};
  z-index: 1;
  border-bottom: ${props => (props.expand ? '2px' : '0px')} solid
    ${props => props.theme.dark};
  font-weight: ${props => (props.expand ? 'bold' : 'inherit')};
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 100%;
`

const Clear = styled.div`
  display: ${props => (props.showFilterControls ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  font-size: 14px;
  font-weight: bold;
  border-right: 1px solid ${props => props.theme.dark};

  :hover {
    background-color: ${props => (props.smallDevice ? null : props.theme.main)};
    cursor: pointer;
  }
`

const Reset = styled.div`
  display: ${props => (props.showFilterControls ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  font-weight: bold;
  font-size: 14px;

  :hover {
    background-color: ${props => (props.smallDevice ? null : props.theme.main)};
    cursor: pointer;
  }
`

const Options = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${props => props.theme.bg};
`

const Option = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.dark};
  padding: ${props => (props.smallDevice ? '6px 10px' : '6px 10px')};
  color: ${props =>
    props.applied || props.inactive ? props.theme.main : props.theme.dark};
  font-size: ${props => (props.smallDevice ? '11px' : '12px')};
  background-color: ${props => props.theme.bg};
  font-weight: initial;

  :hover {
    background-color: ${props => (props.smallDevice ? null : props.theme.main)};
    color: ${props =>
      props.smallDevice
        ? null
        : props.applied || props.inactive
        ? props.theme.bg
        : 'initial'};
    cursor: pointer;
  }
`

const OptionData = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  padding-right: 6px;
`

const mapStateToProps = state => {
  return {
    smallDeviceLandscape: state.ui.smallDeviceLandscape
  }
}

export default connect(mapStateToProps)(SearchFilter)
