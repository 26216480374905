import Loader from 'components/loader/Loader'
import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { db } from 'utils/firebase'
import store from 'store/store'
import * as actions from 'store/actions'
import { collection, getDocs } from 'firebase/firestore'

class Photos extends Component {
  state = { loading: true, albumMode: 'views', photoMode: 'views' }

  componentDidMount() {
    this.populateData()
  }

  populateData = () => {
    let dbRequestsCompleted = 0
    getDocs(collection(db, 'photos'))
      .then(photos => {
        this.photos = photos.docs.map(photo => photo.data())
        this.totalPhotos = this.getTotalPhotos()
        this.totalStock = this.getTotalStock()
        dbRequestsCompleted++
        if (dbRequestsCompleted === 2) {
          this.photoData = this.getPhotoData()
          this.setState({ loading: false })
        }
      })
      .catch(error => store.dispatch(actions.setError(error, false, 'Failed to get photos in Admin component.')))

      getDocs(collection(db, 'albums'))
      .then(albums => {
        this.albums = albums.docs.map(album => album.data())
        this.totalAlbums = this.getTotalAlbums()
        this.albumData = this.getAlbumData()
        dbRequestsCompleted++
        if (dbRequestsCompleted === 2) {
          this.photoData = this.getPhotoData()
          this.setState({ loading: false })
        }
      })
      .catch(error => store.dispatch(actions.setError(error, false, 'Failed to get albums in Admin component.')))
  }

  getTotalAlbums = () => this.albums.length

  getAlbumData = () => {
    let data = {}
    this.albums.forEach(album => {
      data[album.name] = {
        thumb: album.coverURL,
        views: album.views ? album.views.length : 0,
        downloads: album.downloads || 0,
        url: `https://ujdml.co.za/albums/${album.id}`
      }
    })
    return data
  }

  getPhotoData = () => {
    let data = {}
    let album
    this.photos.forEach(photo => {
      album = null
      if (photo.album) album = this.albums.find(album => album.id === photo.album)
      data[photo.id] = {
        thumb: photo.thumbURL,
        views: photo.views ? photo.views.length : 0,
        downloads: photo.downloads || 0,
        name: album ? album.name : `Stock`,
        url: `https://ujdml.co.za/photos/${photo.id}`
      }
    })
    return data
  }

  getTotalPhotos = () => this.photos.filter(photo => photo.album).length

  getTotalStock = () => this.photos.filter(photo => photo.stock === true).length

  convertToNumberString = number => {
    let string = number.toString()
    let str = ''
    let counter = 0
    for (let i = string.length - 1; i >= 0; i--) {
      str += string[i]
      counter++
      if (counter === 3 && i !== 0) {
        str += ','
        counter = 0
      }
    }
    str = str.split('').reverse().join('')
    return str
  }

  scrollStart = event => {
    let id = event.target.id
    if (!id.includes('graph') && !id.includes('table')) return
    let elementType = id.includes('graph') ? 'graph' : 'table'
    let scrollElement = document.getElementById(id.includes('_scrollElement') ? id : `${id}_scrollElement`)

    let firstClick = event[elementType === 'graph' ? 'clientX' : 'clientY']
    let scrollStartPosition = scrollElement[elementType === 'graph' ? 'scrollLeft' : 'scrollTop']

    window.addEventListener('mousemove', scrolling, { passive: true })
    window.addEventListener('mouseup', () => {
      window.removeEventListener('mousemove', scrolling, { passive: true })
    })
    window.addEventListener('mouseleave', () => {
      window.removeEventListener('mousemove', scrolling, { passive: true })
    })

    function scrolling(event) {
      let mousePosition = event[elementType === 'graph' ? 'clientX' : 'clientY']
      let scrollDistance = firstClick - mousePosition
      scrollElement.scrollTo(
        elementType === 'graph' ? scrollStartPosition + scrollDistance : 0,
        elementType === 'graph' ? 0 : scrollStartPosition + scrollDistance
      )
    }
  }

  albumModeChanged = event => this.setState({ albumMode: event.target.id })

  photoModeChanged = event => this.setState({ photoMode: event.target.id })

  render() {
    return (
      <StyledPhotos loading={this.state.loading ? 'true' : null}>
        {this.state.loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Overview>
              <table>
                <tbody>
                  <tr style={{ height: '30px' }}>
                    <td>
                      <strong>Albums:</strong>
                    </td>
                    <td style={{ paddingLeft: '60px', textAlign: 'right' }}>{this.convertToNumberString(this.totalAlbums)}</td>
                  </tr>
                  <tr style={{ height: '30px' }}>
                    <td>
                      <strong>Photos in all albums:</strong>
                    </td>
                    <td style={{ paddingLeft: '60px', textAlign: 'right' }}>{this.convertToNumberString(this.totalPhotos)}</td>
                  </tr>
                  <tr style={{ height: '30px' }}>
                    <td>
                      <strong>Stock photos:</strong>
                    </td>
                    <td style={{ paddingLeft: '60px', textAlign: 'right' }}>{this.convertToNumberString(this.totalStock)}</td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
            </Overview>
            <Tables>
              <AlbumsTable>
                <table
                  style={{
                    tableLayout: 'fixed',
                    border: `2px solid ${this.props.theme.main}`,
                    padding: '20px 20px',
                    userSelect: 'none',
                    width: '100%',
                    rowGap: 0,
                    columnGap: 0
                  }}
                >
                  <thead style={{ height: '40px', verticalAlign: 'top', width: '100%' }}>
                    <tr>
                      <td>
                        <div style={{ width: '96%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <strong>{`Album ${this.state.albumMode}:`}</strong>
                          <div style={{ display: 'inline-flex', justifyContent: 'space-evenly', alignItems: 'center', columnGap: '10px' }}>
                            <ModeSelector id='views' onClick={this.albumModeChanged} active={this.state.albumMode === 'views'}>
                              views
                            </ModeSelector>
                            <div>/</div>
                            <ModeSelector id='downloads' onClick={this.albumModeChanged} active={this.state.albumMode === 'downloads'}>
                              downloads
                            </ModeSelector>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody
                    style={{ display: 'block', height: '300px', overflowY: 'auto', overflowX: 'hidden', width: '100%' }}
                    id='table_albums_scrollElement'
                    onMouseDown={this.scrollStart}
                  >
                    {Object.keys(this.albumData)
                      .sort((a, b) => {
                        if (this.albumData[a][this.state.albumMode] > this.albumData[b][this.state.albumMode]) return -1
                        if (this.albumData[a][this.state.albumMode] === this.albumData[b][this.state.albumMode]) return 0
                        return 1
                      })
                      .map((album, index) => {
                        if (this.albumData[album][this.state.albumMode])
                          return (
                            <tr
                              key={album}
                              style={{
                                width: '100%',
                                display: 'table-row',
                                verticalAlign: 'middle'
                              }}
                            >
                              <td
                                style={{
                                  display: 'table-cell',
                                  padding: '4px 20px',
                                  width: '100px',
                                  textAlign: 'center'
                                }}
                                id='table_albums'
                                onMouseDown={this.scrollStart}
                              >
                                <img src={this.albumData[album].thumb} alt={album} width='auto' style={{ maxHeight: '100px', maxWidth: '100px' }} />
                              </td>
                              <td
                                style={{
                                  display: 'table-cell',
                                  padding: '4px 20px',
                                  width: '100%',
                                  backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                                }}
                                id='table_albums'
                                onMouseDown={this.scrollStart}
                              >
                                <a href={this.albumData[album].url} style={{ textDecoration: `underline solid ${this.props.theme.dark}` }}>
                                  {album}
                                </a>
                              </td>
                              <td
                                style={{
                                  textAlign: 'right',
                                  display: 'table-cell',
                                  padding: '0px 20px 0px 20px',
                                  minWidth: '80px',
                                  backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                                }}
                                id='table_albums'
                                onMouseDown={this.scrollStart}
                              >
                                {this.convertToNumberString(this.albumData[album][this.state.albumMode])}
                              </td>
                              <td style={{ minWidth: '20px', backgroundColor: this.props.theme.bg }} />
                            </tr>
                          )
                        return null
                      })}
                  </tbody>
                </table>
              </AlbumsTable>
              <PhotosTable>
                <table
                  style={{
                    tableLayout: 'fixed',
                    border: `2px solid ${this.props.theme.main}`,
                    padding: '20px 20px',
                    userSelect: 'none',
                    width: '100%',
                    rowGap: 0,
                    columnGap: 0
                  }}
                >
                  <thead style={{ height: '40px', verticalAlign: 'top', width: '100%' }}>
                    <tr>
                      <td>
                        <div style={{ width: '96%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <strong>{`Photo ${this.state.photoMode}:`}</strong>
                          <div style={{ display: 'inline-flex', justifyContent: 'space-evenly', alignItems: 'center', columnGap: '10px' }}>
                            <ModeSelector id='views' onClick={this.photoModeChanged} active={this.state.photoMode === 'views'}>
                              views
                            </ModeSelector>
                            <div>/</div>
                            <ModeSelector id='downloads' onClick={this.photoModeChanged} active={this.state.photoMode === 'downloads'}>
                              downloads
                            </ModeSelector>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody
                    style={{ display: 'block', height: '300px', overflowY: 'auto', overflowX: 'hidden', width: '100%' }}
                    id='table_photos_scrollElement'
                    onMouseDown={this.scrollStart}
                  >
                    {Object.keys(this.photoData)
                      .sort((a, b) => {
                        if (this.photoData[a][this.state.photoMode] > this.photoData[b][this.state.photoMode]) return -1
                        if (this.photoData[a][this.state.photoMode] === this.photoData[b][this.state.photoMode]) return 0
                        return 1
                      })
                      .map((photo, index) => {
                        if (this.photoData[photo][this.state.photoMode])
                          return (
                            <tr
                              key={photo}
                              style={{
                                width: '100%',
                                display: 'table-row',
                                verticalAlign: 'middle'
                              }}
                            >
                              <td
                                style={{
                                  display: 'table-cell',
                                  padding: '4px 20px',
                                  width: '100px',
                                  textAlign: 'center'
                                }}
                                id='table_photos'
                                onMouseDown={this.scrollStart}
                              >
                                <img src={this.photoData[photo].thumb} alt={photo} width='auto' style={{ maxHeight: '100px', maxWidth: '100px' }} />
                              </td>
                              <td
                                style={{
                                  display: 'table-cell',
                                  padding: '4px 20px',
                                  width: '100%',
                                  backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                                }}
                                id='table_photos'
                                onMouseDown={this.scrollStart}
                              >
                                <a href={this.photoData[photo].url} style={{ textDecoration: `underline solid ${this.props.theme.dark}` }}>
                                  {this.photoData[photo].name}
                                </a>
                              </td>
                              <td
                                style={{
                                  textAlign: 'right',
                                  display: 'table-cell',
                                  padding: '0px 20px 0px 20px',
                                  minWidth: '80px',
                                  backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                                }}
                                id='table_photos'
                                onMouseDown={this.scrollStart}
                              >
                                {this.convertToNumberString(this.photoData[photo][this.state.photoMode])}
                              </td>
                              <td style={{ minWidth: '20px', backgroundColor: this.props.theme.bg }} />
                            </tr>
                          )
                        return null
                      })}
                  </tbody>
                </table>
              </PhotosTable>
            </Tables>
          </React.Fragment>
        )}
      </StyledPhotos>
    )
  }
}

const StyledPhotos = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.loading ? 'center' : 'flex-start')};
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  color: ${props => props.theme.dark};
`

const Overview = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin-bottom: 20px;
  flex-shrink: 0;
`

const Tables = styled.div`
  box-sizing: border-box;
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 40px;
  flex-shrink: 0;
`

const ModeSelector = styled.div`
  font-weight: ${props => (props.active ? 'bold' : null)};
  text-decoration: ${props => (props.active ? 'underline' : null)};
  :hover {
    cursor: pointer;
  }
`

const AlbumsTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const PhotosTable = styled(AlbumsTable)``

export default withTheme(Photos)
