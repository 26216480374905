// Auth
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'

// Upload
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
export const ADD_FILES = 'ADD_FILES'
export const CLEAR_FILES = 'CLEAR_FILES'
export const REMOVE_FILE = 'REMOVE_FILE'

// Download
export const DOWNLOAD = 'DOWNLOAD'

// Forms
export const SAVE_APPROVED_FORM_ITEMS = 'SAVE_APPROVED_FORM_ITEMS'
export const SAVE_PENDING_FORM_ITEMS = 'SAVE_PENDING_FORM_ITEMS'
export const ADD_PENDING_FORM_ITEMS = 'ADD_PENDING_FORM_ITEMS'
export const REMOVE_PENDING_FORM_ITEM = 'REMOVE_PENDING_FORM_ITEM'
export const ADD_APPROVED_FORM_ITEMS = 'ADD_APPROVED_FORM_ITEMS'

// UI
export const SHOW_MENU = 'SHOW_MENU'
export const SHOW_AUTH_MODAL = 'SHOW_AUTH_MODAL'
export const SHOW_CHANGE_PASSWORD_MODAL = 'SHOW_CHANGE_PASSWORD_MODAL'
export const SHOW_CONFIRM_DELETE_ACCOUNT_MODAL =
  'SHOW_CONFIRM_DELETE_ACCOUNT_MODAL'
export const SHOW_POLICIES_MODAL = 'SHOW_POLICIES_MODAL'
export const SHOW_VIDEO_POLICIES_MODAL = 'SHOW_VIDEO_POLICIES_MODAL'
export const SET_SMALL_DEVICE = 'SET_SMALL_DEVICE'
export const SET_SMALL_DEVICE_LANDSCAPE = 'SET_SMALL_DEVICE_LANDSCAPE'
export const SET_INPUT_METHOD = 'SET_INPUT_METHOD'
export const TOGGLE_SHOW_SEARCH_BAR = 'TOGGLE_SHOW_SEARCH_BAR'

// Browse
export const POPULATE_PHOTO_COLLECTION = 'POPULATE_PHOTO_COLLECTION'

// Bookings
export const SET_EXISTING_BOOKING = 'SET_EXISTING_BOOKING'
export const SET_FILES = 'SET_FILES'
export const CLEAR_BOOKING_FILES = 'CLEAR_BOOKING_FILES'

// Collections
export const STORE_COLLECTIONS = 'STORE_COLLECTIONS'
export const ADD_COLLECTION = 'ADD_COLLECTION'
export const LOAD_PHOTOS = 'LOAD_PHOTOS'
export const DELETE_COLLECTION = 'DELETE_COLLECTION'
export const UPDATE_STORE = 'UPDATE_STORE'

// Home
export const STORE_ALBUMS = 'STORE_ALBUMS'
export const SAVE_SCROLL_POSITION = 'SAVE_SCROLL_POSITION'

// Config
export const SET_CONFIG = 'SET_CONFIG'
export const SET_EXTERNAL_USER = 'SET_EXTERNAL_USER'
export const REMOVE_EXTERNAL_USER = 'REMOVE_EXTERNAL_USER'
               

// Search
export const SEARCH = 'SEARCH'
export const UPDATE_PHOTO_RESULTS = 'UPDATE_PHOTO_RESULTS'
export const SET_SEARCH_KEYS = 'SET_SEARCH_KEYS'


// Errors
export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'