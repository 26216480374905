export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  }
}

export const updateObjectInArray = (oldArray, index, updatedObject) => {
  return oldArray.map((item, i) => {
    if (i !== index) return item
    return {
      ...item,
      ...updatedObject
    }
  })
}
