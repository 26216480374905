import * as actionTypes from 'store/actions/actionTypes'
import * as actions from 'store/actions'
import { db } from 'utils/firebase'
import { collection, getDocs, where, query, updateDoc, deleteDoc, addDoc, doc } from 'firebase/firestore'
import store from 'store/store'

export const loadPhotos = photos => {
  return {
    type: actionTypes.LOAD_PHOTOS,
    photos
  }
}

export const getCollections = owner => {
  return dispatch => {
    getDocs(query(collection(db, 'collections'), where('owner', '==', owner)))
      .then(response => {
        let collections = []
        response.docs.forEach(_collection => {
          collections.push({ ..._collection.data(), id: _collection.id })
        })
        return dispatch(storeCollections(collections))
      })
      .catch(error => store.dispatch(actions.setError(error, true, 'Failed to get collections from database.')))
  }
}

export const storeCollections = collections => {
  return {
    type: actionTypes.STORE_COLLECTIONS,
    collections
  }
}

export const createCollection = _collection => {
  return dispatch => {
    addDoc(collection(db, 'collections'), _collection)
      .then(response => {
        return dispatch(addCollection({ ..._collection, id: response.id }))
      })
      .catch(error => store.dispatch(actions.setError(error, false, 'Failed to create a new collection.')))
  }
}

export const addCollection = _collection => {
  return {
    type: actionTypes.ADD_COLLECTION,
    collection: _collection
  }
}

export const addToCollection = (_collection, index, photos) => {
  return dispatch => {
    updateDoc(doc(db, 'collections', _collection), {
      photos,
      modified: new Date()
    })
      .then(() => dispatch(updateStore(photos, index)))
      .catch(error => store.dispatch(actions.setError(error, false, 'Failed to add photos to an existing collection.')))
  }
}

export const removeFromCollection = (_collection, index, newPhotoArray) => {
  return dispatch => {
    updateDoc(doc(db, 'collections', _collection), {
      photos: newPhotoArray,
      modified: new Date()
    })
      .then(() => dispatch(updateStore(newPhotoArray, index)))
      .catch(error => store.dispatch(actions.setError(error, false, 'Failed to remove photos from a collection.')))
  }
}

export const updateStore = (photos, index) => {
  return {
    type: actionTypes.UPDATE_STORE,
    photos,
    index
  }
}

export const deleteCollection = collectionId => {
  return dispatch => {
    deleteDoc(doc(db, 'collections', collectionId))
      .then(() => dispatch(deleteCollectionFromStore(collectionId)))
      .catch(error => store.dispatch(actions.setError(error, false, 'Failed to delete a collection.')))
  }
}

export const deleteCollectionFromStore = collectionId => {
  return {
    type: actionTypes.DELETE_COLLECTION,
    collectionId
  }
}
