import thunk from 'redux-thunk'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import authReducer from 'store/reducers/authReducer'
import uploadReducer from 'store/reducers/uploadReducer'
import downloadReducer from 'store/reducers/downloadReducer'
import uiReducer from 'store/reducers/uiReducer'
import browseReducer from 'store/reducers/browseReducer'
import bookingsReducer from 'store/reducers/bookingsReducer'
import formsReducer from 'store/reducers/formsReducer'
import collectionsReducer from 'store/reducers/collectionsReducer'
import homeReducer from 'store/reducers/homeReducer'
import configReducer from 'store/reducers/configReducer'
import searchReducer from 'store/reducers/searchReducer'
import errorReducer from 'store/reducers/errorReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  upload: uploadReducer,
  download: downloadReducer,
  ui: uiReducer,
  browse: browseReducer,
  bookings: bookingsReducer,
  forms: formsReducer,
  collections: collectionsReducer,
  home: homeReducer,
  config: configReducer,
  search: searchReducer,
  error: errorReducer
})

const store =
  process.env.NODE_ENV === 'production'
    ? createStore(rootReducer, applyMiddleware(thunk))
    : createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export default store
