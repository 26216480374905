import React, { Component } from 'react'
import { connect } from 'react-redux'

class PreRenderPhotos extends Component {
  renderCount = 0
  preRenderedPhotos = null
  i = 0

  preRenderedPhotoCounter = event => {
    event.persist()
    this.renderCount += 1
    this.props.preRenderProgress(
      (this.renderCount / this.props.currentPhotoCollection.length) * 100,
      event
    )
  }

  render() {
    if (this.props.currentPhotoCollection) {
      this.preRenderedPhotos = this.props.currentPhotoCollection.map(photo => {
        this.i += 1
        return (
          <img
            src={photo.webURL}
            alt="preRenderedPhoto"
            id="preRenderedPhoto"
            onLoad={this.preRenderedPhotoCounter}
            key={this.i}
            style={{ display: 'none' }}
          />
        )
      })
    }

    return <React.Fragment>{this.preRenderedPhotos}</React.Fragment>
  }
}

const mapStateToProps = state => {
  return {
    currentPhotoCollection: state.browse.currentPhotoCollection
  }
}

export default connect(mapStateToProps)(PreRenderPhotos)
