import ReactDOMServer from 'react-dom/server'
import React from 'react'
import { functions } from 'utils/firebase'
import store from 'store/store'
import { setError } from 'store/actions'

var booking, type, reason, agentName, agentEmail, toRecipients, subject, messageContent

const sendEmail = async props => {
  booking = props.booking
  type = props.type
  reason = props.reason
  agentName = props.agentName
  agentEmail = props.agentName
  toRecipients = createToRecipientList()
  subject = `Booking ${type}: ${booking.name}`
  messageContent = createMessageContent()

  if (toRecipients) return await sendMailViaCloudFunction()
}

function createMessageContent() {
  return ReactDOMServer.renderToStaticMarkup(
    <html
      style={{
        height: '100% !important',
        width: 'auto !important',
        margin: '0 auto !important',
        padding: '0 !important'
      }}
    >
      <head>
        <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet' />
      </head>
      <body
        style={{
          height: '100% !important',
          width: 'auto !important',
          margin: '0 auto !important',
          padding: '10px 10px !important',
          msoLineHeightRule: 'exactly',
          lineHeight: '90%',
          fontFamily: 'Roboto, Arial'
        }}
      >
        <table
          id='body'
          style={{
            height: '100%',
            width: '100%',
            tableLayout: 'fixed'
          }}
        >
          <tbody>
            <tr>
              <td>
                <table
                  id='container'
                  style={{
                    width: '100%',
                    maxWidth: '1000px',
                    backgroundColor: '#e0dede',
                    fontSize: '14px',
                    color: '#57575a',
                    border: '2px solid #57575a',
                    borderCollapse: 'collapse',
                    tableLayout: 'fixed'
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          id='title'
                          style={{
                            tableLayout: 'fixed',
                            width: '100%',
                            backgroundColor: '#e0dede',
                            border: '0',
                            borderCollapse: 'collapse',
                            fontSize: '22px',
                            color: '#57575a'
                          }}
                        >
                          <tbody>
                            <tr style={{ height: '40px' }}>
                              <td style={{ width: '20px' }}></td>
                              <td>
                                <strong>{`Booking ${type}: ${booking.name}`}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          style={{
                            backgroundColor: '#f4f4f4',
                            tableLayout: 'fixed',
                            border: '0',
                            borderCollapse: 'collapse',
                            width: '100%',
                            color: '#57575a'
                          }}
                          id='body'
                        >
                          <tbody>
                            <tr>{renderBody()}</tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          id='footer'
                          style={{
                            backgroundColor: '#e0dede',
                            width: '100%',
                            cellPadding: '0',
                            cellSpacing: '0',
                            border: '0',
                            borderCollapse: 'collapse',
                            tableLayout: 'fixed',
                            padding: '0',
                            margin: '0'
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ width: '40px' }}>
                                <a href='https://ujdml.co.za/' style={{ textDecoration: 'none' }}>
                                  <img
                                    src='https://ujdml.co.za/icon-72x72.png'
                                    height='40'
                                    width='40'
                                    style={{ height: '40px', padding: '0', margin: '0' }}
                                    alt='uj-logo'
                                  />
                                </a>
                              </td>
                              <td style={{ width: '6px' }}></td>
                              <td style={{ paddingTop: '6px' }}>
                                <a href='https://ujdml.co.za/' style={{ textDecoration: 'none' }}>
                                  <span
                                    style={{
                                      color: '#57575a',
                                      fontSize: '22px'
                                    }}
                                  >
                                    <strong>UJ Digital Media Library</strong>
                                  </span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  )
}

function renderBody() {
  if (['submitted', 'accepted'].includes(type)) {
    return (
      <td style={{ fontSize: '14px', padding: '20px 20px 20px 20px' }}>
        <p>{`Dear ${booking.clientName},`}</p>
        <p>
          {`Your booking was ${type}`}
          {type === 'accepted' ? (
            <span>
              {` by `}
              <a href={`mailto:${agentEmail}`} style={{ color: '#57575a' }}>{`${agentName}.`}</a>
            </span>
          ) : (
            <span>{` successfully. You will be contacted by a ${booking.photographer ? 'photographer' : 'videographer'} soon.`}</span>
          )}
        </p>
        <p>
          {`You can modify, cancel or view the status of your booking `}
          <a href='https://ujdml.co.za/bookings' style={{ color: '#57575a' }}>
            here
          </a>
          {`.`}
        </p>
      </td>
    )
  } else
    return (
      <td style={{ fontSize: '20px', padding: '20px 20px 0px 20px' }}>
        <p>{`Dear ${type === 'declined' ? booking.clientName : booking.photographer || booking.videographer},`}</p>
        <p>
          {`Your booking was ${type} by `}
          <span>
            <a href={`mailto:${type === 'cancelled' ? booking.clientEmail : agentEmail}`} style={{ color: '#57575a' }}>
              {type === 'cancelled' ? 'the client' : agentName}
            </a>
            {`.`}
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>REASON:</span> {reason}
        </p>
      </td>
    )
}

function createToRecipientList() {
  if (['submitted', 'accepted'].includes(type)) return [{ emailAddress: { address: booking.clientEmail } }]
  if (type === 'declined') return [{ emailAddress: { address: booking.clientEmail } }]
  if (type === 'cancelled' && ((booking.photographer && booking.photographer !== 'Pending') || (booking.videographer && booking.videographer !== 'Pending')))
    return [
      {
        emailAddress: {
          address: process.env.NODE_ENV === 'development' ? store.getState().config.webAdmin && store.getState().config.webAdmin[0].email : booking.photographerEmail || booking.videographerEmail
        }
      }
    ]
}

var retries = 0
async function sendMailViaCloudFunction() {
  await functions
    .httpsCallable('sendMail')({
      toRecipients,
      ccRecipients: null,
      subject,
      messageContent
    })
    .then(() => 'SUCCESS')
    .catch(async error => {
      if (retries < 4) {
        retries++
        setTimeout(async () => {
          return await sendMailViaCloudFunction()
        }, 400)
      } else {
        store.dispatch(setError(error, false, 'Failed to send booking email in BookingForm component.'))
      }
    })
}

export default sendEmail
