import React, { Component } from 'react'
import styled from 'styled-components'

class Mobile extends Component {
  state = {
    value: this.props.value || ''
  }

  changeHandler = event => {
    let string = event.target.value
    if (!this.validInput(string)) return
    string = this.addSpaceIfNeeded(string)
    string = this.removeSpaceIfNeeded(string)
    string = this.correctPastedNumber(string)
    this.setState({ value: string }, () => {
      if (this.props.onChange) this.props.onChange(string)
    })
  }

  validInput = string => {
    if (/[^0-9\s]/g.test(string)) return false // not digit or space
    if (/\s$/g.test(string) && string.length > this.state.value.length) return false // space entered manually
    if (string.length > 12) return false
    return true
  }

  addSpaceIfNeeded = string => {
    if (string.length === 3 || string.length === 7) return string + ' '
    return string
  }

  removeSpaceIfNeeded = string => {
    if ([4, 8].includes(string.length) && this.state.value >= string) return string.slice(0, string.length - 1)
    return string
  }

  correctPastedNumber = string => {
    if (string.length === 10 && /\d{10}/.test(string))
      return `${string.slice(0, 3)} ${string.slice(3, 6)} ${string.slice(6)}`
    return string
  }

  render() {
    return (
      <StyledMobile
        type="tel"
        id={this.props.id}
        value={this.state.value}
        onChange={this.changeHandler}
        required={this.props.required}
        pattern="[0]{1}[1-9]{1}[0-9]{1} [0-9]{3} [0-9]{4}"
        placeholder="000 000 0000"
        title='000 000 0000'
        onFocus={this.props.onFocus}
      />
    )
  }
}

const StyledMobile = styled.input`
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '18px' : '26px')};
  width: 100%;
  padding: 4px 6px;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  outline: none;
  background-color: white;
  font-size: inherit;
  color: ${props => props.theme.dark};

  :focus {
    border: 2px solid ${props => props.theme.dark};
  }
`

export default Mobile
