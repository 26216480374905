import firebase from 'firebase/compat/app'
import 'firebase/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'

const prodConfig = {
  apiKey: 'AIzaSyCzmWLxUMKMdl1i8RKKBgQ8J5Y63Y3SXns',
  authDomain: 'ujdml-prod.firebaseapp.com',
  databaseURL: 'https://ujdml-prod.firebaseio.com',
  projectId: 'ujdml-prod',
  storageBucket: 'ujdml-prod.appspot.com',
  messagingSenderId: '819304538049',
  appId: '1:819304538049:web:6c51f346b867270a529940'
}

const devConfig = {
  apiKey: 'AIzaSyCkcd_twJqVG1T3JqdEn89tuDYKbRvgof4',
  authDomain: 'ujdml-development.firebaseapp.com',
  databaseURL: 'https://ujdml-development.firebaseio.com',
  projectId: 'ujdml-development',
  storageBucket: 'ujdml-development.appspot.com',
  messagingSenderId: '938447123521',
  appId: '1:938447123521:web:138f5496cac71577e2c2c0'
}

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig

export const firebaseApp = firebase.initializeApp(config)
export const storage = firebase.storage()
export const functions = firebase.functions()
export default firebase
