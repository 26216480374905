import ReactDOMServer from 'react-dom/server'
import React from 'react'
import { functions, db } from 'utils/firebase'
import store from 'store/store'
import { setError } from 'store/actions'
import { doc, setDoc } from 'firebase/firestore'

const sendVerificationCode = async userEmail => {
  const toRecipients = [{ emailAddress: { address: userEmail } }]
  const subject = 'UJDML verification code'
  const verificationCode = Math.floor(1000 + (10000 - 1000) * Math.random()).toString()
  const messageContent = createMessageContent()

  async function updateDatabase() {
    return setDoc(
      doc(db, 'users', userEmail),
      {
        verificationCode: verificationCode
      },
      { merge: true }
    ).catch(error => store.dispatch(setError(error, true, 'Failed to set verification code for new user.')))
  }

  function createMessageContent() {
    return ReactDOMServer.renderToStaticMarkup(
      <html
        style={{
          height: '100% !important',
          width: 'auto !important',
          margin: '0 auto !important',
          padding: '0 !important'
        }}
      >
        <head>
          <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet' />
        </head>
        <body
          style={{
            height: '100% !important',
            width: 'auto !important',
            margin: '0 auto !important',
            padding: '10px 10px !important',
            msoLineHeightRule: 'exactly',
            lineHeight: '90%',
            fontFamily: 'Roboto, Arial'
          }}
        >
          <table
            id='body'
            style={{
              height: '100%',
              width: '100%',
              tableLayout: 'fixed'
            }}
          >
            <tbody>
              <tr>
                <td>
                  <table
                    id='container'
                    style={{
                      width: '100%',
                      maxWidth: '1000px',
                      backgroundColor: '#e0dede',
                      fontSize: '14px',
                      color: '#57575a',
                      border: '2px solid #57575a',
                      borderCollapse: 'collapse',
                      tableLayout: 'fixed'
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            id='title'
                            style={{
                              tableLayout: 'fixed',
                              width: '100%',
                              backgroundColor: '#e0dede',
                              border: '0',
                              borderCollapse: 'collapse',
                              fontSize: '22px',
                              color: '#57575a'
                            }}
                          >
                            <tbody>
                              <tr style={{ height: '40px' }}>
                                <td style={{ width: '20px' }}></td>
                                <td>
                                  <strong>Email verification</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table
                            style={{
                              backgroundColor: '#f4f4f4',
                              tableLayout: 'fixed',
                              border: '0',
                              borderCollapse: 'collapse',
                              width: '100%',
                              color: '#57575a'
                            }}
                            id='body'
                          >
                            <tbody>
                              <tr>
                                <td style={{ padding: '30px 20px' }}>
                                  <span>Your verification code is</span>
                                  <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{` ${verificationCode}`}</span>
                                  <span>{`. Please return to your browser in order to verify your account using this code.`}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table
                            id='footer'
                            style={{
                              backgroundColor: '#e0dede',
                              width: '100%',
                              cellPadding: '0',
                              cellSpacing: '0',
                              border: '0',
                              borderCollapse: 'collapse',
                              tableLayout: 'fixed',
                              padding: '0',
                              margin: '0'
                            }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ width: '40px' }}>
                                  <a href='https://ujdml.co.za/' style={{ textDecoration: 'none' }}>
                                    <img
                                      src='https://ujdml.co.za/icon-72x72.png'
                                      height='40'
                                      width='40'
                                      style={{ height: '40px', padding: '0', margin: '0' }}
                                      alt='uj-logo'
                                    />
                                  </a>
                                </td>
                                <td style={{ width: '6px' }}></td>
                                <td style={{ paddingTop: '6px' }}>
                                  <a href='https://ujdml.co.za/' style={{ textDecoration: 'none' }}>
                                    <span
                                      style={{
                                        color: '#57575a',
                                        fontSize: '22px'
                                      }}
                                    >
                                      <strong>UJ Digital Media Library</strong>
                                    </span>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </body>
      </html>
    )
  }

  await updateDatabase()

  var retries = 0
  return await sendMailViaCloudFunction()

  async function sendMailViaCloudFunction() {
    functions
      .httpsCallable('sendMail')({
        toRecipients,
        ccRecipients: null,
        subject,
        messageContent
      })
      .then(() => 'SUCCESS')
      .catch(async error => {
        if (retries < 5) {
          retries++
          setTimeout(async () => {
            return await sendMailViaCloudFunction()
          }, 400)
        } else store.dispatch(setError(error, true, 'Failed to send verification code to new user.'))
      })
  }
}

export default sendVerificationCode
