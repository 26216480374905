import * as actions from 'store/actions/actionTypes'
import { updateObject } from 'utils/shared'

const initialState = {
  currentPhotoCollection: null,
  currentPhoto: null
}

const populatePhotoCollection = (state, action) => {
  return updateObject(state, { currentPhotoCollection: action.photoArray })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.POPULATE_PHOTO_COLLECTION:
      return populatePhotoCollection(state, action)
    default:
      return state
  }
}

export default reducer
