import Loader from 'components/loader/Loader'
import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import * as actions from 'store/actions'
import Input from 'components/NewInput'
import Alert from 'components/Alert'
import { connect } from 'react-redux'
import { db } from 'utils/firebase'
import { collection, doc, getDoc, getDocs, query, where, writeBatch } from 'firebase/firestore'

class Users extends Component {
  state = { loading: true }

  componentDidMount() {
    this.populateData()
  }

  populateData = () => {
    getDocs(collection(db, 'users'))
      .then(users => {
        this.users = users.docs.map(user => user.data())
        this.getTotalUsers()
        this.getNewUsers()
        this.getNewUsersPerMonth()
        this.getActiveUsers()
        this.getFaculties()
        this.getDepartments()
        getDoc(doc(db, 'analytics', 'activeUsers'))
          .then(res => {
            this.activeUsers = res.data()
            this.getActiveUsersInLastMonth()
            this.setState({ loading: false }, () => {
              let graph1 = document.getElementById('graph_newUsers_scrollElement')
              graph1.scrollTo(graph1.scrollWidth, 0)
              let graph2 = document.getElementById('graph_activeUsers_scrollElement')
              graph2.scrollTo(graph2.scrollWidth, 0)
            })
          })
          .catch(error => this.props.setError(error, false, 'Failed to get user analytics in Admin component.'))
      })
      .catch(error => this.props.setError(error, false, 'Failed to get users in Admin component.'))
  }

  getTotalUsers = () => {
    this.totalUsers = this.users.length
  }

  getNewUsers = () => {
    this.newUsers = 0
    const today = Date.now()
    const thirtyDays = 2592000000
    this.users.forEach(user => {
      let createdOn = new Date(user.createdOn).valueOf()
      if (createdOn > today - thirtyDays) this.newUsers++
    })
  }

  getNewUsersPerMonth = () => {
    let months = createMonthsObject()
    getUserMonths(this.users)
    return renderMonths(this.props.theme, this.scrollStart)

    function createMonthsObject() {
      let monthsObject = {}
      let currentYear = new Date().getFullYear()
      let currentMonth = new Date().getMonth() + 1
      for (let y = 2020; y <= currentYear; y++) {
        for (let m = 1; m <= 12; m++) {
          if (y < currentYear || m <= currentMonth) monthsObject[`${m < 10 ? '0' : ''}${m}/${y.toString().slice(2)}`] = 0
        }
      }
      return monthsObject
    }

    function getUserMonths(users) {
      users.forEach(user => {
        const createdOn = new Date(user.createdOn)
        const month = `${createdOn.getMonth() + 1 < 10 ? '0' : ''}${createdOn.getMonth() + 1}/${createdOn.getFullYear().toString().slice(2)}`
        if (!months[month]) months[month] = 1
        else months[month]++
      })
    }

    function getHighestMonth() {
      let highestMonth = 0
      Object.values(months).forEach(month => {
        if (highestMonth <= month) highestMonth = month
      })
      return highestMonth
    }

    function renderMonths(theme, scrollStart) {
      return Object.keys(months).map(month => {
        let percentage = (months[month] / getHighestMonth()) * 100
        return (
          <GraphItem key={month} id='graph_newUsers' onMouseDown={scrollStart}>
            <strong id='graph_newUsers' onMouseDown={scrollStart}>
              {month}
            </strong>
            <div style={{ height: `${percentage}px`, width: '80px', backgroundColor: theme.dark }} id='graph_newUsers' onMouseDown={scrollStart} />
            <span id='graph_newUsers' onMouseDown={scrollStart}>
              {months[month]}
            </span>
          </GraphItem>
        )
      })
    }
  }

  getActiveUsers = () => {
    this.activeUsersInLastMonth = 0
    const today = Date.now()
    const thirtyDays = 2592000000
    this.users.forEach(user => {
      if (user.lastActive) {
        let lastActive = user.lastActive.toDate().valueOf()
        if (lastActive > today - thirtyDays) this.activeUsersInLastMonth++
      }
    })
  }

  getActiveUsersInLastMonth = () => {
    let days = createDaysObject()
    getActiveUsersPerDay(this.activeUsers)
    return renderDays(this.props.theme, this.scrollStart)

    function createDaysObject() {
      let daysObject = {}
      for (let i = 30; i >= 0; i--) {
        let now = new Date()
        daysObject[new Date(now.setDate(now.getDate() - i)).toLocaleDateString('en-za')] = 0
      }
      return daysObject
    }

    function getActiveUsersPerDay(activeUsers) {
      Object.keys(days).forEach(date => {
        if (activeUsers[date]) days[date] = activeUsers[date].length
      })
    }

    function getHighestDay() {
      let highestDay = 0
      Object.values(days).forEach(day => {
        if (highestDay <= day) highestDay = day
      })
      return highestDay
    }

    function renderDays(theme, scrollStart) {
      return Object.keys(days).map(day => {
        let percentage = (days[day] / getHighestDay()) * 100
        return (
          <GraphItem key={day} id='graph_activeUsers' onMouseDown={scrollStart}>
            <strong id='graph_activeUsers' onMouseDown={scrollStart}>
              {day}
            </strong>
            <div style={{ height: `${percentage}px`, width: '80px', backgroundColor: theme.dark }} id='graph_activeUsers' onMouseDown={scrollStart} />
            <span id='graph_activeUsers' onMouseDown={scrollStart}>
              {days[day]}
            </span>
          </GraphItem>
        )
      })
    }
  }

  getFaculties = () => {
    this.faculties = {}
    this.users.forEach(user => {
      if (!this.faculties[user.faculty]) this.faculties[user.faculty] = 1
      else this.faculties[user.faculty] = this.faculties[user.faculty] + 1
    })
  }

  facultyOptions = () => {
    let options = [
      <option key={'null'} value={''}>
        - select value to change -
      </option>
    ]
    Object.keys(this.faculties)
      .sort()
      .forEach(faculty => {
        options.push(<option key={faculty}>{faculty}</option>)
      })
    return options
  }

  getDepartments = () => {
    this.departments = {}
    this.users.forEach(user => {
      if (!this.departments[user.department]) this.departments[user.department] = 1
      else this.departments[user.department] = this.departments[user.department] + 1
    })
  }

  departmentOptions = () => {
    let options = [
      <option key={'null'} value={''}>
        - select value to change -
      </option>
    ]
    Object.keys(this.departments)
      .sort()
      .forEach(department => {
        options.push(<option key={department}>{department}</option>)
      })
    return options
  }

  alertChangeToDatabase = () => {
    this.alert = (
      <Alert
        alertType='Change database value'
        alertButtonClicked={event => {
          if (event.target.id === 'continue') {
            this.alert = null
            this.setState({ loading: true })
            this.normaliseValue()
          } else {
            this.alert = null
            return this.forceUpdate()
          }
        }}
      />
    )
    this.forceUpdate()
  }

  normaliseValue = async () => {
    getDocs(query(collection(db, 'users'), where(this.changing, '==', this.state[this.changing]))).then(async _user => {
      let batch = writeBatch(db)
      for (const user of _user.docs) {
        batch.update(doc(db, 'users', user.data().email), {
          [this.changing]: this.state[`${this.changing}Value`]
        })
      }
      await batch.commit().catch(error => this.props.setError(error, false, 'Failed to normalise user data in Admin component.'))
      this.props.addApprovedFormItems({
        [this.changing]: this.state[`${this.changing}Value`]
      })
      this.populateData()
    })
  }

  convertToNumberString = number => {
    let string = number.toString()
    let str = ''
    let counter = 0
    for (let i = string.length - 1; i >= 0; i--) {
      str += string[i]
      counter++
      if (counter === 3 && i !== 0) {
        str += ','
        counter = 0
      }
    }
    str = str.split('').reverse().join('')
    return str
  }

  scrollStart = event => {
    let id = event.target.id
    if (!id.includes('graph') && !id.includes('table')) return
    let elementType = id.includes('graph') ? 'graph' : 'table'
    let scrollElement = document.getElementById(id.includes('_scrollElement') ? id : `${id}_scrollElement`)

    let firstClick = event[elementType === 'graph' ? 'clientX' : 'clientY']
    let scrollStartPosition = scrollElement[elementType === 'graph' ? 'scrollLeft' : 'scrollTop']

    window.addEventListener('mousemove', scrolling, { passive: true })
    window.addEventListener('mouseup', () => {
      window.removeEventListener('mousemove', scrolling, { passive: true })
    })
    window.addEventListener('mouseleave', () => {
      window.removeEventListener('mousemove', scrolling, { passive: true })
    })

    function scrolling(event) {
      let mousePosition = event[elementType === 'graph' ? 'clientX' : 'clientY']
      let scrollDistance = firstClick - mousePosition
      scrollElement.scrollTo(
        elementType === 'graph' ? scrollStartPosition + scrollDistance : 0,
        elementType === 'graph' ? 0 : scrollStartPosition + scrollDistance
      )
    }
  }

  render() {
    return (
      <StyledUsers loading={this.state.loading ? 'true' : null}>
        {this.state.loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Overview>
              <table>
                <tbody>
                  <tr style={{ height: '30px' }}>
                    <td>
                      <strong>Registered users:</strong>
                    </td>
                    <td style={{ paddingLeft: '60px', textAlign: 'right' }}>{this.convertToNumberString(this.totalUsers)}</td>
                  </tr>
                  <tr style={{ height: '30px' }}>
                    <td>
                      <strong>Active users (last 30 days):</strong>
                    </td>
                    <td style={{ paddingLeft: '60px', textAlign: 'right' }}>{this.convertToNumberString(this.activeUsersInLastMonth)}</td>
                  </tr>
                  <tr style={{ height: '30px' }}>
                    <td>
                      <strong>New users (last 30 days):</strong>
                    </td>
                    <td style={{ paddingLeft: '60px', textAlign: 'right' }}>{this.convertToNumberString(this.newUsers)}</td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
            </Overview>
            <GraphContainer id='graph_newUsers' onMouseDown={this.scrollStart}>
              <strong id='graph_newUsers' onMouseDown={this.scrollStart}>
                New users per month:
              </strong>
              <Graph id='graph_newUsers_scrollElement' onMouseDown={this.scrollStart}>
                {this.getNewUsersPerMonth()}
              </Graph>
            </GraphContainer>
            <GraphContainer id='graph_activeUsers' onMouseDown={this.scrollStart}>
              <strong id='graph_activeUsers' onMouseDown={this.scrollStart}>
                Active users in the last 30 days:
              </strong>
              <Graph id='graph_activeUsers_scrollElement' onMouseDown={this.scrollStart}>
                {this.getActiveUsersInLastMonth()}
              </Graph>
            </GraphContainer>
            <Tables>
              <Faculties>
                <table
                  style={{
                    tableLayout: 'fixed',
                    border: `2px solid ${this.props.theme.main}`,
                    padding: '20px 20px',
                    userSelect: 'none'
                  }}
                >
                  <thead style={{ height: '40px', verticalAlign: 'top' }}>
                    <tr>
                      <td>
                        <strong>Users per faculty/division:</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody
                    style={{ display: 'block', height: '260px', overflowY: 'auto', overflowX: 'hidden', width: 'auto' }}
                    id='table_faculty_scrollElement'
                    onMouseDown={this.scrollStart}
                  >
                    {Object.keys(this.faculties)
                      .sort((a, b) => {
                        if (this.faculties[a] > this.faculties[b]) return -1
                        if (this.faculties[a] === this.faculties[b]) return 0
                        return 1
                      })
                      .map((faculty, index) => {
                        return (
                          <tr
                            key={faculty}
                            style={{
                              display: 'table-row'
                            }}
                            id='table_faculty'
                            onMouseDown={this.scrollStart}
                          >
                            <td
                              style={{
                                display: 'table-cell',
                                padding: '4px 20px',
                                backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                              }}
                              title={faculty}
                              id='table_faculty'
                              onMouseDown={this.scrollStart}
                            >
                              {faculty}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                display: 'table-cell',
                                padding: '0px 20px 0px 20px',
                                backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                              }}
                              id='table_faculty'
                              onMouseDown={this.scrollStart}
                            >
                              {this.convertToNumberString(this.faculties[faculty])}
                            </td>
                            <td style={{ padding: '4px 10px' }} id='table_faculty' onMouseDown={this.scrollStart}></td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
                <NormaliseData>
                  <span>
                    <strong>Normalise faculty data:</strong>
                  </span>
                  <Input
                    type='select'
                    id='facultySelector'
                    width='500px'
                    onChange={event => {
                      this.setState({ faculty: event.target.value })
                    }}
                  >
                    {this.facultyOptions()}
                  </Input>
                  {this.state.faculty ? (
                    <NewValueInput>
                      <Input
                        type='datalist'
                        id='facultyValue'
                        placeholder='Please enter a new value'
                        onChange={event => {
                          this.setState({ facultyValue: event.target.value })
                        }}
                        width='300px'
                      >
                        {this.props.approved.faculty || <option value={null}></option>}
                      </Input>
                      <Input
                        type='button'
                        id='submitFacultyValue'
                        onClick={() => {
                          this.changing = 'faculty'
                          this.alertChangeToDatabase()
                        }}
                        width='100px'
                        disabled={!this.state.facultyValue || this.state.facultyValue.trim() === ''}
                      >
                        Submit
                      </Input>
                    </NewValueInput>
                  ) : null}
                </NormaliseData>
              </Faculties>
              <Departments>
                <table
                  style={{
                    tableLayout: 'fixed',
                    border: `2px solid ${this.props.theme.main}`,
                    padding: '20px 20px',
                    userSelect: 'none'
                  }}
                >
                  <thead style={{ height: '40px', verticalAlign: 'top' }}>
                    <tr>
                      <td>
                        <strong>Users per department:</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody
                    style={{ display: 'block', height: '260px', overflowY: 'auto', overflowX: 'hidden', width: 'auto' }}
                    id='table_department_scrollElement'
                    onMouseDown={this.scrollStart}
                  >
                    {Object.keys(this.departments)
                      .sort((a, b) => {
                        if (this.departments[a] > this.departments[b]) return -1
                        if (this.departments[a] === this.departments[b]) return 0
                        return 1
                      })
                      .map((department, index) => {
                        return (
                          <tr
                            key={department}
                            style={{
                              display: 'table-row'
                            }}
                            id='table_department'
                            onMouseDown={this.scrollStart}
                          >
                            <td
                              style={{
                                display: 'table-cell',
                                padding: '4px 20px',
                                backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                              }}
                              title={department}
                              id='table_department'
                              onMouseDown={this.scrollStart}
                            >
                              {department}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                display: 'table-cell',
                                padding: '0px 20px 0px 20px',
                                backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                              }}
                              id='table_department'
                              onMouseDown={this.scrollStart}
                            >
                              {this.convertToNumberString(this.departments[department])}
                            </td>
                            <td style={{ padding: '4px 10px' }} id='table_department' onMouseDown={this.scrollStart}></td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
                <NormaliseData>
                  <span>
                    <strong>Normalise department data:</strong>
                  </span>
                  <Input
                    type='select'
                    id='departmentSelector'
                    width='500px'
                    onChange={event => {
                      this.setState({ department: event.target.value })
                    }}
                  >
                    {this.departmentOptions()}
                  </Input>
                  {this.state.department ? (
                    <NewValueInput>
                      <Input
                        type='datalist'
                        id='departmentValue'
                        placeholder='Please enter a new value'
                        onChange={event => {
                          this.setState({ departmentValue: event.target.value })
                        }}
                        width='300px'
                      >
                        {this.props.approved.department || <option value={null}></option>}
                      </Input>
                      <Input
                        type='button'
                        id='submitDepartmentValue'
                        onClick={() => {
                          this.changing = 'department'
                          this.alertChangeToDatabase()
                        }}
                        width='100px'
                        disabled={!this.state.departmentValue || this.state.departmentValue.trim() === ''}
                      >
                        Submit
                      </Input>
                    </NewValueInput>
                  ) : null}
                </NormaliseData>
              </Departments>
            </Tables>
            <NormaliseDescription>
              Use the above inputs to normalise the values given by staff. For instance, you might find values for <em>CBE</em>, <em>CoBE</em> as well as{' '}
              <em>College of Business and Economics</em>. To make sure that all of these names are counted together, it may be necessary to normalise the data
              from time to time by changing 'CBE' and 'CoBE' to 'College of Business and Economics'. Please note that such actions are permanent and cannot be
              undone.{' '}
            </NormaliseDescription>
            {this.alert}
          </React.Fragment>
        )}
      </StyledUsers>
    )
  }
}

const StyledUsers = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.loading ? 'center' : 'flex-start')};
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  color: ${props => props.theme.dark};
`

const Overview = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin-bottom: 20px;
  flex-shrink: 0;
`

const GraphContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 90%;
  padding: 10px 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
  border: 1px solid ${props => props.theme.dark};
`
const Graph = styled.div`
  user-select: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  overflow-x: auto;
  width: 100%;
  column-gap: 20px;
  padding: 20px 0px 30px 0px;
  :hover {
    cursor: default;
  }
`

const GraphItem = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  row-gap: 15px;
  width: 100px;
`

const Tables = styled.div`
  box-sizing: border-box;
  width: 90%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`

const Faculties = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 48%;
`

const Departments = styled(Faculties)``

const NormaliseData = styled.div`
  width: 100%;
  margin: 30px 20px 0px 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 20px;
`

const NewValueInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
`

const NormaliseDescription = styled.div`
  margin-top: 20px;
  padding-bottom: 30px;
  width: 70%;
  text-align: justify;
  font-size: 14px;
`

const mapStateToProps = state => {
  return {
    approved: state.forms.approved
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addApprovedFormItems: (newItems) => dispatch(actions.addApprovedFormItems(newItems)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Users))
