// if option is universal, show it but disable it
// add albumName 'Stock' to stock photos so that they can be distinguished from ohers

import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import * as actions from 'store/actions'
import PhotoCard from 'components/PhotoCard'
import Alert from 'components/Alert'
import Loader from 'components/loader/Loader'
import AlbumCard from 'components/AlbumCard'
import FilterInput from 'pages/search/SearchFilter'

class Search extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      nothingFound: true,
      showFilters: false,
      filters: {},
      tooNarrow: false
    }

    this.prevPhotoResults = null
    this.prevAlbumResults = null
  }
  
  init = () => {
    this.getSearchQuery()
    this.initFilters()
    this.selection = []
    if (!this.props.searchQuery) this.resetScrollPosition()
    if (!this.urlListener) this.urlListener = this.props.history.listen(() => {
      if (this.props.match.params.searchQuery === this.searchQuery) return
      this.init()
      this.search()
    })
  }
  
  componentDidMount() {
    this.init()
    this.search()
  }
  
  componentDidUpdate() {
    if (this.prevPhotoResults !== this.props.photoResults || this.prevAlbumResults !== this.props.albumResults) {
      this.prevPhotoResults = this.props.photoResults
      this.prevAlbumResults = this.props.albumResults
      this.displayResults()
    }
  }
  
  componentWillUnmount() {
    this.urlListener()
    this.props.search(null)
    this.prevPhotoResults = null
    this.prevSearchQuery = null
  }
  
  initFilters = () => {
    this.filteredPhotoResults = []
    this.filteredAlbumResults = []
    this.emptyFiltersDeleted = false
  }
  
  getSearchQuery = () => {
    this.searchQuery = this.props.match.params.searchQuery
  }

  search = () => {
    this.setState({ loading: true })
    this.props.search(this.searchQuery)
    this.blurSearchBarForMobile()
  }

  blurSearchBarForMobile = () => {
    if (this.props.smallDevice && document.getElementById('searchbar')) document.getElementById('searchbar').blur()
  }

  displayResults = () => {
    if ((!this.props.albumResults && !this.props.photoResults) || (this.props.albumResults.length === 0 && this.props.photoResults.length === 0)) {
      return this.setState({ loading: false, nothingFound: true })
    }
    this.filteredPhotoResults = this.props.photoResults
    this.filteredAlbumResults = this.props.albumResults
    this.populateFilters()
    this.setState({ loading: false, nothingFound: false }, () => this.scrollToPreviousPosition())
  }

  populateFilters = () => {
    const filters = {
      tags: {
        name: 'Tags',
        expand: false,
        options: {}
      },
      venue: {
        name: 'Venue',
        expand: false,
        options: {}
      },
      campus: {
        name: 'Campus',
        expand: false,
        options: {}
      },
      faculty: {
        name: 'Faculty',
        expand: false,
        options: {}
      },
      department: {
        name: 'Department',
        expand: false,
        options: {}
      },
      albumName: {
        name: 'Album name',
        expand: false,
        options: {}
      }
    }

    Object.keys(filters).forEach(filter => {
      this.props.photoResults.forEach(photo => {
        if (filter === 'tags') {
          photo.tags.forEach(tag => {
            filters.tags.options[tag] = {
              name: tag,
              applied: false
            }
          })
        } else {
          if (!photo.stock)
            filters[filter].options[photo.info[filter]] = {
              name: photo.info[filter],
              applied: false
            }
        }
      })
      //sort options aphabetically
      let sortedOptions = {}
      Object.keys(filters[filter].options)
        .sort()
        .forEach(key => {
          sortedOptions[key] = filters[filter].options[key]
        })
      if (filter !== 'tags')
        sortedOptions.Unspecified = {
          name: 'Unspecified',
          applied: false
        }
      filters[filter].options = sortedOptions
    })
    this.setState({ filters }, () => this.countOptions())
  }

  countOptions = () => {
    let filters = { ...this.state.filters }
    Object.keys(filters).forEach(filter => {
      Object.keys(filters[filter].options).forEach(option => {
        filters[filter].options[option].currentCount = 0
      })
      this.filteredPhotoResults.forEach(photo => {
        if (filter === 'tags' && photo.tags) {
          photo.tags.forEach(tag => {
            filters.tags.options[tag].currentCount++
            filters.tags.options[tag].inactive = false
          })
        } else {
          if (!photo.stock) {
            filters[filter].options[photo.info[filter]].currentCount++
            filters[filter].options[photo.info[filter]].inactive = false
          } else {
            filters[filter].options.Unspecified.currentCount++
            filters[filter].options.Unspecified.inactive = false
          }
        }
      })
      Object.keys(filters[filter].options).forEach(option => {
        if (filters[filter].options[option].currentCount === 0) {
          filters[filter].options[option].applied = true
          filters[filter].options[option].inactive = true
        }
        if (filters[filter].options[option].currentCount === filters[filter].options[option].totalCount) {
          filters[filter].options[option].applied = false
        }

        if (!filters[filter].options[option].totalCount) {
          filters[filter].options[option].totalCount = filters[filter].options[option].currentCount
          if (filters[filter].options[option].totalCount === this.props.photoResults.length) {
            filters[filter].options[option].universal = true
          }
        }
      })
    })
    //sort tags according to total count
    let sortedTagOptions = {}
    Object.keys(filters.tags.options)
      .sort((a, b) => {
        if (filters.tags.options[a].totalCount < filters.tags.options[b].totalCount) return 1
        if (filters.tags.options[a].totalCount > filters.tags.options[b].totalCount) return -1
        return 0
      })
      .forEach(tag => {
        sortedTagOptions[tag] = filters.tags.options[tag]
      })
    filters.tags.options = sortedTagOptions
    //delete empty filters
    if (!this.emptyFiltersDeleted) {
      Object.keys(filters).forEach(filter => {
        if (Object.keys(filters[filter].options).length === 1 && Object.keys(filters[filter].options)[0] === 'Unspecified') {
          delete filters[filter]
        }
      })
      this.emptyFiltersDeleted = true
    }
    this.setState({ filters })
  }

  filterOptionClicked = (filter, option) => {
    let filters = { ...this.state.filters }
    if (!filters[filter].options[option].applied) {
      //Activate filter
      filters[filter].options[option].applied = true
      this.filteredPhotoResults = this.filteredPhotoResults.filter(photo => {
        if (filter === 'tags') {
          if (photo.tags.includes(option)) return false
        } else {
          if (!photo.stock && photo.info[filter] === option) return false
          if (option === 'Unspecified' && photo.stock) return false
          if (this.filteredAlbumResults) {
            this.filteredAlbumResults = this.filteredAlbumResults.filter(album => {
              if (album[filter] === option) return false
              return true
            })
          }
        }
        return true
      })
    } else {
      //Deactivate filter
      filters[filter].options[option].applied = false
      this.props.photoResults.forEach(photo => {
        if (!this.filteredPhotoResults.includes(photo)) {
          if (filter === 'tags') {
            if (photo.tags.includes(option)) {
              photo.tags.forEach(tag => {
                let unique = true
                //if any of this photo's other tags are applied and they are unique to this photo, unapply them as well
                if (filters.tags.options[tag].applied && option !== tag) {
                  this.props.photoResults.forEach(_photo => {
                    if (_photo.tags.includes(tag) && _photo !== photo) unique = false
                  })
                }
                if (unique) filters.tags.options[tag].applied = false
              })
              this.filteredPhotoResults.push(photo)
            }
          } else {
            if ((!photo.stock && photo.info[filter] === option) || (option === 'Unspecified' && photo.stock)) this.filteredPhotoResults.push(photo)
          }
        }
      })
      if (filter !== 'tags') {
        this.props.albumResults.forEach(album => {
          if (!this.filteredAlbumResults.includes(album)) {
            if (album[filter] === option) {
              this.filteredAlbumResults.push(album)
            }
          }
        })
      }
    }
    this.setState({ filters })
    this.countOptions()
    this.setState({
      tooNarrow: this.filteredPhotoResults.length === 0 ? true : false
    })
    this.forceUpdate()
  }

  showFilters = () => {
    this.setState({ showFilters: !this.state.showFilters })
  }

  resetFilters = () => {
    this.filteredPhotoResults = this.props.photoResults
    this.filteredAlbumResults = this.props.albumResults
    let filters = { ...this.state.filters }
    Object.keys(filters).forEach(filter => {
      Object.keys(filters[filter].options).forEach(option => {
        filters[filter].options[option].applied = false
      })
    })
    this.countOptions()
    this.setState({ filters, tooNarrow: false })
  }

  clearFilters = () => {
    this.filteredPhotoResults = []
    this.filteredAlbumResults = []
    let filters = { ...this.state.filters }
    Object.keys(filters).forEach(filter => {
      Object.keys(filters[filter].options).forEach(option => {
        filters[filter].options[option].applied = true
      })
    })
    this.countOptions()
    this.setState({ filters, tooNarrow: true })
  }

  photoClicked = event => {
    event.stopPropagation()
    if (event.target.id.includes('button')) return
    if (event.ctrlKey || this.selection.length > 0) this.photoSelected(event)
    else {
      this.props.populatePhotoCollection(this.filteredPhotoResults)
      this.props.history.push(`/photos/${this.getPhotoId(event)}`)
    }
  }

  getPhotoId = event => {
    return event.target.id.slice(event.target.id.lastIndexOf('-') + 1)
  }

  photoSelected = (event, passedId) => {
    if (event) event.stopPropagation()
    let photoId = passedId || this.getPhotoId(event)
    if (this.selection.includes(photoId)) this.selection.splice(this.selection.indexOf(photoId), 1)
    else this.selection.splice(0, 0, photoId)
    this.forceUpdate()
  }

  selectAll = () => {
    this.clearSelection()
    this.filteredPhotoResults.forEach(photo => {
      this.selection.push(photo.id)
    })
    this.forceUpdate()
  }

  clearSelection = () => {
    this.selection = []
    this.forceUpdate()
  }

  alertButtonClicked = event => {
    if (event.target.id === 'continue') {
      this.alert = null
      this.forceUpdate()
    }
  }

  alertModalBackdropClicked = event => {
    if (event.target.id === 'modalBackdrop') {
      this.alert = null
      this.forceUpdate()
    }
  }

  downloadHandler = event => {
    event.stopPropagation()
    this.props.populatePhotoCollection(this.props.photoResults)
    if (event.target.id.includes('button')) this.props.download(this.getPhotoId(event))
    else if (this.selection.length > 0) this.props.download(this.selection, this.props.searchQuery)
    else this.props.download('all', this.props.searchQuery)
  }

  albumClickedHandler = event => {
    this.props.history.push(`/albums/${event.target.id}`)
  }

  addToCollection = event => {
    event.stopPropagation()
    if (!event.target.id.includes('button') && this.selection.length === 0) {
      this.alert = <Alert alertType='Add to collection' alertButtonClicked={this.alertButtonClicked} backdropClicked={this.alertButtonClicked} />
      this.forceUpdate()
    } else {
      let photoIds = null
      let photos = []
      if (this.selection.length === 0) photoIds = [this.getPhotoId(event)]
      else photoIds = this.selection
      photoIds.forEach(id => {
        photos.push(this.props.photoResults.find(photo => photo.id === id))
      })
      this.props.loadPhotos(photos)
    }
  }

  explorerScrollHandler = event => {
    if (Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.offsetHeight) <= 1) this.props.showMorePhotoResults()
    this.setScrollPosition(event.target)
  }

  setScrollPosition = explorer => {
    window.sessionStorage.setItem('searchScrollPosition', explorer.scrollTop.toString())
  }

  resetScrollPosition = () => {
    window.sessionStorage.setItem('searchScrollPosition', '0')
  }

  scrollToPreviousPosition = () => {
    const scrollPosition = window.sessionStorage.getItem('searchScrollPosition')
    const explorer = document.getElementById('explorer')
    if (explorer) explorer.scrollTo(0, scrollPosition || 0)
  }

  render() {
    let smallDevice = this.props.smallDevice

    if (this.filteredAlbumResults && this.filteredAlbumResults.length > 0) {
      this.albums = this.filteredAlbumResults.map(album => {
        return (
          <AlbumCard
            albumName={`Album: ${album.name}`}
            key={album.id}
            id={album.id}
            src={album.coverURL}
            alt={album.albumName}
            clicked={this.albumClickedHandler}
            inputMethod={this.props.inputMethod}
            searchAlbum
          />
        )
      })
    } else this.albums = null

    this.photos = this.filteredPhotoResults ? this.filteredPhotoResults.map(photo => {
      return (
        <PhotoCard
          key={photo.id}
          id={photo.id}
          src={photo.thumbURL}
          alt={photo.id}
          onClick={this.photoClicked}
          select={this.photoSelected}
          selected={this.selection.includes(photo.id)}
          download={this.downloadHandler}
          hideButtons={this.selection.length > 0}
          addToCollection={this.addToCollection}
        />
      )
    }) : null

    return this.state.loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <StyledSearch>
          {this.state.nothingFound || this.state.tooNarrow ? (
            <NothingFound>
              {this.state.nothingFound ? 'Nothing found. Please try again.' : 'Your search is too narrow. Please apply at least one filter.'}
            </NothingFound>
          ) : (
            <Explorer id='explorer' onClick={this.clearSelection} onScroll={this.explorerScrollHandler}>
              {this.albums}
              {this.photos}
            </Explorer>
          )}
        </StyledSearch>

        <Footer disabled={this.state.nothingFound}>
          <FilterInput
            filters={this.state.filters}
            filterOptionClicked={this.filterOptionClicked}
            resetFilters={this.resetFilters}
            clearFilters={this.clearFilters}
            smallDevice={this.props.smallDevice}
            inputMethod={this.props.inputMethod}
            showFilters={this.showFilters}
            disabled={this.state.nothingFound}
          ></FilterInput>
          {this.props.smallDevice ? (
            <React.Fragment>
              <SelectAll
                onClick={this.state.nothingFound ? null : this.selectAll}
                title='Select all'
                showFilters={this.state.showFilters}
                disabled={this.state.nothingFound}
                smallDevice={smallDevice}
              >
                <i className='material-icons'>check_box</i>
              </SelectAll>
              <ClearSelection
                onClick={this.state.nothingFound ? null : this.clearSelection}
                title='Clear selection'
                showFilters={this.state.showFilters}
                disabled={this.state.nothingFound}
                smallDevice={smallDevice}
              >
                <i className='material-icons'>check_box_outline_blank</i>
              </ClearSelection>
            </React.Fragment>
          ) : (
            <SelectionPanel disabled={this.state.nothingFound}>
              <SelectAll
                onClick={this.state.nothingFound ? null : this.selectAll}
                title='Select all'
                disabled={this.state.nothingFound}
                smallDevice={smallDevice}
              >
                <i className='material-icons'>check_box</i>
              </SelectAll>
              <ClearSelection
                onClick={this.state.nothingFound ? null : this.clearSelection}
                title='Clear selection'
                disabled={this.state.nothingFound}
                smallDevice={smallDevice}
              >
                <i className='material-icons'>check_box_outline_blank</i>
              </ClearSelection>
            </SelectionPanel>
          )}

          <AddButton
            title='Add to collection'
            onClick={this.addToCollection}
            hidden={!this.props.currentUser || !this.props.currentUser.staff}
            smallDevice={smallDevice}
          >
            <i className='material-icons'>add_circle_outline</i>
          </AddButton>
          <DownloadButton
            title={this.selection > 0 ? 'Download selected' : 'Download all'}
            onClick={this.state.nothingFound ? null : this.downloadHandler}
            showFilters={this.state.showFilters}
            disabled={this.state.nothingFound}
            smallDevice={smallDevice}
          >
            <i className='material-icons'>save_alt</i>
          </DownloadButton>
        </Footer>
        {this.alert}
      </React.Fragment>
    )
  }
}

const StyledSearch = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  grid-area: main;
`

const Explorer = styled.div`
  display: grid;
  box-sizing: border-box;
  height: auto;
  max-height: 100%;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-around;
  grid-gap: 30px;
  padding: 10px 40px 40px 40px;
  overflow-y: scroll;
  background-color: ${props => props.theme.bg};
`

const NothingFound = styled.div`
  position: ${props => (props.smallDevice ? 'absolute' : null)};
  box-sizing: border-box;
  left: 0px;
  top: 100px;
  width: ${props => (props.smallDevice ? '100%' : '90%')};
  height: ${props => (props.smallDevice ? null : '100%')};
  display: flex;
  justify-content: center;
  position: absolute;
  color: ${props => props.theme.dark};
  margin-top: ${props => (props.smallDevice ? null : '100px')};
  padding: ${props => (props.smallDevice ? '0px 40px 0px 30px' : null)};
  text-align: center;
`

const Footer = styled.div`
  grid-area: footer;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.light};
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};
`

const SelectionPanel = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};
`

const SelectAll = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '30px')};
  width: ${props => (props.smallDevice ? '60px' : null)};
  font-size: ${props => (props.smallDevice ? '30px' : '24px')};
  border-left: ${props => (props.smallDevice ? `1px solid ${props.disabled ? props.theme.main : props.theme.dark}` : null)};
  padding-top: ${props => (props.smallDevice ? null : '4px')};
  opacity: ${props => (props.smallDevice ? (props.showFilters ? 0 : 1) : null)};
  transition: ${props => (props.smallDevice ? 'opacity 0.5s linear' : null)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: ${props => (props.disabled ? null : '27px')};
      padding-top: ${props => (props.disabled ? null : '3px')};
      cursor: ${props => (props.disabled ? null : 'pointer')};
    }
  }
`

const ClearSelection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '30px')};
  width: ${props => (props.smallDevice ? '60px' : null)};
  font-size: ${props => (props.smallDevice ? '30px' : '24px')};
  border-left: ${props => (props.smallDevice ? `1px solid ${props.disabled ? props.theme.main : props.theme.dark}` : null)};
  padding-top: ${props => (props.smallDevice ? null : '4px')};
  opacity: ${props => (props.smallDevice ? (props.showFilters ? 0 : 1) : null)};
  transition: ${props => (props.smallDevice ? 'opacity 0.5s linear' : null)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: ${props => (props.disabled ? null : '27px')};
      padding-top: ${props => (props.disabled ? null : '3px')};
      cursor: ${props => (props.disabled ? null : 'pointer')};
    }
  }
`

const AddButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? '60px' : '80px')};
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: pointer;
      font-size: 43px;
    }
  }
`

const DownloadButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? '60px' : '80px')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '40px' : '50px')};
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  opacity: ${props => (props.smallDevice ? (props.showFilters ? 0 : 1) : null)};
  transition: ${props => (props.smallDevice ? 'opacity 0.5s linear' : null)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: ${props => (props.disabled ? null : '53px')};
      cursor: ${props => (props.disabled ? null : 'pointer')};
    }
  }
`

const mapStateToProps = state => {
  return {
    currentPhotoCollection: state.browse.currentPhotoCollection,
    inputMethod: state.ui.inputMethod,
    smallDevice: state.ui.smallDevice,
    currentUser: state.auth.currentUser,
    searchQuery: state.search.searchQuery,
    searchKeys: state.search.searchKeys,
    albumResults: state.search.albumResults,
    photoResults: state.search.photoResults
  }
}

const mapDispatchToProps = dispatch => {
  return {
    populatePhotoCollection: photoResults => dispatch(actions.populatePhotoCollection(photoResults)),
    search: searchQuery => dispatch(actions.search(searchQuery)),
    showMorePhotoResults: () => dispatch(actions.showMorePhotoResults()),
    loadPhotos: photos => dispatch(actions.loadPhotos(photos)),
    download: (files, folderName) => dispatch(actions.download(files, folderName))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
