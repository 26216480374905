import React, { Component } from 'react'
import styled from 'styled-components'

class UnsupportedBrowser extends Component {
  render() {
    return (
      <StyledUnsupportedBrowser>
        <p>{`We have detected that you are using Internet Explorer, which has been discontinued and should no longer be used for anything.`}</p>
        <p>
          Please use{' '}
          <a href='https://www.mozilla.org/en-US/firefox/new/'>
            <u>Firefox</u>
          </a>
          ,{' '}
          <a href='https://www.google.com/chrome/'>
            <u>Chrome</u>
          </a>
          ,{' '}
          <a href='https://www.microsoft.com/en-us/edge'>
            <u>Microsoft Edge</u>
          </a>{' '}
          or{' '}
          <a href='https://www.apple.com/safari/'>
            <u>Safari</u>
          </a>{' '}
          instead.
        </p>
      </StyledUnsupportedBrowser>
    )
  }
}

const StyledUnsupportedBrowser = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: darkgrey;
`

export default UnsupportedBrowser
