import React from 'react'
import styled from 'styled-components'
import Thumbnail from 'components/Thumbnail'
import Text from 'components/Text'

const AlbumCard = props => {
  return (
    <StyledAlbumCard onClick={props.clicked} id={props.id} inputMethod={props.inputMethod} searchAlbum={props.searchAlbum} title={props.albumName}>
      <StyledThumbnail searchAlbum={props.searchAlbum} id={props.id}>
        <Thumbnail id={props.id} src={props.src} alt={props.alt} />
      </StyledThumbnail>
      <Title id={props.id} searchAlbum={props.searchAlbum}>
        <Text id={props.id}>{props.albumName}</Text>
      </Title>
    </StyledAlbumCard>
  )
}

const StyledAlbumCard = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 10px 250px 30px 10px;
  grid-template-areas:
    '.'
    'thumbnail'
    'title'
    '.';
  height: 300px;
  width: 300px;
  box-sizing: border-box;
  padding: 10px 15px;
  background-color: ${props => (props.searchAlbum ? props.theme.light : props.theme.bg)};
  border: ${props => (props.searchAlbum ? '2px' : '1px')} solid ${props => props.theme.dark};
  border-radius: 5px;
  color: ${props => props.theme.dark};

  :hover {
    cursor: pointer;
    padding: ${props => (props.inputMethod === 'touch' ? '10px 15px' : '9px 14px')};
    border-width: ${props => (props.inputMethod === 'touch' ? '1px' : props.searchAlbum ? '3px' : '2px')};
    font-weight: ${props => (props.inputMethod === 'touch' ? 'initial' : 'bold')};
  }
`

const StyledThumbnail = styled.div`
  grid-area: thumbnail;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 90%;
`

const Title = styled.div`
  grid-area: title;
  font-size: ${props => (props.searchAlbum ? '16px' : props.theme.fontSize)};
  font-weight: ${props => (props.searchAlbum ? 'bold' : 'initial')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :hover {
    cursor: pointer;
  }
`

export default AlbumCard
