import * as actions from 'store/actions/actionTypes'

const initialState = {
  currentUser: null
}

const updateCurrentUser = (state, action) => {
  if (action.userObject !== null) {
    return {
      ...state,
      currentUser: { ...state.currentUser, ...action.userObject }
    }
  } else return { ...state, currentUser: null }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_CURRENT_USER:
      return updateCurrentUser(state, action)
    default:
      return state
  }
}

export default reducer
