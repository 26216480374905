import React from 'react'
import Text from 'components/Text'
import Modal from 'components/Modal'
import Loader from 'components/loader/Loader'
import styled from 'styled-components'
import Input from 'components/NewInput'

const Alert = props => {
  let message = null
  let buttonText = null
  let buttonId = null
  let buttonDanger = false
  let secondButtonText = null
  let secondButtonId = null
  let secondButtonDanger = false
  let escapable = false

  const alertButtonClicked = event => {
    props.alertButtonClicked(event)
  }

  switch (props.alertType) {
    // Create account modal:
    case 'Password mismatch':
      message = "Your passwords don't match. Please retype your password."
      buttonText = 'Retry'
      buttonId = 'passwordMismatch'
      break
    case 'Invalid password':
      message = (
        <span>
          Your password must be at least eight characters long and contain:{' '}
          <p
            style={{
              marginLeft: '20px',
              marginTop: '10px',
              marginBottom: '-10px',
              fontSize: '16px'
            }}
          >
            one uppercase letter,
          </p>{' '}
          <p
            style={{
              marginLeft: '20px',
              marginBottom: '-10px',
              fontSize: '16px'
            }}
          >
            one lowercase letter,
          </p>{' '}
          <p
            style={{
              marginLeft: '20px',
              marginBottom: '-10px',
              fontSize: '16px'
            }}
          >
            one number,
          </p>{' '}
          <p
            style={{
              marginLeft: '20px',
              marginBottom: '-10px',
              fontSize: '16px'
            }}
          >
            and one special character.
          </p>
        </span>
      )
      buttonText = 'Retry'
      buttonId = 'invalidPassword'
      break
    case 'Verify account':
      message = `An email was sent to ${props.emailValue} with a four digit code. Please enter this code below in order to verify your account.`
      buttonText = 'Verify'
      buttonId = 'verifyAccount'
      break
    case 'Incorrect code':
      message = `The code that you have entered is incorrect. Please try again.`
      buttonText = 'Continue'
      buttonId = 'wrongCode'
      break
    case 'Invalid email':
      message = 'Only email addresses ending with @uj.ac.za or @jbs.ac.za are allowed. Please try again.' // NTS: all domains?
      buttonText = 'Continue'
      buttonId = 'invalidEmailContinue'
      break
    case 'Invalid email address':
      message = `The email address, ${props.emailValue}, is already in use. Please try again.`
      buttonText = 'Continue'
      buttonId = 'invalidEmailContinue'
      break

    // Sign in modal:
    case 'Reset password email sent':
      message = `A password reset link will be sent to ${props.emailValue}.`
      buttonText = 'Continue'
      buttonId = 'continue'
      break
    case 'Email address not found':
      message = 'Email address not found. Please retype your email address or create a new account.'
      buttonText = 'Continue'
      buttonId = 'continue'
      break
    case 'Incorrect password':
      message = 'Your password is incorrect. Please retype your password.'
      buttonText = 'Continue'
      buttonId = 'continue'
      break
    case 'Account not verified':
      message = 'Your account has not been verified. Please verify your account by clicking on the link in the email that was sent to you.'
      buttonText = 'Continue'
      buttonId = 'continue'
      break

    // Bookings:
    case 'Create a booking':
      message = 'Do you want to book a photographer or a videographer?'
      buttonText = 'Photographer'
      buttonId = 'photographer'
      secondButtonText = 'Videographer'
      secondButtonId = 'videographer'
      break
    case 'Booking submitted':
      message = 'Your booking was submitted successfully and will be considered for approval.'
      buttonText = 'Continue'
      buttonId = 'continue'
      break
    case 'Booking updated successfully':
      message = 'Your booking was successfully updated.'
      buttonText = 'Continue'
      buttonId = 'continue'
      break
    case 'Confirm exit':
      message = 'Are you sure you want to exit? All changes will be lost.'
      buttonText = 'Cancel'
      buttonId = 'cancel'
      secondButtonText = 'Exit'
      secondButtonId = 'exit'
      break
    case 'Confirm booking update':
      message =
        'Please note that, because you have changed the date/time of the booking, any previous acceptance thereof will be void. It will be treated as a new submission.'
      buttonText = 'Cancel'
      buttonId = 'cancel'
      secondButtonText = 'Update booking'
      secondButtonId = 'update'
      break
    case 'Cancel booking':
      message = 'Are you sure you want to cancel this booking? This cannot be undone.'
      buttonText = 'No'
      buttonId = 'cancel'
      secondButtonText = 'Yes'
      secondButtonId = 'continue'
      secondButtonDanger = true
      break
    case 'Notice of leave':
      message = 'Please note that the photographer is currently on leave. He will reply to your request on Tuesday, 30 November 2021.'
      buttonText = 'Continue'
      buttonId = 'continue'
      break
    case 'File error':
      message = 'One or more files were larger than the maximum allowed size of 8MB and were therefore not included.'
      buttonText = 'Continue'
      buttonId = 'continue'
      break
    case 'File type error':
      message = 'One or more files were of an invalid type and were therefore not included.'
      buttonText = 'Continue'
      buttonId = 'continue'
      break

    // Upload:
    case 'Uploading photos...':
      message = `Uploading photos...  ${props.progress}%`
      buttonText = props.completed ? 'Continue' : 'Cancel'
      buttonId = props.completed ? 'continue' : 'cancel'
      break
    case 'Upload successful':
      message = `Your album was uploaded successfully. It will reflect in the browser as soon as image conversions are completed.`
      buttonText = 'Continue'
      buttonId = 'continue'
      break
    case 'Cancelling upload...':
      message = `Cancelling upload and deleting temporary files. Please be patient.`
      buttonText = null
      buttonId = null
      break
    case 'Album cover required':
      message = 'Please select a cover for the album that you wish to upload.'
      buttonText = 'Continue'
      buttonId = 'removeError'
      break
    case 'Missing tags':
      message = 'Please add at least one tag per photo.'
      buttonText = 'Continue'
      buttonId = 'removeError'
      break

    case 'Size limit exceeded':
      message = 'One or more files have exceeded the height or width limit of 15,000 pixels and has been removed.'
      buttonText = 'Continue'
      buttonId = 'removeError'
      break

    // Download:
    case 'Photo download':
      message = 'Please select download quality:'
      buttonText = 'web'
      buttonId = 'web'
      secondButtonText = 'print'
      secondButtonId = 'print'
      escapable = true
      break
    case 'Download helper':
      message = 'Please select at least one photo to download.'
      buttonText = 'Continue'
      buttonId = 'continue'
      escapable = true
      break

    // Collections:
    case 'Add to collection':
      message = 'Please select at least one photo to add to a collection.'
      buttonText = 'Continue'
      buttonId = 'continue'
      escapable = true
      break
    case 'Delete collection':
      message = 'Are you sure you want to delete this collection? This cannot be undone.'
      buttonText = 'Cancel'
      buttonId = 'cancel'
      secondButtonText = 'Continue'
      secondButtonId = 'continue'
      escapable = true
      break

    // Profile:
    case 'Password changed successfully':
      message = 'Your password was changed successfully.'
      buttonText = 'Continue'
      buttonId = 'continue'
      break
    case 'Delete account':
      message = 'Are you sure you want to delete your account? This cannot be undone.'
      buttonText = 'No'
      buttonId = 'cancel'
      secondButtonText = 'Yes'
      secondButtonId = 'continue'
      secondButtonDanger = true
      break

    // Admin
    case 'Change database value':
      message = 'You are about to change values in the database. This cannot be undone. Are you sure you want to continue?'
      buttonText = 'Cancel'
      buttonId = 'cancel'
      secondButtonText = 'Continue'
      secondButtonId = 'continue'
      secondButtonDanger = true
      break
    case 'Reject photo deletion request':
      message = 'Please provide a reason for declining this request. This message will be sent to the person who has made the request.'
      buttonText = 'Submit'
      buttonId = 'submit'
      secondButtonText = 'Cancel'
      secondButtonId = 'cancel'
      break

    // Photo View
    case 'Report photo':
      message =
        'If you feel that this photo offends you or is in any way inappropriate, please give a short motivation below so that the photo can be flagged for removal by an administrator as soon as possible.'
      buttonText = 'Submit'
      buttonId = 'submit'
      secondButtonText = 'Cancel'
      secondButtonId = 'cancel'
      break
    case 'Photo reported successfully':
      message =
        'The photo has been reported to the administrator of the UJDML for consideration. You will receive an email within one business day with the outcome of your request.'
      buttonText = 'Continue'
      buttonId = 'continue'
      break

    // Errors and general:
    case 'Unexpected error':
      message = 'An unexpected error occurred. Please contact the webmaster at janp@uj.ac.za for assistance.'
      buttonText = 'Continue'
      buttonId = 'removeError'
      break
    case 'Unauthorised user':
      message = 'You need to be signed in to perform this action.'
      buttonText = 'Continue'
      buttonId = 'continue'
      break
    default:
      break
  }

  let StyledAlert = (
    <Modal
      title={props.alertType}
      width={props.width || 'auto'}
      maxWidth={props.smallDevice ? null : '60%'}
      height={props.height || 'auto'}
      smallDevice={props.smallDevice}
      backdropClicked={props.backdropClicked}
      escapable={escapable}
    >
      <Text textAlign={props.center ? 'center' : null}>{message}</Text>
      {props.loader ? <Loader /> : null}
      {props.alertType === 'Verify account' ? (
        <VerificationCodeInput>
          <Input type='number' maxLength={4} autoFocus id='verificationCodeInput' width='80px' />
        </VerificationCodeInput>
      ) : null}
      {props.alertType === 'Report photo' ? (
        <ReportPhotoInput>
          <Input type='text' label='Reason' width='60%' autoFocus id='reportPhotoReason' required />
          {props.anonymousUser ? <Input type='email' label='Email' width='60%' placeholder='Email address' id='reportPhotoEmail' required /> : null}
        </ReportPhotoInput>
      ) : null}
      {props.alertType === 'Reject photo deletion request' ? (
        <ReportPhotoInput>
          <Input type='text' id='reasonForDecline' label='Reason for declining' width='60%' required />
        </ReportPhotoInput>
      ) : null}
      <Buttons>
        {buttonText ? (
          <Input type='button' onClick={alertButtonClicked} id={buttonId} autoFocus danger={buttonDanger} width='auto'>
            {buttonText}
          </Input>
        ) : null}
        {secondButtonText ? (
          <Input type='button' onClick={alertButtonClicked} disabled={props.printDisabled} id={secondButtonId} danger={secondButtonDanger} width='auto'>
            {secondButtonText}
          </Input>
        ) : null}
      </Buttons>
      {props.alertType === 'Verify account' ? (
        <ResendVerificationEmail
          id='resendVerificationEmail'
          onClick={() => {
            props.resendVerificationEmail()
            setTimeout(() => {
              document.getElementById('resendVerificationEmail').hidden = true
            }, 500)
          }}
        >
          Resend verification email
        </ResendVerificationEmail>
      ) : null}
    </Modal>
  )

  return StyledAlert
}

const Buttons = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  margin-top: 20px;
  width: 100%;
`

const ResendVerificationEmail = styled.div`
  position: absolute;
  box-sizing: border-box;
  bottom: 10px;
  left: calc(50% - 74px);
  font-weight: bold;
  font-size: 12px;

  :hover {
    cursor: pointer;
    font-size: 12.2px;
    bottom: 10px;
    left: calc(50% - 75px);
  }
`

const VerificationCodeInput = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ReportPhotoInput = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default Alert
