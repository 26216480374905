//	A Google-style autocomplete input Component that returns an input field that provides
//  selectable options that are populated when the typed input matches a word
//  provided in the options array.

//  Requirements:
//  add optionsArray prop (array of strings)
//  assign unique id to AutoCompleteInput

import React, { Component } from 'react'
import styled from 'styled-components'
import Text from 'components/Text'

class AutoCompleteInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      placeOptionsAbove: false
    }

    this.focus = 0
    this.value = ''
    this.resetCounter = 0
  }

  componentDidMount() {
    this.inputElement = document.getElementById(this.props.id)
  }

  componentDidUpdate() {
    if (this.props.resetCounter > this.resetCounter) {
      this.value = ''
      this.setState({ value: '' })
    }
    this.resetCounter = this.props.resetCounter
  }

  calculateSpaceBeneathInput = () => {
    const yPosition = this.inputElement.getBoundingClientRect().y
    const availableSpace = window.innerHeight - yPosition - 80
    this.setState({ placeOptionsAbove: availableSpace < 100 ? true : false })
  }

  assignLastKeyPress = event => {
    this.keyCode = event.keyCode
    let scrollOptions = { block: 'nearest' }

    if (this.keyCode === 40 && this.index > 0) {
      // key down
      event.preventDefault()
      if (this.focus === this.index) {
        this.focus = 1
        document.getElementById(this.focus).scrollIntoView(scrollOptions)
      } else {
        this.focus += 1
        document.getElementById(this.focus).scrollIntoView(scrollOptions)
      }
      this.populateOptions()
    } else if (this.keyCode === 38 && this.index > 0) {
      // key up
      event.preventDefault()
      if (this.focus === 1 || this.focus === 0) {
        this.focus = this.index
        document.getElementById(this.focus).scrollIntoView(scrollOptions)
      } else {
        this.focus -= 1
        document.getElementById(this.focus).scrollIntoView(scrollOptions)
      }
      this.populateOptions()
    } else if (this.keyCode === 13 && this.focus > 0) {
      // enter
      event.preventDefault()
      this.value = this.props.multiple
        ? this.value.slice(0, this.value.lastIndexOf(',') + 1).concat(this.value.includes(',') ? ' ' : '', this.indexValues[this.focus - 1], ', ')
        : this.value.slice(0, this.value.lastIndexOf(',') + 1).concat(this.indexValues[this.focus - 1])
        this.setState({ value: this.value })
      this.nextPhrase = ''
      this.focus = 0
      this.populateOptions()
      this.inputElement.focus()
      this.inputElement.setSelectionRange(this.state.value.length, this.state.value.length - 1)
    } else if (this.keyCode === 188) {
      // prevent repeating commas
      if (this.value.slice(this.value.length - 2, this.value.length) === ', ') {
        this.value = this.value.slice(0, this.value.length - 2)
        this.setState({ value: this.value })
      }
    } else if (this.keyCode === 32) {
      // prevent repeating spaces
      if (this.nextPhrase === '') {
        this.value = this.value.slice(0, this.value.length - 1)
        this.setState({ value: this.value })
      }
    }
  }

  inputChangedHandler = event => {
    this.focus = 0
    this.setState({ value: event.target.value }, () => {
      this.value = this.state.value.toLowerCase()
      if (this.keyCode === 188 && this.keyCode !== 8 && this.props.multiple) {
        // Add space after a comma
        this.value = this.value.concat(' ')
        this.setState({ value: this.value })
      }
      this.calculateNextPhrase()
    })
    if (document.getElementById('autoCompleteDiv')) {
      document.getElementById('autoCompleteDiv').scrollTo(0, 0)
    }
  }

  calculateNextPhrase = () => {
    if (this.value.includes(',')) {
      this.nextPhrase = this.value.slice(this.value.lastIndexOf(' ') + 1, this.value.length)
    } else this.nextPhrase = this.value
    this.populateOptions()
  }

  populateOptions = () => {
    if (!this.props.optionsArray) return
    this.options = null
    this.index = 0
    this.indexValues = []
    if (this.nextPhrase && this.nextPhrase !== '') {
      this.options = this.props.optionsArray.map(option => {
        let words = option.split(' ')
        for (let i = 0; i < words.length; i++) {
          if (
            (words[i].slice(0, this.nextPhrase.length) === this.nextPhrase || (this.nextPhrase.includes(' ') && option.includes(this.nextPhrase))) &&
            !this.value.includes(option)
          ) {
            this.index += 1
            this.indexValues.push(option)
            return (
              <TagItemDiv
                key={option}
                id={this.index}
                focus={this.focus}
                onMouseOver={this.mouseOverOption}
                onMouseLeave={this.mouseLeaveOption}
                onMouseDown={this.optionClickedHandler}
              >
                <Text id={this.index} fontWeight='bold'>
                  {option.slice(0, option.indexOf(this.nextPhrase))}
                </Text>
                <Text id={this.index}>{this.nextPhrase}</Text>
                <Text id={this.index} fontWeight='bold'>
                  {option.slice(option.indexOf(this.nextPhrase) + this.nextPhrase.length, option.length)}
                </Text>
              </TagItemDiv>
            )
          }
        }

        return null
      })
    }
    this.getTextBoxPosition()
    this.forceUpdate()
  }

  getTextBoxPosition = () => {
    this.textBoxHeight = this.inputElement.getBoundingClientRect().height
  }

  onFocusHandler = event => {
    this.calculateSpaceBeneathInput()
    if (this.state.value.length > 1) {
      let newValue = `${this.state.value}, `
      this.setState({ value: newValue })
    }
    document.getElementById(event.target.id).setSelectionRange(this.state.value.length, this.state.value.length)
    if (this.props.onFocus) this.props.onFocus('focus')
  }

  onBlurHandler = () => {
    let value = this.state.value
    if (value[value.length - 2] === ',') {
      let newValue = value.slice(0, value.length - 2)
      this.setState({ value: newValue })
    }
    if (value.slice(value.lastIndexOf(','), value.length).length > 2 && value.slice(value.lastIndexOf(','), value.length).length < 4) {
      let newValue = value.slice(0, value.lastIndexOf(','))
      this.setState({ value: newValue })
    }
    if (value.length === 1) {
      this.setState({ value: '' })
    }
    this.value = this.state.value
    if (this.props.addValueToAutoCompleteOptions) {
      this.props.addValueToAutoCompleteOptions(this.state.value)
    }
    this.nextPhrase = ''
    this.populateOptions()
    if (this.props.onBlur) {
      this.props.onBlur('blur')
    }
  }

  mouseOverOption = event => {
    this.focus = parseInt(event.target.id)
    this.populateOptions()
  }

  mouseLeaveOption = () => {
    this.focus = 0
    this.populateOptions()
  }

  optionClickedHandler = event => {
    this.inputElement.focus()
    this.value = this.value.slice(0, this.value.lastIndexOf(',') + 1).concat(this.value.includes(',') ? ' ' : '', this.indexValues[event.target.id - 1], ', ')
    this.setState({
      value: this.value
    })
    this.nextPhrase = ''
    this.focus = 0
    this.populateOptions()
    this.inputElement.focus()
    setTimeout(() => {
      this.inputElement.focus()
    }, 10)
  }

  render() {
    return (
      <StyledAutoCompleteInput height={this.props.height} margin={this.props.margin} placeOptionsAbove={this.state.placeOptionsAbove} id='autoCompleteInput'>
        <TextInput
          type='text'
          autoComplete='off'
          value={this.state.value}
          onChange={this.inputChangedHandler}
          onFocus={this.onFocusHandler}
          onBlur={this.onBlurHandler}
          onKeyDown={this.assignLastKeyPress}
          index={this.index}
          id={this.props.id}
          placeholder={this.props.placeholder}
          width={this.props.width}
          height={this.props.height}
          fontSize={this.props.fontSize}
          gridArea={this.props.gridArea}
        />
        {this.index > 0 ? (
          <AutoCompleteDiv
            id='autoCompleteDiv'
            index={this.index}
            width={`${this.inputElement.clientWidth}px`}
            placeOptionsAbove={this.state.placeOptionsAbove}
            textBoxHeight={this.textBoxHeight}
          >
            {this.options}
          </AutoCompleteDiv>
        ) : null}
      </StyledAutoCompleteInput>
    )
  }
}

const StyledAutoCompleteInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => (props.placeOptionsAbove ? 'column-reverse' : 'column')};
  justify-content: ${props => (props.placeOptionsAbove ? 'flex-start' : 'flex-start')};
  overflow: visible;
  height: auto;
  margin: ${props => props.margin || 'auto'};
  z-index: 300;
`

const TextInput = styled.input`
  position: relative;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '16px'};
  box-sizing: border-box;
  padding: 12px 10px;
  outline: none;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  background-color: ${props => props.theme.bg};
  font-size: ${props => props.fontSize || '14px'};
  grid-area: ${props => props.gridArea || null};

  :focus {
    border: 2px solid ${props => props.theme.dark};
  }
`

const AutoCompleteDiv = styled.div`
  position: absolute;
  top: ${props => (props.placeOptionsAbove ? null : `${props.textBoxHeight}px`)};
  bottom: ${props => (props.placeOptionsAbove ? `${props.textBoxHeight - 1}px` : null)};
  min-width: ${props => props.width};
  height: auto;
  max-height: 100px;
  box-sizing: border-box;
  padding: 6px 8px;
  border: 1px solid ${props => props.theme.dark};
  background-color: ${props => props.theme.bg};
  overflow-y: scroll;
`

const TagItemDiv = styled.div`
  width: 100%;
  height: 20px;
  background-color: ${props => (props.focus === props.id ? props.theme.light : props.theme.bg)};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :hover {
    cursor: pointer;
  }
`

export default AutoCompleteInput
