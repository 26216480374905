import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from 'store/store'
import detectBrowser from 'utils/detectBrowser'
import UnsupportedBrowser from 'pages/UnsupportedBrowser'
import ErrorBoundary from './ErrorBoundary'

let app

if (detectBrowser() === 'ie') app = <UnsupportedBrowser />
else
  app = (
    <Provider store={store}>
      <BrowserRouter>
        <React.StrictMode>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </React.StrictMode>
      </BrowserRouter>
    </Provider>
  )

ReactDOM.render(app, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
