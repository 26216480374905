import React from 'react'
import styled, { keyframes } from 'styled-components'

const Text = props => {
  return (
    <StyledText
      onClick={props.clicked}
      gridArea={props.gridArea}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      margin={props.margin}
      padding={props.padding}
      id={props.id}
      color={props.color}
      customColor={props.customColor}
      textAlign={props.textAlign}
      fade={props.fade}
      scroll={props.scroll}
      ellipsis={props.ellipsis}
      clip={props.clip}
      width={props.width}
      height={props.height}
      clickable={props.clickable}
      alignSelf={props.alignSelf}
      justifySelf={props.justifySelf}
    >
      {props.children}
    </StyledText>
  )
}

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1
  }

  50% {
    opacity: 1
  }

  75% {
    opacity: 1
  }

  100% {
    opacity: 0;
  }
`

const StyledText = styled.span`
  grid-area: ${props => props.gridArea || null};
  display: ${props => (props.ellipsis ? 'block' : 'inline')};
  color: ${props =>
    props.customColor
      ? props.customColor
      : props.theme[props.color] || 'inherit'};
  font-weight: ${props => props.fontWeight || 'null'};
  padding: ${props => props.padding || null};
  margin: ${props => props.margin || null};
  text-decoration: none;
  font-size: ${props => props.fontSize || 'inherit'};
  text-align: ${props => (props.textAlign ? props.textAlign : 'inherit')};
  animation: ${props => (props.fade ? fadeInOut : null)};
  animation-duration: 4s;
  overflow-x: ${props => (props.scroll ? 'scroll' : 'hidden')};
  white-space: ${props => (props.ellipsis || props.clip ? 'nowrap' : null)};
  text-overflow: ${props =>
    props.ellipsis ? 'ellipsis' : props.clip ? 'clip' : null};
  overflow: ${props => (props.ellipsis || props.clip ? 'hidden' : null)};
  width: ${props =>
    props.width ? props.width : props.ellipsis || props.clip ? '100%' : null};
  height: ${props => props.height || 'auto'};
  align-self: ${props => props.alignSelf || null};
  justify-self: ${props => props.justifySelf || null};

  :hover {
    cursor: ${props => (props.clickable ? 'pointer' : 'inherit')};
    font-weight: ${props => (props.clickable ? 'bold' : 'inherit')};
  }
`

export default Text
