import React, { Component } from 'react'
import styled from 'styled-components'

class Number extends Component {
  state = {
    value: this.props.value || ''
  }

  changeHandler = event => {
    let string = event.target.value
    if (!this.validInput(string)) return
    this.setState({ value: string }, () => {
      if (this.props.onChange) this.props.onChange(string)
    })
  }

  validInput = string => {
    if (/[^0-9]/g.test(string)) return false // not digit
    if (this.props.maxLength && string.length > this.props.maxLength) return false
    return true
  }

  render() {
    return (
      <StyledNumber
        type='text'
        id={this.props.id}
        width={this.props.width || null}
        value={this.state.value}
        onChange={this.changeHandler}
        required={this.props.required}
        pattern={this.props.maxLength ? `[0-9]{${this.props.maxLength}}` : '[0-9]'}
      />
    )
  }
}

const StyledNumber = styled.input`
  box-sizing: border-box;
  height: 26px;
  width: ${props => props.width || '100%'};
  padding: 4px 6px;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  outline: none;
  background-color: white;
  text-align: center;

  :focus {
    border: 2px solid ${props => props.theme.dark};
  }
`

export default Number
