import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from 'store/actions'
import { auth, db, functions } from 'utils/firebase'
import Input, { getInputValue } from 'components/NewInput'
import TextLink from 'components/TextLink'
import Loader from 'components/loader/Loader'
import Alert from 'components/Alert'
import sendVerificationCode from './sendVerificationCode'
import { doc, getDoc } from 'firebase/firestore'

class SignInModal extends Component {
  state = {
    loading: false,
    forgotPassword: false,
    emailValue: ''
  }

  signInHandler = async event => {
    event.preventDefault()
    const emailValue = getInputValue('email')
    const passwordValue = getInputValue('password')
    const rememberMe = getInputValue('rememberMe')

    this.setState({ loading: true, emailValue })
    await auth.setPersistence(rememberMe).catch(error => this.props.setError(error, false, 'Failed to set auth persistence.'))
    auth
      .signIn(emailValue, passwordValue)
      .then(() => {
        auth
          .currentUser()
          .getIdTokenResult()
          .then(response => {
            let user = response.claims
            this.uid = user.user_id
            if (response.claims.staff) {
              this.props.updateCurrentUser({
                uid: user.user_id,
                email: user.email,
                fullName: user.name,
                staff: true,
                anonymous: false
              })
            } else {
              this.alert = (
                <Alert
                  alertType='Verify account'
                  emailValue={emailValue}
                  alertButtonClicked={this.alertButtonClickedHandler}
                  resendVerificationEmail={() => sendVerificationCode(emailValue)}
                />
              )
              this.setState({ loading: false })
            }
          })
      })
      .catch(error => {
        if (error.code === 'auth/user-not-found') {
          this.alert = <Alert alertType='Email address not found' width='400px' alertButtonClicked={this.removeAlertHandler} />
        } else if (error.code === 'auth/wrong-password') {
          this.alert = <Alert alertType='Incorrect password' width='400px' alertButtonClicked={this.removeAlertHandler} />
        } else this.props.setError(error, false, 'Failed to sign in user. Reason unknown.')
        this.setState({ loading: false })
      })
  }

  showForgotPasswordModal = () => {
    this.setState({ forgotPassword: !this.state.forgotPassword })
  }

  hideForgotPasswordModal = event => {
    if (event.target.id === 'modalBackdrop') {
      this.setState({ forgotPassword: false })
    }
  }

  alertButtonClickedHandler = async event => {
    const emailValue = getInputValue('email')
    switch (event.target.id) {
      case 'verifyAccount':
        this.verifyAccount(getInputValue('verificationCodeInput'))
        break
      case 'wrongCode':
        this.alert = (
          <Alert
            alertType='Verify account'
            emailValue={emailValue}
            alertButtonClicked={this.alertButtonClickedHandler}
            resendVerificationEmail={() => sendVerificationCode(emailValue)}
          />
        )
        this.forceUpdate()
        break
      default:
        break
    }
  }

  verifyAccount = async givenCode => {
    this.setState({ loading: true })
    let verificationCode = await getDoc(doc(db, 'users', this.state.emailValue))
      .then(res => res.data().verificationCode)
      .catch(error => this.props.setError(error, false, 'Failed to get verification code from database in SignInModal.'))
    if (givenCode === verificationCode) {
      await this.addUserType()
      await auth.currentUser().getIdToken(true)
      this.alert = null
      this.props.showAuthModal(false)
    } else {
      this.alert = <Alert alertType='Incorrect code' alertButtonClicked={this.alertButtonClickedHandler} />
      this.setState({ loading: false })
    }
    return
  }

  retries = 0
  addUserType = async () => {
    return await functions
      .httpsCallable('addUserType')({
        uid: this.uid,
        type: 'staff'
      })
      .then(() => 'SUCCESS')
      .catch(async error => {
        this.retries++
        if (this.retries < 5)
          setTimeout(async () => {
            return await this.addUserType()
          }, 400)
        else this.props.setError(error, false, 'Failed to add user type in SignInModal')
      })
  }

  removeAlertHandler = () => {
    this.alert = null
    this.forceUpdate()
  }

  resetPasswordHandler = event => {
    const emailValue = getInputValue('forgotPasswordEmail')
    event.preventDefault()
    this.setState({
      forgotPassword: false,
      loading: true
    })
    auth
      .resetEmail(emailValue)
      .then(() => {
        this.alert = <Alert alertType='Reset password email sent' width='400px' emailValue={emailValue} alertButtonClicked={this.removeAlertHandler} />
        this.setState({
          loading: false
        })
      })
      .catch(error => {
        if (error.code === 'auth/invalid-email' || error.code === 'auth/user-not-found') {
          this.alert = <Alert alertType='Email address not found' width='400px' alertButtonClicked={this.removeAlertHandler} />
          this.setState({ loading: false, forgotPassword: true })
        } else this.props.setError(error, false, 'Failed to reset password in SignInModal.')
      })
  }

  render() {
    return (
      <Container>
        {this.state.loading ? (
          <Loader />
        ) : this.state.forgotPassword ? (
          <SignInForm onSubmit={this.resetPasswordHandler}>
            <Input type='email' id='forgotPasswordEmail' autoFocus placeholder='Please enter your email address' pattern='none'/>
            <Input type='button' buttonType='submit' width='auto' justify='center' id='submitForgotPassword' onClick={this.resetPasswordHandler}>
              Reset password
            </Input>
          </SignInForm>
        ) : (
          <SignInForm onSubmit={this.signInHandler}>
            <Input type='email' id='email' value={this.state.emailValue} label='UJ email address' autoFocus required />
            <Input type='password' id='password' autoComplete='current-password' label='Password' required />
            <Input type='checkbox' text='Remember me?' id='rememberMe' justify='center' />
            <SubmitButton>
              <Input type='button' id='submitButton' buttonType='submit'>
                Sign in
              </Input>
            </SubmitButton>
            <ResetPassword>
              <TextLink fontSize='16px' clicked={this.showForgotPasswordModal} tabIndex='0'>
                Reset Password
              </TextLink>
            </ResetPassword>
          </SignInForm>
        )}
        <GoBack onClick={this.props.changeWindowMode} id='staff' loading={this.state.loading.toString()}>
          GO BACK
        </GoBack>
        {this.alert}
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SignInForm = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 20px;
`

const SubmitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 20px 0px;
`

const ResetPassword = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
`

const GoBack = styled.div`
  display: ${props => (props.loading === 'true' ? 'none' : 'block')};
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.dark};

  :hover {
    cursor: pointer;
    font-size: 15px;
    left: 14px;
    bottom: 14px;
  }
`

const mapStateToProps = state => {
  return {
    smallDevice: state.ui.smallDevice,
    config: state.config
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentUser: userObject => dispatch(actions.updateCurrentUser(userObject)),
    showAuthModal: value => dispatch(actions.showAuthModal(value)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInModal)
