import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from 'store/actions'
import UploadPhotoCard from 'pages/upload/UploadPhotoCard'

class UploadExplorer extends Component {
  state = {
    dropAreaColor: null,
    tags: {},
    albumCover: ''
  }

  componentDidMount() {
    this.stagedFilesHolder = this.props.stagedFiles
    this.populateExplorerWithStagedFiles()
    this.createTagOptions()
    if (document.getElementById('styledUploadExplorer')) {
      document.getElementById('styledUploadExplorer').scrollTo(0, 0)
    }
  }

  componentDidUpdate() {
    if (this.props.stagedFiles !== this.stagedFilesHolder) {
      this.populateExplorerWithStagedFiles()
      this.stagedFilesHolder = this.props.stagedFiles
    }
    if (this.tagOptions.length === 0) {
      this.createTagOptions()
      this.forceUpdate()
    }
  }

  addValueToAutoCompleteOptions = event => {
    const newTags = event.split(',')
    newTags.forEach(tag => {
      if (!this.tagOptions.includes(tag.trim()) && tag.trim().length > 1) {
        this.tagOptions.push(tag.trim())
        this.forceUpdate()
      }
    })
  }

  albumCoverSelectedHandler = event => {
    if (event.target.id.includes('?albumCover')) {
      this.props.albumCoverSelected(
        event.target.id.slice(0, event.target.id.indexOf('?'))
      )
      this.setState(
        { albumCover: event.target.id.slice(0, event.target.id.indexOf('?')) },
        () => {
          this.populateExplorerWithStagedFiles()
        }
      )
    }
  }

  tagOptions = []
  createTagOptions = () => {
    this.props.approved.tags.forEach(tagOption => {
      this.tagOptions.push(tagOption.key)
    })
  }

  populateExplorerWithStagedFiles = () => {
    this.imageArray = []
    this.props.stagedFiles.forEach(file => {
      const fileName = file.name
      const easyFileName = fileName.slice(0, fileName.lastIndexOf('.'))
      this.imageArray.push({
        url: URL.createObjectURL(file),
        alt: easyFileName,
        fileName
      })
    })
    this.imageArray.sort((a, b) => {
      return a.fileName < b.fileName ? 1 : a.fileName > b.fileName ? -1 : 0 
    })
    this.stagedFiles = this.imageArray.map(image => {
      return (
        <UploadPhotoCard
          key={image.alt}
          id={`${image.alt}-photoCard`}
          fileName={image.fileName}
          albumCoverSelected={this.albumCoverSelectedHandler}
          albumCover={this.state.albumCover === image.fileName}
          src={image.url}
          alt={image.alt}
          removePhoto={this.removePhoto}
          tagOptions={this.tagOptions}
          addValueToAutoCompleteOptions={this.addValueToAutoCompleteOptions}
        />
      )
    })
    this.forceUpdate()
  }

  removePhoto = event => {
    this.props.removeFile(event.target.id)
    this.populateExplorerWithStagedFiles(this.props.albumCover)
  }

  dragOverHandler = event => {
    event.preventDefault()
    this.setState({ dropAreaColor: '#bbe6c0' })
  }

  dragLeaveHandler = event => {
    event.preventDefault()
    this.setState({ dropAreaColor: null })
  }

  dropHandler = event => {
    event.preventDefault()
    this.setState({ dropAreaColor: null })
    this.files = []
    let sizeLimitExceeded = false
    let numberOfFiles = event.dataTransfer.files.length
    let imagesChecked = 0
    Object.values(event.dataTransfer.files).forEach(file => {
      if (file.type === 'image/jpeg') {
        let img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
          if (img.width > 15000 || img.height > 15000) {
            sizeLimitExceeded = true
          } else {
            this.files.push(file)
          }
          imagesChecked += 1
          if (imagesChecked === numberOfFiles) {
            if (sizeLimitExceeded) {
              this.props.sizeLimitExceeded()
            }
            this.props.addFiles(this.files)
          }
        }
      }
    })
  }

  render() {
    return (
      <StyledUploadExplorer
        id="styledUploadExplorer"
        onDrop={this.dropHandler}
        onDragOver={this.dragOverHandler}
        onDragLeave={this.dragLeaveHandler}
        dropAreaColor={this.state.dropAreaColor}
      >
        {this.stagedFiles}
      </StyledUploadExplorer>
    )
  }
}

const StyledUploadExplorer = styled.div`
  position: relative;
  display: grid;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-around;
  grid-gap: 30px;
  padding: 10px 10px;
  overflow-y: auto;
  background-color: ${props =>
    props.dropAreaColor ? '#bbe6c0' : props.theme.bg};
`

const mapStateToProps = state => {
  return {
    stagedFiles: state.upload.files,
    approved: state.forms.approved
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addFiles: files => dispatch(actions.addFiles(files)),
    removeFile: fileName => dispatch(actions.removeFile(fileName))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadExplorer)
