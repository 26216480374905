import store from 'store/store'
import { setError } from 'store/actions/errorActions'
import { clearFiles } from 'store/actions/index'
import { db, functions, storage, firebase } from 'utils/firebase'
import { addPendingFormItems } from 'store/actions/formActions'
import getDateTaken from 'utils/getDateTaken'
import createSearchKeysFromString from 'utils/createSearchKeysFromString'
import { addDoc, collection, deleteDoc, doc, writeBatch } from 'firebase/firestore'

var files, state, photographer, bookingData, tagsObject, updateProgress, progress, albumId, keys, retries, batch

const uploadFiles = async (_files, _state, _updateProgress, _bookingData) => {
  initializeUpload(_state, _files, _updateProgress, _bookingData)

  if (state.uploadMode !== 'stock') albumId = await getNewAlbumId()

  updateProgress(progress)
  await uploadFilesSequentially()
  if (albumId) {
    keys = createAlbumSearchKeys()
    createAlbumDocument()
    if (state.uploadMode === 'existing album') {
      updateBookingStatus()
      retries = 0
      sendCompletionEmail()
    }
  }
  await commitToDatabase()
  updateProgress(null)
  store.dispatch(clearFiles())
  return
}

function initializeUpload(_state, _files, _updateProgress, _bookingData) {
  albumId = null
  retries = 0
  batch = writeBatch(db)
  state = { ..._state }
  files = sortFiles(_files)
  photographer = getPhotographerName()
  updateProgress = _updateProgress
  bookingData = _bookingData
  tagsObject = makeTagsObject()
  progress = initialiseProgress()
}

async function uploadFilesSequentially() {
  addTagsToPendingFormItems()

  for (const file of files) {
    const fileInfo = await prepareFileInfo(file)
    retries = 0
    await uploadFile(file, fileInfo)
    convertPhoto(fileInfo)
    createPhotoDocument(fileInfo)
  }
  return
}

async function prepareFileInfo(file) {
  const originalFileName = file.name
  const id = await getPhotoId()
  const storageRef = setStorageReference(id)
  const dateTaken = await getDateTaken(file)
  const albumCover = isAlbumCover(file)
  const metadata = setMetaData(id)
  return { originalFileName, id, storageRef, dateTaken, albumCover, metadata }
}

function sortFiles(files) {
  return files.sort((a, b) => a.name.localeCompare(b.name, { ignorePunctuation: true }))
}

function getPhotographerName() {
  return store.getState().auth.currentUser.fullName
}

function makeTagsObject() {
  let tagsObject = {}
  for (const file of files) {
    let originalFileName = removeFileNameExtension(file.name)
    let tags = createTagArrayFromTagInput(originalFileName)
    tagsObject[file.name] = tags
  }
  return tagsObject
}

function removeFileNameExtension(fileName) {
  return fileName.slice(0, fileName.lastIndexOf('.'))
}

function createTagArrayFromTagInput(originalFileName) {
  const tagInput = document.getElementById(`tagInput-${originalFileName}`)
  const tagString = tagInput.value
  return tagString
    .split(',')
    .map(tag => tag.trim().toLowerCase())
    .filter(tag => tag)
}

function initialiseProgress() {
  return {
    albumName: state.albumName || 'stock',
    numberOfFiles: files.length,
    totalBytes: calculateTotalBytes(),
    transferred: 0,
    currentFile: null,
    uploadedFiles: []
  }
}

function calculateTotalBytes() {
  return files.reduce((x, y) => x + y.size, 0)
}

async function getNewAlbumId() {
  return await addDoc(collection(db, 'albums'), {})
    .then(response => response.id)
    .catch(error => store.dispatch(setError(error, true, 'Failed to get new album ID in Upload component.')))
}

async function getPhotoId() {
  return await addDoc(collection(db, 'photos'), {})
    .then(res => res.id)
    .catch(error => store.dispatch(setError(error, true, 'Failed to get new photo ID in Upload component.')))
}

function setStorageReference(id) {
  return storage.ref(`photos/${albumId || `stock/${id}`}/${id}_print.jpg`)
}

function setMetaData(id) {
  return {
    contentType: 'image/jpeg',
    contentDisposition: `attachment; filename="${id}_print.jpg"`,
    cacheControl: 'public, max-age=525600000' // one year
  }
}

function isAlbumCover(file) {
  return file.name === state.albumCover
}

async function uploadFile(file, fileInfo) {
  return new Promise(function (resolve) {
    let uploadTask = fileInfo.storageRef.put(file, fileInfo.metadata)
    uploadTask.on(
      'state_changed',
      snapshot => {
        if (isUploadCancelled()) uploadTask.cancel()
        updateUploadProgress(file, fileInfo, snapshot.bytesTransferred)
      },
      //error
      () => {
        removePartialData()
      },
      //success
      () => {
        resolve(uploadSuccess(file, fileInfo))
      }
    )
  })
}

function isUploadCancelled() {
  let uploadState = store.getState().upload
  return uploadState.progress.cancel
}

function updateUploadProgress(file, fileInfo, bytesTransferred) {
  let currentFile = { name: fileInfo.originalFileName, id: fileInfo.id, size: file.size, transferred: bytesTransferred }
  progress.currentFile = currentFile
  let totalTransferred = progress.transferred + bytesTransferred
  updateProgress({ ...progress, transferred: totalTransferred, cancel: isUploadCancelled() })
}

async function removePartialData() {
  await deletePhotoDocuments()
  await Promise.all([deleteFilesFromStorage(), deleteAlbumDocument()])
  batch = writeBatch(db)
  updateProgress(null)
}

async function deleteFilesFromStorage() {
  for (const fileInfo of progress.uploadedFiles) {
    const folder = storage.ref(`photos/${fileInfo.id}`)
    const files = await folder.listAll().catch(error => store.dispatch(setError(error, false, 'Failed to list photos to delete in Upload component.')))
    for (const file of files.items) {
      try {
        file.delete()
      } catch {
        return
      }
    }
  }
  return
}

async function deletePhotoDocuments() {
  for (const file of progress.uploadedFiles) {
    await deleteDoc(doc(db, 'photos', file.id)).catch(error =>
      store.dispatch(setError(error, false, 'Failed to delete photo document from database in Upload component.'))
    )
  }
  await deleteDoc(doc(db, 'photos', progress.currentFile.id)).catch(error =>
    store.dispatch(setError(error, false, 'Failed to delete photo document from database in Upload component.'))
  )
  return
}

async function deleteAlbumDocument() {
  if (!albumId) return
  await deleteDoc(doc(db, 'albums', albumId)).catch(error =>
    store.dispatch(setError(error, false, 'Failed to delete album document from database in Upload component.'))
  )
  return
}

function convertPhoto(fileInfo) {
  const id = fileInfo.id
  firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('convertPhoto')({
      fileBucket: getFileBucket(),
      filePath: `photos/${albumId || `stock/${id}`}/${id}_print.jpg`,
      id,
      albumId,
      albumCover: fileInfo.albumCover
    })
    .catch(error => {
      retries++
      if (retries < 5)
        setTimeout(() => {
          convertPhoto(fileInfo)
        }, 1000)
      else store.dispatch(setError(error, false, 'Failed to convert photos in Upload component.'))
    })
}

function getFileBucket() {
  return process.env.NODE_ENV === 'production' ? 'ujdml-prod.appspot.com' : 'ujdml-development.appspot.com'
}

function uploadSuccess(file, fileInfo) {
  progress.transferred += file.size
  progress.uploadedFiles.push(fileInfo)
}

function addTagsToPendingFormItems() {
  let allTags = []
  Object.values(tagsObject).forEach(tagArray => {
    allTags = allTags.concat(tagArray)
  })
  allTags = [...new Set(allTags)]

  store.dispatch(addPendingFormItems({ tags: allTags }))
}

function createPhotoDocument(fileInfo) {
  batch.update(doc(db, 'photos', fileInfo.id), {
    id: fileInfo.id,
    stock: state.uploadMode === 'stock',
    album: albumId,
    dateTaken: fileInfo.dateTaken,
    originalFileName: fileInfo.originalFileName,
    tags: tagsObject[fileInfo.originalFileName],
    keys: createSearchKeysFromTags(fileInfo.originalFileName),
    info: createPhotoInfo(),
    views: [],
    downloads: 0
  })
}

function createPhotoInfo() {
  if (state.uploadMode === 'stock') return null
  else
    return {
      department: state.department,
      faculty: state.faculty,
      campus: state.campus,
      venue: state.venue,
      albumName: state.albumName
    }
}

function createAlbumSearchKeys() {
  const keys = [
    ...new Set(
      [formatString(state.campus)]
        .concat(createSearchKeysFromString(formatString(state.faculty)))
        .concat(createSearchKeysFromString(formatString(state.department)))
        .concat(createSearchKeysFromString(formatString(state.venue)))
        .concat(createSearchKeysFromString(state.description))
        .concat(createSearchKeysFromString(state.name))
        .concat(createSearchKeysFromTags())
    )
  ]

  return keys
}

function formatString(str) {
  return str.toLowerCase().trim()
}

function createSearchKeysFromTags(originalFileName) {
  let combinedTags = []
  if (originalFileName) {
    for (const tag of tagsObject[originalFileName]) {
      const words = tag.split(' ')
      for (const word of words) {
        combinedTags = combinedTags.concat(createSearchKeysFromString(word))
      }
    }
  } else {
    for (const tags of Object.values(tagsObject)) {
      for (const tag of tags) {
        const words = tag.split(' ')
        for (const word of words) {
          combinedTags = combinedTags.concat(createSearchKeysFromString(word))
        }
      }
    }
  }
  combinedTags = [...new Set(combinedTags)]
  return combinedTags
}

function createAlbumDocument() {
  batch.update(doc(db, 'albums', albumId), {
    id: albumId,
    uploaded: new Date(),
    name: state.albumName,
    campus: state.campus,
    contact: state.contact,
    date: state.date,
    department: state.department,
    description: state.description,
    faculty: state.faculty,
    photographer,
    keys,
    venue: state.venue,
    views: [],
    downloads: 0,
    totalPhotos: files.length
  })
}

function updateBookingStatus() {
  batch.update(doc(db, 'bookings', state.id), { status: 'Completed' })
}

function sendCompletionEmail() {
  const toRecipients = [{ emailAddress: { address: bookingData.clientEmail } }]
  const ccRecipients = bookingData.clientEmail !== bookingData.contactEmail ? [{ emailAddress: { address: bookingData.contactEmail } }] : null
  const bookingName = bookingData.name
  const clientName = bookingData.clientName
  const subject = `Photos uploaded: ${bookingName}`
  const messageContent = `
            <div style={padding: '40px 60px' margin: '40px 60px'}>
            <h2 style={fontSize: '40px'}>Photos uploaded: ${bookingName}</h2>
            <p>Dear ${clientName},</p>
            <p>Your photos have been uploaded and can be viewed <a href="https://ujdml.co.za/albums/${albumId}">here</a>.</p>
            <p>Please do not reply to this email.</p>
            <p>Regards.</p>
            <h3>UJ Digital Media Library</h3>
            </div>
          `

  functions
    .httpsCallable('sendMail')({
      toRecipients,
      ccRecipients,
      subject,
      messageContent
    })
    .catch(error => {
      if (retries < 4) {
        retries++
        setTimeout(() => {
          return sendCompletionEmail()
        }, 400)
      } else store.dispatch(setError(error, false, 'Failed to send completion email in Upload component.'))
    })
}

async function commitToDatabase() {
  return await batch.commit().catch(error => store.dispatch(setError(error, false, 'Failed to write to database in Upload component.')))
}

export default uploadFiles
