import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { auth, db } from 'utils/firebase'
import { deleteDoc, doc } from 'firebase/firestore'
import * as actions from 'store/actions'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Input from 'components/Input'
import Loader from 'components/loader/Loader'
import Alert from 'components/Alert'

class ConfirmDeleteAccountModal extends Component {
  state = {
    passwordValue: '',
    loading: false
  }

  passwordChangedHandler = event => {
    this.setState({ passwordValue: event.target.value })
  }

  backdropClicked = event => {
    if (event.target.id === 'modalBackdrop') {
      this.props.showConfirmDeleteAccountModal()
    }
  }

  retryPasswordHandler = () => {
    this.alert = null
    this.setState({ passwordValue: '' })
    document.getElementById('passwordInput').focus()
  }

  confirmAccountDeletionHandler = event => {
    event.preventDefault()
    this.setState({ loading: true })
    auth
      .signIn(this.props.currentUser.email, this.state.passwordValue)
      .then(() => {
        deleteDoc(doc(db, 'users', this.props.currentUser.email))
          .then(() => {
            auth.deleteUser()
              .then(() => {
                this.props.updateCurrentUser(null)
                this.props.showConfirmDeleteAccountModal()
                this.props.history.push('/')
              })
              .catch(error => {
                this.props.setError(error, false, 'Failed to delete auth user object via the ConfirmDeleteAccountModal.')
              })
          })
          .catch(error => this.props.setError(error, false, 'Failed to delete user db object via the ConfirmDeleteAccountModal.'))
      })
      .catch(error => {
        if (error.code === 'auth/wrong-password')
          this.alert = <Alert alertType='Incorrect password' width='400px' alertButtonClicked={this.retryPasswordHandler} />
        else this.props.setError(error, false, 'Failed to delete account via the ConfirmDeleteAccountModal.')

        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <React.Fragment>
        <Modal title='Confirm account deletion' width='600px' backdropClicked={this.backdropClicked}>
          <form onSubmit={this.confirmAccountDeletionHandler}>
            <StyledConfirmDeleteAccountModal>
              <InputLine>
                <Input
                  type='password'
                  value={this.state.passwordValue}
                  inputLabel='Password'
                  id='passwordInput'
                  changed={this.passwordChangedHandler}
                  width='70%'
                  autoFocus
                  required
                />
              </InputLine>
              <InputLine height='40px' />
              <InputLine>
                <Button type='submit' fontSize='16px' disabled={this.state.passwordValue.length < 6}>
                  Submit
                </Button>
              </InputLine>
              <InputLine />
            </StyledConfirmDeleteAccountModal>
          </form>
        </Modal>
        {this.state.loading ? <Loader backdrop={true} /> : null}
        {this.alert}
      </React.Fragment>
    )
  }
}

const StyledConfirmDeleteAccountModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`

const InputLine = styled.div`
  width: 100%;
  height: ${props => props.height || 'auto'};
  display: flex;
  justify-content: center;
  align-items: center;
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showConfirmDeleteAccountModal: () => dispatch(actions.showConfirmDeleteAccountModal()),
    updateCurrentUser: userObject => dispatch(actions.updateCurrentUser(userObject)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteAccountModal)
