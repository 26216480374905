import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as actions from 'store/actions'
import styled from 'styled-components'
import { db } from 'utils/firebase'
import PhotoCard from 'components/PhotoCard'
import Alert from 'components/Alert'
import Loader from 'components/loader/Loader'
import Share from 'components/Share'
import { collection, doc, getDoc, getDocs, query, updateDoc, where, arrayUnion, increment, writeBatch } from 'firebase/firestore'

class AlbumView extends Component {
  state = {
    loading: true,
    showInfo: false,
    transitionBusy: false
  }

  selection = []
  photoArray = []
  currentUser = null

  componentDidMount() {
    this.init()
  }

  componentDidUpdate() {
    this.setAvailableWidthForMobile()
    if (this.currentUser !== this.props.currentUser) {
      this.currentUser = this.props.currentUser
      this.updateAlbumViews(this.props.match.params.albumName)
    }
  }

  componentWillUnmount() {
    this.setScrollPosition()
  }

  init = () => {
    this.setAvailableWidthForMobile()
    let albumId = this.props.match.params.albumName
    this.currentUser = this.props.currentUser
    if (!this.props.smallDevice) this.setState({ showInfo: true })
    this.populatePhotoCollection(albumId)
    this.updateAlbumViews(albumId)
  }

  setAvailableWidthForMobile = () => {
    if (!this.props.smallDevice) return
    if (this.availableWidth === calculateAvailableWidth()) return
    this.availableWidth = calculateAvailableWidth()
    this.calculateToolbarButtons()
    this.forceUpdate()

    function calculateAvailableWidth() {
      return `${window.innerWidth - 60}px`
    }
  }

  // Populate the photoArray with all images from specific album based on albumName from URL
  populatePhotoCollection = async albumName => {
    this.photoArray = await getDocs(query(collection(db, 'photos'), where('album', '==', albumName)))
      .then(photos => {
        return photos.docs.map(photo => photo.data())
      })
      .catch(error => {
        this.props.setError(error, true, 'Failed to load photos in AlbumView component.')
      })

    this.photoArray.sort((a, b) => {
      if (a.originalFileName < b.originalFileName) return -1
      else if (a.originalFileName > b.originalFileName) return 1
      else return 0
    })

    this.albumData = await getDoc(doc(db, 'albums', albumName))
      .then(album => album.data())
      .catch(error => this.props.setError(error, true, 'Failed to load albumData in AlbumView component.'))
    this.setState({ loading: false })
    if (document.getElementById('description')) document.getElementById('description').scrollTo(0, 0)
    this.getScrollPosition()
  }

  updateAlbumViews = id => {
    if (this.props.currentUser && this.props.currentUser.uid) {
      updateDoc(doc(db, 'albums', id), {
        views: arrayUnion(this.props.currentUser.uid)
      }).catch(error => {
        this.props.setError(error, false, 'Failed to update album views in AlbumView component.')
      })
    }
  }

  updateAlbumDownloads = id => {
    updateDoc(doc(db, 'albums', id), {
      downloads: increment(1)
    }).catch(error => this.props.setError(error, false, 'Failed to update album downloads in AlbumView component.'))
  }

  updatePhotoDownloads = photos => {
    // NTS: move to download component
    if (typeof photos === 'string') {
      updateDoc(doc(db, 'photos', photos), {
        downloads: increment(1)
      }).catch(error => this.props.setError(error, false, 'Failed to update photo downloads in AlbumView component.'))
    } else {
      let batch = writeBatch(db)
      photos.forEach(photo => {
        batch.update(doc(db, 'photos', photo.name), {
          downloads: increment(1)
        })
      })
      batch.commit().catch(error => this.props.setError(error, true, 'Failed to update photo downloads in AlbumView component.'))
    }
  }

  // Open the photo view for the clicked photo (unless ctrl is pressed, which selects the photo instead)
  photoClickedHandler = event => {
    event.stopPropagation()
    let id = event.target.id.slice(event.target.id.lastIndexOf('-') + 1)
    if (event.target.id.includes('button')) return
    if (event.ctrlKey || this.selection.length > 0) this.selectPhoto(null, id)
    else {
      this.props.populatePhotoCollection(this.photoArray)
      this.props.history.push('/photos/' + id)
    }
  }

  // Add photo to selection (for downloading a specific set of images)
  selectPhoto = (event, passedId) => {
    if (event) event.stopPropagation()
    let id = passedId || event.target.id.slice(event.target.id.lastIndexOf('-') + 1)
    if (this.selection.includes(id)) this.selection.splice(this.selection.indexOf(id), 1)
    else this.selection.push(id)

    this.forceUpdate()
  }

  selectAll = () => {
    this.clearSelection()
    this.photoArray.forEach(photo => {
      this.selection.push(photo.id)
    })
    this.forceUpdate()
  }

  clearSelection = () => {
    this.selection = []
    this.forceUpdate()
  }

  alertButtonClicked = event => {
    if (['continue', 'modalBackdrop'].includes(event.target.id)) {
      this.alert = null
      this.forceUpdate()
    }
  }

  downloadHandler = event => {
    event.stopPropagation()
    const id = event.target.id.slice(event.target.id.lastIndexOf('-') + 1)
    this.props.populatePhotoCollection(this.photoArray)
    if (event.target.id.includes('button')) this.props.download(id, this.albumData.name)
    else if (this.selection.length > 0) this.props.download(this.selection, this.albumData.name)
    else this.props.download('all', this.albumData.name)
  }

  showHideInfo = () => {
    this.infoWidth = this.props.smallDevice ? null : `${document.getElementById('info').clientWidth}px`
    this.setState({ showInfo: !this.state.showInfo, transitionBusy: true })
  }

  transitionEnd = () => {
    this.setState({ transitionBusy: false })
  }

  showShare = () => {
    this.shareWindow = <Share hideShare={this.hideShare} />
    this.forceUpdate()
  }

  hideShare = () => {
    this.shareWindow = null
    this.forceUpdate()
  }

  addToCollection = event => {
    event.stopPropagation()
    if (event.target.id === 'addToCollection' && this.selection.length === 0) {
      this.alert = (
        <Alert
          alertType='Add to collection'
          width={this.props.smallDevice ? '80%' : null}
          alertButtonClicked={this.alertButtonClicked}
          backdropClicked={this.alertButtonClicked}
        />
      )
      this.forceUpdate()
    } else {
      let photoIds = null
      let photos = []
      if (this.selection.length === 0) photoIds = [event.target.id.slice(event.target.id.lastIndexOf('-') + 1)]
      else photoIds = this.selection
      photoIds.forEach(id => {
        photos.push(this.photoArray.find(photo => photo.id === id))
      })
      this.props.loadPhotos(photos)
    }
  }

  calculateToolbarButtons = () => {
    this.toolbarButtons = 3
    if (this.props.currentUser && this.props.currentUser.staff) this.toolbarButtons += 2
  }

  setScrollPosition = () => {
    const explorer = document.getElementById('explorer')
    if (!explorer) return
    const albumId = this.props.match.params.albumName
    const scrollPosition = explorer.scrollTop
    sessionStorage.setItem(albumId, scrollPosition)
  }

  getScrollPosition = () => {
    const explorer = document.getElementById('explorer')
    if (!explorer) return
    const albumId = this.props.match.params.albumName
    const savedPosition = sessionStorage.getItem(albumId) || 0
    explorer.scrollTo(0, savedPosition)
  }

  render() {
    let smallDevice = this.props.smallDevice

    if (this.photoArray) {
      this.photos = this.photoArray.map(photo => {
        return (
          <PhotoCard
            key={photo.id}
            id={photo.id}
            src={photo.thumbURL}
            alt={photo.id}
            onClick={this.photoClickedHandler}
            select={this.selectPhoto}
            selected={this.selection.includes(photo.id)}
            download={this.downloadHandler}
            hideButtons={this.selection.length > 0}
            addToCollection={this.addToCollection}
          />
        )
      })
    }

    return this.state.loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <StyledAlbumView anonymousUser={!this.props.currentUser || this.props.currentUser.anonymous}>
          {this.props.currentUser && this.props.currentUser.staff ? (
            <Info
              id='info'
              showInfo={this.state.showInfo}
              transitionBusy={this.state.transitionBusy}
              onTransitionEnd={this.transitionEnd}
              availableWidth={this.availableWidth}
              smallDevice={smallDevice}
            >
              <Title transitionBusy={this.state.transitionBusy} width={this.infoWidth} availableWidth={this.availableWidth} smallDevice={smallDevice}>
                {this.albumData.name}
              </Title>
              <AlbumDetails width={this.infoWidth} transitionBusy={this.state.transitionBusy} availableWidth={this.availableWidth} smallDevice={smallDevice}>
                <AlbumDetailTitle smallDevice={smallDevice}>Date</AlbumDetailTitle>
                <AlbumDetail smallDevice={smallDevice}>
                  {new Date(this.albumData.date.toMillis()).toLocaleDateString('en-ZA', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </AlbumDetail>

                <AlbumDetailTitle smallDevice={smallDevice}>Venue</AlbumDetailTitle>
                <AlbumDetail id='venue' smallDevice={smallDevice}>
                  {this.albumData.venue}
                </AlbumDetail>

                <AlbumDetailTitle smallDevice={smallDevice}>Faculty/Division</AlbumDetailTitle>
                <AlbumDetail id='faculty' smallDevice={smallDevice}>
                  {this.albumData.faculty}
                </AlbumDetail>

                <AlbumDetailTitle smallDevice={smallDevice}>Department</AlbumDetailTitle>
                <AlbumDetail id='department' smallDevice={smallDevice}>
                  {this.albumData.department}
                </AlbumDetail>

                <AlbumDetailTitle smallDevice={smallDevice}>Contact person</AlbumDetailTitle>
                <AlbumDetail smallDevice={smallDevice}>{this.albumData.contact || this.albumData.contactPerson}</AlbumDetail>

                <AlbumDetailTitle smallDevice={smallDevice}>Photographer</AlbumDetailTitle>
                <AlbumDetail smallDevice={smallDevice}>{this.albumData.photographer}</AlbumDetail>

                <AlbumDetailTitle smallDevice={smallDevice} description>
                  Description
                </AlbumDetailTitle>
                <AlbumDetail smallDevice={smallDevice} description id='description'>
                  {this.albumData.description}
                </AlbumDetail>
              </AlbumDetails>
            </Info>
          ) : (
            <AlbumTitle smallDevice={smallDevice}>{this.albumData.name}</AlbumTitle>
          )}

          <Explorer tabIndex='-1' onClick={this.clearSelection} id='explorer'>
            {this.photos}
          </Explorer>
        </StyledAlbumView>

        <Footer>
          <ShowHideInfo
            onClick={this.showHideInfo}
            hidden={!this.props.currentUser || !this.props.currentUser.staff}
            title={this.state.showInfo ? 'Hide info' : 'Show info'}
            smallDevice={smallDevice}
          >
            {this.state.showInfo ? 'hide info' : 'show info'}
          </ShowHideInfo>

          <Toolbar smallDeviceLandscape={this.props.smallDeviceLandscape} id='bottom-toolbar'>
            {smallDevice ? (
              <React.Fragment>
                <SelectAll
                  onClick={this.selectAll}
                  showShare={this.props.currentUser && this.props.currentUser.staff}
                  toolbarButtons={this.toolbarButtons}
                  smallDevice={smallDevice}
                >
                  <i className='material-icons'>check_box</i>
                </SelectAll>
                <ClearSelection
                  onClick={this.clearSelection}
                  showShare={this.props.currentUser && this.props.currentUser.staff}
                  toolbarButtons={this.toolbarButtons}
                  smallDevice={smallDevice}
                >
                  <i className='material-icons'>check_box_outline_blank</i>
                </ClearSelection>
              </React.Fragment>
            ) : (
              <SelectionPanel keepRight={!this.props.currentUser || !this.props.currentUser.staff}>
                <SelectAll onClick={this.selectAll} title='Select all' smallDevice={smallDevice}>
                  <i className='material-icons'>check_box</i>
                </SelectAll>
                <ClearSelection onClick={this.clearSelection} title='Clear selection' smallDevice={smallDevice}>
                  <i className='material-icons'>check_box_outline_blank</i>
                </ClearSelection>
              </SelectionPanel>
            )}

            <AddButton
              id='addToCollection'
              onClick={this.addToCollection}
              title='Add to collection'
              hidden={!this.props.currentUser || !this.props.currentUser.staff}
              toolbarButtons={this.toolbarButtons}
              smallDevice={smallDevice}
            >
              <i className='material-icons' id='addToCollection' onClick={this.addToCollection}>
                add_circle_outline
              </i>
            </AddButton>
            <ShareButton
              onClick={this.showShare}
              title='Share album'
              hidden={!this.props.currentUser || !this.props.currentUser.staff}
              toolbarButtons={this.toolbarButtons}
              smallDevice={smallDevice}
            >
              <i className='material-icons' onClick={this.showShare}>
                share
              </i>
            </ShareButton>
            <DownloadButton
              title={this.selection.length > 0 ? 'Download selected' : 'Download album'}
              onClick={this.downloadHandler}
              toolbarButtons={this.toolbarButtons}
              smallDevice={smallDevice}
            >
              <i className='material-icons'>save_alt</i>
            </DownloadButton>
          </Toolbar>
        </Footer>
        {this.shareWindow}
        {this.alert}
      </React.Fragment>
    )
  }
}

const StyledAlbumView = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  grid-area: main;
  display: flex;
  flex-direction: ${props => (props.anonymousUser ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`

const AlbumTitle = styled.div`
  position: relative;
  width: 100%;
  height: ${props => (props.smallDevice ? 'auto' : '30px')};
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '16px' : '30px')};
  color: ${props => props.theme.dark};
  margin-bottom: ${props => (props.smallDevice ? '10px' : '20px')};
`

const Info = styled.div`
  position: ${props => (props.smallDevice ? 'absolute' : 'relative')};
  box-sizing: border-box;
  height: 100%;
  width: ${props => (props.showInfo ? (props.smallDevice ? props.availableWidth : '700px') : '0px')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: ${props => (props.smallDevice ? 'auto' : 'hidden')};
  color: ${props => props.theme.main};
  background-color: ${props => (props.smallDevice ? props.theme.bg : null)};
  border-right: ${props => (props.smallDevice ? '0px' : '1px')} solid ${props => (props.showInfo ? props.theme.main : props.theme.bg)};
  overflow: hidden;
  transition: width ${props => (props.smallDevice ? '0.4s' : '0.3s')} linear;
  z-index: ${props => (props.smallDevice ? 2 : null)};
`

const Title = styled.div`
  position: relative;
  font-size: ${props => (props.smallDevice ? '18px' : '20px')};
  font-weight: bold;
  flex-shrink: ${props => (props.smallDevice ? null : 0)};
  color: ${props => props.theme.dark};
  margin-bottom: ${props => (props.smallDevice ? null : '20px')};
  padding-bottom: ${props => (props.smallDevice ? '20px' : null)};
  overflow: hidden;
  width: ${props => (props.smallDevice ? props.availableWidth : props.transitionBusy ? props.width : '100%')};
  height: auto;
`

const AlbumDetails = styled.div`
  position: relative;
  height: 100%;
  width: ${props => (props.smallDevice ? props.availableWidth : props.transitionBusy ? props.width : '100%')};
  flex-shrink: ${props => (props.smallDevice ? null : 0)};
  overflow-x: hidden;
  overflow-y: ${props => (props.smallDevice ? 'auto' : 'hidden')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${props => props.theme.dark};
`

const AlbumDetailTitle = styled.div`
  flex-shrink: ${props => (props.smallDevice ? null : 0)};
  font-weight: bold;
  padding-left: 10px;
  font-size: ${props => (props.smallDevice ? '12px' : 'initial')};
  padding-bottom: ${props => (props.smallDevice ? (props.description ? '10px' : null) : null)};
`

const AlbumDetail = styled.div`
  flex-shrink: ${props => (props.smallDevice ? null : props.description ? 1 : 0)};
  font-size: ${props => (props.smallDevice ? '14px' : 'initial')};
  padding-left: 20px;
  padding-right: ${props => (props.description ? '20px' : '0px')};
  padding-top: ${props => (props.smallDevice ? '2px' : null)};
  padding-bottom: ${props => (props.smallDevice ? '20px' : null)};
  margin: ${props => (props.smallDevice ? null : '4px 0px 15px 0px')};
  overflow-y: ${props => (props.description ? 'auto' : 'hidden')};
  max-height: ${props => (props.smallDevice ? (props.description ? 'auto' : 'hidden') : null)};
  min-height: ${props => (props.smallDevice ? (props.description ? '150px' : null) : null)};
  text-align: ${props => (props.smallDevice ? null : 'justify')};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-weight: ${props => (props.clickable ? 'bold' : null)};
      font-size: ${props => (props.clickable ? '15.7px' : null)};
      cursor: ${props => (props.clickable ? 'pointer' : 'select')};
    }
  }
`

const Explorer = styled.div`
  position: relative;
  display: grid;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-around;
  grid-gap: 30px;
  padding: 10px 40px 40px 40px;
  overflow-y: auto;
  overflow-x: hidden; //from mobile
  background-color: ${props => props.theme.bg};
  :focus {
    outline: none;
  }
  z-index: 1; //from mobile
`

const Footer = styled.div`
  grid-area: footer;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.light};
  color: ${props => props.theme.dark};
`

const ShowHideInfo = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? '80px' : '100px')};
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: ${props => (props.smallDevice ? '14px' : '16px')};
  font-weight: bold;
  color: ${props => props.theme.dark};
  border-right: 1px solid ${props => props.theme.dark};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: 17px;
      cursor: pointer;
    }
  }
`

const SelectionPanel = styled.div`
  position: absolute;
  right: ${props => (props.keepRight ? '80px' : '240px')};
  bottom: 0px;
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-left: 1px solid ${props => props.theme.dark};
`

const Toolbar = styled.div`
  width: calc(100% - 80px); // from mobile, not yet configured for laptop
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
`

const SelectAll = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '30px')};
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : 'auto')};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.dark};
  font-size: ${props => (props.smallDevice ? '30px' : '24px')};
  padding-top: ${props => (props.smallDevice ? null : '4px')};
  border-left: ${props => (props.smallDevice ? '1px' : '0px')} solid ${props => props.theme.dark};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: 27px;
      padding-top: 3px;
      cursor: pointer;
    }
  }
`

const ClearSelection = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '30px')};
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : 'auto')};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.dark};
  font-size: ${props => (props.smallDevice ? '30px' : '24px')};
  padding-bottom: ${props => (props.smallDevice ? null : '4px')};
  border-left: ${props => (props.smallDevice ? '1px' : '0px')} solid ${props => props.theme.dark};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: 27px;
      padding-bottom: 3px;
      cursor: pointer;
    }
  }
`

const AddButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : '80px')};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '30px' : '40px')};
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)}; // props.disabled doesn't exist

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: pointer;
      font-size: 43px;
    }
  }
`

const ShareButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : '80px')};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '30px' : '40px')};
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)}; // props.disabled doesn't exist

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: pointer;
      font-size: 43px;
    }
  }
`

const DownloadButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : '80px')};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '40px' : '50px')};
  color: ${props => props.theme.dark};
  border-left: 1px solid ${props => props.theme.dark};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: 53px;
      cursor: pointer;
    }
  }
`

const mapStateToProps = state => {
  return {
    currentPhotoCollection: state.browse.currentPhotoCollection,
    smallDevice: state.ui.smallDevice,
    smallDeviceLandscape: state.ui.smallDeviceLandscape,
    currentUser: state.auth.currentUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    populatePhotoCollection: photoArray => dispatch(actions.populatePhotoCollection(photoArray)),
    loadPhotos: photos => dispatch(actions.loadPhotos(photos)),
    download: (files, folderName) => dispatch(actions.download(files, folderName)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AlbumView))
