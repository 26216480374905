import * as actions from 'store/actions/actionTypes'

const initialState = {
  admins: null,
  photographers: null,
  videographers: null,
  webAdmin: null,
  externalUsers: null
}

const setConfig = (state, action) => {
  return {
    ...state,
    admins: action.config.admins,
    photographers: action.config.photographers,
    videographers: action.config.videographers,
    webAdmin: action.config.webAdmin,
    externalUsers: action.config.externalUsers || []
  }
}

const setExternalUser = (state, action) => {
  const externalUsers = state.externalUsers && state.externalUsers.length > 0 ? [...state.externalUsers] : []
  externalUsers.push(action.email)
  return {
    ...state,
    externalUsers
  }
}

const removeExternalUser = (state, action) => {
  const externalUsers = state.externalUsers ? [...state.externalUsers] : []
  externalUsers.splice(externalUsers.findIndex(i => i === action.email), 1)
  return {
    ...state,
    externalUsers
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CONFIG:
      return setConfig(state, action)
    case actions.SET_EXTERNAL_USER:
      return setExternalUser(state, action)
    case actions.REMOVE_EXTERNAL_USER:
      return removeExternalUser(state, action)
    default:
      return state
  }
}

export default reducer
