import { Component } from 'react'
import styled from 'styled-components'

class Button extends Component {

  buttonClickedHandler = event => {
    if (this.props.onClick) this.props.onClick(event)
  }

  render() {
    return (
      <StyledButton
        type={this.props.buttonType || "button"}
        id={this.props.id}
        autoFocus={this.props.autoFocus}
        disabled={this.props.disabled}
        danger={this.props.danger}
        onClick={this.buttonClickedHandler}
        smallDevice={this.props.smallDevice}
        width={this.props.width || '100px'}

      >
        {this.props.children}
      </StyledButton>
    )
  }
}

const StyledButton = styled.button`
  width: ${props => props.width || 'auto'};
  height: 26px;
  font-family: 'Roboto';
  font-size: ${props => props.fontSize || 'inherit'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  outline: none;
  background-color: ${props => props.theme.bg};
  color: ${props => props.theme[props.disabled ? 'main' : 'dark']};
  border: solid 1px ${props => props.theme[props.disabled ? 'main' : 'dark']};
  border-radius: 5px;
  box-shadow: -1.5px 1.5px ${props => props.theme.main};

  :focus {
    border-width: ${props => (props.disabled ? null : '2px')};
    border-style: ${props => (props.disabled ? 'dotted' : null)};
    border-color: ${props => (props.danger ? props.theme.danger : props.theme.dark)};
    padding: ${props => (props.disabled ? null : '2px 5px')};
  }

  :hover {
    background-color: ${props => props.theme[props.disabled || props.danger || props.touchInput ? 'bg' : 'light']};
    color: ${props => (props.danger ? props.theme.danger : props.theme[props.disabled ? 'main' : 'dark'])};
    font-weight: ${props => (props.disabled ? null : 'bold')};
    padding: ${props => (props.disabled || props.touchInput ? null : '2px 4px')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }

  :focus:hover {
    padding: ${props => (props.disabled || props.touchInput ? null : '2px 3px')};
  }

  :active {
    transform: ${props => (props.touchInput || props.disabled ? 'none' : 'translateY(1px) translateX(-1px)')};
    box-shadow: ${props => props.disabled ? null : 'none'};
    color: ${props => (props.danger ? props.theme.danger : props.theme[props.disabled ? 'main' : 'dark'])};
  }
`

export default Button
