import React, { Component } from 'react'
import styled from 'styled-components'

class Extension extends Component {
  state = {
    value: this.props.value || ''
  }

  changeHandler = event => {
    let string = event.target.value
    if (!this.validInput(string)) return
    string = this.addXIfNeeded(string)
    this.setState({ value: string }, () => {
      if (this.props.onChange) this.props.onChange(string)
    })
  }

  validInput = string => {
    if (/[^0-9x]/g.test(string)) return false // not digit or x
    if (/x$/g.test(string) && string.length > this.state.value.length && string.length !== 1) return false // 'x' entered manually
    if (string.length > 5) return false
    return true
  }

  addXIfNeeded = string => {
    if (/^\d/.test(string)) return 'x' + string
    return string
  }

  focusHandler = event => {
    if (this.state.value === '') this.setState({ value: 'x' }, () => {
      if (this.props.onFocus) this.props.onFocus(event)
    })
  }

  blurHandler = event => {
    if (this.state.value === 'x') this.setState({ value: '' }, () => {
      if (this.props.onBlur) this.props.onBlur(event)
    })
  }

  render() {
    return (
      <StyledExtension
        type="tel"
        id={this.props.id}
        value={this.state.value}
        onChange={this.changeHandler}
        onFocus={this.focusHandler}
        onBlur={this.blurHandler}
        required={this.props.required}
        pattern="[x]{1}[0-9]{4}"
        placeholder="x0000"
        title="x0000"
      />
    )
  }
}

const StyledExtension = styled.input`
  box-sizing: border-box;
  height: 26px;
  width: 100%;
  padding: 4px 6px;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  outline: none;
  background-color: white;
  color: ${props => props.theme.dark};

  :focus {
    border: 2px solid ${props => props.theme.dark};
  }
`

export default Extension
