import  { firebaseApp } from './firebase'
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInAnonymously as _signInAnonymously,
  setPersistence as _setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
  onIdTokenChanged,
  sendPasswordResetEmail,
  signOut as _signOut,
  updateProfile as _updateProfile,
  updatePassword as _updatePassword,
  deleteUser as _deleteUser,
  getIdTokenResult as _getIdTokenResult,
  getIdToken as _getIdToken
} from 'firebase/auth'

export const auth = getAuth(firebaseApp)

export const createUser = (email, password) => createUserWithEmailAndPassword(auth, email, password)

export const resendVerificationEmail = () => sendEmailVerification()

export const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password)

export const signInAnonymously = () => _signInAnonymously(auth)

export const setPersistence = staySignedIn => staySignedIn ? _setPersistence(auth, browserLocalPersistence) : _setPersistence(auth, browserSessionPersistence)

export const onStateChange = passedFunction => onAuthStateChanged(auth, passedFunction)

export const onIdTokenChange = (passedFunction, errorFunction) => onIdTokenChanged(auth, passedFunction, errorFunction)

export const resetEmail = emailAddress => sendPasswordResetEmail(auth, emailAddress)

export const currentUser = () => auth.currentUser

export const deleteUser = () => _deleteUser(auth.currentUser)

export const updateProfile = (profile) => _updateProfile(auth.currentUser, profile)

export const updatePassword = (newPassword) => _updatePassword(auth.currentUser, newPassword)

export const signOut = () => _signOut(auth)

export const getIdTokenResult = () => _getIdTokenResult(auth.currentUser, true)

export const getIdToken = () => _getIdToken(auth.currentUser, true)
