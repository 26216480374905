import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import AuthModal from 'components/auth/AuthModal'
import ConfirmDeleteAccountModal from 'components/auth/ConfirmDeleteAccountModal'
import PoliciesModal from 'pages/bookings/PoliciesModal'
import VideoPoliciesModal from 'pages/bookings/VideoPoliciesModal'
import Loader from 'components/loader/Loader.js'
import Header from 'pages/layout/Header'
import HeaderMobile from 'pages/layout/HeaderMobile'
import DropDownMenu from 'pages/layout/DropDownMenu'
import ProgressCard from 'pages/upload/ProgressCard'
import CollectionsWindow from 'pages/collections/CollectionsWindow'
import Download from 'components/download/Download'
import Error from './Error'

class Layout extends Component {
  onDragOverHandler = event => {
    event.preventDefault()
  }

  onDropHandler = event => {
    event.preventDefault()
  }

  continueButtonClicked = () => {
    this.alert = null
    this.forceUpdate()
  }

  render() {
    return (
      <StyledLayout onDragOver={this.onDragOverHandler} onDrop={this.onDropHandler} smallDevice={this.props.smallDevice}>
        {this.props.smallDevice ? <HeaderMobile /> : <Header />}
        {this.props.currentUser && this.props.currentUser.staff ? (
          <React.Fragment>
            {this.props.children}
            {this.props.showMenu ? <DropDownMenu /> : null}
            {this.props.showConfirmDeleteAccountModal ? <ConfirmDeleteAccountModal /> : null}
            {this.props.showPoliciesModal ? <PoliciesModal /> : null}
            {this.props.showVideoPoliciesModal ? <VideoPoliciesModal /> : null}
            {this.props.uploads ? <ProgressCard /> : null}
            {this.props.showDownload ? <Download /> : null}
            {this.props.showCollectionsWindow ? <CollectionsWindow /> : null}
            <ProgressCard />
          </React.Fragment>
        ) : this.props.currentUser && this.props.currentUser.anonymous && !this.props.currentUser.staff ? (
          <React.Fragment>
            {window.location.pathname.match(/^(?:\/albums\/|\/photos\/|\/collections\/)/gi) ? this.props.children : null}
            {this.props.showAuthModal ? <AuthModal /> : null}
            {this.props.showDownload ? <Download /> : null}
          </React.Fragment>
        ) : this.props.currentUser === null ? (
          <React.Fragment>
            {this.props.children}
            {this.props.showAuthModal ? <AuthModal /> : null}
          </React.Fragment>
        ) : (
          <Loader />
        )}
        {this.props.error && <Error />}
      </StyledLayout>
    )
  }
}

const StyledLayout = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.bg};
  display: grid;
  grid-template-rows: ${props => (props.smallDevice ? '48px 12px 1fr 12px 50px' : '48px 20px 1fr 20px 60px')};
  grid-template-columns: ${props => (props.smallDevice ? '10px 1fr 10px' : '30px 1fr 30px')};
  grid-template-areas:
    'header header header'
    '. .  .'
    '. main .'
    '. . .'
    'footer footer footer';
`

const mapStateToProps = state => {
  return {
    showMenu: state.ui.showMenu,
    showBookingFormModal: state.ui.showBookingFormModal,
    showAuthModal: state.ui.showAuthModal,
    showCollectionsWindow: state.collections.photos,
    showChangePasswordModal: state.ui.showChangePasswordModal,
    showConfirmDeleteAccountModal: state.ui.showConfirmDeleteAccountModal,
    showPoliciesModal: state.ui.showPoliciesModal,
    showVideoPoliciesModal: state.ui.showVideoPoliciesModal,
    currentUser: state.auth.currentUser,
    smallDevice: state.ui.smallDevice,
    showDownload: state.download.downloadType,
    error: state.error.error
  }
}

export default connect(mapStateToProps)(Layout)
