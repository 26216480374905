import React from 'react'
import styled from 'styled-components'
import Text from 'components/Text'

const Modal = props => {
  const escapeKeyPressed = event => {
    if (event.key === 'Escape') {
      window.removeEventListener('keydown', escapeKeyPressed)
      props.backdropClicked()
    }
  }

  if (props.escapable) {
    window.addEventListener('keydown', escapeKeyPressed)
  }

  return (
    <ContainerDiv onClick={props.backdropClicked} id='modalBackdrop'>
      <StyledModal
        fontSize={props.fontSize}
        width={props.width}
        maxWidth={props.maxWidth}
        height={props.height}
        smallDevice={props.smallDevice}
        scroll={props.scroll}
        id={props.modalId}
      >
        <Title smallDevice={props.smallDevice} id={props.modalId}>
          <Text id={props.modalId}>{props.title}</Text>
        </Title>
        <Body justify={props.justify} smallDevice={props.smallDevice} id={props.modalId}>
          {props.children}
        </Body>
      </StyledModal>
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
  z-index: 10;
`

const StyledModal = styled.div`
  position: relative;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 40px 1fr;
  grid-template-areas:
    'title'
    'body';
  width: ${props => (props.smallDevice ? '80%' : props.width ? props.width : 'auto')};
  max-width: ${props => props.maxWidth || null};
  height: ${props => props.height || (props.smallDevice ? 'auto' : null)};
  max-height: ${props => (props.smallDevice ? '100%' : '70%')};
  border: 2px solid ${props => props.theme.dark};
  border-radius: 5px;
  overflow: hidden;
`

const Title = styled.div`
  grid-area: title;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 10px 10px;
  border-bottom: 1px solid grey;
  background-color: ${props => props.theme.dark};
  color: ${props => props.theme.bg};
  z-index: 2;
  font-size: 16px;
`

const Body = styled.div`
  grid-area: body;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.bg};
  color: ${props => props.theme.dark};
  font-size: ${props => props.fontSize || '14px'};
  padding: ${props => (props.smallDevice ? '20px 10px' : '40px 40px')};
  text-align: ${props => (props.justify ? 'justify' : null)};
  overflow-x: hidden;
  overflow-y: auto;
`

export default Modal
