import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from 'store/actions'

const DropDownMenu = props => {
  const profileClicked = () => {
    props.history.push('/profile')
  }

  const bookingsClicked = () => {
    props.history.push('/bookings')
  }

  const collectionsClicked = () => {
    props.history.push('/collections')
  }

  return (
    <ContainerDiv onClick={props.showMenu} id="menuBackdrop">
      <StyledDropDownMenu>
        <Item onClick={bookingsClicked}>bookings</Item>
        <Item onClick={collectionsClicked}>collections</Item>
        <Item onClick={profileClicked}>my profile</Item>
      </StyledDropDownMenu>
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
`

const StyledDropDownMenu = styled.div`
  grid-area: main;
  position: absolute;
  top: 48px;
  right: 0px;
  width: 100px;
  background-color: ${props => props.theme.bg};
  border: 2px solid ${props => props.theme.dark};
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid ${props => props.theme.main};
  background-color: ${props => props.theme.bg};
  :hover {
    background-color: ${props => props.theme.dark};
    cursor: pointer;
    color: ${props => props.theme.light};
  }
  color: ${props => props.theme.dark};
`

const mapDispatchToProps = dispatch => {
  return {
    showMenu: () => dispatch(actions.showMenu())
  }
}

export default withRouter(connect(null, mapDispatchToProps)(DropDownMenu))
