import * as actionTypes from 'store/actions/actionTypes'
import * as actions from 'store/actions'
import store from 'store/store'
import { db } from 'utils/firebase'
import { getDoc, doc, writeBatch, arrayUnion, updateDoc, arrayRemove } from 'firebase/firestore'

export const requestPendingFormItems = () => {
  return dispatch => {
    if (store.getState().forms.pending)
      return dispatch({
        type: actionTypes.SAVE_PENDING_FORM_ITEMS,
        pending: store.getState().forms.pending
      })
    getDoc(doc(db, 'formData', 'pending'))
      .then(response =>
        dispatch({
          type: actionTypes.SAVE_PENDING_FORM_ITEMS,
          pending: response.data()
        })
      )
      .catch(error => store.dispatch(actions.setError(error, false, 'Failed to get pending form items from database.')))
  }
}

export const requestApprovedFormItems = () => {
  return dispatch => {
    if (store.getState().forms.approved)
      return dispatch({
        type: actionTypes.SAVE_APPROVED_FORM_ITEMS,
        approved: store.getState().forms.approved
      })
    getDoc(doc(db, 'formData', 'approved'))
      .then(response => {
        const formItems = response.data()
        const sortedFormItems = {}
        Object.keys(formItems).forEach(category => {
          sortedFormItems[category] = formItems[category].sort()
        })
        return dispatch({
          type: actionTypes.SAVE_APPROVED_FORM_ITEMS,
          approved: sortedFormItems
        })
      })
      .catch(error => store.dispatch(actions.setError(error, false, 'Failed to get approved form items from database.')))
  }
}

export const addPendingFormItems = newItems => {
  return async dispatch => {
    const approvedFormItems =
      store.getState().forms.approved ||
      (await getDoc(doc(db, 'formData', 'approved'))
        .then(res => res.data())
        .catch(error => store.dispatch(actions.setError(error, false, 'Failed to get approved form items while trying to add a pending item.'))))
    const batch = writeBatch(db)
    const itemsToAdd = {}
    let changesMade = false

    Object.keys(newItems).forEach(category => {
      const item = newItems[category]
      if (approvedFormItems[category].includes(item)) {
        return dispatch({
          type: 'DO_NOTHING'
        })
      }
      if (typeof item !== 'string') {
        for (const i of item) {
          batch.update(doc(db, 'formData', 'pending'), {
            [category]: arrayUnion(i)
          })
        }
      } else {
        batch.update(doc(db, 'formData', 'pending'), {
          [category]: arrayUnion(item)
        })
      }

      itemsToAdd[category] = item
      changesMade = true
    })

    if (changesMade) batch.commit()
    return dispatch({
      type: actionTypes.ADD_PENDING_FORM_ITEMS,
      items: itemsToAdd
    })
  }
}

export const removePendingFormItem = (category, item) => {
  return dispatch => {
    updateDoc(doc(db, 'formData', 'pending'), {
      [category]: arrayRemove(item)
    })

    return dispatch({
      type: actionTypes.REMOVE_PENDING_FORM_ITEM,
      category,
      item
    })
  }
}

export const addApprovedFormItems = newItems => {
  return dispatch => {
    const batch = writeBatch(db)
    const itemsToAdd = {}

    Object.keys(newItems).forEach(category => {
      const item = newItems[category]

      batch.update(doc(db, 'formData', 'approved'), {
        [category]: arrayUnion(item)
      })
      itemsToAdd[category] = item
    })

    batch.commit()
    return dispatch({
      type: actionTypes.ADD_APPROVED_FORM_ITEMS,
      items: itemsToAdd
    })
  }
}
