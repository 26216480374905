import * as actions from 'store/actions/actionTypes'
import { updateObject } from 'utils/shared'

const initialState = {
  error: null,
  breaking: false
}

const setError = (state, action) => {
  if (state.error) return state
  return updateObject(state, { error: action.error, breaking: action.breaking })
}

const clearError = (state) => {
  return updateObject(state, { error: null, breaking: false })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ERROR:
      return setError(state, action)
    case actions.CLEAR_ERROR:
      return clearError(state)
    default:
      return state
  }
}

export default reducer
