import * as actionTypes from 'store/actions/actionTypes'
import store from 'store/store'

export const showMenu = () => {
  return {
    type: actionTypes.SHOW_MENU
  }
}

export const showAuthModal = value => {
  return {
    type: actionTypes.SHOW_AUTH_MODAL,
    value
  }
}

export const showChangePasswordModal = () => {
  return {
    type: actionTypes.SHOW_CHANGE_PASSWORD_MODAL
  }
}

export const showConfirmDeleteAccountModal = () => {
  return {
    type: actionTypes.SHOW_CONFIRM_DELETE_ACCOUNT_MODAL
  }
}

export const showPoliciesModal = () => {
  return {
    type: actionTypes.SHOW_POLICIES_MODAL
  }
}

export const showVideoPoliciesModal = () => {
  return {
    type: actionTypes.SHOW_VIDEO_POLICIES_MODAL
  }
}

export const setSmallDevice = status => {
  return {
    type: actionTypes.SET_SMALL_DEVICE,
    status
  }
}

export const setSmallDeviceLandscape = status => {
  return {
    type: actionTypes.SET_SMALL_DEVICE_LANDSCAPE,
    status
  }
}

export const setInputMethod = inputType => {
  if (store.getState().ui.inputMethod === inputType) return null
  return {
    type: actionTypes.SET_INPUT_METHOD,
    inputType
  }
}

export const toggleShowSearchBar = () => {
  return {
    type: actionTypes.TOGGLE_SHOW_SEARCH_BAR
  }
}
