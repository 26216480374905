import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from 'store/actions'
import Input, { getInputValue } from 'components/NewInput'
import Loader from 'components/loader/Loader'

class PendingFormItems extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    this.pending = null
    if (!this.props.pending) this.props.requestPendingFormItems()
    if (!this.props.approved) this.props.requestApprovedFormItems()
    if (this.props.pending && this.props.approved) this.setState({ loading: false }, () => this.getPendingFormItems())
  }

  componentDidUpdate() {
    if (this.props.pending && this.props.approved && this.state.loading) this.setState({ loading: false }, () => this.getPendingFormItems())
  }

  getPendingFormItems = () => {
    this.pending = {}
    Object.keys(this.props.pending).forEach(category => {
      this.pending[category] = this.props.pending[category].map(item => item.key)
    })
    this.populatePendingFormItems()
  }

  populatePendingFormItems = () => {
    this.listIsEmpty = true
    this.pendingItems = Object.keys(this.pending).map(section => {
      let id
      if (this.pending[section].length > 0) {
        this.listIsEmpty = false
        return (
          <Section key={section}>
            <Title>{`${section.slice(0, 1).toUpperCase()}${section.slice(1)}`}</Title>
            {this.pending[section].map((item, index) => {
              id = `${section}-${item}-${index}`
              return (
                <Item key={id}>
                  <Input type='text' id={id} value={item} width='60%' />
                  <Controls>
                    <i className='material-icons' name={id} section={section} item={item} index={index} title='Accept item' onClick={this.acceptItem}>
                      check
                    </i>
                    <i className='material-icons' name={id} section={section} item={item} index={index} title='Reject item' onClick={this.rejectItem}>
                      clear
                    </i>
                  </Controls>
                </Item>
              )
            })}
          </Section>
        )
      }
      return null
    })
    if (this.state.loading) this.setState({ loading: false })
    else this.forceUpdate()
  }

  acceptItem = event => {
    const i = event.target
    const value = getInputValue(i.getAttribute('name')).trim()
    const section = i.getAttribute('section')
    const item = i.getAttribute('item')
    const index = i.getAttribute('index')

    this.pending[section].splice(index, 1)
    this.props.removePendingFormItem(section, item)
    this.props.addApprovedFormItems({ [section]: value })

    this.populatePendingFormItems()
  }

  rejectItem = event => {
    const i = event.target
    const section = i.getAttribute('section')
    const item = i.getAttribute('item')
    const index = i.getAttribute('index')

    this.pending[section].splice(index, 1)
    this.props.removePendingFormItem(section, item)

    this.populatePendingFormItems()
  }

  render() {
    return this.state.loading ? (
      <Loader />
    ) : (
      <StyledFormItems listIsEmpty={this.listIsEmpty}>
        {this.listIsEmpty ? <NoItems>There are no pending items to review. Please check back later.</NoItems> : this.pendingItems}
      </StyledFormItems>
    )
  }
}

const StyledFormItems = styled.div`
  position: relative;
  width: 100%;
  height: ${props => (props.listIsEmpty ? '200px' : '100%')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`
const Section = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${props => props.theme.main};
  margin-bottom: 20px;
  padding: 10px 10px;
`

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  margin-bottom: 20px;
  width: 100%;
`
const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0px 6px 20px;
  width: 100%;
`
const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
  width: 60px;
  margin-left: 10px;

  i {
    margin: 1px 1px;
  }

  i:hover {
    cursor: pointer;
    font-size: 28px;
    margin: 0;
  }
`

const NoItems = styled.div`
  width: 100%;
  font-size: 20px;
  color: ${props => props.theme.main};
  display: flex;
  justify-content: center;
  align-items: center;
`

const mapDispatchToProps = dispatch => {
  return {
    requestPendingFormItems: () => dispatch(actions.requestPendingFormItems()),
    requestApprovedFormItems: () => dispatch(actions.requestApprovedFormItems()),
    addApprovedFormItems: newItems => dispatch(actions.addApprovedFormItems(newItems)),
    removePendingFormItem: (category, item) => dispatch(actions.removePendingFormItem(category, item))
  }
}

const mapStateToProps = state => {
  return {
    pending: state.forms.pending,
    approved: state.forms.approved
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingFormItems)
