import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { db } from 'utils/firebase'
import Loader from 'components/loader/Loader'
import Photo from 'components/Photo'
import Input, { getInputValue } from 'components/NewInput'
import store from 'store/store'
import { addApprovedFormItems, setError } from 'store/actions'
import { collection, deleteDoc, doc, getDocs, updateDoc, writeBatch, arrayUnion } from 'firebase/firestore'

class TagSuggestions extends Component {
  state = {
    loading: true
  }

  tagSuggestions = []
  photoArray = []
  position = 0

  componentDidMount() {
    this.getTagSuggestionsFromDatabase()
  }

  getTagSuggestionsFromDatabase = () => {
    getDocs(collection(db, 'tagSuggestions'))
      .then(tagSuggestions => {
        tagSuggestions.docs.forEach(doc => {
          this.tagSuggestions.push({ ...doc.data(), id: doc.id })
        })
        if (this.tagSuggestions.length > 0) {
          this.populateTagSuggestions()
          this.setState({ loading: false })
        } else this.setState({ loading: false })
      })
      .catch(error => store.dispatch(setError(error, false, 'Failed to get tagSuggestions in Admin component.')))
  }

  populateTagSuggestions = () => {
    this.setPhotoArray()
    this.setCurrentTags()
    this.setSuggestedTags()
  }

  setPhotoArray = () => {
    this.tagSuggestions.forEach(doc => {
      this.photoArray.push({
        name: doc.name,
        url: doc.webURL,
        id: doc.id
      })
    })
  }

  setCurrentTags = () => {
    this.currentTags =
      this.tagSuggestions[this.position].currentTags.length > 0
        ? this.tagSuggestions[this.position].currentTags.map((tag, index) => {
            if (index < this.tagSuggestions[this.position].currentTags.length - 1) {
              return `${tag}, `
            } else return tag
          })
        : 'None'
  }

  setSuggestedTags = () => {
    this.tagObjects = []
    this.tagList = []
    this.tagSuggestions[this.position].suggestedTags.forEach(doc => {
      doc.suggestedTags.forEach(tag => {
        if (!this.tagList.includes(tag)) {
          this.tagObjects.push({ user: doc.suggestedBy, tag })
          this.tagList.push(tag)
        }
      })
    })
    this.suggestions = this.tagObjects.map(listItem => {
      let tag = listItem.tag
      let id = `${tag}-${listItem.user}`
      return (
        <TagSuggestion key={id}>
          <Input type='text' value={tag} id={id} />
          <Controls>
            <i className='material-icons' name={id} title={`Accept tag by ${listItem.user}`} onClick={this.acceptTag}>
              check
            </i>
            <i className='material-icons' name={id} title={`Reject tag by ${listItem.user}`} onClick={this.rejectTag}>
              clear
            </i>
          </Controls>
        </TagSuggestion>
      )
    })
  }

  changePosition = event => {
    if (event.target.id === 'Next') {
      if (this.position === this.tagSuggestions.length - 1) {
        this.position = 0
      } else this.position += 1
    } else {
      if (this.position === 0) {
        this.position = this.tagSuggestions.length - 1
      } else this.position -= 1
    }
    this.setCurrentTags()
    this.setSuggestedTags()
    this.forceUpdate()
  }

  acceptTag = event => {
    const id = event.target.getAttribute('name')
    const tag = id.slice(0, id.lastIndexOf('-'))
    const user = id.slice(id.lastIndexOf('-') + 1)
    const suggestedTagsObject = this.tagSuggestions[this.position]
    const newTagValue = getInputValue(id)
    const batch = writeBatch(db)
    let dbReference = doc(db, 'tagSuggestions', suggestedTagsObject.id)
    let docPosition

    const userObject = suggestedTagsObject.suggestedTags.find((doc, index) => {
      docPosition = index
      return doc.suggestedBy === user
    })

    // Add tag
    suggestedTagsObject.currentTags.push(tag)
    batch.update(doc(db, 'photos', suggestedTagsObject.id), {
      tags: arrayUnion(newTagValue)
    })

    // Add tag to approved form items
    store.dispatch(addApprovedFormItems({tags: newTagValue}))

    // Remove tag from user suggestions
    userObject.suggestedTags = userObject.suggestedTags.filter(suggestion => suggestion !== tag)
    suggestedTagsObject.suggestedTags[docPosition] = userObject
    batch.update(dbReference, suggestedTagsObject)

    // if empty, remove user
    if (userObject.suggestedTags.length === 0) {
      suggestedTagsObject.suggestedTags = suggestedTagsObject.suggestedTags.filter(obj => obj.suggestedBy !== user)
    }

    // remove duplicate suggestions
    if (suggestedTagsObject.suggestedTags.length > 0) {
      suggestedTagsObject.suggestedTags.forEach(doc => {
        if (doc.suggestedTags.includes(tag)) doc.suggestedTags = doc.suggestedTags.filter(suggestion => suggestion !== tag)
        if (doc.suggestedTags.length === 0)
          suggestedTagsObject.suggestedTags = suggestedTagsObject.suggestedTags.filter(obj => obj.suggestedBy !== doc.suggestedBy)
      })
    }

    // update database
    if (suggestedTagsObject.suggestedTags.length > 0) {
      batch.update(dbReference, suggestedTagsObject)
      this.setCurrentTags()
      this.setSuggestedTags()
    } else {
      batch.delete(dbReference)
      this.deleteCurrentItem()
      if (this.tagSuggestions.length > 0) {
        this.changePosition({ target: { id: 'Next' } })
      } else this.tagSuggestions = []
    }

    batch.commit().catch(error => store.dispatch(setError(error, false, 'Failed to accept tag in Admin component.')))
    this.forceUpdate()
  }

  rejectTag = event => {
    const id = event.target.getAttribute('name')
    const tag = id.slice(0, id.lastIndexOf('-'))
    const user = id.slice(id.lastIndexOf('-') + 1)
    const suggestedTagsObject = this.tagSuggestions[this.position]
    let docPosition
    let dbReference = doc(db, 'tagSuggestions', suggestedTagsObject.id)

    const userObject = suggestedTagsObject.suggestedTags.find((doc, index) => {
      docPosition = index
      return doc.suggestedBy === user
    })

    // Remove tag
    userObject.suggestedTags = userObject.suggestedTags.filter(existingTag => existingTag !== tag)
    suggestedTagsObject.suggestedTags[docPosition] = userObject

    // if empty, remove user
    if (userObject.suggestedTags.length === 0) suggestedTagsObject.suggestedTags = suggestedTagsObject.suggestedTags.filter(obj => obj.suggestedBy !== user)

    // Remove duplicate suggestions
    if (suggestedTagsObject.suggestedTags.length > 0) {
      suggestedTagsObject.suggestedTags.forEach(doc => {
        if (doc.suggestedTags.includes(tag)) doc.suggestedTags = doc.suggestedTags.filter(existingTag => existingTag !== tag)
        if (doc.suggestedTags.length === 0)
          suggestedTagsObject.suggestedTags = suggestedTagsObject.suggestedTags.filter(obj => obj.suggestedBy !== doc.suggestedBy)
      })
    }

    // update database
    if (suggestedTagsObject.suggestedTags.length > 0) {
      updateDoc(dbReference, suggestedTagsObject).catch(error => store.dispatch(setError(error, false, 'Failed to reject tag in Admin component.')))
      this.setSuggestedTags()
    } else {
      deleteDoc(dbReference).catch(error => store.dispatch(setError(error, false, 'Failed to delete tag in Admin component.')))
      this.deleteCurrentItem()
      if (this.tagSuggestions.length > 0) {
        this.changePosition({ target: { id: 'Next' } })
      } else this.tagSuggestions = []
    }

    this.forceUpdate()
  }

  deleteCurrentItem = () => {
    this.tagSuggestions.splice(this.position, 1)
    this.photoArray.splice(this.position, 1)
  }

  render() {
    return this.state.loading ? (
      <React.Fragment>
        <Loader />
      </React.Fragment>
    ) : this.tagSuggestions.length > 0 ? (
      <StyledTagSuggestions>
        <PhotoArea>
          {this.tagSuggestions.length > 1 ? (
            <NavigationControl title='Previous'>
              <i className='material-icons' onClick={this.changePosition} id='Previous'>
                navigate_before
              </i>
            </NavigationControl>
          ) : null}

          <Photo
            src={this.photoArray[this.position].url}
            id={this.photoArray[this.position].id}
            alt={this.photoArray[this.position].name}
            onClick={this.visitAlbum}
            clickable
          />
          {this.tagSuggestions.length > 1 ? (
            <NavigationControl title='Next'>
              <i className='material-icons' onClick={this.changePosition} id='Next'>
                navigate_next
              </i>
            </NavigationControl>
          ) : null}
        </PhotoArea>
        <Link to={`/albums/${this.tagSuggestions[this.position].album}`}>
          <Title>{this.tagSuggestions[this.position].name}</Title>
        </Link>
        <CurrentTags>Current tags: {<p style={{ fontWeight: 'bold', marginLeft: '10px' }}>{this.currentTags}</p>}</CurrentTags>
        <NewTags>{this.suggestions}</NewTags>
      </StyledTagSuggestions>
    ) : (
      <NoTags>There are no suggestions to review. Please check back later.</NoTags>
    )
  }
}

const StyledTagSuggestions = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
`

const PhotoArea = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
`

const NavigationControl = styled.div`
  font-size: 40px;
  margin: 0px 2px;
  :hover {
    font-size: 44px;
    margin: 0px 0px;
    cursor: pointer;
  }
`

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 1.2px;
  margin-bottom: 30px;
  text-align: center;

  :hover {
    font-size: 16.2px;
    cursor: pointer;
    padding-bottom: 0.4px;
  }
`

const CurrentTags = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NewTags = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
`

const TagSuggestion = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100px;
  padding: 0px 10px;

  i {
    margin: 1px 1px;
    font-size: 26px;
  }

  i:hover {
    cursor: pointer;
    font-size: 28px;
    margin: 0px;
  }
`

const NoTags = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  margin: 100px 0px;
  color: ${props => props.theme.main};
`

export default TagSuggestions
