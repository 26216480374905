import * as actions from 'store/actions/actionTypes'
import { updateObject, updateObjectInArray } from 'utils/shared'

const initialState = {
  collections: null,
  photos: null
}

const loadPhotos = (state, action) => {
  return updateObject(state, { photos: action.photos })
}

const storeCollections = (state, action) => {
  return updateObject(state, { collections: action.collections })
}

const addCollection = (state, action) => {
  let collections = [...state.collections]
  collections.push(action.collection)
  return updateObject(state, { collections })
}

const updateStore = (state, action) => {
  return updateObject(state, {
    collections: updateObjectInArray(state.collections, action.index, {
      photos: action.photos,
      modified: new Date()
    })
  })
}

const deleteCollectionFromStore = (state, action) => {
  let index = state.collections.findIndex(
    collection => collection.id === action.collectionId
  )
  let collections = state.collections.slice()
  collections.splice(index, 1)
  return updateObject(state, { collections })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.STORE_COLLECTIONS:
      return storeCollections(state, action)
    case actions.ADD_COLLECTION:
      return addCollection(state, action)
    case actions.LOAD_PHOTOS:
      return loadPhotos(state, action)
    case actions.UPDATE_STORE:
      return updateStore(state, action)
    case actions.DELETE_COLLECTION:
      return deleteCollectionFromStore(state, action)
    default:
      return state
  }
}

export default reducer
