import Loader from 'components/loader/Loader'
import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import * as actions from 'store/actions'
import Input from 'components/NewInput'
import Alert from 'components/Alert'
import { connect } from 'react-redux'
import { db } from 'utils/firebase'
import { collection, getDocs, query, where, doc, writeBatch } from 'firebase/firestore'

class Bookings extends Component {
  state = { loading: true, scope: 'all', filter: 'all' }

  componentDidMount() {
    this.populateData()
  }

  populateData = () => {
    getDocs(collection(db, 'bookings'))
      .then(bookings => {
        this.bookings = bookings.docs.map(booking => booking.data())
        this.getTotalBookings()
        this.getBookingsPerMonth()
        this.getFaculties()
        this.getDepartments()
        this.setState({ loading: false }, () => {
          let graph = document.getElementById('graph_scrollElement')
          graph.scrollTo(graph.scrollWidth, 0)
        })
      })
      .catch(error => this.props.setError(error, false, 'Failed to get bookings in Admin component.'))
  }

  getTotalBookings = () => {
    this.totalBookings = this.bookings.length
  }

  getBookingsPerMonth = () => {
    let months = createMonthsObject(this.bookings, this.state.scope)
    getBookings(this.bookings, this.state.scope, this.state.filter)
    this.getHighestMonth(months)
    return renderMonths(this.props.theme, this.scrollStart, this.highestMonth)

    function createMonthsObject(bookings) {
      let monthsObject = {}
      let lastBookingDate = null
      bookings.forEach(booking => {
        const bookingDate = booking.deadline ? booking.deadline.toDate() : booking.startDate ? booking.startDate.toDate() : booking.dateOfEvent.toDate()
        if (!lastBookingDate || lastBookingDate < bookingDate) lastBookingDate = bookingDate
      })
      let lastYear = lastBookingDate.getFullYear()
      let lastMonth = lastBookingDate.getMonth() + 1
      for (let y = 2020; y <= lastYear; y++) {
        for (let m = 1; m <= 12; m++) {
          if (y < lastYear || m <= lastMonth) monthsObject[`${m < 10 ? '0' : ''}${m}/${y.toString().slice(2)}`] = 0
        }
      }
      return monthsObject
    }

    function getBookings(bookings, scope, filter) {
      bookings.forEach(booking => {
        if (
          (scope === 'video' && booking.type === 'Photography') ||
          (scope === 'photo' && booking.type === 'Videography') ||
          (filter === 'accepted' && !['Completed', 'Accepted'].includes(booking.status)) ||
          (filter === 'completed' && booking.status !== 'Completed') ||
          (filter === 'pending' && !['Pending', 'Updated'].includes(booking.status)) ||
          (filter === 'declined' && booking.status !== 'Declined') ||
          (filter === 'cancelled' && booking.status !== 'Cancelled')
        )
          return
        const bookingDate = booking.deadline ? booking.deadline.toDate() : booking.startDate ? booking.startDate.toDate() : booking.dateOfEvent.toDate()
        const month = `${bookingDate.getMonth() + 1 < 10 ? '0' : ''}${bookingDate.getMonth() + 1}/${bookingDate.getFullYear().toString().slice(2)}`
        months[month]++
      })
    }

    function renderMonths(theme, scrollStart, highestMonth) {
      return Object.keys(months).map(month => {
        let percentage = (months[month] / highestMonth) * 100
        return (
          <GraphItem key={month} id='graph' onMouseDown={scrollStart}>
            <strong id='graph' onMouseDown={scrollStart}>
              {month}
            </strong>
            <div style={{ height: `${percentage}px`, width: '80px', backgroundColor: theme.dark }} id='graph' onMouseDown={scrollStart} />
            <span id='graph' onMouseDown={scrollStart}>
              {months[month]}
            </span>
          </GraphItem>
        )
      })
    }
  }

  getHighestMonth = months => {
    if (this.highestMonth) return
    let highestMonth = 0
    Object.values(months).forEach(month => {
      if (highestMonth <= month) highestMonth = month
    })
    this.highestMonth = highestMonth
  }

  modeChanged = event => {
    let id = event.target.id
    let mode = id.slice(5, id.lastIndexOf('_'))
    let value = id.slice(id.lastIndexOf('_') + 1)
    this.setState({ [mode]: value })
  }

  getFaculties = () => {
    this.faculties = {}
    this.bookings.forEach(booking => {
      if (!this.faculties[booking.faculty]) this.faculties[booking.faculty] = 1
      else this.faculties[booking.faculty] = this.faculties[booking.faculty] + 1
    })
  }

  facultyOptions = () => {
    let options = [
      <option key={'null'} value={''}>
        - select value to change -
      </option>
    ]
    Object.keys(this.faculties)
      .sort()
      .forEach(faculty => {
        options.push(<option key={faculty}>{faculty}</option>)
      })
    return options
  }

  getDepartments = () => {
    this.departments = {}
    this.bookings.forEach(booking => {
      if (!this.departments[booking.department]) this.departments[booking.department] = 1
      else this.departments[booking.department] = this.departments[booking.department] + 1
    })
  }

  departmentOptions = () => {
    let options = [
      <option key={'null'} value={''}>
        - select value to change -
      </option>
    ]
    Object.keys(this.departments)
      .sort()
      .forEach(department => {
        options.push(<option key={department}>{department}</option>)
      })
    return options
  }

  alertChangeToDatabase = () => {
    this.alert = (
      <Alert
        alertType='Change database value'
        alertButtonClicked={event => {
          if (event.target.id === 'continue') {
            this.alert = null
            this.setState({ loading: true })
            this.normaliseValue()
          } else {
            this.alert = null
            return this.forceUpdate()
          }
        }}
      />
    )
    this.forceUpdate()
  }

  normaliseValue = async () => {
    getDocs(query(collection(db, 'bookings'), where(this.changing, '==', this.state[this.changing])))
      .then(async _booking => {
        let batch = writeBatch(db)
        for (const booking of _booking.docs) {
          batch.update(doc(db, 'bookings', booking.id), { [this.changing]: this.state[`${this.changing}Value`] })
        }
        await batch.commit().catch(error => this.props.setError(error, true, 'Failed to normalise booking values in Admin component.'))
        this.props.addApprovedFormItems({
          [this.changing]: this.state[`${this.changing}Value`]
        })
        this.populateData()
      })
      .catch(error => this.props.setError(error, false, 'Failed to get booking values in Admin component.'))
  }

  convertToNumberString = number => {
    let string = number.toString()
    let str = ''
    let counter = 0
    for (let i = string.length - 1; i >= 0; i--) {
      str += string[i]
      counter++
      if (counter === 3 && i !== 0) {
        str += ','
        counter = 0
      }
    }
    str = str.split('').reverse().join('')
    return str
  }

  scrollStart = event => {
    let id = event.target.id
    if (!id.includes('graph') && !id.includes('table')) return
    let elementType = id.includes('graph') ? 'graph' : 'table'
    let scrollElement = document.getElementById(id.includes('_scrollElement') ? id : `${id}_scrollElement`)

    let firstClick = event[elementType === 'graph' ? 'clientX' : 'clientY']
    let scrollStartPosition = scrollElement[elementType === 'graph' ? 'scrollLeft' : 'scrollTop']

    window.addEventListener('mousemove', scrolling, { passive: true })
    window.addEventListener('mouseup', () => {
      window.removeEventListener('mousemove', scrolling, { passive: true })
    })
    window.addEventListener('mouseleave', () => {
      window.removeEventListener('mousemove', scrolling, { passive: true })
    })

    function scrolling(event) {
      let mousePosition = event[elementType === 'graph' ? 'clientX' : 'clientY']
      let scrollDistance = firstClick - mousePosition
      scrollElement.scrollTo(
        elementType === 'graph' ? scrollStartPosition + scrollDistance : 0,
        elementType === 'graph' ? 0 : scrollStartPosition + scrollDistance
      )
    }
  }

  render() {
    return (
      <StyledBookings loading={this.state.loading ? 'true' : null}>
        {this.state.loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Overview>
              <table>
                <tbody>
                  <tr style={{ height: '30px' }}>
                    <td>
                      <strong>Bookings since inception:</strong>
                    </td>
                    <td style={{ paddingLeft: '60px', textAlign: 'right' }}>{this.convertToNumberString(this.totalBookings)}</td>
                  </tr>
                </tbody>
              </table>
            </Overview>
            <GraphContainer id='graph' onMouseDown={this.scrollStart}>
              <GraphHeader>
                <strong>Bookings per month:</strong>
                <GraphModes>
                  <GraphModesRow>
                    <GraphModeItem active={this.state.scope === 'all'} id='mode_scope_all' onClick={this.modeChanged}>
                      all
                    </GraphModeItem>
                    <GraphModeItem>/</GraphModeItem>
                    <GraphModeItem active={this.state.scope === 'photo'} id='mode_scope_photo' onClick={this.modeChanged}>
                      photo
                    </GraphModeItem>
                    <GraphModeItem>/</GraphModeItem>
                    <GraphModeItem active={this.state.scope === 'video'} id='mode_scope_video' onClick={this.modeChanged}>
                      video
                    </GraphModeItem>
                  </GraphModesRow>
                  <GraphModesRow>
                    <GraphModeItem active={this.state.filter === 'all'} id='mode_filter_all' onClick={this.modeChanged}>
                      all
                    </GraphModeItem>
                    <GraphModeItem>/</GraphModeItem>
                    <GraphModeItem active={this.state.filter === 'accepted'} id='mode_filter_accepted' onClick={this.modeChanged}>
                      accepted
                    </GraphModeItem>
                    <GraphModeItem>/</GraphModeItem>

                    <GraphModeItem active={this.state.filter === 'completed'} id='mode_filter_completed' onClick={this.modeChanged}>
                      completed
                    </GraphModeItem>
                    <GraphModeItem>/</GraphModeItem>
                    <GraphModeItem active={this.state.filter === 'pending'} id='mode_filter_pending' onClick={this.modeChanged}>
                      pending
                    </GraphModeItem>
                    <GraphModeItem>/</GraphModeItem>
                    <GraphModeItem active={this.state.filter === 'declined'} id='mode_filter_declined' onClick={this.modeChanged}>
                      declined
                    </GraphModeItem>
                    <GraphModeItem>/</GraphModeItem>
                    <GraphModeItem active={this.state.filter === 'cancelled'} id='mode_filter_cancelled' onClick={this.modeChanged}>
                      cancelled
                    </GraphModeItem>
                  </GraphModesRow>
                </GraphModes>
              </GraphHeader>
              <Graph id='graph_scrollElement' onMouseDown={this.scrollStart}>
                {this.getBookingsPerMonth()}
              </Graph>
            </GraphContainer>
            <Tables>
              <Faculties>
                <table
                  style={{
                    tableLayout: 'fixed',
                    border: `2px solid ${this.props.theme.main}`,
                    padding: '20px 20px',
                    userSelect: 'none'
                  }}
                >
                  <thead style={{ height: '40px', verticalAlign: 'top' }}>
                    <tr>
                      <td>
                        <strong>Bookings per faculty/division:</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody
                    style={{ display: 'block', height: '260px', overflowY: 'auto', overflowX: 'hidden', width: 'auto' }}
                    id='table_faculty_scrollElement'
                    onMouseDown={this.scrollStart}
                  >
                    {Object.keys(this.faculties)
                      .sort((a, b) => {
                        if (this.faculties[a] > this.faculties[b]) return -1
                        if (this.faculties[a] === this.faculties[b]) return 0
                        return 1
                      })
                      .map((faculty, index) => {
                        return (
                          <tr
                            key={faculty}
                            style={{
                              display: 'table-row'
                            }}
                            id='table_faculty'
                            onMouseDown={this.scrollStart}
                          >
                            <td
                              style={{
                                display: 'table-cell',
                                padding: '4px 20px',
                                backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                              }}
                              title={faculty}
                              id='table_faculty'
                              onMouseDown={this.scrollStart}
                            >
                              {faculty}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                display: 'table-cell',
                                padding: '0px 20px 0px 20px',
                                backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                              }}
                              id='table_faculty'
                              onMouseDown={this.scrollStart}
                            >
                              {this.convertToNumberString(this.faculties[faculty])}
                            </td>
                            <td style={{ padding: '4px 10px' }} id='table_faculty' onMouseDown={this.scrollStart}></td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
                <NormaliseData>
                  <span>
                    <strong>Normalise faculty data:</strong>
                  </span>
                  <Input
                    type='select'
                    id='facultySelector'
                    width='500px'
                    onChange={event => {
                      this.setState({ faculty: event.target.value })
                    }}
                  >
                    {this.facultyOptions()}
                  </Input>
                  {this.state.faculty ? (
                    <NewValueInput>
                      <Input
                        type='datalist'
                        id='facultyValue'
                        placeholder='Please enter a new value'
                        onChange={event => {
                          this.setState({ facultyValue: event.target.value })
                        }}
                        width='300px'
                      >
                        {this.props.approvedFormItems.faculty || <option value={null}></option>}
                      </Input>
                      <Input
                        type='button'
                        id='submitFacultyValue'
                        onClick={() => {
                          this.changing = 'faculty'
                          this.alertChangeToDatabase()
                        }}
                        width='100px'
                        disabled={!this.state.facultyValue || this.state.facultyValue.trim() === ''}
                      >
                        Submit
                      </Input>
                    </NewValueInput>
                  ) : null}
                </NormaliseData>
              </Faculties>
              <Departments>
                <table
                  style={{
                    tableLayout: 'fixed',
                    border: `2px solid ${this.props.theme.main}`,
                    padding: '20px 20px',
                    userSelect: 'none'
                  }}
                >
                  <thead style={{ height: '40px', verticalAlign: 'top' }}>
                    <tr>
                      <td>
                        <strong>Bookings per department:</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody
                    style={{ display: 'block', height: '260px', overflowY: 'auto', overflowX: 'hidden', width: 'auto' }}
                    id='table_department_scrollElement'
                    onMouseDown={this.scrollStart}
                  >
                    {Object.keys(this.departments)
                      .sort((a, b) => {
                        if (this.departments[a] > this.departments[b]) return -1
                        if (this.departments[a] === this.departments[b]) return 0
                        return 1
                      })
                      .map((department, index) => {
                        return (
                          <tr
                            key={department}
                            style={{
                              display: 'table-row'
                            }}
                            id='table_department'
                            onMouseDown={this.scrollStart}
                          >
                            <td
                              style={{
                                display: 'table-cell',
                                padding: '4px 20px',
                                backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                              }}
                              title={department}
                              id='table_department'
                              onMouseDown={this.scrollStart}
                            >
                              {department}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                display: 'table-cell',
                                padding: '0px 20px 0px 20px',
                                backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                              }}
                              id='table_department'
                              onMouseDown={this.scrollStart}
                            >
                              {this.convertToNumberString(this.departments[department])}
                            </td>
                            <td style={{ padding: '4px 10px' }} id='table_department' onMouseDown={this.scrollStart}></td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
                <NormaliseData>
                  <span>
                    <strong>Normalise department data:</strong>
                  </span>
                  <Input
                    type='select'
                    id='departmentSelector'
                    width='500px'
                    onChange={event => {
                      this.setState({ department: event.target.value })
                    }}
                  >
                    {this.departmentOptions()}
                  </Input>
                  {this.state.department ? (
                    <NewValueInput>
                      <Input
                        type='datalist'
                        id='departmentValue'
                        placeholder='Please enter a new value'
                        onChange={event => {
                          this.setState({ departmentValue: event.target.value })
                        }}
                        width='300px'
                      >
                        {this.props.approvedFormItems.department || <option value={null}></option>}
                      </Input>
                      <Input
                        type='button'
                        id='submitDepartmentValue'
                        onClick={() => {
                          this.changing = 'department'
                          this.alertChangeToDatabase()
                        }}
                        width='100px'
                        disabled={!this.state.departmentValue || this.state.departmentValue.trim() === ''}
                      >
                        Submit
                      </Input>
                    </NewValueInput>
                  ) : null}
                </NormaliseData>
              </Departments>
            </Tables>
            <NormaliseDescription>
              Use the above inputs to normalise the values given by staff. For instance, you might find values for <em>CBE</em>, <em>CoBE</em> as well as{' '}
              <em>College of Business and Economics</em>. To make sure that all of these names are counted together, it may be necessary to normalise the data
              from time to time by changing 'CBE' and 'CoBE' to 'College of Business and Economics'. Please note that such actions are permanent and cannot be
              undone.{' '}
            </NormaliseDescription>
            {this.alert}
          </React.Fragment>
        )}
      </StyledBookings>
    )
  }
}

const StyledBookings = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.loading ? 'center' : 'flex-start')};
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  color: ${props => props.theme.dark};
`

const Overview = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin-bottom: 20px;
  flex-shrink: 0;
`

const GraphContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 90%;
  height: 320px;
  padding: 10px 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
  border: 1px solid ${props => props.theme.dark};
`
const Graph = styled.div`
  user-select: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  column-gap: 20px;
  padding: 20px 0px 30px 0px;
  :hover {
    cursor: default;
  }
`

const GraphItem = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  row-gap: 15px;
  width: 100px;
  transition: height 0.5s linear;
`

const GraphHeader = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`

const GraphModes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`

const GraphModesRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  margin-bottom: 10px;
`

const GraphModeItem = styled.div`
  position: relative;
  display: inline;
  width: auto;
  margin-left: ${props => (props.active ? '9px' : '10px')};
  margin-right: ${props => (props.active ? '0px' : '1px')};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  text-decoration: ${props => (props.active ? 'underline' : null)};
  :hover {
    cursor: ${props => (props.id ? 'pointer' : 'default')};
  }
`

const Tables = styled.div`
  box-sizing: border-box;
  width: 90%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`

const Faculties = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 48%;
`

const Departments = styled(Faculties)``

const NormaliseData = styled.div`
  width: 100%;
  margin: 30px 20px 0px 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 20px;
`

const NewValueInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
`

const NormaliseDescription = styled.div`
  margin-top: 20px;
  padding-bottom: 30px;
  width: 70%;
  text-align: justify;
  font-size: 14px;
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    approved: state.forms.approved
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addApprovedFormItems: (newItems) => dispatch(actions.addApprovedFormItems(newItems)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Bookings))
