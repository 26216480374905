import { Component } from 'react'
import styled from 'styled-components'

class Select extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value || ''
    }

    this.validateProps()
  }

  validateProps = () => {
    if (!this.props.children)
      throw new Error(`<Input type='select' /> must have at least one <option /> element as its child.`)
  }

  inputChangedHandler = event => {
    this.setState({ value: event.target.value })
    if (this.props.onChange) this.props.onChange(event)
  }

  render() {
    return (
      <StyledSelect
        id={this.props.id}
        value={this.state.value}
        required={this.props.required}
        onChange={this.inputChangedHandler}
        onFocus={this.props.onFocus}
        disabled={this.props.disabled}
        autoComplete='off'
        autoFocus={this.props.autoFocus}
        smallDevice={this.props.smallDevice}
        width={this.props.width}
      >
        {this.props.children}
      </StyledSelect>
    )
  }
}

const StyledSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  height: 26px;
  width: ${props => props.width || '100%'};
  padding: 0px 2px;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  outline: none;
  background-color: white;
  color: ${props => props.theme.dark};
  font-size: inherit;
  font-family: 'Roboto', 'Arial';
  text-align: center;

  :focus {
    border: 2px solid ${props => props.theme.dark};
  }

  :disabled {
    background-color: ${props => props.theme.bg};
  }

  option {
    font-family: 'Roboto', 'Arial';
  }
`
export default Select
