import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import * as actionTypes from 'store/actions/actionTypes'
import { db } from 'utils/firebase'

export const setConfig = config => {
  return {
    type: actionTypes.SET_CONFIG,
    config
  }
}

export const setExternalUser = email => {
  return dispatch => {
    updateDoc(doc(db, 'config', 'config'), {
      externalUsers: arrayUnion(email)
    }).then(() => {
      return dispatch({
        type: actionTypes.SET_EXTERNAL_USER,
        email
      })
    })
  }
}

export const removeExternalUser = email => {
  return dispatch => {
    updateDoc(doc(db, 'config', 'config'), {
      externalUsers: arrayRemove(email)
    }).then(() => {
      return dispatch({
        type: actionTypes.REMOVE_EXTERNAL_USER,
        email
      })
    })
  }
}