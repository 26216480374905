import React, { Component } from 'react'
import styled from 'styled-components'

class DateInput extends Component {
  constructor(props) {
    super(props)

    let date = this.props.value ? this.props.value.toDate() : new Date()
    this.state = {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      date: date
    }

    this.getDayOptions()
    this.setMonthOptions()
    this.setYearOptions()
  }
  
  componentDidMount() {
    this.setInputValue()
    document.getElementById(this.props.id).setAttribute('type', 'date')
  }

  getDayOptions = () => {
    this.dayOptions = []
    for (let i = 1; i <= this.calculateDaysInMonth(); i++) {
      this.dayOptions.push(<option key={i}>{i}</option>)
    }
    this.forceUpdate()
  }

  calculateDaysInMonth = () => {
    switch (this.state.month) {
      case 0:
        return 31
      case 1:
        if ((this.state.year / 4) % 1 === 0 && (this.state.year / 100) % 1 !== 0) {
          return 29
        } else if (
          (this.state.year / 4) % 1 === 0 &&
          (this.state.year / 100) % 1 === 0 &&
          (this.state.year / 400) % 1 === 0
        ) {
          return 29
        } else return 28
      case 2:
        return 31
      case 3:
        return 30
      case 4:
        return 31
      case 5:
        return 30
      case 6:
        return 31
      case 7:
        return 31
      case 8:
        return 30
      case 9:
        return 31
      case 10:
        return 30
      case 11:
        return 31
      default:
        break
    }
  }

  setMonthOptions = () => {
    this.monthOptions = [
      { number: 0, text: 'JAN' },
      { number: 1, text: 'FEB' },
      { number: 2, text: 'MAR' },
      { number: 3, text: 'APR' },
      { number: 4, text: 'MAY' },
      { number: 5, text: 'JUN' },
      { number: 6, text: 'JUL' },
      { number: 7, text: 'AUG' },
      { number: 8, text: 'SEP' },
      { number: 9, text: 'OCT' },
      { number: 10, text: 'NOV' },
      { number: 11, text: 'DEC' }
    ].map(month => {
      return (
        <option key={month.text} value={month.number}>
          {month.text}
        </option>
      )
    })
  }

  setYearOptions = () => {
    let currentYear = new Date().getFullYear()
    let yearOptions = []
    for (let i = currentYear - 1; i < currentYear + 5; i++) {
      yearOptions.push(<option key={i}>{i}</option>)
    }
    this.yearOptions = yearOptions
  }

  changeHandler = event => {
    this.setDate(event)
  }

  setDate = event => {
    let date = this.state.date
    let value = parseInt(event.target.value, 10)
    switch (event.target.id) {
      case 'day':
        date.setDate(value)
        this.setState({ day: value })
        break
      case 'month':
        date.setMonth(value)
        this.setState({ month: value }, () => {
          this.getDayOptions()
        })
        break
      case 'year':
        date.setFullYear(value)
        this.setState({ year: value }, () => {
          this.getDayOptions()
        })
        break
      default:
        break
    }
    this.setState({ date: date }, () => {
      this.setInputValue()
      if (this.props.onChange) this.props.onChange(event)
    })
  }

  setInputValue = () => {
    document.getElementById(this.props.id).setAttribute('value', this.state.date)
  }

  focusHandler = event => {
    event.target.addEventListener('keydown', this.invertArrowKeys)
    if (this.props.onFocus) this.props.onFocus(event)
  }

  blurHandler = event => {
    event.target.removeEventListener('keydown', this.invertArrowKeys)
  }

  invertArrowKeys = event => {
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      event.preventDefault()
      let id = event.target.id
      let value = parseInt(event.target.value)
      let optionCount = {
        day: { min: 1, max: this.dayOptions.length },
        month: { min: 0, max: 11 },
        year: {
          min: this.yearOptions[0].props.children,
          max: this.yearOptions[this.yearOptions.length - 1].props.children
        }
      }
      if (event.key === 'ArrowUp')
        this.setState({ [id]: value < optionCount[id].max ? value + 1 : optionCount[id].min })
      if (event.key === 'ArrowDown')
        this.setState({ [id]: value > optionCount[id].min ? value - 1 : optionCount[id].max })
      this.setDate(event)
    }
  }

  render() {
    return (
      <StyledDateInput id={this.props.id}>
        <Day
          id="day"
          label="Day"
          value={this.state.day}
          onChange={this.changeHandler}
          onFocus={this.focusHandler}
          onBlur={this.blurHandler}
          smallDevice={this.props.smallDevice}
          required
        >
          {this.dayOptions}
        </Day>
        <Month
          id="month"
          label="Month"
          value={this.state.month}
          onChange={this.changeHandler}
          onFocus={this.focusHandler}
          onBlur={this.blurHandler}
          smallDevice={this.props.smallDevice}
          required
        >
          {this.monthOptions}
        </Month>
        <Year
          id="year"
          label="Year"
          value={this.state.year}
          onChange={this.changeHandler}
          onFocus={this.focusHandler}
          onBlur={this.blurHandler}
          smallDevice={this.props.smallDevice}
          required
        >
          {this.yearOptions}
        </Year>
      </StyledDateInput>
    )
  }
}

const StyledDateInput = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: inherit;
`

const Day = styled.select`
  box-sizing: border-box;
  width: 34px;
  height: 26px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  appearance: none;
  margin-right: 15px;
  padding: 0px 2px;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  outline: none;
  background-color: white;
  color: ${props => props.theme.dark};
  font-size: inherit;


  :focus {
    border: 2px solid ${props => props.theme.dark};
  }

  :disabled {
    background-color: ${props => props.theme.bg};
  }
`

const Month = styled(Day)`
  width: 50px;
`

const Year = styled(Day)`
  width: 60px;
`

export default DateInput
