import React, { Component } from 'react'
import styled from 'styled-components'
import Text from 'components/Text'
import 'components/loader/Loader.css'

class Loader extends Component {
  render() {
    return (
      <StyledLoader backdrop={this.props.backdrop} bringToFront={this.props.bringToFront}>
        <div className="loader" />
        {this.props.percent ? (
          <Text
            fontSize="30px"
            margin="30px 0px 0px 0px"
          >{`${this.props.percent}%`}</Text>
        ) : null}
      </StyledLoader>
    )
  }
}

const StyledLoader = styled.div`
  box-sizing: border-box;
  grid-area: ${props => props.gridArea || 'main'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.main};
  background-color: ${props => (props.backdrop ? props.theme.bg : null)};
  position: ${props => (props.backdrop ? 'fixed' : 'relative')};
  width: ${props => (props.backdrop ? '100%' : 'auto')};
  height: ${props => (props.backdrop ? '100%' : 'auto')};
  margin-top: -50px;
  z-index: ${props => props.bringToFront ? '5' : '3'};
`

export default Loader
