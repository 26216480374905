import React from 'react'
import * as actions from 'store/actions/actionTypes'

const initialState = {
  pending: null,
  approved: null
}

const savePendingFormItems = (state, action) => {
  let options = {}
  Object.keys(action.pending).forEach(category => {
    options[category] = action.pending[category].map(item => {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      )
    })
  })
  return { ...state, pending: options }
}

const saveApprovedFormItems = (state, action) => {
  let options = {}
  Object.keys(action.approved).forEach(category => {
    options[category] = action.approved[category].map(item => {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      )
    })
  })
  return { ...state, approved: options }
}

const addPendingFormItems = (state, action) => {
  const options = { ...state.pending }
  Object.keys(action.items).forEach(category => {
    if (!options[category]) options[category] = []
    if (typeof action.items[category] !== 'string') {
      for (const i of action.items[category]) {
        options[category].push(
          <option key={i} value={i}>
            {i}
          </option>
        )
      }
    } else {
      options[category].push(
        <option key={action.items[category]} value={action.items[category]}>
          {action.items[category]}
        </option>
      )
    }
  })
  return { ...state, pending: options }
}

const addApprovedFormItems = (state, action) => {
  const options = { ...state.approved }
  Object.keys(action.items).forEach(category => {
    if (!options[category]) options[category] = []
    options[category].push(
      <option key={action.items[category]} value={action.items[category]}>
        {action.items[category]}
      </option>
    )
  })
  const sortedOptions = {}
  Object.keys(options).forEach(category => {
    sortedOptions[category] = options[category].sort((a, b) => {
      return a.value > b.value ? 1 : a.value < b.value ? -1 : 0
    })
  })
  return { ...state, pending: sortedOptions }
}

const removePendingFormItem = (state, action) => {
  const options = { ...state.pending }
  const array = options[action.category]
  options[action.category].splice(
    array.findIndex(element => element.value === action.item),
    1
  )
  return { ...state, pending: options }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SAVE_PENDING_FORM_ITEMS:
      return savePendingFormItems(state, action)
    case actions.SAVE_APPROVED_FORM_ITEMS:
      return saveApprovedFormItems(state, action)
    case actions.ADD_PENDING_FORM_ITEMS:
      return addPendingFormItems(state, action)
    case actions.ADD_APPROVED_FORM_ITEMS:
      return addApprovedFormItems(state, action)
    case actions.REMOVE_PENDING_FORM_ITEM:
      return removePendingFormItem(state, action)
    case 'DO_NOTHING':
      return state
    default:
      return state
  }
}

export default reducer
