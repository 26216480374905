import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Text from 'components/Text'
import * as actions from 'store/actions'

class ProgressCard extends Component {
  constructor(props) {
    super(props)

    this.progress = this.props.progress
  }

  componentDidUpdate() {
    if (this.progress !== this.props.progress) {
      this.progress = this.props.progress
      this.forceUpdate()
    }
  }

  renderTotal = () => {
    return <Total>{Math.round((this.progress.transferred / this.progress.totalBytes) * 100)}%</Total>
  }

  getUploadPercentage = () => {
    let currentFile = this.progress && this.progress.currentFile
    return Math.round((currentFile.transferred / currentFile.size) * 100)
  }

  cancelUpload = () => {
    this.props.updateProgress({ ...this.progress, cancel: true })
  }

  render() {
    let progress = this.progress
    let currentFile = progress && progress.currentFile

    return progress ? (
      <StyledUploadCard>
        {progress.cancel ? null : (
          <Title>
            <Name>{`Uploading: ${progress.albumName}`}</Name>
            {this.renderTotal()}
          </Title>
        )}
        {progress.cancel ? (
          <Cancelling>Cancelling upload...</Cancelling>
        ) : (
          <Body>
            {currentFile ? (
              <CurrentFile>
                <Name>{`Uploading ${progress.uploadedFiles.length + 1}/${
                  progress.numberOfFiles
                }`}</Name>
                {currentFile ? <Total>{`(${this.getUploadPercentage()}%)`}</Total> : null}
              </CurrentFile>
            ) : null}
            {currentFile ? (
              <Cancel>
                <Text clicked={this.cancelUpload}>Cancel</Text>
              </Cancel>
            ) : null}
          </Body>
        )}
      </StyledUploadCard>
    ) : null
  }
}

const StyledUploadCard = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 400px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.bg};
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  z-index: 2;
  color: ${props => props.theme.dark};
`

const Title = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  background-color: ${props => props.theme.dark};
  color: ${props => props.theme.bg};
`

const Name = styled.div`
  box-sizing: border-box;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 3px 6px;
`

const Total = styled.div`
  box-sizing: border-box;
  padding: 3px 6px;
  text-align: right;
`

const Body = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
`

const Cancelling = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CurrentFile = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
`

const Cancel = styled.div`
  color: ${props => props.theme.dark};
  font-weight: bold;
  text-align: right;
  :hover {
    cursor: pointer;
    color: ${props => props.theme.danger};
  }
`

const mapStateToProps = state => {
  return {
    progress: state.upload.progress
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProgress: progress => dispatch(actions.updateProgress(progress))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressCard)
