import React, { Component } from 'react'
import styled from 'styled-components'

class Text extends Component {
  state = {
    value: this.props.value || ''
  }

  changeHandler = event => {
    this.setState({ value: event.target.value }, () => {
      if (this.props.onChange) this.props.onChange(event)
    })
  }

  render() {
    return (
      <StyledText
        type="text"
        id={this.props.id}
        value={this.state.value}
        onChange={this.changeHandler}
        autoComplete='off'
        autoFocus={this.props.autoFocus}
        placeholder={this.props.placeholder}
        readOnly={this.props.readOnly}
        title={this.props.title}
        required={this.props.required}
        disabled={this.props.disabled}
        smallDevice={this.props.smallDevice}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
      />
    )
  }
}

const StyledText = styled.input`
  box-sizing: border-box;
  height: 26px;
  width: 100%;
  padding: 8px 8px;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  outline: none;
  background-color: white;
  color: ${props => props.theme.dark};
  font-size: inherit;

  :focus {
    border-width: ${props => props.readOnly ? null : '2px'};
    padding: ${props => props.readOnly ? null : '7px 7px'};
  }

  :hover {
    cursor: ${props => props.readOnly ? 'default' : null};
  }
`

export default Text
