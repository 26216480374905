import { Component } from 'react'
import styled from 'styled-components'

class TextArea extends Component {
  state = {
    value: this.props.value || ''
  }

  changeHandler = event => {
    this.setState({ value: event.target.value }, () => {
      if (this.props.onChange) this.props.onChange(event)
    })
  }

  render() {
    return (
      <StyledTextArea
        id={this.props.id}
        value={this.state.value}
        placeholder={this.props.placeholder}
        required={this.props.required}
        minLength={this.props.minLength}
        maxLength={this.props.maxLength}
        autoComplete="off"
        onChange={this.changeHandler}
        rows={this.props.rows || null}
        onFocus={this.props.onFocus}
      />
    )
  }
}

const StyledTextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  padding: 8px 10px;
  color: ${props => props.theme.dark};
  font-family: 'Roboto';
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  resize: none;
  font-size: inherit;

  :focus {
    border-width: 2px;
    padding: 7px 9px;
    outline: none;
  }
`

export default TextArea
