import ReactDOMServer from 'react-dom/server'
import React from 'react'
import store from 'store/store'
import { functions } from 'utils/firebase'
import { setError } from 'store/actions'

var retries = 0

const createCalendarItem = async (booking, user, updated) => {
  return await callCloudFunction(booking, user, updated)
}

const callCloudFunction = async (booking, user, updated) => {
  return await functions
    .httpsCallable(updated ? 'updateCalendarItem' : 'createCalendarItem')({
      subject: `${booking.name}${updated ? ' (updated)' : ''}`,
      content: createContent(booking, user),
      startTime: getStartTime(booking),
      endTime: getEndTime(booking),
      venue: getVenue(booking),
      attendees: setAttendees(booking),
      calendarId: updated ? booking.calendarId : null
    })
    .then(res => {
      if (!updated) {
        return JSON.parse(res.data).id
      }
      return 'SUCCESS'
    })
    .catch(async error => {
      retries++
      if (retries < 4)
        setTimeout(async () => {
          return await callCloudFunction(booking, user, updated)
        }, 400)
      else store.dispatch(setError(error, false, 'Failed to create/update calandar item in BookingForm component.'))
    })
}

const createContent = booking => {
  return ReactDOMServer.renderToStaticMarkup(
    <html
      style={{
        height: '100% !important',
        width: 'auto !important',
        margin: '0 auto !important',
        padding: '0 !important'
      }}
    >
      <head>
        <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet' />
      </head>
      <body
        style={{
          height: '100% !important',
          width: 'auto !important',
          margin: '0 auto !important',
          padding: '10px 10px !important',
          msoLineHeightRule: 'exactly',
          lineHeight: '90%',
          fontFamily: 'Roboto, Arial'
        }}
      >
        <table
          id='body'
          style={{
            height: '100%',
            width: '100%',
            tableLayout: 'fixed'
          }}
        >
          <tbody>
            <tr>
              <td>
                <table
                  id='container'
                  style={{
                    width: '100%',
                    maxWidth: '1000px',
                    backgroundColor: '#e0dede',
                    fontSize: '14px',
                    color: '#57575a',
                    border: '2px solid #57575a',
                    borderCollapse: 'collapse',
                    tableLayout: 'fixed'
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          id='title'
                          style={{
                            tableLayout: 'fixed',
                            width: '100%',
                            backgroundColor: '#e0dede',
                            border: '0',
                            borderCollapse: 'collapse',
                            fontSize: '22px',
                            color: '#57575a'
                          }}
                        >
                          <tbody>
                            <tr style={{ height: '40px' }}>
                              <td style={{ width: '20px' }}></td>
                              <td>
                                <strong>{`${booking.type} Booking${booking.type === 'Videography' ? ` (${booking.videoBookingType})` : ''}`}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          style={{
                            backgroundColor: '#f4f4f4',
                            tableLayout: 'fixed',
                            border: '0',
                            borderCollapse: 'collapse',
                            width: '100%',
                            color: '#57575a'
                          }}
                          id='body'
                        >
                          {renderContentBasedOnBookingType(booking)}
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          id='footer'
                          style={{
                            backgroundColor: '#e0dede',
                            width: '100%',
                            cellPadding: '0',
                            cellSpacing: '0',
                            border: '0',
                            borderCollapse: 'collapse',
                            tableLayout: 'fixed',
                            padding: '0',
                            margin: '0'
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ width: '40px' }}>
                                <a href='https://ujdml.co.za/' style={{ textDecoration: 'none' }}>
                                  <img
                                    src='https://ujdml.co.za/icon-72x72.png'
                                    height='40'
                                    width='40'
                                    style={{ height: '40px', padding: '0', margin: '0' }}
                                    alt='uj-logo'
                                  />
                                </a>
                              </td>
                              <td style={{ width: '6px' }}></td>
                              <td style={{ paddingTop: '6px' }}>
                                <a href='https://ujdml.co.za/' style={{ textDecoration: 'none' }}>
                                  <span
                                    style={{
                                      color: '#57575a',
                                      fontSize: '22px'
                                    }}
                                  >
                                    <strong>UJ Digital Media Library</strong>
                                  </span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  )
}

function renderContentBasedOnBookingType(booking) {
  if (booking.type === 'Photography') {
    return (
      <tbody>
        {title()}
        {description()}
        {date()}
        {contact()}
        {client()}
        {files('Supporting documents', booking.files)}
        {requirements()}
      </tbody>
    )
  }
  if (booking.type === 'Videography') {
    if (booking.videoBookingType === 'Event')
      return (
        <tbody>
          {title()}
          {description()}
          {date()}
          {contact()}
          {client()}
          {files('Supporting documents', booking.files)}
          {requirements()}
        </tbody>
      )

    if (booking.videoBookingType === 'Promotional')
      return (
        <tbody>
          {title()}
          {description()}
          {date()}
          {contact()}
          {client()}
          {interviewees()}
          {files('Script', booking.files)}
          {requirements()}
        </tbody>
      )

    if (booking.videoBookingType === 'Special projects')
      return (
        <tbody>
          {title()}
          {description()}
          {date()}
          {/* {files('Supporting material', booking.files)} */}
          {contact()}
          {client()}
          {requirements()}
        </tbody>
      )

    if (booking.videoBookingType === 'Editing only')
      return (
        <tbody>
          {title()}
          {description()}
          {date()}
          {/* {files('Existing footage', booking.attachments)}*/}
          {contact()}
          {client()}
          {storageMedium()}
          {requirements()}
        </tbody>
      )
  }
  return null

  function title() {
    return (
      <tr>
        <td style={{ fontSize: '20px', padding: '20px 20px 0px 20px' }}>
          <strong>{booking.name}</strong>
        </td>
      </tr>
    )
  }

  function description() {
    return (
      <tr>
        <td style={{ textAlign: 'justify', padding: '10px 40px 0px 40px' }}>
          <em>{booking.description}</em>
        </td>
      </tr>
    )
  }

  function date() {
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let date = booking.dateOfEvent || booking.startDate
    let event = booking.type === 'Photography' || (booking.type === 'Videography' && (booking.videoBookingType === 'Event' || booking.videoBookingType === 'Special projects'))

    return (
      <tr>
        <td style={{ padding: '20px 20px 0px 20px' }}>
          <strong style={{ fontSize: '14px' }}>{event ? 'Date, time and venue:' : 'Start date:'}</strong>
          <p style={{ margin: '6px 0px 0px 20px' }}>{`${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`}</p>
          {event ? <p style={{ margin: '6px 0px 0px 20px' }}>{`${booking.startTime} - ${booking.endTime}`}</p> : null}
          {event ? <p style={{ margin: '6px 0px 0px 20px' }}>{`${booking.venue} (${booking.campus})`}</p> : null}
        </td>
      </tr>
    )
  }

  function contact() {
    return (
      <tr>
        <td style={{ padding: '20px 20px 0px 20px' }}>
          <strong style={{ fontSize: '14px' }}>Contact person:</strong>
          <p style={{ margin: '6px 0px 0px 20px' }}>{booking.contactPerson}</p>
          <p style={{ margin: '6px 0px 0px 20px' }}>
            <a href={`mailto:${booking.contactEmail}`} style={{ color: '#57575a', textDecoration: 'underline solid #57575a', margin: '0' }}>
              {booking.contactEmail}
            </a>
          </p>
          <p style={{ margin: '6px 0px 0px 20px' }}>
            <a href={`tel:${booking.contactMobile}`} style={{ color: '#57575a', textDecoration: 'underline solid #57575a', margin: '0' }}>
              {booking.contactMobile}
            </a>
          </p>
        </td>
      </tr>
    )
  }

  function client() {
    return (
      <tr>
        <td style={{ padding: '20px 20px 0px 20px' }}>
          <strong style={{ fontSize: '14px' }}>Client:</strong>
          <p style={{ margin: '6px 0px 0px 20px' }}>{booking.clientName}</p>
          <p style={{ margin: '6px 0px 0px 20px' }}>{`${booking.department}, ${booking.faculty}`}</p>
          <p style={{ margin: '6px 0px 0px 20px' }}>
            <a href={`mailto:${booking.clientEmail}`} style={{ color: '#57575a', textDecoration: 'underline solid #57575a', margin: '0' }}>
              {booking.clientEmail}
            </a>
          </p>
          <p style={{ margin: '6px 0px 0px 20px' }}>
            <a
              href={`tel:011559${booking.clientPhone}`}
              style={{ color: '#57575a', textDecoration: 'underline solid #57575a', margin: '0' }}
            >{`011 559 ${booking.clientPhone}`}</a>
          </p>
        </td>
      </tr>
    )
  }

  function interviewees() {
    return (
      <tr>
        <td style={{ padding: '20px 20px 0px 20px' }}>
          <strong style={{ fontSize: '14px' }}>Interviewees:</strong>
          <p style={{ margin: '10px 0px 0px 20px' }}>{booking.interviewees}</p>
        </td>
      </tr>
    )
  }

  function storageMedium() {
    return (
      <tr>
        <td style={{ padding: '20px 20px 0px 20px' }}>
          <strong style={{ fontSize: '14px' }}>Storage medium:</strong>
          {booking.storageMedium === 'externalStorage' ? (
            <p style={{ margin: '10px 0px 0px 20px' }}>External storage</p>
          ) : (
            <p style={{ margin: '10px 0px 0px 20px' }}>
              <a href={booking.storageMediumURL} style={{ textDecoration: 'underline', color: '#57575a' }}>
                {booking.storageMediumURL}
              </a>
            </p>
          )}
        </td>
      </tr>
    )
  }

  function files(title, files) {
    return (
      <tr>
        <td style={{ padding: '20px 20px 0px 20px' }}>
          <strong style={{ fontSize: '14px' }}>{title}:</strong>
          {renderFiles(files)}
        </td>
      </tr>
    )
    function renderFiles(files) {
      let filesList =
        files && files.length > 0 ? (
          files.map((file, index) => (
            <p key={file.name} style={{ margin: `0px 0px ${index === files.length - 1 ? '20px' : '0px'} 20px` }}>
              <a href={file.url} style={{ textDecoration: 'underline', color: '#57575a' }}>
                {file.name}
              </a>
            </p>
          ))
        ) : (
          <p style={{ margin: '10px 0px 0px 20px' }}>None</p>
        )
      return filesList
    }
  }

  function requirements() {
    return (
      <tr>
        <td style={{ padding: '20px 20px 20px 20px' }}>
          <strong style={{ fontSize: '14px' }}>Notes / additional requirements:</strong>
          <p style={{ margin: '10px 0px 0px 20px' }}>{booking.requirements}</p>
        </td>
      </tr>
    )
  }
}

const getStartTime = booking => {
  const date = booking.dateOfEvent || booking.startDate
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}T${booking.startTime || '08:00'}:00`
}

const getEndTime = booking => {
  const date = booking.dateOfEvent || booking.startDate
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}T${booking.endTime || '09:00'}:00`
}

const getVenue = booking => {
  if (booking.venue && booking.campus) return `${booking.venue} (${booking.campus})`
  else return 'N/A'
}

const setAttendees = booking => {
  let attendees = [
    {
      emailAddress: {
        address: booking.clientEmail,
        name: booking.clientName
      },
      type: 'required'
    }
  ]
  if (booking.clientEmail !== booking.contactEmail) {
    attendees.push({
      emailAddress: {
        address: booking.contactEmail,
        name: booking.contactPerson
      },
      type: 'required'
    })
  }
  let agents = store.getState().config[booking.type === 'Photography' ? 'photographers' : 'videographers']
  let admins = store.getState().config.admins
  let webAdmin = store.getState().config.webAdmin[0] || null

  if (process.env.NODE_ENV === 'development' && store.getState().config.webAdmin[0]) {
    attendees.push({
      emailAddress: {
        address: webAdmin.email,
        name: webAdmin.name
      },
      type: 'required'
    })
  } else {
    agents.forEach(agent => {
      attendees.push({
        emailAddress: {
          address: agent.email,
          name: agent.name
        },
        type: 'required'
      })
    })
    admins.forEach(admin => {
      if ((booking.type === 'Photography' && admin.notifications.photo) || (booking.type === 'Videography' && admin.notifications.video))
        attendees.push({
          emailAddress: {
            address: admin.email,
            name: admin.name
          },
          type: 'optional'
        })
    })
  }
  return attendees
}

export default createCalendarItem
