import Loader from 'components/loader/Loader'
import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { db } from 'utils/firebase'
import store from 'store/store'
import { setError } from 'store/actions'
import { doc, getDoc } from 'firebase/firestore'

class Searches extends Component {
  state = { loading: true }

  componentDidMount() {
    this.populateData()
  }

  populateData = () => {
    getDoc(doc(db, 'analytics', 'searchTerms'))
      .then(res => {
        this.searchTerms = res.data()
        this.setState({ loading: false })
      })
      .catch(error => store.dispatch(setError(error, false, 'Failed to get searchTerms in Admin component.')))
  }

  convertToNumberString = number => {
    let string = number.toString()
    let str = ''
    let counter = 0
    for (let i = string.length - 1; i >= 0; i--) {
      str += string[i]
      counter++
      if (counter === 3 && i !== 0) {
        str += ','
        counter = 0
      }
    }
    str = str.split('').reverse().join('')
    return str
  }

  scrollStart = event => {
    let id = event.target.id
    if (!id.includes('graph') && !id.includes('table')) return
    let elementType = id.includes('graph') ? 'graph' : 'table'
    let scrollElement = document.getElementById(id.includes('_scrollElement') ? id : `${id}_scrollElement`)

    let firstClick = event[elementType === 'graph' ? 'clientX' : 'clientY']
    let scrollStartPosition = scrollElement[elementType === 'graph' ? 'scrollLeft' : 'scrollTop']

    window.addEventListener('mousemove', scrolling, { passive: true })
    window.addEventListener('mouseup', () => {
      window.removeEventListener('mousemove', scrolling, { passive: true })
    })
    window.addEventListener('mouseleave', () => {
      window.removeEventListener('mousemove', scrolling, { passive: true })
    })

    function scrolling(event) {
      let mousePosition = event[elementType === 'graph' ? 'clientX' : 'clientY']
      let scrollDistance = firstClick - mousePosition
      scrollElement.scrollTo(
        elementType === 'graph' ? scrollStartPosition + scrollDistance : 0,
        elementType === 'graph' ? 0 : scrollStartPosition + scrollDistance
      )
    }
  }

  render() {
    return (
      <StyledSearches loading={this.state.loading ? 'true' : null}>
        {this.state.loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Tables>
              <SearchesTable>
                <table
                  style={{
                    tableLayout: 'fixed',
                    border: `2px solid ${this.props.theme.main}`,
                    padding: '20px 20px',
                    userSelect: 'none',
                    width: '100%',
                    rowGap: 0,
                    columnGap: 0
                  }}
                >
                  <thead style={{ height: '40px', verticalAlign: 'top', width: '100%' }}>
                    <tr>
                      <td>Search terms:</td>
                    </tr>
                  </thead>
                  <tbody
                    style={{ display: 'block', height: '700px', overflowY: 'auto', overflowX: 'hidden', width: '100%' }}
                    id='table_searches_scrollElement'
                    onMouseDown={this.scrollStart}
                  >
                    {Object.keys(this.searchTerms)
                      .sort((a, b) => {
                        if (this.searchTerms[a].count > this.searchTerms[b].count) return -1
                        if (this.searchTerms[a].count === this.searchTerms[b].count) return 0
                        return 1
                      })
                      .map((term, index) => {
                        if (!this.searchTerms[term].count) return null
                        return (
                          <tr
                            key={term}
                            style={{
                              width: '100%',
                              display: 'table-row',
                              verticalAlign: 'middle'
                            }}
                          >
                            <td
                              style={{
                                display: 'table-cell',
                                padding: '4px 20px',
                                width: '100%',
                                backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                              }}
                              id='table_searches'
                              onMouseDown={this.scrollStart}
                            >
                              {term}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                display: 'table-cell',
                                padding: '0px 20px 0px 20px',
                                minWidth: '80px',
                                backgroundColor: index % 2 === 0 ? this.props.theme.bg : this.props.theme.light
                              }}
                              id='table_searches'
                              onMouseDown={this.scrollStart}
                            >
                              {this.convertToNumberString(this.searchTerms[term].count)}
                            </td>
                            <td style={{ minWidth: '20px', backgroundColor: this.props.theme.bg }} />
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </SearchesTable>
            </Tables>
          </React.Fragment>
        )}
      </StyledSearches>
    )
  }
}

const StyledSearches = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.loading ? 'center' : 'flex-start')};
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  color: ${props => props.theme.dark};
`

const Tables = styled.div`
  box-sizing: border-box;
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 40px;
  flex-shrink: 0;
  margin-top: 40px;
`

const SearchesTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`
export default withTheme(Searches)
