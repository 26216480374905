import React, { Component } from 'react'
import { connect } from 'react-redux'
import { db } from 'utils/firebase'
import styled from 'styled-components'
import * as actions from 'store/actions/index.js'
import DropArea from 'pages/upload/DropArea.js'
import Explorer from 'pages/upload/Explorer.js'
import Input from 'components/Input.js'
import DateInput from 'components/DateInput.js'
import Alert from 'components/Alert.js'
import Loader from 'components/loader/Loader.js'
import uploadFiles from 'pages/upload/uploadFiles.js'
import { collection, getDocs, query, where } from 'firebase/firestore'

class Uploads extends Component {
  state = {
    id: '',
    name: '',
    venue: '',
    campus: '',
    faculty: '',
    department: '',
    contact: '',
    date: '',
    description: '',
    albumCover: '',
    loading: true,
    uploadMode: 'blank',
    fileInputFocus: false
  }

  incompletedBookingsArray = []

  componentDidMount() {
    this.populateBookingOptions()
  }

  populateBookingOptions = async () => {
    let photographer = this.props.currentUser.fullName
    this.bookingsData = {}

    this.bookingOptions = await getDocs(
      query(
        collection(db, 'bookings'),
        where('status', '==', 'Accepted'),
        where('photographer', '==', photographer),
        where('dateOfEvent', '<=', this.getEndOfToday())
      )
    )
      .then(bookings => {
        return bookings.docs.map(booking => {
          this.bookingsData[booking.id] = { ...booking.data(), id: booking.id }
          return (
            <option value={booking.id} key={booking.id}>
              {booking.data().name}
            </option>
          )
        })
      })
      .catch(error => this.props.setError(error, true, 'Failed to get booking options in Upload component.'))

    this.bookingOptions.unshift(
      <option value='blank' key='blank' />,
      <option value='new album' key='new'>
        -- Create a new album --
      </option>,
      <option value='stock' key='stock'>
        -- Add to stock --
      </option>
    )

    this.setState({ loading: false })
  }

  getEndOfToday = () => {
    let date = new Date()
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
    return date
  }

  selectAlbumHandler = event => {
    let selectedValue = event.target.value
    if (selectedValue === 'blank')
      this.setState({
        id: '',
        name: '',
        venue: '',
        campus: '',
        faculty: '',
        department: '',
        contact: '',
        date: '',
        description: '',
        uploadMode: 'blank',
        uploadButtonDisabled: true
      })
    else if (selectedValue === 'new album')
      this.setState({
        id: '',
        albumName: '',
        venue: '',
        campus: '',
        faculty: '',
        department: '',
        contact: '',
        date: '',
        description: '',
        uploadMode: 'new album'
      })
    else if (selectedValue === 'stock')
      this.setState({
        id: '',
        albumName: 'Stock',
        venue: '',
        campus: '',
        faculty: '',
        department: '',
        contact: '',
        date: '',
        description: '',
        uploadMode: 'stock',
        uploadButtonDisabled: true
      })
    else {
      let booking = this.bookingsData[selectedValue]
      this.setState({
        uploadMode: 'existing album',
        id: booking.id,
        albumName: booking.name,
        venue: booking.venue,
        campus: booking.campus,
        faculty: booking.faculty,
        department: booking.department,
        contact: booking.contact || booking.contactPerson,
        date: booking.dateOfEvent,
        description: booking.description
      })
    }
  }

  updateInput = event => {
    if (event.target) this.setState({ [event.target.id]: event.target.value })
  }

  addFilesHandler = async event => {
    const files = Object.values(event.target.files).map(file => file)
    let sizeLimitExceeded = false

    for (const file of files) {
      if (!this.isValidImage(file)) return
      let img = this.createImage(file)
      await this.imageLoaded(img)
      if (this.imageSizeWithinBoundaries(img)) this.props.addFiles([file])
      else sizeLimitExceeded = true
    }

    if (sizeLimitExceeded) this.alertSizeLimitExceeded()
  }

  isValidImage = file => file.type === 'image/jpeg'

  createImage = file => {
    let img = new Image()
    img.src = URL.createObjectURL(file)
    return img
  }

  imageLoaded = img => {
    return new Promise(resolve => {
      img.onload = () => resolve()
    })
  }

  imageSizeWithinBoundaries = img => img.width < 15000 && img.height < 15000

  alertSizeLimitExceeded = () => {
    this.alert = <Alert alertType='Size limit exceeded' alertButtonClicked={this.alertButtonClicked} />
    this.forceUpdate()
  }

  albumCoverSelectedHandler = albumCover => this.setState({ albumCover })

  uploadHandler = async event => {
    event.preventDefault()
    if (!this.isAlbumCoverSelected()) return
    if (!this.stockHasTags()) return
    const bookingData = this.bookingsData[this.state.id] || null
    await uploadFiles(this.props.files, this.state, this.props.updateProgress, bookingData)
    this.setState({ uploadMode: 'blank', id: '', uploadButtonDisabled: true })
  }

  isAlbumCoverSelected = () => {
    if (this.state.uploadMode === 'stock') return true
    if (!this.state.albumCover) {
      this.alert = <Alert alertType='Album cover required' alertButtonClicked={this.alertButtonClicked} />
      this.forceUpdate()
      return false
    }
    return true
  }

  stockHasTags = () => {
    if (this.state.uploadMode !== 'stock') return true
    let minimumOneTag = true
    for (const file of this.props.files) {
      const originalFileName = file.name.slice(0, file.name.lastIndexOf('.'))
      const tags = document.getElementById(`tagInput-${originalFileName}`).value
      if (tags.trim() === '') minimumOneTag = false
    }
    if (!minimumOneTag) {
      this.alert = <Alert alertType='Missing tags' alertButtonClicked={this.alertButtonClicked} />
      this.forceUpdate()
      return false
    }
    return true
  }

  alertButtonClicked = event => {
    switch (event.target.id) {
      case 'continue':
        this.props.clearFiles()
        this.alert = null
        this.setState({
          uploadMode: 'blank'
        })
        break
      case 'cancel':
        this.alert = <Alert alertType='Cancelling upload...' width='500px' loader />
        this.setState({ cancel: true })
        this.forceUpdate()
        break
      case 'removeError':
        this.alert = null
        this.forceUpdate()
        break
      default:
        break
    }
  }

  clearFiles = event => {
    if (event.keyCode === 13 || event.keyCode === 32 || event.type === 'click') {
      this.props.clearFiles()
      this.files = []
    }
  }

  resetForm = () => {
    this.alert = null
    this.props.clearFiles()
    document.getElementById('albumName').value = 'blank'
    this.setState({ uploadMode: 'blank' })
  }

  fileInputFocus = event => {
    if (event.type === 'focus') {
      this.setState({ fileInputFocus: true })
    } else this.setState({ fileInputFocus: false })
  }

  backButtonClicked = () => {
    this.props.history.goBack()
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading ? (
          <Loader />
        ) : (
          <StyledUpload onSubmit={this.uploadHandler} id='uploadForm'>
            <SidePanel>
              <AlbumSelector>
                <Input
                  type='select'
                  value={this.state.uploadMode === 'existing album' ? this.state.id : this.state.uploadMode}
                  inputLabel='Choose an album to upload to:'
                  width='100%'
                  height='30px'
                  fontSize='14px'
                  id='albumName'
                  labelPadding='0px 10px'
                  autoFocus
                  changed={this.selectAlbumHandler}
                >
                  {this.bookingOptions}
                </Input>
              </AlbumSelector>
              {this.state.uploadMode !== 'blank' && this.state.uploadMode !== 'stock' ? (
                <AlbumDetails>
                  {this.state.uploadMode === 'new album' ? null : <AlbumDetailTitle>Album name:</AlbumDetailTitle>}
                  <AlbumDetail newAlbum={this.state.uploadMode === 'new album'}>
                    {this.state.uploadMode === 'new album' ? (
                      <Input
                        type='text'
                        id='albumName'
                        width='92%'
                        inputLabel='Album name'
                        value={this.state.albumName}
                        changed={this.updateInput}
                        required
                        autoFocus
                      />
                    ) : (
                      this.state.albumName
                    )}
                  </AlbumDetail>
                  {this.state.uploadMode === 'new album' ? null : <AlbumDetailTitle>Date:</AlbumDetailTitle>}
                  <AlbumDetail newAlbum={this.state.uploadMode === 'new album'}>
                    {this.state.uploadMode === 'new album' ? (
                      <DateInput changed={this.updateInput} id='date' />
                    ) : (
                      new Date(this.state.date.toDate()).toLocaleDateString('en-ZA', {
                        weekday: 'short',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })
                    )}
                  </AlbumDetail>
                  {this.state.uploadMode === 'new album' ? null : <AlbumDetailTitle>Campus:</AlbumDetailTitle>}
                  <AlbumDetail newAlbum={this.state.uploadMode === 'new album'}>
                    {this.state.uploadMode === 'new album' ? (
                      <Input
                        type='select'
                        id='campus'
                        inputLabel='Campus'
                        width='auto'
                        height='26px'
                        fontSize='14px'
                        value={this.state.campus}
                        changed={this.updateInput}
                        required
                      >
                        <option value={null} />
                        <option value='APB'>APB</option>
                        <option value='APK'>APK</option>
                        <option value='DFC'>DFC</option>
                        <option value='SWC'>SWC</option>
                        <option value='offCampus'>Off campus</option>
                      </Input>
                    ) : (
                      this.state.campus
                    )}
                  </AlbumDetail>
                  {this.state.uploadMode === 'new album' ? null : <AlbumDetailTitle>Venue:</AlbumDetailTitle>}
                  <AlbumDetail newAlbum={this.state.uploadMode === 'new album'}>
                    {this.state.uploadMode === 'new album' ? (
                      <Input
                        type='datalist'
                        width='92%'
                        id='venue'
                        list='venueInput'
                        inputLabel='Venue'
                        value={this.state.venue}
                        changed={this.updateInput}
                        required
                      >
                        {this.props.approved.venue || <option />}
                      </Input>
                    ) : (
                      this.state.venue
                    )}
                  </AlbumDetail>
                  {this.state.uploadMode === 'new album' ? null : <AlbumDetailTitle>Faculty:</AlbumDetailTitle>}
                  <AlbumDetail newAlbum={this.state.uploadMode === 'new album'}>
                    {this.state.uploadMode === 'new album' ? (
                      <Input
                        type='datalist'
                        width='92%'
                        id='faculty'
                        list='facultyInput'
                        inputLabel='Faculty/Division'
                        value={this.state.faculty}
                        changed={this.updateInput}
                        required
                      >
                        {this.props.approved.faculty || <option />}
                      </Input>
                    ) : (
                      this.state.faculty
                    )}
                  </AlbumDetail>
                  {this.state.uploadMode === 'new album' ? null : <AlbumDetailTitle>Department:</AlbumDetailTitle>}
                  <AlbumDetail newAlbum={this.state.uploadMode === 'new album'}>
                    {this.state.uploadMode === 'new album' ? (
                      <Input
                        type='datalist'
                        width='92%'
                        id='department'
                        list='departmentInput'
                        inputLabel='Department'
                        value={this.state.department}
                        changed={this.updateInput}
                        required
                      >
                        {this.props.approved.department || <option />}
                      </Input>
                    ) : (
                      this.state.department
                    )}
                  </AlbumDetail>
                  {this.state.uploadMode === 'new album' ? null : <AlbumDetailTitle>Contact person:</AlbumDetailTitle>}
                  <AlbumDetail newAlbum={this.state.uploadMode === 'new album'}>
                    {this.state.uploadMode === 'new album' ? (
                      <Input type='text' width='92%' inputLabel='Contact person' id='contact' value={this.state.contact} changed={this.updateInput} required />
                    ) : (
                      this.state.contact
                    )}
                  </AlbumDetail>
                  {this.state.uploadMode === 'new album' ? null : <AlbumDetailTitle>Photographed by:</AlbumDetailTitle>}
                  {this.state.uploadMode === 'new album' ? null : (
                    <AlbumDetail color='dark' margin={this.state.uploadMode === 'new album' ? '0px 10px' : 'initial'}>
                      {this.props.currentUser.fullName}
                    </AlbumDetail>
                  )}
                </AlbumDetails>
              ) : null}
              {this.state.uploadMode !== 'blank' && this.state.uploadMode !== 'stock' ? (
                <Description>
                  {this.state.uploadMode === 'new album' ? null : <AlbumDetailTitle>Description: </AlbumDetailTitle>}

                  {this.state.uploadMode === 'new album' ? (
                    <Input
                      type='textarea'
                      id='description'
                      margin='0px 10px'
                      inputLabel='Description'
                      value={this.state.description}
                      changed={this.updateInput}
                      required
                    />
                  ) : (
                    <AlbumDetail description>{this.state.description}</AlbumDetail>
                  )}
                </Description>
              ) : null}
            </SidePanel>

            {this.props.files ? (
              <Explorer
                albumCoverSelected={this.albumCoverSelectedHandler}
                albumCover={this.state.albumCover}
                sizeLimitExceeded={this.sizeLimitExceeded}
                enableUploadButton={this.enableUploadButton}
                approvedFormItems={this.props.approvedFormItems ? this.props.approvedFormItems : null}
              />
            ) : (
              <DropArea enableUploadButton={this.enableUploadButton} sizeLimitExceeded={this.sizeLimitExceeded} />
            )}
          </StyledUpload>
        )}
        <Footer>
          <FileInputLabel focus={this.state.fileInputFocus}>
            <FileInput
              onInput={this.addFilesHandler}
              type='file'
              id='dropArea'
              multiple
              accept='image/jpeg'
              onFocus={this.fileInputFocus}
              onBlur={this.fileInputFocus}
            />
            <i className='material-icons'>add</i>
          </FileInputLabel>
          <ClearFilesButton disabled={this.props.files ? (this.props.files.length === 0 ? true : false) : true}>
            <i className='material-icons' onClick={this.clearFiles} tabIndex='0' onKeyUp={this.clearFiles}>
              delete
            </i>
          </ClearFilesButton>
          <UploadButton
            onClick={this.state.uploadMode !== 'blank' && this.props.files && this.props.files.length > 0 ? this.uploadHandler : null}
            disabled={this.props.progress || this.state.uploadMode === 'blank' || !this.props.files || this.props.files.length === 0}
          >
            <i className='material-icons'>cloud_upload</i>
          </UploadButton>
        </Footer>
        {this.alert}
      </React.Fragment>
    )
  }
}

const StyledUpload = styled.form`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  grid-area: main;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${props => props.theme.dark};
`

const SidePanel = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 750px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 30px;
  overflow: hidden;
  border-right: 1px solid ${props => props.theme.main};
`

const AlbumSelector = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-shrink: 0;
`

const AlbumDetails = styled.div`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${props => props.theme.dark};
`

const AlbumDetailTitle = styled.div`
  flex-shrink: 0;
  font-weight: bold;
  padding-left: 10px;
`

const AlbumDetail = styled.div`
  flex-shrink: ${props => (props.description ? 1 : 0)};
  padding-left: 20px;
  width: ${props => (props.description ? '90%' : '100%')};
  padding-right: ${props => (props.description ? '20px' : '0px')};
  margin: ${props => (props.newAlbum ? '4px 0px 0px 0px' : '4px 0px 15px 0px')};
  overflow-y: ${props => (props.description ? 'auto' : 'hidden')};
  :hover {
    font-weight: ${props => (props.clickable ? 'bold' : null)};
    font-size: ${props => (props.clickable ? '15.7px' : null)};
    cursor: ${props => (props.clickable ? 'pointer' : 'select')};
  }
  text-align: justify;
`

const Description = styled.div`
  height: auto;
  max-height: 100%;
  margin-top: 20px;
  padding-right: 20px;
  text-align: justify;
  text-justify: auto;
  overflow-y: auto;
  font-size: 16px;
`

const Footer = styled.div`
  grid-area: footer;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.light};
`

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const FileInputLabel = styled.label`
  box-sizing: border-box;
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: ${props => props.theme.dark};
  border-left: 1px solid ${props => props.theme.dark};
  border: ${props => (props.focus ? `1px dotted ${props.theme.dark}` : null)};
  padding: 1px 1px;

  :hover {
    cursor: pointer;
    font-size: 54px;
    padding: ${props => (props.disabled ? null : '0px 0px')};
  }
`

const ClearFilesButton = styled.div`
  box-sizing: border-box;
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  border-left: 1px solid ${props => props.theme.dark};
  padding: 1px 1px;

  :hover {
    cursor: ${props => (props.disabled ? 'null' : 'pointer')};
    font-size: ${props => (props.disabled ? '50px' : '54px')};
    padding: ${props => (props.disabled ? null : '0px 0px')};
  }

  :focus {
    border: 1px dotted ${props => props.theme.dark};
  }
`

const UploadButton = styled.div`
  box-sizing: border-box;
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: ${props => props.theme[props.disabled ? 'main' : 'dark']};
  border-left: 1px solid ${props => props.theme[props.disabled ? 'main' : 'dark']};
  padding: 1px 1px;

  :hover {
    cursor: ${props => (props.disabled ? 'null' : 'pointer')};
    font-size: ${props => (props.disabled ? '50px' : '54px')};
    padding: ${props => (props.disabled ? null : '0px 0px')};
  }

  :focus {
    border: 1px dotted ${props => props.theme.dark};
  }
`

const mapStateToProps = state => {
  return {
    files: state.upload.files,
    currentUser: state.auth.currentUser,
    approved: state.forms.approved,
    progress: state.upload.progress
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addFiles: files => dispatch(actions.addFiles(files)),
    clearFiles: () => dispatch(actions.clearFiles()),
    updateProgress: progress => dispatch(actions.updateProgress(progress)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Uploads)
