import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from 'store/actions'
import { db, storage } from 'utils/firebase'
import Input, { getInputValue } from 'components/NewInput'
import TextLink from 'components/TextLink'
import Alert from 'components/Alert'
import Loader from 'components/loader/Loader'
import detectBrowser from 'utils/detectBrowser'
import sendEmail from 'pages/bookings/sendEmail'
import createCalendarItem from 'pages/bookings/createCalendarItem'
import uploadFiles from 'pages/bookings/uploadFiles'
import { addDoc, setDoc, updateDoc, collection, doc } from 'firebase/firestore'

class BookingForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      consentAccepted: false,
      validStartTime: true,
      validEndTime: true,
      storageMedium: this.props.existingBooking ? this.props.existingBooking.storageMedium : null,
      videoBookingType: this.props.existingBooking ? this.props.existingBooking.videoBookingType : 'Event'
    }

    if (this.props.type === 'Videography') {
      this.bookingTypeChangedHandler({
        target: { value: this.state.videoBookingType }
      })
    }
  }

  componentDidMount() {
    this.countCharacters()
    this.scrollToTop()
    // if (this.props.type === 'Photography')
    // setTimeout(() => {
    //   this.alert = <Alert alertType='Notice of leave' alertButtonClicked={() => {
    //     this.alert = null
    //     this.forceUpdate()
    //   }} />
    //   this.forceUpdate()
    // }, 1000);
  }

  bookingTypeChangedHandler = event => {
    switch (event.target.value) {
      case 'Event':
        this.bookingTypeDescription = (
          <BookingTypeDescription>
            <p>
              <strong>Event videos</strong> include public lectures, conferences, exhibitions or awards ceremonies. Our experienced videographers will provide
              video coverage for various types of campus events.
            </p>
            <p>The creative approach may consist of interviews, soundbites and cut-aways from the event.</p>
          </BookingTypeDescription>
        )
        break
      case 'Promotional': // strong to underlined?
        this.bookingTypeDescription = (
          <BookingTypeDescription>
            <p>
              A <strong>promotional video</strong> is used to highlight certain aspects of the university to the public. This could include ground-breaking
              research, new academic programs, university facilities or student/staff success stories.
            </p>
            <p>The creative approach may consist of interviews, text, graphics, photos, voice-overs and background music.</p>
          </BookingTypeDescription>
        )
        break
      case 'Special projects':
        this.bookingTypeDescription = (
          <BookingTypeDescription>
            <p>
              <strong>Special projects</strong> include the filming and editing of documentaries, speeches, welcome addresses, and informational videos.
            </p>
          </BookingTypeDescription>
        )
        break
      case 'Editing only':
        this.bookingTypeDescription = (
          <BookingTypeDescription>
            <p>
              <strong>Video editing</strong> consists of:
            </p>
            <ul>
              <li>Rearranging, adding and/or removing sections of video clips and/or audio clips.</li>
              <li>Applying colour correction, filters, and other enhancements to video clips.</li>
              <li>Creating smooth transitions between clips.</li>
              <li>Adding UJ branding where appropriate.</li>
            </ul>
          </BookingTypeDescription>
        )
        break
      default:
        break
    }
    this.setState({ videoBookingType: event.target.value })
  }

  scrollToTop = () => {
    let bookingForm = document.getElementById('bookingForm')
    if (bookingForm) bookingForm.scrollTo(0, 0)
  }

  submitForm = event => {
    if (event) event.preventDefault()
    if (this.state.submitting || !this.timeIsValid() || !this.filesAttached()) return
    this.setState({ loading: true, submitting: true }, async () => {
      let albumData = this.createAlbumData()
      let bookingId = await addDoc(collection(db, 'bookings'), {})
        .then(res => res.id)
        .catch(error => this.props.setError(error, false, 'Failed to create a booking id in BookingForm component.'))
      albumData.files = await uploadFiles(bookingId, null)
      albumData.calendarId = await createCalendarItem(albumData, this.props.currentUser)
      setDoc(doc(db, 'bookings', bookingId), albumData).catch(error =>
        this.props.setError(error, false, 'Failed to create a booking document in BookingForm component.')
      )
      sendEmail({ type: 'submitted', booking: albumData }).catch(error =>
        this.props.setError(error, false, 'Failed to send booking confirmation email in BookingForm component.')
      )
      this.addFormItems()
      this.alertSuccess()
      this.setState({ loading: false, submitting: false })
    })
  }

  createAlbumData = () => {
    let existingBooking = this.props.existingBooking
    let albumData = {
      type: existingBooking ? existingBooking.type : this.props.type,
      name: getInputValue('name'),
      description: getInputValue('description'),
      contactPerson: getInputValue('contactPerson'),
      contactEmail: getInputValue('contactEmail'),
      contactMobile: getInputValue('contactMobile'),
      requirements: getInputValue('requirements') || 'None',
      faculty: existingBooking ? existingBooking.faculty : this.props.currentUser.faculty,
      department: existingBooking ? existingBooking.department : this.props.currentUser.department,
      dateOfBooking: existingBooking ? existingBooking.dateOfBooking : new Date(),
      clientName: existingBooking ? existingBooking.clientName : this.props.currentUser.fullName,
      clientEmail: existingBooking ? existingBooking.clientEmail : this.props.currentUser.email,
      clientPhone: existingBooking ? existingBooking.clientPhone : this.props.currentUser.officePhone,
      status: 'Submitted'
    }

    if (this.props.type === 'Videography') {
      albumData.videoBookingType = getInputValue('videoBookingType')
      albumData.videographer = existingBooking ? existingBooking.videographer : 'Pending'
      switch (this.state.videoBookingType) {
        case 'Event':
          albumData.dateOfEvent = getInputValue('dateOfEvent')
          albumData.startTime = getInputValue('startTime')
          albumData.endTime = getInputValue('endTime')
          albumData.venue = getInputValue('venue')
          albumData.campus = getInputValue('campus')
          break
        case 'Promotional':
          albumData.startDate = getInputValue('startDate')
          albumData.interviewees = getInputValue('interviewees')
          break
        case 'Special projects':
          albumData.dateOfEvent = getInputValue('dateOfEvent')
          albumData.startTime = getInputValue('startTime')
          albumData.endTime = getInputValue('endTime')
          albumData.venue = getInputValue('venue')
          albumData.campus = getInputValue('campus')
          break
        case 'Editing only':
          albumData.startDate = getInputValue('startDate')
          albumData.storageMedium = this.state.storageMedium
          albumData.storageMediumURL = getInputValue('storageMediumURL') || 'undefined'
          break
        default:
          break
      }
    }

    if (this.props.type === 'Photography') {
      albumData.photographer = existingBooking ? existingBooking.photographer : 'Pending'
      albumData.dateOfEvent = getInputValue('dateOfEvent')
      albumData.startTime = getInputValue('startTime')
      albumData.endTime = getInputValue('endTime')
      albumData.venue = getInputValue('venue')
      albumData.campus = getInputValue('campus')
    }

    if (this.props.existingBooking) {
      albumData.status = 'Updated'
      albumData.updatedOn = new Date()
      albumData.updatedBy = this.props.currentUser.fullName
      albumData.calendarId = this.props.existingBooking.calendarId
      if (this.dateOrTimeHasChanged() && !this.props.currentUser.videographer && !this.props.photographer) {
        if (albumData.videographer) albumData.videographer = 'Pending'
        if (albumData.photographer) albumData.photographer = 'Pending'
      }
    }

    return albumData
  }

  addFormItems = () => {
    if (!getInputValue('venue')) return

    this.props.addPendingFormItems({
      venue: getInputValue('venue')
    })
  }

  alertSuccess = () => {
    this.alert = (
      <Alert
        alertType={this.props.existingBooking ? 'Booking updated successfully' : 'Booking submitted'}
        width='500px'
        alertButtonClicked={this.removeBookingForm}
      />
    )
  }

  removeBookingForm = () => {
    this.setState({ changesMade: false }, () => this.props.removeBookingForm())
  }

  updateFormHandler = event => {
    if (event) event.preventDefault()
    if (this.state.submitting || !this.timeIsValid() || !this.filesAttached()) return
    if (this.dateOrTimeHasChanged() && !this.props.currentUser.videographer && !this.props.currentUser.photographer) this.alertNewSubmission()
    else this.updateForm()
  }

  alertNewSubmission = () => {
    this.alert = (
      <Alert
        alertType='Confirm booking update'
        width='500px'
        alertButtonClicked={event => {
          if (event.target.id === 'update') {
            this.alert = null
            this.updateForm()
          }
          if (event.target.id === 'cancel') {
          }
          this.alert = null
          this.forceUpdate()
        }}
      />
    )
    this.forceUpdate()
  }

  updateForm = async event => {
    if (event) event.preventDefault()
    this.setState({ loading: true, submitting: true }, async () => {
      let albumData = this.createAlbumData()
      await this.deleteFiles()
      albumData.files = await uploadFiles(null, this.props.existingBooking)
      updateDoc(doc(db, 'bookings', this.props.existingBooking.id), albumData).catch(error =>
        this.props.setError(error, false, 'Failed to update booking in BookingForm component.')
      )
      await createCalendarItem(albumData, this.props.currentUser, 'updated')
      this.addFormItems()
      this.alertSuccess()
      this.setState({ loading: false, submitting: false })
    })
  }

  deleteFiles = async () => {
    let existingFileNames = this.props.existingBooking.files ? this.props.existingBooking.files.map(existingFile => existingFile.name) : null
    if (existingFileNames) {
      let newFileNames = getInputValue('files').map(newFile => newFile.name) || []
      for (const existingFileName of existingFileNames) {
        if (!newFileNames.includes(existingFileName)) {
          await storage
            .ref(`/bookings/${this.props.existingBooking.id}/${existingFileName}`)
            .delete()
            .catch(error => this.props.setError(error, false, 'Failed to delte files in BookingForm component.'))
        }
      }
    }
  }

  exitForm = event => {
    if (event.target.id === 'exit') {
      this.props.clearBookingFiles()
      this.props.removeBookingForm()
    } else {
      this.alert = null
      this.forceUpdate()
    }
  }

  determineChange = () => {
    let changesMade = false
    let inputs = [
      'name',
      'description',
      'files',
      'venue',
      'campus',
      'contactPerson',
      'contactMobile',
      'contactEmail',
      'interviewees',
      'requirements',
      'storageMediumURL',
      'startTime',
      'endTime',
      'dateOfEvent',
      'startDate'
    ]
    if (this.props.existingBooking) {
      inputs.forEach(input => {
        let value = getInputValue(input)
        if (['dateOfEvent', 'startDate'].includes(input)) {
          if (value && value.toDateString() !== this.props.existingBooking[input].toDate().toDateString()) changesMade = true
        } else if ((value && value !== this.props.existingBooking[input]) || value === '') changesMade = true
      })
      if (this.props.existingBooking.videoBookingType === 'Editing only' && this.state.storageMedium !== this.props.existingBooking.storageMedium)
        changesMade = true
    } else {
      inputs.forEach(input => {
        if (!['dateOfEvent', 'startDate', 'startTime', 'endTime'].includes(input) && getInputValue(input)) changesMade = true
      })
    }
    return changesMade
  }

  filesAttached = () => {
    if (this.props.type === 'Photography') return true
    if (this.state.videoBookingType !== 'Promotional') return true
    if (getInputValue('files') && getInputValue('files').length > 0) {
      this.setState({ filesMissing: false })
      return true
    } else {
      this.setState({ filesMissing: true, loading: false })
      return false
    }
  }

  renderBookingType = () => {
    return (
      <BookingType smallDevice={this.props.smallDevice}>
        <BookingTypeSelector>
          <Input
            type='select'
            id='videoBookingType'
            label='Type of booking'
            value={this.state.videoBookingType}
            onChange={this.bookingTypeChangedHandler}
            width='200px'
            autoFocus
          >
            <option>Event</option>
            <option>Promotional</option>
            <option>Special projects</option>
            <option>Editing only</option>
          </Input>
        </BookingTypeSelector>
        <BookingTypeDescription>{this.bookingTypeDescription}</BookingTypeDescription>
      </BookingType>
    )
  }

  inputChangedHandler = () => {
    this.forceUpdate()
  }

  renderName = () => {
    return (
      <InputLine smallDevice={this.props.smallDevice} margin={this.props.type === 'Photography' ? '20px 0px 10px 0px' : null}>
        <Input
          type='text'
          label={`Name of ${this.props.type === 'Photography' || this.state.videoBookingType === 'Event' ? 'event' : 'project'}`}
          id='name'
          placeholder={
            this.props.smallDevice
              ? 'Please provide a unique and descriptive name'
              : `Please give a full, descriptive and unique name for your ${
                  this.props.type === 'Photography' || this.state.videoBookingType === 'Event' ? 'event' : 'project'
                }`
          }
          autoFocus={this.props.type === 'Photography'}
          value={this.props.existingBooking ? this.props.existingBooking.name : null}
          required
          onChange={this.inputChangedHandler}
        />
      </InputLine>
    )
  }

  renderDescription = () => {
    return (
      <React.Fragment>
        <InputLine smallDevice={this.props.smallDevice} marginBottom='6px'>
          <Input
            type='textarea'
            id='description'
            label={this.props.type === 'Photography' || this.state.videoBookingType === 'Event' ? 'Event description' : 'Project brief'}
            placeholder={`Please describe your ${
              this.props.type === 'Photography' || this.state.videoBookingType === 'Event' ? 'event' : 'project'
            } in detail. This description will be used as a permanent record to provide context for your ${
              this.props.type === 'Photography' || this.state.videoBookingType === 'Event' ? 'event album' : 'project'
            }. Consider using the 5 W's (who, what, when, where, why) when describing your project and please note that an insufficient description may lead to the rejection of your booking.`}
            rows={this.props.smallDevice ? '8' : '3'}
            minLength={120}
            maxLength={300}
            required
            onChange={this.countCharacters}
            value={this.props.existingBooking ? this.props.existingBooking.description : null}
          />
        </InputLine>
        <InputLine justifyContent='right' fontSize='12px' marginBottom='10px' paddingRight={this.props.smallDevice ? '20px' : null}>
          {this.characterCount}
        </InputLine>
      </React.Fragment>
    )
  }

  countCharacters = event => {
    let str = event ? event.target.value : this.props.existingBooking ? this.props.existingBooking.description : ''
    this.characterCount = (
      <CharacterCount color={str.length === 0 ? 'dark' : str.length < 120 || str.length > 300 ? 'danger' : 'dark'}>{`${str.length} characters (${
        str.length < 120 ? 'minimum 120' : 'maximum 300'
      })`}</CharacterCount>
    )
    this.forceUpdate()
  }

  renderFiles = label => {
    let document_formats = '.doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.xls,.xlsm,.xlsx,.ods,.pps,.ppt,.pptx,.odp,.key'
    let image_formats = '.gif,.jpg,.jpeg,.png'
    //let audio_formats = '.mp3,.mpa,.ogg,.wav,.wma'
    return (
      <InputLine>
        <Input
          type='file'
          id='files'
          label={label}
          width='100%'
          invalid={this.state.filesMissing}
          maxSize={8388608} //8MB
          existingValue={this.props.existingBooking ? this.props.existingBooking.files : null}
          onChange={this.inputChangedHandler}
          title={
            label === 'Supporting documents'
              ? 'Add supporting documents like invitations and programmes'
              : label === 'Project script'
              ? 'Please attach your project script here'
              : null
          }
          accept={label === 'Supporting documents' ? `${document_formats},${image_formats}` : label === 'Project script' ? document_formats : null}
        />
      </InputLine>
    )
  }

  renderDateTime = () => {
    let existingBooking = this.props.existingBooking
    return this.props.smallDevice ? (
      <React.Fragment>
        <InputLine>
          <Input
            type='date'
            id='dateOfEvent'
            label={`Date of ${this.props.type === 'Photography' || this.state.videoBookingType === 'Event' ? 'event' : 'project'}`}
            value={existingBooking ? existingBooking.dateOfEvent : null}
            onChange={this.dateOrTimeHasChanged}
          />
        </InputLine>
        <InputLine>
          <Input
            type='time'
            id='startTime'
            label='Start-time'
            width='80px'
            value={this.props.existingBooking ? this.props.existingBooking.startTime : '12:00'}
            onChange={this.dateOrTimeHasChanged}
            max={getInputValue('endTime')}
            valid={this.state.validStartTime}
            required
            margin='0px 20px 0px 0px'
          />
          <Input
            type='time'
            id='endTime'
            label='End-time'
            width='80px'
            value={this.props.existingBooking ? this.props.existingBooking.endTime : '12:00'}
            onChange={this.dateOrTimeHasChanged}
            min={getInputValue('startTime')}
            valid={this.state.validEndTime}
            required
          />
        </InputLine>
      </React.Fragment>
    ) : (
      <InputLine justifyContent='space-between'>
        <Input
          type='date'
          id='dateOfEvent'
          width='50%'
          label={`Date of ${this.props.type === 'Photography' || this.state.videoBookingType === 'Event' ? 'event' : 'project'}`}
          value={existingBooking ? existingBooking.dateOfEvent : null}
          onChange={this.dateOrTimeHasChanged}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-start', columnGap: '20px', width: '45%' }}>
          <Input
            type='time'
            id='startTime'
            label='Start-time'
            width='80px'
            value={this.props.existingBooking ? this.props.existingBooking.startTime : '12:00'}
            onChange={this.dateOrTimeHasChanged}
            max={getInputValue('endTime')}
            valid={this.state.validStartTime}
            required
          />
          <Input
            type='time'
            id='endTime'
            label='End-time'
            width='80px'
            value={this.props.existingBooking ? this.props.existingBooking.endTime : '12:00'}
            onChange={this.dateOrTimeHasChanged}
            min={getInputValue('startTime')}
            valid={this.state.validEndTime}
            margin='0px 0px 0px 20px'
            required
          />
        </div>
      </InputLine>
    )
  }

  renderStartDate = () => {
    let existingBooking = this.props.existingBooking
    return (
      <InputLine>
        <Input
          type='date'
          id='startDate'
          width='50%'
          label={existingBooking && existingBooking.deadline ? 'Project deadline' : 'Project start date'}
          value={existingBooking ? existingBooking.deadline || existingBooking.startDate : null}
          onChange={this.dateOrTimeHasChanged}
        />
      </InputLine>
    )
  }

  dateOrTimeHasChanged = () => {
    this.forceUpdate()
    let existingBooking = this.props.existingBooking
    if (!existingBooking) return false
    if (existingBooking.dateOfEvent && dateHasChanged(existingBooking.dateOfEvent, getInputValue('dateOfEvent'))) return true
    if (existingBooking.deadline && dateHasChanged(existingBooking.deadline, getInputValue('deadline'))) return true
    if (existingBooking.startTime !== getInputValue('startTime')) return true
    if (existingBooking.endTime !== getInputValue('endTime')) return true
    return false

    function dateHasChanged(oldDate, newDate) {
      return oldDate.toDate().toDateString() !== newDate.toDateString()
    }
  }

  timeIsValid = () => {
    if (detectBrowser() !== 'safari') return true
    if (this.props.type === 'Videography' && ['Promotional', 'Editing only'].includes(this.state.videoBookingType)) return true
    let startTime = getInputValue('startTime')
    let endTime = getInputValue('endTime')
    let validStartTime = startTime < endTime && startTime.length > 0
    let validEndTime = startTime < endTime && endTime.length > 0

    this.setState({ validStartTime, validEndTime }, () => {
      if (!validStartTime || !validEndTime) {
        document.getElementById('startTime').scrollIntoView()
        document.getElementById('bookingDetails').scrollBy(0, -30)
      }
    })

    return validStartTime && validEndTime
  }

  renderVenue = () => {
    return (
      <InputLine justifyContent='space-between'>
        <Input
          type='datalist'
          id='venue'
          label='Venue'
          width='45%'
          required
          value={this.props.existingBooking ? this.props.existingBooking.venue : null}
          onChange={() => this.forceUpdate()}
        >
          {this.props.approved.venue || <option value={null}></option>}
        </Input>
        <Input
          type='select'
          id='campus'
          label='Campus'
          width='45%'
          required
          value={this.props.existingBooking ? this.props.existingBooking.campus : null}
          onChange={() => this.forceUpdate()}
        >
          <option value={null} />
          <option>APB</option>
          <option>APK</option>
          <option>DFC</option>
          <option>SWC</option>
          <option>Off campus</option>
        </Input>
      </InputLine>
    )
  }

  renderContactPerson = () => {
    return (
      <React.Fragment>
        <InputLine>
          <Input
            type='text'
            id='contactPerson'
            label={`Contact person ${this.props.type === 'Photography' || this.state.videoBookingType === 'Event' ? 'at event' : 'for project'}`}
            width='45%'
            value={this.props.existingBooking ? this.props.existingBooking.contactPerson : null}
            required
            onChange={() => this.forceUpdate()}
          />
        </InputLine>
        <InputLine justifyContent='space-between'>
          <Input
            type='mobile'
            id='contactMobile'
            value={this.props.existingBooking ? this.props.existingBooking.contactMobile : null}
            label="Contact person's mobile number"
            width='45%'
            required
            onChange={() => this.forceUpdate()}
          />
          <Input
            type='email'
            id='contactEmail'
            label="Contact person's email address"
            autoComplete='off'
            width='45%'
            required
            pattern='none'
            placeholder=' '
            value={this.props.existingBooking ? this.props.existingBooking.contactEmail : null}
            onChange={() => this.forceUpdate()}
          />
        </InputLine>
      </React.Fragment>
    )
  }

  renderRequirements = () => {
    return (
      <InputLine>
        <Input
          type='textarea'
          id='requirements'
          label='Notes / Additional requirements'
          rows='1'
          value={this.props.existingBooking ? this.props.existingBooking.requirements : null}
          onChange={() => this.forceUpdate()}
        />
      </InputLine>
    )
  }

  renderInterviewees = () => {
    return (
      <InputLine>
        <Input type='text' id='interviewees' label='Who will be available for recorded interviews?' onChange={() => this.forceUpdate()} />
      </InputLine>
    )
  }

  renderStorageMedium = () => {
    return (
      <React.Fragment>
        <InputLine marginBottom='0px'>
          <Input
            type='checkbox'
            id='externalStorage'
            onChange={event => {
              if (event.target.checked) return false
              this.setState({
                storageMedium: event.target.id
              })
            }}
            label='How will you share the video footage with us?'
            text='External storage medium'
            checked={this.state.storageMedium === 'externalStorage'}
            manual
          />
        </InputLine>
        <InputLine justifyContent='flex-start'>
          <Input
            type='checkbox'
            id='cloudStorage'
            onChange={event => {
              if (event.target.checked) return false
              this.setState({
                storageMedium: event.target.id
              })
            }}
            text='Cloud storage'
            checked={this.state.storageMedium === 'cloudStorage'}
            width='300px'
            manual
          />
          {this.state.storageMedium === 'cloudStorage' ? (
            <Input
              type='text'
              id='storageMediumURL'
              value={this.props.existingBooking ? this.props.existingBooking.storageMediumURL : ''}
              placeholder='Please enter the URL where the footage is located'
              required={this.state.storageMedium === 'cloudStorage'}
              onChange={() => this.forceUpdate()}
            />
          ) : null}
        </InputLine>
      </React.Fragment>
    )
  }

  renderTerms = () => {
    let existingBooking = this.props.existingBooking
    this.showTerms = !existingBooking || (existingBooking && existingBooking.clientEmail === this.props.currentUser.email && this.determineChange())
    return this.showTerms ? (
      <InputLine margin='10px 0px 0px 0px'>
        <Terms smallDevice={this.props.smallDevice}>
          {`I, ${this.props.currentUser.fullName}, hereby accept the `}
          <TextLink
            fontWeight='bold'
            underlined
            clicked={() => {
              this.props.type === 'Photography' ? this.props.showPoliciesModal() : this.props.showVideoPoliciesModal()
            }}
          >
            terms and conditions
          </TextLink>
          {` for making this booking and confirm that the end product will be used for marketing purposes only.`}
        </Terms>
      </InputLine>
    ) : null
  }

  renderButtons = () => {
    let existingBooking = this.props.existingBooking
    let buttonMode
    let isDisabled = false
    if (existingBooking) {
      if (this.determineChange()) {
        buttonMode = 'update'
        isDisabled =
          !this.state.consentAccepted && !this.props.currentUser.photographer && !this.props.currentUser.videographer && !this.props.currentUser.admin
      } else buttonMode = 'exit'
    } else {
      buttonMode = 'submit'
      isDisabled = !this.state.consentAccepted
    }

    return (
      <InputLine justifyContent='center' columnGap='20px' paddingBottom='20px'>
        {this.showTerms && (
          <Input
            type='checkbox'
            text='I agree'
            id='consent'
            width='80px'
            onChange={event => {
              this.setState({ consentAccepted: event.target.value })
            }}
          />
        )}
        <Input
          type='button'
          buttonType={buttonMode === 'exit' ? 'button' : 'submit'}
          onClick={buttonMode === 'exit' ? this.exitForm : null}
          id={buttonMode}
          width='100px'
          disabled={isDisabled}
        >
          {buttonMode === 'exit' ? 'Done' : buttonMode === 'update' ? 'Update' : 'Submit'}
        </Input>
        {buttonMode === 'update' && (
          <Input type='button' buttonType='button' onClick={this.exitForm} id='exit' width='100px'>
            Cancel
          </Input>
        )}
      </InputLine>
    )
  }

  render() {
    let smallDevice = this.props.smallDevice

    return (
      <React.Fragment>
        {this.state.loading ? (
          <React.Fragment>
            <Loader bringToFront />
            <Backdrop loading='true' />
          </React.Fragment>
        ) : null}
        <Backdrop
          id='backdrop'
          onClick={() => {
            if (this.determineChange()) {
              this.alert = <Alert alertType='Confirm exit' width='400px' height='200px' alertButtonClicked={this.exitForm} />
              this.forceUpdate()
            } else this.exitForm({ target: { id: 'exit' } })
          }}
        />
        <StyledBookingForm
          id='bookingForm'
          smallDevice={smallDevice}
          typeSelected={this.state.videoBookingType}
          onSubmit={this.props.existingBooking ? this.updateFormHandler : this.submitForm}
        >
          <Title smallDevice={smallDevice}>{`${this.props.type} Booking Form`}</Title>

          <BookingDetails smallDevice={smallDevice} tabIndex='-1' id='bookingDetails'>
            {this.props.type === 'Videography' ? this.renderBookingType() : null}
            {this.renderName()}
            {this.renderDescription()}

            {this.props.type === 'Videography' ? (
              this.state.videoBookingType === 'Event' ? (
                <React.Fragment>
                  {this.renderDateTime()}
                  {this.renderVenue()}
                  {this.renderFiles('Supporting documents')}
                </React.Fragment>
              ) : this.state.videoBookingType === 'Promotional' ? (
                <React.Fragment>
                  {this.renderStartDate()}
                  {this.renderFiles('Project script')}
                  {this.renderInterviewees()}
                </React.Fragment>
              ) : this.state.videoBookingType === 'Special projects' ? (
                <React.Fragment>
                  {this.renderDateTime()}
                  {this.renderVenue()}
                </React.Fragment>
              ) : this.state.videoBookingType === 'Editing only' ? (
                <React.Fragment>
                  {this.renderStartDate()}
                  {this.renderStorageMedium()}
                </React.Fragment>
              ) : null
            ) : null}

            {this.props.type === 'Photography' ? (
              <React.Fragment>
                {this.renderDateTime()}
                {this.renderVenue()}
                {this.renderFiles('Supporting documents')}
              </React.Fragment>
            ) : null}

            {this.renderContactPerson()}
            {this.renderRequirements()}
            {this.renderTerms()}
            {this.renderButtons()}
          </BookingDetails>
        </StyledBookingForm>
        {this.alert}
      </React.Fragment>
    )
  }
}

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.bg};
  opacity: 0.5;
  z-index: ${props => (props.loading ? '4' : '2')};
`

const StyledBookingForm = styled.form`
  position: absolute;
  justify-self: center;
  align-self: center;
  width: ${props => (props.smallDevice ? 'calc(100% - 20px)' : '755px')};
  height: auto;
  max-height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  background-color: ${props => props.theme.light};
  border: 2px solid ${props => props.theme.dark};
  border-radius: 5px;
  color: ${props => props.theme.dark};
  z-index: 3;
`

const Title = styled.div`
  position: sticky;
  top: 0px;
  width: 100%;
  height: ${props => (props.smallDevice ? '30px' : '42px')};
  padding: 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.main};
  color: ${props => props.theme.dark};
  font-size: ${props => (props.smallDevice ? '20px' : '24px')};
  border-bottom: 1px solid ${props => props.theme.dark};
`

const BookingDetails = styled.div`
  position: relative;
  display: flex;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${props => (props.smallDevice ? '0px 20px' : '0px 40px')};
`

const InputLine = styled.div`
  position: relative;
  width: ${props => (props.width || props.smallDevice ? '96%' : '100%')};
  display: flex;
  flex-shrink: 0;
  column-gap: ${props => props.columnGap || null};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'center'};
  color: ${props => props.theme.dark};
  font-size: ${props => props.fontSize || null};
  align-items: center;
  margin: ${props => props.margin || null};
  margin-bottom: ${props => props.marginBottom || '10px'};
  padding-right: ${props => props.paddingRight || null};
  padding-bottom: ${props => props.paddingBottom || null};
`

const CharacterCount = styled.div`
  color: ${props => props.theme[props.color]};
`

const BookingType = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
`

const BookingTypeSelector = styled.div`
  position: relative;
  flex-shrink: 0;
`

const BookingTypeDescription = styled.div`
  position: relative;
  width: 98%;
  height: auto;
  font-size: 14px;
  margin: auto;
  overflow: hidden;
  text-overflow: wrap;
  text-align: justify;
  color: ${props => props.theme.dark};
`

const Terms = styled.span`
  position: relative;
  width: 100%;
  display: block;
  text-align: justify;
  color: ${props => props.theme.dark};
  padding: ${props => (props.smallDevice ? '0px 10px' : '0px 20px')};
  font-size: ${props => (props.smallDevice ? '14px' : '16px')};
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    smallDevice: state.ui.smallDevice,
    approved: state.forms.approved,
    files: state.bookings.files
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showPoliciesModal: () => dispatch(actions.showPoliciesModal()),
    showVideoPoliciesModal: () => dispatch(actions.showVideoPoliciesModal()),
    addPendingFormItems: newItems => dispatch(actions.addPendingFormItems(newItems)),
    clearBookingFiles: () => dispatch(actions.clearBookingFiles()),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingForm)
