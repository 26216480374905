import * as actions from 'store/actions/actionTypes'
import { updateObject } from 'utils/shared'

const initialState = {
  albums: null,
  lastVisible: null,
  allAlbumsRetrieved: false,
  scrollPosition: 0,
}

const storeAlbums = (state, action) => {
  let newArray = [...action.albumsArray]
  return updateObject(state, {
    albums: newArray,
    lastVisible: action.lastVisible,
    allAlbumsRetrieved: action.allAlbumsRetrieved,
  })
}

const saveScrollPosition = (state, action) => {
  return updateObject(state, { scrollPosition: action.scrollPosition })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.STORE_ALBUMS:
      return storeAlbums(state, action)
    case actions.SAVE_SCROLL_POSITION:
      return saveScrollPosition(state, action)
    default:
      return state
  }
}

export default reducer
