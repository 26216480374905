import * as actions from 'store/actions/actionTypes'
import { updateObject } from 'utils/shared'

const initialState = {
  showMenu: false,
  showAuthModal: false,
  showChangePasswordModal: false,
  showConfirmDeleteAccountModal: false,
  awaitingDownload: false,
  showPoliciesModal: false,
  showVideoPoliciesModal: false,
  smallDevice: false,
  smallDeviceLandscape: false,
  inputMethod: 'touch',
  showSearchBar: false
}

const showMenu = state => {
  return updateObject(state, { showMenu: !state.showMenu })
}

const toggleShowSearchBar = state => {
  return updateObject(state, { showSearchBar: !state.showSearchBar })
}

const showAuthModal = (state, action) => {
  return updateObject(state, {
    showAuthModal: action.value
  })
}

const showChangePasswordModal = state => {
  return updateObject(state, {
    showChangePasswordModal: !state.showChangePasswordModal
  })
}

const showConfirmDeleteAccountModal = state => {
  return updateObject(state, {
    showConfirmDeleteAccountModal: !state.showConfirmDeleteAccountModal
  })
}

const showPoliciesModal = state => {
  return updateObject(state, {
    showPoliciesModal: !state.showPoliciesModal
  })
}

const showVideoPoliciesModal = state => {
  return updateObject(state, {
    showVideoPoliciesModal: !state.showVideoPoliciesModal
  })
}

const setSmallDevice = (state, action) => {
  return updateObject(state, {
    smallDevice: action.status
  })
}

const setSmallDeviceLandscape = (state, action) => {
  return updateObject(state, {
    smallDeviceLandscape: action.status
  })
}

const setInputMethod = (state, action) => {
  return updateObject(state, {
    inputMethod: action.inputType
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SHOW_MENU:
      return showMenu(state)
    case actions.TOGGLE_SHOW_SEARCH_BAR:
      return toggleShowSearchBar(state)
    case actions.SHOW_CHANGE_PASSWORD_MODAL:
      return showChangePasswordModal(state)
    case actions.SHOW_AUTH_MODAL:
      return showAuthModal(state, action)
    case actions.SHOW_CONFIRM_DELETE_ACCOUNT_MODAL:
      return showConfirmDeleteAccountModal(state)
    case actions.SHOW_POLICIES_MODAL:
      return showPoliciesModal(state)
    case actions.SHOW_VIDEO_POLICIES_MODAL:
      return showVideoPoliciesModal(state)
    case actions.SET_SMALL_DEVICE:
      return setSmallDevice(state, action)
    case actions.SET_SMALL_DEVICE_LANDSCAPE:
      return setSmallDeviceLandscape(state, action)
    case actions.SET_INPUT_METHOD:
      return setInputMethod(state, action)
    default:
      return state
  }
}

export default reducer
