import * as actions from 'store/actions/actionTypes'
import { updateObject } from 'utils/shared'

const initialState = {
  existingBooking: null,
  files: {}
}

const setExistingBooking = (state, action) => {
  return updateObject(state, { existingBooking: action.booking })
}

const setFiles = (state, action) => {
  let newFilesObject = {...state.files}
  newFilesObject[action.id] = action.files
  return updateObject(state, { files: newFilesObject} )
}

const clearBookingFiles = (state) => {
  return updateObject(state, { files: {} })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_EXISTING_BOOKING:
      return setExistingBooking(state, action)
    case actions.SET_FILES:
      return setFiles(state, action)
    case actions.CLEAR_BOOKING_FILES:
        return clearBookingFiles(state)
    default:
      return state
  }
}

export default reducer
