import * as actionTypes from 'store/actions/actionTypes'
import * as actions from 'store/actions'
import store from 'store/store'
import { db } from 'utils/firebase'
import { query, collection, getDocs, where, orderBy, limit, updateDoc, doc, startAfter, getDoc, increment } from 'firebase/firestore'

var prevSearchQuery = null
var searchQuery
var albumResults
var photoResults
var lastPhoto
var allPhotosObtained = false

export const search = _searchQuery => {
  searchQuery = _searchQuery
  return async dispatch => {
    if (searchQuery === prevSearchQuery) return dispatch(updateStore(searchQuery, photoResults, albumResults))
    if (!searchQuery) return dispatch(updateStore(null))
    init(searchQuery)

    await Promise.all([searchAlbums(), searchPhotos()])
    updateAnalytics(searchQuery)

    return dispatch(updateStore(searchQuery, photoResults, albumResults))
  }
}

function init(searchQuery) {
  prevSearchQuery = searchQuery
  albumResults = null
  photoResults = null
  lastPhoto = null
  allPhotosObtained = false
}

async function searchAlbums() {
  return (albumResults = await getDocs(query(collection(db, 'albums'), where('keys', 'array-contains', searchQuery), orderBy('date', 'desc')))
    .then(albums => albums.docs.map(album => album.data()))
    .catch(error => store.dispatch(actions.setError(error, true, 'Failed to search for albums in the Search component.'))))
}

async function searchPhotos() {
  return (photoResults = await getDocs(
    query(collection(db, 'photos'), where('keys', 'array-contains-any', searchQuery.split(' ')), orderBy('dateTaken', 'desc'), limit(50))
  )
    .then(photos => {
      if (photos.docs.length < 50) allPhotosObtained = true
      return photos.docs.map((photo, index) => {
        if (index === photos.docs.length - 1) lastPhoto = photo
        return photo.data()
      })
    })
    .catch(error => store.dispatch(actions.setError(error, true, 'Failed to search for photos in the Search component.'))))
}

function updateAnalytics(searchQuery) {
  updateDoc(doc(db, 'analytics', 'searchTerms'), {
    [`${searchQuery}.count`]: increment(1),
    [`${searchQuery}.results`]: (photoResults ? photoResults.length : 0) + (albumResults ? albumResults.length : 0)
  }).catch(error => store.dispatch(actions.setError(error, false, 'Failed to update search analytics.')))
}

export const showMorePhotoResults = () => {
  return async dispatch => {
    if (allPhotosObtained) return
    photoResults = photoResults.concat(
      await getDocs(
        query(
          collection(db, 'photos'),
          where('keys', 'array-contains-any', searchQuery.split(' ')),
          orderBy('dateTaken', 'desc'),
          startAfter(lastPhoto),
          limit(50)
        )
      )
        .then(photos => {
          if (photos.docs.length < 50) allPhotosObtained = true
          return photos.docs.map((photo, index) => {
            if (index === photos.docs.length - 1) lastPhoto = photo
            return photo.data()
          })
        })
        .catch(error => store.dispatch(actions.setError(error, false, 'Failed to show more photo results in the Search component.')))
    )
    if (allPhotosObtained) return
    return dispatch(updatePhotoResults(photoResults))
  }
}

const updatePhotoResults = photoResults => {
  return {
    type: actionTypes.UPDATE_PHOTO_RESULTS,
    photoResults
  }
}

export const getSearchKeys = () => {
  return async dispatch => {
    let searchKeys = []
    await getDoc(doc(db, 'formData', 'searchKeys'))
      .then(res => {
        Object.values(res.data()).forEach(keyArray => {
          searchKeys = searchKeys.concat(keyArray)
          searchKeys = [...new Set(searchKeys)]
          searchKeys.sort()
        })
      })
      .catch(error => {
        return store.dispatch(actions.setError(error, false, 'Failed to get search keys from the database in the Search component.'))
      })
    return dispatch(setSearchKeys(searchKeys))
  }
}

const setSearchKeys = searchKeys => {
  return {
    type: actionTypes.SET_SEARCH_KEYS,
    searchKeys
  }
}

const updateStore = (searchQuery, photoResults, albumResults) => {
  return {
    type: actionTypes.SEARCH,
    searchQuery,
    photoResults,
    albumResults
  }
}
