import { Component } from 'react'
import styled from 'styled-components'

class Checkbox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: this.props.checked || false
    }

    this.validateProps()
  }

  componentDidMount() {
    document.getElementById(this.props.id).value = this.state.checked
  }

  lastGivenCheckedValue = this.props.checked
  componentDidUpdate() {
    if (this.props.manual && this.props.checked !== this.lastGivenCheckedValue) {
      this.lastGivenCheckedValue = this.props.checked
      document.getElementById(this.props.id).value = this.props.checked
      this.setState({ checked: this.props.checked })
    }
  }

  validateProps = () => {
    if (!this.props.text) throw new Error("<Input type='checkbox' /> must have a text property")
  }

  onFocusHandler = event => {
    document.addEventListener('keydown', this.checkboxClickedHandler)
    if (this.props.onFocus) this.props.onFocus(event)
  }

  onBlurHandler = event => {
    document.removeEventListener('keydown', this.checkboxClickedHandler)
    if (this.props.onBlur) this.props.onBlur(event)
  }

  checkboxClickedHandler = event => {
    if (event.target.className === 'material-icons') {
      document.getElementById(this.props.id).click()
      return false
    }
    if (event.type === 'click' || event.keyCode === 32) {
      if (!this.props.manual) {
        this.setState({ checked: !this.state.checked }, () => {
          document.getElementById(this.props.id).value = this.state.checked
          if (this.props.onChange) this.props.onChange(event)
        })
      }
      else if (this.props.onChange) this.props.onChange(event)
    }
  }

  render() {
    return (
      <Container
        onClick={this.checkboxClickedHandler}
        onFocus={this.onFocusHandler}
        onBlur={this.onBlurHandler}
        tabIndex='0'
        id={this.props.id}
        type='checkbox'
        value={this.state.checked}
        checked={this.state.checked}
        required={this.props.required}
        onChange={this.props.onChange}
        smallDevice={this.props.smallDevice}
        fontSize={this.props.fontSize}
        justify={this.props.justify || null}
      >
        <CheckBox>
          {<i className='material-icons'>{this.state.checked ? 'check_box' : 'check_box_outline_blank'}</i>}
        </CheckBox>
        {this.props.text}
      </Container>
    )
  }
}

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: center;
  user-select: none;
  padding: 5px 5px;
  font-size: ${props => props.fontSize || 'inherit'};

  :hover {
    cursor: default;
  }

  :focus {
    border: 1px dotted ${props => props.theme.dark};
    padding: 4px 4px;
    outline: none;
  }
`

const CheckBox = styled.div`
  font-size: 22px;
  height: ${props => (props.smallDevice ? '18px' : '22px')};
  margin-right: 2px;
`

export default Checkbox
