import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from 'store/actions'
import Modal from 'components/Modal'
import Text from 'components/Text'
import Button from 'components/Button'

class PoliciesModal extends Component {
  componentDidMount() {
    if (document.getElementById('policiesModal')) {
      document.getElementById('policiesModal').scrollTo(0, 0)
    }
  }

  backdropClicked = event => {
    if (event.target.id === 'modalBackdrop') {
      this.props.showPoliciesModal()
    }
  }

  buttonClicked = () => {
    this.props.showPoliciesModal()
  }

  render() {
    return (
      <Modal
        backdropClicked={this.backdropClicked}
        title="Service Policies"
        width="80%"
        height="70%"
        scroll="auto"
        modalId="policiesModal"
        smallDevice={this.props.smallDevice}
      >
        <Policies smallDevice={this.props.smallDevice}>
          <Text>
            Photographic Services exist to provide high quality photographic
            support to all faculties and departments within the University. We
            strive to be professional, punctual and to adhere to strict
            turnaround times so that our clients receive quality photographs
            within a limited waiting period in order to promote their events on
            any internal or external platform. To maintain an ongoing and
            acceptable quality of service, some policies are in place to ensure
            that a mutual understanding exists between ourselves and our
            clients.
          </Text>
          <Break />
          <Table smallDevice={this.props.smallDevice}>
            <tbody>
              <Row>
                <Number>1.</Number>
                <Policy colSpan="3" fontWeight="bold" alignLeft>
                  SCOPE OF WORK
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                <Number />
                <Policy colSpan="3">
                  Photographs will only be taken if they directly serve the
                  purpose of marketing or promoting the brand of the University.
                  Careful consideration of the above will determine which events
                  will be attended and for how long. Photography services are
                  available to all staff members as well as the Student
                  Representative Council but not to students.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                <Number>2.</Number>
                <Policy colSpan="3" fontWeight="bold" alignLeft>
                  THE BOOKING PROCESS
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>2.1</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  No photographs will be taken without the successful completion
                  of the online booking process as set out below:
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                {this.props.smallDevice ? null : <Number />}
                <Number>2.1.1</Number>
                <Policy>A client completes an online booking form.</Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                {this.props.smallDevice ? null : <Number />}
                <Number>2.1.2</Number>
                <Policy>
                  Once the online booking has been submitted, all available
                  photographers will be notified of the event electronically by
                  means of an Outlook calendar item. The client will also
                  receive an electronic record of the booking request via email.
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                {this.props.smallDevice ? null : <Number />}
                <Number>2.1.3</Number>
                <Policy>
                  After being notified of an event, photographers will have an
                  opportunity to consult with the client and make amendments to
                  the booking if required.
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                {this.props.smallDevice ? null : <Number />}
                <Number>2.1.4</Number>
                <Policy>
                  At this point, the photographers will either accept or reject
                  a booking, after which the client will immediately be notified
                  of the outcome via email. In case of a booking being accepted,
                  the client will also receive an Outlook calendar item that
                  will contain the details of the booking.
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>2.2</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  Photographic Services operate on a strict 'first come, first
                  served' basis. It is the responsibility of the client to
                  ensure that booking requests are made as soon as events have
                  been planned in order to maximise the odds of a photographer
                  being available. In cases where two events take place at the
                  same time, attempts will be made to accommodate both parties.
                  Any change of the agreed booking times will be at the
                  discretion of the client who has made the first booking. If no
                  agreement can be made, an external photographer may be
                  contracted by the client who has made the second booking.
                  Arranging and paying for such a photographer is the sole
                  responsibility of the client who has made the second booking.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                <Number>3.</Number>
                <Policy colSpan="3" fontWeight="bold" alignLeft>
                  ACCESS TO PHOTOGRAPHS
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                <Number />
                <Policy colSpan="3">
                  Upon the completion of an assignment, a selection of the best
                  photographs will be posted to the UJ Photo Library. These
                  photographs will be accessible and free to use immediately for
                  all staff members within the University. Under no
                  circumstances can any image be sold to any party without the
                  written consent of Photographic Services.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                <Number>4.</Number>
                <Policy colSpan="3" fontWeight="bold" alignLeft>
                  STORAGE OF PHOTOGRAPHS
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                <Number />
                <Policy colSpan="3">
                  Photographs will be stored on the UJ Photo Library for a
                  minimum of one year, after which they will be sent for
                  archiving at the UJ Library's Archives and Special Collections
                  on the Doornfontein Campus. Photographic Services will not in
                  any way be held responsible for the storing or retrieval of
                  archived images.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                <Number>5.</Number>
                <Policy colSpan="3" fontWeight="bold" alignLeft>
                  STAFF PHOTOS
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                <Number />
                <Policy colSpan="3">
                  Staff photos (staff profiles) for use on the UJ website can
                  only be done at the Photographic Studio on APK. This is to
                  ensure uniformity on the official website of the University.
                  Sessions will be held monthly and interested staff will be
                  notified as soon as sessions are scheduled.
                </Policy>
              </Row>
              <BlankRow />
              <BlankRow />
              <Row>
                <Number>6.</Number>
                <Policy colSpan="3" fontWeight="bold" alignLeft>
                  COST OF SERVICE
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                <Number />
                <Policy colSpan="3">
                  Our services are free of charge to all UJ staff members as
                  long as their events take place on UJ property and during
                  business hours (08:00 to 16:30). Standard AA-rates will apply
                  for transport to off-campus events. For after hours events, an
                  overtime fee will be applicable and will be charged as 'time
                  away from home', thus including travel time to and from the
                  event. After hours events that are cancelled less than 24
                  hours before the start time of the event will be charged at
                  the full rate.
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                <Number />
                <Policy colSpan="3">Overtime rates are as follows:</Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>6.1</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  Monday to Thursday:{' '}
                  <Text fontWeight="bold">R220 per hour</Text>
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>6.2</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  Fridays, Saturdays and eves of public holidays:{' '}
                  <Text fontWeight="bold">R320 per hour</Text>
                </Policy>
              </Row>
              <BlankRow />
              <Row>
                {this.props.smallDevice ? null : <Number />}
                <Number>6.3</Number>
                <Policy colSpan={this.props.smallDevice ? '4' : '2'}>
                  Sundays and public holidays:{' '}
                  <Text fontWeight="bold">R420 per hour</Text>
                </Policy>
              </Row>
            </tbody>
          </Table>
          <Break />
          <Break />
          <ButtonDiv>
            <Button type="button" onMouseUp={this.buttonClicked} width="100px">
              OK
            </Button>
          </ButtonDiv>
        </Policies>
      </Modal>
    )
  }
}

const Policies = styled.div`
  position: absolute;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${props => props.smallDevice ? '100%' : 'calc(100% - 60px)'};
  height: 100%;
  padding: ${props => (props.smallDevice ? '10px 6px' : '10px 20px')};
  font-size: 20px;
  text-align: left;
  `

const Break = styled.br`
  margin: 14px 0px;
`

const Table = styled.table`
  margin: ${props => (props.smallDevice ? '4px 4px' : '4px 20px')};
  padding-right: 20px;
  width: 100%;
  font-size: 18px;
`

const Row = styled.tr`
  height: 30px;
`

const BlankRow = styled.tr`
  height: 12px;
`

const Number = styled.td`
  width: 50px;
  text-align: left;
  vertical-align: top;
`

const Policy = styled.td`
  text-align: ${props => (props.alignLeft ? 'left' : 'justify')};
  vertical-align: top;
  font-weight: ${props => props.fontWeight || null};
`

const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const mapStateToProps = state => {
  return {
    smallDevice: state.ui.smallDevice
  }
}
const mapDispatchToProps = dispatch => {
  return {
    showPoliciesModal: () => dispatch(actions.showPoliciesModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PoliciesModal)
