import ReactDOMServer from 'react-dom/server'
import { functions } from 'utils/firebase'
import * as actionTypes from 'store/actions/actionTypes'
import store from 'store/store'

var retries

export const setError = (error, breaking, customMessage) => {
  return async dispatch => {
    const errorObject = {
      error: error,
      userName: store.getState().auth.currentUser && (store.getState().auth.currentUser.name || 'Not signed in'),
      userEmail: store.getState().auth.currentUser && (store.getState().auth.currentUser.email || 'Not signed in'),
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
      url: window.location.href,
      customMessage: customMessage
    }
    const message = createMessage(errorObject, breaking)

    retries = 0
    await sendMailViaCloudFunction(error, message)

    return dispatch({
      type: actionTypes.SET_ERROR,
      error: errorObject,
      breaking: breaking
    })
  }
}

export const clearError = () => {
  return {
    type: actionTypes.CLEAR_ERROR
  }
}

function createMessage(errorObject, breaking) {
  return ReactDOMServer.renderToStaticMarkup(
    <html
      style={{
        height: '100% !important',
        width: 'auto !important',
        margin: '0 auto !important',
        padding: '0 !important'
      }}
    >
      <head>
        <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet' />
      </head>
      <body
        style={{
          height: '100% !important',
          width: 'auto !important',
          margin: '0 auto !important',
          padding: '10px 10px !important',
          msoLineHeightRule: 'exactly',
          lineHeight: '90%',
          fontFamily: 'Roboto, Arial'
        }}
      >
        <table
          id='body'
          style={{
            height: '100%',
            width: '100%',
            tableLayout: 'fixed'
          }}
        >
          <tbody>
            <tr>
              <td>
                <table
                  id='container'
                  style={{
                    width: '100%',
                    maxWidth: '1000px',
                    backgroundColor: '#e0dede',
                    fontSize: '14px',
                    color: '#57575a',
                    border: '2px solid #57575a',
                    borderCollapse: 'collapse',
                    tableLayout: 'fixed'
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          id='title'
                          style={{
                            tableLayout: 'fixed',
                            width: '100%',
                            backgroundColor: '#e0dede',
                            border: '0',
                            borderCollapse: 'collapse',
                            fontSize: '22px',
                            color: '#57575a'
                          }}
                        >
                          <tbody>
                            <tr style={{ height: '40px' }}>
                              <td style={{ width: '20px' }}></td>
                              <td>
                                <strong>{`UJDML Error: ${errorObject.error.name || 'Unnamed'}`}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          style={{
                            backgroundColor: '#f4f4f4',
                            tableLayout: 'fixed',
                            border: '0',
                            borderCollapse: 'collapse',
                            width: '100%',
                            color: '#57575a'
                          }}
                          id='body'
                        >
                          <tbody>
                            <tr>
                              <td style={{ fontSize: '14px', padding: '20px 20px 20px 20px', lineHeight: '16px' }}>
                                {breaking ? (
                                  <strong style={{ color: 'red' }}>A breaking error has occurred on the UJDML!</strong>
                                ) : (
                                  <p>An error has occured on the UJDML:</p>
                                )}
                                <br />
                                <p>
                                  <strong>Date: </strong>
                                  {` ${errorObject.date}`}
                                </p>
                                <p>
                                  <strong>Time: </strong>
                                  {` ${errorObject.time}`}
                                </p>
                                <p>
                                  <strong>URL: </strong>
                                  {` ${errorObject.url}`}
                                </p>
                                <p>
                                  <strong>User name: </strong>
                                  {` ${errorObject.userName}`}
                                </p>
                                <p>
                                  <strong>User email: </strong>
                                  {` ${errorObject.userEmail}`}
                                </p>
                                <br />
                                {errorObject.error.name && (
                                  <p>
                                    <strong>Error name: </strong>
                                    {` ${errorObject.error.name}`}
                                  </p>
                                )}

                                {errorObject.error.code && (
                                  <p>
                                    <strong>Error code: </strong>
                                    {` ${errorObject.error.code}`}
                                  </p>
                                )}
                                {errorObject.error.message && (
                                  <p>
                                    <strong>Error message: </strong>
                                    {` ${errorObject.error.message}`}
                                  </p>
                                )}
                                {errorObject.customMessage && (
                                  <p>
                                    <strong>Custom message: </strong>
                                    {` ${errorObject.customMessage}`}
                                  </p>
                                )}
                                {errorObject.stack && (
                                  <p>
                                    <strong>Stack: </strong>
                                    {` ${errorObject.stack}`}
                                  </p>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          id='footer'
                          style={{
                            backgroundColor: '#e0dede',
                            width: '100%',
                            cellPadding: '0',
                            cellSpacing: '0',
                            border: '0',
                            borderCollapse: 'collapse',
                            tableLayout: 'fixed',
                            padding: '0',
                            margin: '0'
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ width: '40px' }}>
                                <a href='https://ujdml.co.za/' style={{ textDecoration: 'none' }}>
                                  <img
                                    src='https://ujdml.co.za/icon-72x72.png'
                                    height='40'
                                    width='40'
                                    style={{ height: '40px', padding: '0', margin: '0' }}
                                    alt='uj-logo'
                                  />
                                </a>
                              </td>
                              <td style={{ width: '6px' }}></td>
                              <td style={{ paddingTop: '6px' }}>
                                <a href='https://ujdml.co.za/' style={{ textDecoration: 'none' }}>
                                  <span
                                    style={{
                                      color: '#57575a',
                                      fontSize: '22px'
                                    }}
                                  >
                                    <strong>UJ Digital Media Library</strong>
                                  </span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  )
}

async function sendMailViaCloudFunction(error, message) {
  return await functions
    .httpsCallable('sendMail')({
      toRecipients: [{ emailAddress: { address: store.getState().config.webAdmin && store.getState().config.webAdmin[0].email } }],
      subject: `UJDML Error: ${error.name || error.code || 'Unnamed'}`,
      messageContent: message
    })
    .then(() => 'SUCCESS')
    .catch(async () => {
      if (retries < 4) {
        retries++
        setTimeout(async () => {
          return await sendMailViaCloudFunction(error, message)
        }, 400)
      } else return 'FAILED'
    })
}
