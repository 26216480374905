import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Thumbnail from 'components/Thumbnail'

class PhotoCard extends Component {
  touchStartHandler = event => {
    if (this.isMultiFingerTouch(event)) return
    event.preventDefault()
    this.recordFirstTouch(event)
    this.recordLastTouch(event)
    this.startTouchTimer(event)
  }

  touchMoveHandler = event => {
    event.preventDefault()
    if (this.isMultiFingerTouch(event)) return this.clearTouchTimer()
    this.recordLastTouch(event)
    if (this.isScrolling()) this.clearTouchTimer()
  }

  touchEndHandler = event => {
    event.preventDefault()
    this.clearTouchTimer()
    if (this.isScrolling()) return
    if (!this.isClick(event)) return
    this.props.onClick(event)
    if (event.target.id.includes('download')) this.props.download(event)
    if (event.target.id.includes('collection')) this.props.removeFromCollection ? this.props.removeFromCollection(event) : this.props.addToCollection(event)
  }

  isMultiFingerTouch(event) {
    return event.touches.length > 1
  }

  recordFirstTouch(event) {
    this.touchStartTime = event.timeStamp
    this.startX = event.touches[0].screenX
    this.startY = event.touches[0].screenY
  }

  recordLastTouch(event) {
    this.currentX = event.touches[0].screenX
    this.currentY = event.touches[0].screenY
  }

  isScrolling() {
    const movementX = Math.abs(this.currentX - this.startX)
    const movementY = Math.abs(this.currentY - this.startY)

    return movementX > 10 || movementY > 10
  }

  isClick(event) {
    return event.timeStamp - this.touchStartTime < 300
  }

  startTouchTimer(event) {
    this.touchTimer = setTimeout(() => this.props.select(event), 300)
  }

  clearTouchTimer() {
    if (this.touchTimer) clearTimeout(this.touchTimer)
  }

  render() {
    let id = this.props.id
    let smallDevice = this.props.smallDevice
    return (
      <StyledPhotoCard
        selected={this.props.selected}
        inputMethod={this.props.inputMethod}
        onTouchStart={this.touchStartHandler}
        onTouchEnd={this.touchEndHandler}
        onTouchMove={this.touchMoveHandler}
        onClick={this.props.onClick}
        id={`photoCard-${id}`}
        smallDevice={smallDevice}
      >
      <Photo>
        <Thumbnail src={this.props.src} alt={this.props.id} id={`thumb-${id}`} />
      </Photo>
        <Toolbar id={`toolbar-${id}`} selected={this.props.selected}>
          {smallDevice ? null : (
            <Button id={`button-${id}`} onClick={this.props.select} title={this.props.selected ? 'Deselect' : 'Select'}>
              <i className='material-icons' id={`button-${id}`}>
                {`check_box${!this.props.selected ? '_outline_blank' : ''}`}
              </i>
            </Button>
          )}
          <Button id={`button-downloadPhoto-${id}`} hidden={this.props.hideButtons} onClick={this.props.download} title='Download photo'>
            <i className='material-icons' id={`button-downloadPhoto-${id}`} onClick={this.props.download}>
              save_alt
            </i>
          </Button>
          <Button
            id={`button-collection-${id}`}
            onClick={this.props.removeFromCollection ? this.props.removeFromCollection : this.props.addToCollection}
            hidden={this.props.hideButtons}
            title={`${this.props.removeFromCollection ? 'Remove from' : 'Add to'} collection`}
          >
            <i
              className='material-icons'
              id={`button-collection-${id}`}
              onClick={this.props.removeFromCollection ? this.props.removeFromCollection : this.props.addToCollection}
            >
              {this.props.removeFromCollection ? 'remove_circle_outline' : 'add_circle_outline'}
            </i>
          </Button>
        </Toolbar>
      </StyledPhotoCard>
    )
  }
}

const StyledPhotoCard = styled.div`
  position: relative;
  height: 300px;
  max-height: 300px;
  width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.selected ? '9px' : '10px')};
  border: ${props => (props.selected ? '2px' : '1px')} solid ${props => (props.selected ? props.theme.dark : props.theme.main)};
  border-radius: 5px;
  color: ${props => (props.selected ? props.theme.dark : props.theme.light)};
  user-select: none;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      padding: ${props => (props.selected ? null : '9px')};
      border: 2px solid ${props => props.theme.dark};
      color: ${props => props.theme.dark};
      cursor: pointer;
    }
  }
`

const Photo = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  max-height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Toolbar = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: flex-end;
  column-gap: 6px;
  color: ${props => (props.selected ? props.theme.dark : props.theme.main)};
`

const Button = styled.div`
  position: relative;
  display: ${props => (props.hidden ? 'none' : 'block')};

  i {
    margin: 0px 1px;
    font-size: 30px;
  }

  @media (hover: hover) and (pointer: fine) {
    i:hover {
      font-size: 32px;
      margin: 0px 0px;
      cursor: pointer;
    }
  }
`

const mapStateToProps = state => {
  return {
    inputMethod: state.ui.inputMethod,
    smallDevice: state.ui.smallDevice
  }
}

export default connect(mapStateToProps)(PhotoCard)
