import React from 'react'
import styled from 'styled-components'

const Button = props => {
  return (
    <StyledButton
      type={props.type}
      onClick={props.clicked}
      onMouseUp={props.onMouseUp}
      onTouchStart={props.clicked}
      disabled={props.disabled}
      width={props.width}
      height={props.height}
      fontSize={props.fontSize}
      id={props.id}
      autoFocus={props.autoFocus}
      danger={props.danger}
      onFocus={props.onFocus}
      touchInput={props.touchInput}
    >
      {props.children}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  min-height: 30px;
  font-size: ${props => props.fontSize || 'inherit'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px;
  padding: 2px 10px;
  outline: none;
  background-color: ${props => props.theme.bg};
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  border: solid 1px
    ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  border-radius: 5px;

  :active {
    transform: ${props =>
      props.touchInput || props.disabled ? 'none' : 'translateY(1px)'};
    color: ${props => (props.danger ? 'red' : props.theme.dark)};
  }

  :focus {
    border: ${props => (props.disabled ? '1px' : '2px')} solid
      ${props => (props.danger ? 'red' : props.theme.dark)};
    font-weight: ${props =>
      props.touchInput || props.disabled ? 'initial' : 'bold'};
    box-sizing: border-box;
    margin: 9px 9px;
  }

  :hover {
    background-color: ${props =>
      props.disabled
        ? props.theme.bg
        : props.danger
        ? props.theme.bg
        : props.touchInput
        ? props.theme.bg
        : props.theme.light};
    color: ${props =>
      props.disabled
        ? props.theme.main
        : props.danger
        ? 'red'
        : props.touchInput
        ? props.theme.dark
        : props.theme.dark};
    font-weight: ${props => (props.disabled ? null : 'bold')};
    border: ${props =>
        props.touchInput ? '1px' : props.disabled ? '1px' : '2px'}
      solid
      ${props =>
        props.disabled ? null : props.danger ? 'red' : props.theme.dark};
    margin: ${props =>
      props.disabled
        ? null
        : props.danger
        ? '9px 10px'
        : props.touchInput
        ? '10px 10px'
        : '9px 9px'};
    padding-left: ${props =>
      props.disabled || props.touchInput ? null : '11px'};
    cursor: ${props => (props.disabled ? 'arrow' : 'pointer')};
  }
`

export default Button
