import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as actions from '../store/actions'
import styled from 'styled-components'
import Input from 'components/NewInput'

class Error extends Component {
  goBack = () => {
    this.props.history.goBack()
    window.location.reload()
  }

  dismiss = () => {
    this.props.clearError()
    this.props.clearErrorBoundary && this.props.clearErrorBoundary()
  }

  render() {
    return (
      <StyledError>
        <p>Oops! Something went wrong.</p>
        <p>The webmaster has been notified and no further action is required from you.</p>
        <br />
        <Input type='button' id='goBack' width='80px' onClick={this.props.breaking ? this.goBack : this.dismiss}>
          {this.props.breaking ? 'Go back' : 'Dismiss'}
        </Input>
      </StyledError>
    )
  }
}

const StyledError = styled.div`
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.bg};
  z-index: 12;
  color: ${props => props.theme.dark};
  font-size: 20px;

  p {
    padding: 0;
    margin: 0;
    line-height: 30px;
  }
`

const mapStateToProps = state => {
  return {
    error: state.error.error,
    breaking: state.error.breaking
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearError: () => dispatch(actions.clearError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Error))
