import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as actions from 'store/actions'
import { db, functions, storage } from 'utils/firebase'
import Input, { getInputValue } from 'components/NewInput'
import Loader from 'components/loader/Loader'
import TagSuggestions from 'pages/admin/TagSuggestions'
import PendingFormItems from './PendingFormItems'
import store from 'store/store'
import { setError } from 'store/actions'
import Photo from 'components/Photo'
import sendEmail from 'pages/admin/sendEmail'
import Alert from 'components/Alert'
import { collection, deleteDoc, doc, getDoc, getDocs, limit, query, updateDoc, where, writeBatch, arrayRemove, increment } from 'firebase/firestore'

class Tasks extends Component {
  state = { loading: true }

  componentDidMount() {
    this.getUserTypes()
    this.getReportedPhotos()
  }

  componentDidUpdate() { 
    if (this.userTypes && this.userTypes.externalUsers !== this.props.externalUsers) this.getUserTypes()
  }

  getUserTypes() {
    getDoc(doc(db, 'config', 'config'))
      .then(res => {
        this.userTypes = res.data()
        this.setState({ loading: false })
      })
      .catch(error => store.dispatch(setError(error, false, 'Failed to get config object in Admin component.')))
  }

  removeUserType = async (userEmail, type) => {
    this.setState({ loading: true })
    this.retries = 0
    await this.callRemoveUserTypeCloudFunction({ uid: await this.getUserId(userEmail), userEmail, type })
    this.getUserTypes()
  }

  callRemoveUserTypeCloudFunction = async data => {
    return await functions
      .httpsCallable('removeUserType')(data)
      .then(() => 'SUCCESS')
      .catch(error => {
        this.retries++
        if (this.retries < 4)
          setTimeout(async () => {
            return await this.callRemoveUserTypeCloudFunction(data)
          }, 400)
        else store.dispatch(setError(error, false, 'Failed to remove user type in Admin component.'))
      })
  }

  addUserType = async () => {
    let userEmail = getInputValue('userEmail')
    let type = getInputValue('userTypes')
    this.setState({ loading: true })
    let uid = await this.getUserId(userEmail)
    await this.callAddUserTypeCloudFunction({ uid, userEmail, type })
    this.getUserTypes()
  }

  callAddUserTypeCloudFunction = async data => {
    return await functions
      .httpsCallable('addUserType')(data)
      .then(() => 'SUCCESS')
      .catch(error => {
        this.retries++
        if (this.retries < 4)
          setTimeout(async () => {
            return await this.callAddUserTypeCloudFunction(data)
          }, 400)
        else store.dispatch(setError(error, false, 'Failed to add user type in Admin component.'))
      })
  }

  getUserId = async userEmail => {
    return await getDoc(doc(db, 'users', userEmail))
      .then(user => user.data().uid)
      .catch(error => store.dispatch(setError(error, false, 'Failed to get user id in Admin component.')))
  }

  getReportedPhotos = async () => {
    this.reportedPhoto = <ReportedPhoto>There are no reported photos. Please check back later.</ReportedPhoto>
    await getDocs(query(collection(db, 'photos'), where('reported', '==', true), limit(1)))
      .then(res => {
        if (res.size === 0) return
        const photo = res.docs[0].data()
        const reportedData = photo.reportedData[0]
        return (this.reportedPhoto = (
          <ReportedPhoto>
            <div style={{ height: '300px' }}>
              <Photo src={photo.webURL} alt='Reported photo' id={photo.id} />
            </div>
            <p style={{ fontSize: '18px' }}>{`"${reportedData.reason}" - ${reportedData.reportedBy}`}</p>
            <ReportedPhotoButtons>
              <Input type='button' id='deletePhoto' onClick={() => this.deletePhoto(photo, reportedData)}>
                Delete photo
              </Input>
              <Input type='button' id='rejectRequest' width='120px' onClick={() => this.rejectRequest(photo, reportedData)}>
                Reject request
              </Input>
            </ReportedPhotoButtons>
          </ReportedPhoto>
        ))
      })
      .catch(error => store.dispatch(setError(error, false, 'Failed to get reported photos in Admin component.')))
    this.forceUpdate()
  }

  deletePhoto = async (photo, reportedData) => {
    this.setState({ loading: true })
    await Promise.all([storage.refFromURL(photo.printURL).delete(), storage.refFromURL(photo.webURL).delete(), storage.refFromURL(photo.thumbURL).delete()])
    await deleteDoc(doc(db, 'photos', photo.id)).catch(error => store.dispatch(setError(error, false, 'Failed to delete photo in Admin component.')))
    if (photo.album) await this.updateAlbumData(photo)
    await this.getReportedPhotos()
    await sendEmail('deleted', reportedData)
    this.setState({ loading: false })
  }

  updateAlbumData = async photo => {
    const album = await getDoc(doc(db, 'albums', photo.album))
      .then(res => res.data())
      .catch(error => store.dispatch(setError(error, false, 'Failed to get album data in Admin component.')))
    var newCoverURL = null
    const albumTags = [
      ...new Set(
        await getDocs(query(collection(db, 'photos'), where('album', '==', photo.album)))
          .then(res => {
            let allTags = []
            res.docs.forEach(doc => {
              if (doc.data().id !== photo.id) {
                allTags = allTags.concat(doc.data().tags)
                if (!newCoverURL) newCoverURL = doc.data().thumbURL
              }
            })
            return allTags
          })
          .catch(error => store.dispatch(setError(error, false, 'Failed to get album tags in Admin component.')))
      )
    ]
    const uniqueTags = []
    for (const tag of photo.tags) {
      if (!albumTags.includes(tag)) uniqueTags.push(tag)
    }
    const batch = writeBatch(db)
    for (const tag of uniqueTags) {
      batch.update(doc(db, 'albums', photo.album), {
        keys: arrayRemove(tag)
      })
    }
    if (album.coverURL === photo.thumbURL)
      batch.update(doc(db, 'albums', photo.album), {
        coverURL: newCoverURL
      })
    batch.update(doc(db, 'albums', photo.album), {
      totalPhotos: increment(-1),
      photosConverted: increment(-1)
    })
    return batch.commit().catch(error => store.dispatch(setError(error, false, 'Failed to update album data in Admin component.')))
  }

  rejectRequest = (photo, reportedData) => {
    this.alert = (
      <Alert
        alertType='Reject photo deletion request'
        alertButtonClicked={async event => {
          if (event.target.id === 'cancel') {
            this.alert = null
            this.forceUpdate()
          } else {
            const reason = getInputValue('reasonForDecline')
            this.setState({ loading: true })
            await sendEmail('reject', reportedData, photo, this.props.currentUser.email, reason)
            await getDoc(doc(db, 'photos', photo.id))
              .then(res => {
                if (res.data().reportedData.length > 1) {
                  updateDoc(doc(db, 'photos', photo.id), {
                    reportedData: arrayRemove(reportedData)
                  }).catch(error => store.dispatch(setError(error, false, 'Failed to update rejected photos in Admin component.')))
                } else return
                updateDoc(doc(db, 'photos', photo.id), {
                  reported: false,
                  reportedData: []
                })
              })
              .catch(error => store.dispatch(setError(error, false, 'Failed to reject reported photo in Admin component.')))
            await this.getReportedPhotos()
            this.alert = null
            this.setState({ loading: false })
          }
        }}
        backdropClicked={event => {
          if (event.target.id === 'modalBackdrop' && !getInputValue('reasonForDecline')) {
            this.alert = null
            this.forceUpdate()
          }
        }}
      />
    )
    this.forceUpdate()
  }

  removeExternalUser = email => {
    this.setState({ loading: true })
    this.props.removeExternalUser(email)
  }

  addExternalUser = () => {
    this.setState({ loading: true })
    this.props.setExternalUser(getInputValue('externalUserEmail'))
  }

  render() {
    return (
      <StyledTasks loading={this.state.loading ? 'true' : null}>
        {this.state.loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <ReportedPhotos>
              <BoxHeading>Reported photos</BoxHeading>
              {this.reportedPhoto}
            </ReportedPhotos>
            <TagSuggestionsBox>
              <BoxHeading>Tag suggestions</BoxHeading>
              <TagSuggestions />
            </TagSuggestionsBox>
            <PendingItemsBox>
              <BoxHeading>Pending form items</BoxHeading>
              <PendingFormItems />
            </PendingItemsBox>
            <PriveledgedUsers>
              <BoxHeading>Priveledged users</BoxHeading>
              <UsersList>
                <UserType>
                  <UserTypeHeading>Admins</UserTypeHeading>
                  {this.userTypes.admins && this.userTypes.admins.length > 0
                    ? this.userTypes.admins.map(user => {
                        return (
                          <UserTypeItem key={user.email}>
                            {user.name}
                            <i className='material-icons' title='Remove priveledge' onClick={() => this.removeUserType(user.email, 'admin')}>
                              delete
                            </i>
                          </UserTypeItem>
                        )
                      })
                    : 'None'}
                </UserType>
                <UserType>
                  <UserTypeHeading>Web admin</UserTypeHeading>
                  {this.userTypes.webAdmin && this.userTypes.webAdmin.length > 0
                    ? this.userTypes.webAdmin.map(user => {
                        return (
                          <UserTypeItem key={user.email}>
                            {user.name}
                            <i className='material-icons' title='Remove priveledge' onClick={() => this.removeUserType(user.email, 'webAdmin')}>
                              delete
                            </i>
                          </UserTypeItem>
                        )
                      })
                    : 'None'}
                </UserType>
                <UserType>
                  <UserTypeHeading>Photographers</UserTypeHeading>
                  {this.userTypes.photographers && this.userTypes.photographers.length > 0
                    ? this.userTypes.photographers.map(user => {
                        ;<i className='material-icons'>delete</i>
                        return (
                          <UserTypeItem key={user.email}>
                            {user.name}
                            <i className='material-icons' title='Remove priveledge' onClick={() => this.removeUserType(user.email, 'photographer')}>
                              delete
                            </i>
                          </UserTypeItem>
                        )
                      })
                    : 'None'}
                </UserType>
                <UserType>
                  <UserTypeHeading>Videographers</UserTypeHeading>
                  {this.userTypes.videographers && this.userTypes.videographers.length > 0
                    ? this.userTypes.videographers.map(user => {
                        return (
                          <UserTypeItem key={user.email}>
                            {user.name}
                            <i className='material-icons' title='Remove priveledge' onClick={() => this.removeUserType(user.email, 'videographer')}>
                              delete
                            </i>
                          </UserTypeItem>
                        )
                      })
                    : 'None'}
                </UserType>
              </UsersList>
              <AddUser>
                <Input type='email' id='userEmail' placeholder='User email address' label='Add user to priveledged role' />
                <Input type='select' id='userTypes' label='Select user role'>
                  <option value='admin'>Administrator</option>
                  <option value='webAdmin'>Web admin</option>
                  <option value='photographer'>Photographer</option>
                  <option value='videographer'>Videographer</option>
                </Input>
                <Input type='button' id='addUser' onClick={this.addUserType} margin='16px 0px 0px 0px'>
                  Submit
                </Input>
              </AddUser>
            </PriveledgedUsers>
            <ExternalUsers>
              <BoxHeading>External users</BoxHeading>
              <UsersList>
                <UserType>
                 
                  {this.userTypes.externalUsers && this.userTypes.externalUsers.length > 0
                    ? this.userTypes.externalUsers.map(email => {
                        return (
                          <UserTypeItem key={email}>
                            {email}
                            <i className='material-icons' title='Remove priveledge' onClick={() => this.removeExternalUser(email)}>
                              delete
                            </i>
                          </UserTypeItem>
                        )
                      })
                    : 'None'}
                </UserType>
                
              </UsersList>
              <AddUser>
                <Input type='email' id='externalUserEmail' placeholder='User email address' label='Add user to priveledged role' />
                <Input type='button' id='addExternalUser' onClick={this.addExternalUser} margin='16px 0px 0px 0px'>
                  Submit
                </Input>
              </AddUser>
            </ExternalUsers>
          </React.Fragment>
        )}
        {this.state.loading ? null : this.alert}
      </StyledTasks>
    )
  }
}

const StyledTasks = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.loading ? 'center' : 'flex-start')};
  align-items: center;
  color: ${props => props.theme.dark};
  overflow-y: auto;
`

const PriveledgedUsers = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 70%;
  height: auto;
  padding: 50px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 10px;
  margin-top: 60px;
`

const ExternalUsers = styled(PriveledgedUsers)`
  
`

const ReportedPhotos = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 70%;
  height: auto;
  padding: 50px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 10px;
  margin-top: 60px;
`

const ReportedPhoto = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: ${props => props.theme.main};
`

const ReportedPhotoButtons = styled.div`
  position: relative;
  width: 200px;
  display: flex;
  justify-content: space-around;
  column-gap: 40px;
  align-items: center;
`

const UsersList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const BoxHeading = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: ${props => props.theme.dark};
`

const UserType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% / 4);
  padding-left: 20px;
`

const UserTypeHeading = styled.div`
  font-weight: bold;
  padding-bottom: 6px;
`

const UserTypeItem = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  margin: 6px 0px 0px 4px;
  font-size: 14px;

  i {
    font-size: 18px;
    margin: 0px 1px 1px 1px;
    :hover {
      cursor: pointer;
      font-size: 19px;
      margin: 0px 1px 0px 0px;
    }
  }
`

const AddUser = styled.div`
  width: 80%;
  padding-top: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;
`

const TagSuggestionsBox = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 70%;
  height: auto;
  margin-top: 60px;
  padding: 50px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 10px;
`

const PendingItemsBox = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 70%;
  height: auto;
  margin-top: 60px;
  padding: 50px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 10px;
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    externalUsers: state.config.externalUsers
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setExternalUser: email => dispatch(actions.setExternalUser(email)),
    removeExternalUser: email => dispatch(actions.removeExternalUser(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks)
