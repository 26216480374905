import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { db, auth } from 'utils/firebase'
import * as actions from 'store/actions'
import TextLink from 'components/TextLink'
import Input, { getInputValue } from 'components/NewInput'
import Loader from 'components/loader/Loader'
import Alert from 'components/Alert'
import ChangePasswordModal from 'components/auth/ChangePasswordModal'
import { doc, updateDoc } from 'firebase/firestore'

class Profile extends Component {
  state = {
    changesMade: false,
    loading: false,
    saving: false,
    typing: false
  }

  calculateChanges = () => {
    let changesMade = false
    const user = this.props.currentUser
    this.name = getInputValue('name')
    this.surname = getInputValue('surname')
    this.department = getInputValue('department')
    this.faculty = getInputValue('faculty')
    this.extension = getInputValue('extension')

    if (
      user.name !== this.name ||
      user.surname !== this.surname ||
      user.department !== this.department ||
      user.faculty !== this.faculty ||
      user.officePhone !== this.extension
    )
      changesMade = true
    if (changesMade !== this.state.changesMade) this.setState({ changesMade })
  }

  saveChangesHandler = () => {
    this.setState({ saving: true })
    this.props.addPendingFormItems({
      department: this.department,
      faculty: this.faculty
    })
    updateDoc(doc(db, 'users', this.props.currentUser.email), {
      name: this.name,
      surname: this.surname,
      department: this.department,
      faculty: this.faculty,
      officePhone: this.extension
    })
      .then(() => {
        if (this.name !== this.props.currentUser.name || this.surname !== this.props.currentUser.surname) {
          auth.updateProfile({
            displayName: `${this.name} ${this.surname}`
          })
        }
      })
      .then(() => {
        this.props.updateCurrentUser({
          name: this.name,
          surname: this.surname,
          department: this.department,
          faculty: this.faculty,
          officePhone: this.extension
        })
        this.setState({ changesMade: false, saving: false }, () => {
          document.getElementById('saveChanges').blur()
        })
      })
      .catch(error => {
        this.props.setError(error, false, 'Failed to update profile information in Profile component.')
      })
  }

  changePasswordHandler = () => {
    this.props.showChangePasswordModal()
  }

  signOutHandler = () => {
    this.setState({ loading: true })
    auth
      .signOut()
      .then(() => {
        this.props.updateCurrentUser(null)
        this.props.history.push('/')
        window.location.reload()
      })
      .catch(error => {
        this.props.setError(error, false, 'Failed to sign out in Profile component.')
      })
  }

  deleteAccountHandler = () => {
    this.alert = <Alert alertType='Delete account' width={this.props.smallDevice ? '80%' : '500px'} alertButtonClicked={this.deleteAccount} />
    this.forceUpdate()
  }

  deleteAccount = event => {
    if (event.target.id === 'continue') {
      this.props.showConfirmDeleteAccountModal() // NTS: show delete successful alert once done
    } else {
      this.alert = null
      this.forceUpdate()
    }
  }

  inputFocusHandler = () => {
    if (this.props.smallDevice && this.props.inputMethod === 'touch') {
      this.setState({ typing: !this.state.typing })
    }
  }

  render() {
    const user = this.props.currentUser
    return this.state.loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <StyledProfile smallDevice={this.props.smallDevice} typing={this.state.typing}>
          <Info smallDevice={this.props.smallDevice}>
            <Input
              type='text'
              label='Name:'
              id='name'
              value={user.name}
              onChange={this.calculateChanges}
              onFocus={this.inputFocusHandler}
              onBlur={this.inputFocusHandler}
            />
            <Input
              type='text'
              label='Surname:'
              id='surname'
              value={user.surname}
              onChange={this.calculateChanges}
              onFocus={this.inputFocusHandler}
              onBlur={this.inputFocusHandler}
            />

            <Input
              type='datalist'
              label='Department:'
              id='department'
              value={user.department}
              onChange={this.calculateChanges}
              onFocus={this.inputFocusHandler}
              onBlur={this.inputFocusHandler}
            >
              {this.props.approved.department || <option />}
            </Input>

            <Input
              type='datalist'
              label='Faculty / Division:'
              id='faculty'
              value={user.faculty}
              onChange={this.calculateChanges}
              onFocus={this.inputFocusHandler}
              onBlur={this.inputFocusHandler}
            >
              {this.props.approved.faculty || <option />}
            </Input>

            <Input
              type='extension'
              label='Office phone extension:'
              id='extension'
              value={`x${user.officePhone}`}
              onChange={this.calculateChanges}
              onFocus={this.inputFocusHandler}
              onBlur={this.inputFocusHandler}
            />

            <SaveChangesDiv smallDevice={this.props.smallDevice}>
              <Input type='button' id='saveChanges' width='auto' disabled={!this.state.changesMade} onClick={this.saveChangesHandler}>
                Save changes
              </Input>
            </SaveChangesDiv>
          </Info>

          <AccountActionsDiv hidden={this.state.typing && this.props.inputMethod === 'touch'}>
            <TextLink clicked={this.signOutHandler}>Sign out</TextLink>
            <TextLink padding='6px 0px' clicked={this.changePasswordHandler}>
              Change password
            </TextLink>
            <DeleteAccountDiv>
              <TextLink clicked={this.deleteAccountHandler} dangerLink>
                Delete account
              </TextLink>
            </DeleteAccountDiv>
          </AccountActionsDiv>
        </StyledProfile>
        {this.alert ? this.alert : null}
        {this.props.shouldShowChangePasswordModal ? <ChangePasswordModal /> : null}
        {this.state.saving ? <Loader /> : null}
      </React.Fragment>
    )
  }
}

const StyledProfile = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% + 60px);
  overflow-y: auto;
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.smallDevice ? 'space-between' : 'flex-start')};
  align-items: center;
  row-gap: 40px;
`
const Info = styled.div`
  position: relative;
  box-sizing: border-box;
  width: ${props => (props.smallDevice ? '90%' : '400px')};
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: ${props => props.theme.dark};
  background-color: ${props => (props.smallDevice ? 'inherit' : props.theme.light)};
  border-color: ${props => props.theme.dark};
  border-radius: 5px;
  border: ${props => (props.smallDevice ? null : '1px solid')};
  padding: ${props => (props.smallDevice ? '20px 40px' : '20px 60px')};
  margin-top: ${props => (props.smallDevice ? null : '40px')};
  overflow: hidden;
`

const SaveChangesDiv = styled.div`
  justify-content: center;
  padding-top: ${props => (props.typing ? '0px' : '30px')};
  display: ${props => (props.hidden ? 'none' : 'flex')};
`

const AccountActionsDiv = styled.div`
  position: relative;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 10px 0px;
`

const DeleteAccountDiv = styled.div`
  padding-top: 10px;
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    smallDevice: state.ui.smallDevice,
    inputMethod: state.ui.inputMethod,
    approved: state.forms.approved,
    shouldShowChangePasswordModal: state.ui.showChangePasswordModal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showChangePasswordModal: () => dispatch(actions.showChangePasswordModal()),
    showConfirmDeleteAccountModal: () => dispatch(actions.showConfirmDeleteAccountModal()),
    updateCurrentUser: userObject => dispatch(actions.updateCurrentUser(userObject)),
    addPendingFormItems: (newItems) => dispatch(actions.requestApprovedFormItems(newItems)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
