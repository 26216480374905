import { storage } from 'utils/firebase'
import { getInputValue } from 'components/NewInput'
import store from 'store/store'
import { setError } from 'store/actions'

const uploadFiles = async (newBookingId, existingBooking) => {
  let newFiles = getInputValue('files') || []
  let newFileNames = newFiles.map(file => file.name)
  let existingFiles = existingBooking && existingBooking.files ? existingBooking.files.filter(file => newFileNames.includes(file.name)) : []

  for (const file of newFiles) {
    if (!file.url) {
      await storage
        .ref(`bookings/${newBookingId || existingBooking.id}/${file.name}`)
        .put(file)
        .catch(error => store.dispatch(setError(error, false, 'Failed to add attachment in BookingForm component.')))
      existingFiles.push({
        name: file.name,
        url: await storage
          .ref(`bookings/${newBookingId || existingBooking.id}/${file.name}`)
          .getDownloadURL()
          .catch(error => store.dispatch(setError(error, false, 'Failed to get attachment download URL in BookingForm component.')))
      })
    }
  }

  return existingFiles
}

export default uploadFiles
