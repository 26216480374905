import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Text from 'components/Text'

class SortInput extends Component {
  state = {
    showSort: false,
    showFilters: false,
    enableTransition: false
  }

  componentDidMount() {
    this.populateOptions()
  }

  sortBy = this.props.sortBy
  sortDirection = this.props.sortDirection
  componentDidUpdate() {
    if (this.sortBy !== this.props.sortBy) {
      this.sortBy = this.props.sortBy
      this.populateOptions()
    }
    if (this.sortDirection !== this.props.sortDirection) {
      this.sortDirection = this.props.sortDirection
      this.populateOptions()
    }
  }

  populateOptions = () => {
    let options = {
      dateOfEvent: 'Date',
      name: 'Name'
    }
    this.options = Object.keys(options).map(option => {
      return (
        <Option
          key={option}
          id={`sortOption-${option}`}
          onClick={this.props.sortOptionClicked}
          active={this.props.sortBy === option}
          firstOption={option === Object.keys(options)[0]}
          lastOption={option === Object.keys(options)[Object.keys(options).length - 1]}
          smallDevice={this.props.smallDevice}
        >
          <Text id={`sortOption-${option}`} clip='true'>
            {options[option]}
          </Text>
          <Arrow hidden={this.props.sortBy !== option} id={`sortOption-${option}`}>
            <i className='material-icons sort-arrow' id={`sortOption-${option}`}>
              {this.props.sortDirection === 'ascending' ? 'arrow_downward' : 'arrow_upward'}
            </i>
          </Arrow>
        </Option>
      )
    })
    this.forceUpdate()
  }

  showSort = event => {
    event.stopPropagation()
    if (event.target.id.slice(0, 11) !== 'sortOption-') {
      this.setState({ showSort: !this.state.showSort }, () => {
        if (this.state.showSort) {
          this.setState({ enableTransition: true })
          document.addEventListener('click', this.showSort)
        } else {
          this.setState({ enableTransition: true })
          document.removeEventListener('click', this.showSort)
        }
      })
    }
  }

  disableTransition = () => {
    this.setState({ enableTransition: false })
  }

  render() {
    return (
      <React.Fragment>
        <StyledSort
          onClick={this.props.disabled ? null : this.showSort}
          smallDevice={this.props.smallDevice}
          smallDeviceLandscape={this.props.smallDeviceLandscape}
          showSort={this.state.showSort}
          showFilters={this.props.showFilters}
          disabled={this.props.disabled}
          enableTransition={this.state.enableTransition}
          onTransitionEnd={this.disableTransition}
        >
          <InputBox>
            <i className={this.props.smallDevice ? 'material-icons sort-small' : 'material-icons sort'}>sort</i>
            <Text>Sort</Text>
          </InputBox>
          {this.state.showSort ? <Options>{this.options}</Options> : null}
        </StyledSort>
      </React.Fragment>
    )
  }
}

const StyledSort = styled.div`
  position: absolute;
  bottom: 12px;
  left: ${props => (props.smallDevice ? '110px' : props.showFilters ? '275px' : '200px')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  width: ${props => (props.smallDevice ? (props.showSort ? '130px' : '80px') : props.showSort ? '180px' : '150px')};
  border: ${props => (props.smallDevice && props.showSort ? '2px' : '1px')} solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  border-radius: 13px;
  font-size: ${props => (props.smallDevice ? '14px' : '15px')};
  background-color: ${props => props.theme.light};
  z-index: 1;
  transition: left 0.2s linear, width ${props => (props.enableTransition ? '.2s' : '0s')} linear;
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  :hover {
    border-width: ${props => (props.smallDevice ? null : props.disabled ? '1px' : '2px')};
    margin: ${props => (props.smallDevice ? null : '-1px -1px')};
    width: ${props => (props.smallDevice ? null : props.showSort ? '182px' : '152px')};
  }
`

const InputBox = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  width: 100%;
  padding: 4px 4px;
  :hover {
    cursor: pointer;
  }
`

const Options = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  border-top: ${props => (props.showSort ? `1px solid ${props.theme.dark}` : null)};
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-color: ${props => props.theme.bg};
  z-index: 1;
`

const Option = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.dark};
  padding: ${props => (props.smallDevice ? '6px 10px' : '6px 10px')};
  color: ${props => (props.applied || props.inactive ? props.theme.main : props.theme.dark)};
  font-size: ${props => (props.smallDevice ? '12px' : '14px')};
  background-color: ${props => props.theme.bg};
  font-weight: ${props => (props.active ? 'bold' : null)};
  z-index: 1;

  :hover {
    background-color: ${props => (props.smallDevice ? null : props.theme.main)};
    color: ${props => (props.applied || props.inactive ? props.theme.bg : 'initial')};
    cursor: pointer;
  }
`

const Arrow = styled.div`
  box-sizing: border-box;
  display: ${props => (props.hidden ? 'hidden' : 'flex')};
  justify-content: center;
  align-items: center;
  width: 20px;
  font-size: 20px;
  overflow-x: hidden;
  padding-right: 6px;
`

const mapStateToProps = state => {
  return {
    smallDevice: state.ui.smallDevice,
    smallDeviceLandscape: state.ui.smallDeviceLandscape
  }
}

export default connect(mapStateToProps)(SortInput)
