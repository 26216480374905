import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import * as actions from 'store/actions'
import Text from 'components/Text'
import BurgerMenuIcon from 'pages/layout/BurgerMenuIcon'
import AuthModal from 'components/auth/AuthModal'
import ujLogo from 'pages/layout/ujLogo.png'
import Input, { getInputValue } from 'components/NewInput'

class HeaderMobile extends Component {
  state = {
    currentPage: '',
    showSearchBar: false
  }

  visitorsLandingPage = '/'
  componentDidMount() {
    this.setState({
      currentPage: this.props.location.pathname.slice(1, this.props.location.pathname.length)
    })
    this.determineVisitorsLandingPage()
  }

  currentUser = null
  showSearchBar = false
  componentDidUpdate() {
    if (this.props.currentUser !== this.currentUser) {
      this.currentUser = this.props.currentUser
      this.hideAuthModal()
      this.forceUpdate()
    }
    if (this.showSearchBar !== this.props.showSearchBar) {
      this.setState({ showSearchBar: this.props.showSearchBar })
      this.showSearchBar = this.props.showSearchBar
    }
    let pathname = this.props.location.pathname.slice(1, this.props.location.pathname.length)
    if (this.state.currentPage !== pathname) {
      this.setState({ currentPage: pathname })
    }
    if (this.props.searchKeys && !this.searchKeyOptions) this.renderSearchKeyOptions()
  }

  determineVisitorsLandingPage = () => {
    if (window.location.pathname.includes('/photos/') && !window.location.pathname.match(/(_\d\d\d)$/)) this.visitorsLandingPage = window.location.pathname
    else if (window.location.pathname.includes('/photos/'))
      this.visitorsLandingPage = `/albums/${window.location.pathname.slice(8, window.location.pathname.length - 4)}`
    else if (window.location.pathname.includes('/albums/')) this.visitorsLandingPage = window.location.pathname
    else if (window.location.pathname.includes('/collections/')) this.visitorsLandingPage = window.location.pathname
    else this.visitorsLandingPage = '/'
  }

  burgerMenuClicked = () => {
    this.props.showMenu()
  }

  searchQueryEnteredHandler = event => {
    if (event) event.preventDefault()
    this.props.search(null)
    this.props.history.push(`/search/${getInputValue('searchbar')}`)
  }

  logoClicked = () => {
    if (this.props.currentUser && this.props.currentUser.staff) this.props.history.push('/')
    else this.props.history.push(this.visitorsLandingPage)
  }

  searchIconClicked = () => {
    this.props.toggleShowSearchBar()
    setTimeout(() => {
      document.getElementById('searchbar').focus()
    }, 200)
  }

  searchInputBlur = event => {
    // if (event && event === 'hideSearchBar') {
    //   this.props.toggleShowSearchBar()
    // }
    if (getInputValue('searchbar') === '') this.props.toggleShowSearchBar()
  }

  profileClicked = () => {
    this.props.history.push('/profile')
  }

  bookingsClicked = () => {
    this.props.history.push('/bookings')
  }

  authModal = null
  showAuthModal = () => {
    this.authModal = <AuthModal signedIn hideAuthModal={this.hideAuthModal} />
    this.forceUpdate()
  }

  hideAuthModal = () => {
    this.authModal = null
    this.forceUpdate()
  }

  getSearchKeys = () => {
    if (!this.props.searchKeys) this.props.getSearchKeys()
  }

  searchKeyOptions = null
  renderSearchKeyOptions = () => {
    if (!this.props.searchKeys) return
    let searchKeys = []
    for (const keyArray of Object.values(this.props.searchKeys)) {
      searchKeys = searchKeys.concat(keyArray)
    }
    this.searchKeyOptions = searchKeys
    this.forceUpdate()
  }

  render() {
    return (
      <React.Fragment>
        <StyledHeader>
          <Logo onClick={this.logoClicked}>
            <img onClick={this.logoClicked} style={{ width: '48px', height: '48px' }} src={ujLogo} alt='logo' />
          </Logo>
          <MidSection>
            <Title onClick={this.logoClicked} showSearchBar={this.state.showSearchBar}>
              <Text onClick={this.logoClicked}>University of Johannesburg</Text>
              <Text onClick={this.logoClicked}>Digital Media Library</Text>
            </Title>
            {this.props.currentUser && this.props.currentUser.email ? (
              <Search>
                <SearchIcon onClick={this.searchIconClicked} showSearchBar={this.state.showSearchBar}>
                  <i className='material-icons'>search</i>
                </SearchIcon>

                <SearchBar showSearchBar={this.state.showSearchBar}>
                  <form id='searchQuery' onSubmit={this.searchQueryEnteredHandler}>
                    <Input
                      type='autocomplete'
                      id='searchbar'
                      placeholder='search'
                      width='100%'
                      hidden={!this.state.showSearchBar}
                      onBlur={this.searchInputBlur}
                      onFocus={this.getSearchKeys}
                      optionsArray={this.searchKeyOptions}
                      submitOnEnter={this.searchQueryEnteredHandler}
                    />
                  </form>
                </SearchBar>
              </Search>
            ) : null}
          </MidSection>

          {this.props.currentUser && this.props.currentUser.email ? (
            <Menu>
              <BurgerMenuIcon onClick={this.burgerMenuClicked} />
            </Menu>
          ) : null}
          {this.props.currentUser && this.props.currentUser.anonymous ? <StaffSignIn onClick={this.showAuthModal}>Staff Login</StaffSignIn> : null}
        </StyledHeader>
        {this.authModal}
      </React.Fragment>
    )
  }
}

const StyledHeader = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.light};
  border-bottom: 1px solid ${props => props.theme.main};
  color: ${props => props.theme.dark};
`

const Logo = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  margin-right: 4px;
  z-index: 1;

  :hover {
    cursor: pointer;
  }
`

const MidSection = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

const Title = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: ${props => (props.showSearchBar ? '0px' : 'auto')};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  font-size: 10px;
  font-weight: bold;
  z-index: 1;
  transition: width 0.5s linear;

  :hover {
    cursor: pointer;
  }
`

const Search = styled.div`
  position: absolute;
  box-sizing: border-box;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`

const SearchIcon = styled.div`
  position: absolute;
  right: 0px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.showSearchBar ? 0 : 1)};
  transition: opacity 400ms linear;
  font-size: 30px;
`

const SearchBar = styled.div`
  position: absolute;
  right: 0px;
  top: 10px;
  width: ${props => (props.showSearchBar ? '100%' : '0px')};
  height: auto;
  overflow-y: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.5s linear;

  #searchQuery {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: visible;
  }
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  font-weight: bold;
`

const StaffSignIn = styled.div`
  position: relative;
  width: auto;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 20px 10px 10px;
  white-space: nowrap;
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    showMenu: state.ui.showMenu,
    showSearchBar: state.ui.showSearchBar,
    searchQuery: state.search.searchQuery,
    searchKeys: state.search.searchKeys
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showMenu: () => dispatch(actions.showMenu()),
    populatePhotoCollection: photoArray => dispatch(actions.populatePhotoCollection(photoArray)),
    toggleShowSearchBar: () => dispatch(actions.toggleShowSearchBar()),
    search: searchQuery => dispatch(actions.search(searchQuery)),
    getSearchKeys: () => dispatch(actions.getSearchKeys())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderMobile))
