import * as actions from 'store/actions/actionTypes'
import { updateObject } from 'utils/shared'

const initialState = {
  downloadType: null,
  folderName: null
}

const download = (state, action) => {
  return updateObject(state, { downloadType: action.downloadType, folderName: action.folderName || null })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.DOWNLOAD:
      return download(state, action)
    default:
      return state
  }
}

export default reducer
