import React from 'react'
import styled from 'styled-components'

const DotMenuIcon = props => {
  return (
    <Icon onClick={props.onClick}>
      <Bar />
      <Bar />
      <Bar />
    </Icon>
  )
}

const Icon = styled.div`
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 10px 10px;

  :hover {
    cursor: pointer;
  }
`

const Bar = styled.div`
  width: 30px;
  height: 2.5px;
  background-color: ${props => props.theme.dark};
  border-radius: 5px;
`

export default DotMenuIcon
