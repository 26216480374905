import { Component } from 'react'
import styled from 'styled-components'

class Time extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value || '12:00'
    }
  }

  changedHandler = event => {
    this.setState({ value: event.target.value }, () => {
      if (this.props.onChange) this.props.onChange(event)
    })
  }

  render() {
      return (
        <StyledTime
          type='time'
          id={this.props.id}
          value={this.state.value}
          onChange={this.changedHandler}
          onFocus={this.props.onFocus}
          max={this.props.max}
          min={this.props.min}
        />
      )
  }
}

const StyledTime = styled.input`
  box-sizing: border-box;
  width: 80px;
  height: ${props => (props.smallDevice ? '18px' : '26px')};
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  padding: 2px 6px;
  font-family: 'Roboto';
  background-color: white;
  color: ${props => props.theme.dark};
  font-size: inherit;

  :focus {
    outline: none;
    border-width: 2px;
    padding: 1px 5px;
  }
`

export default Time
