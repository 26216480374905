import React from 'react'
import styled from 'styled-components'

const Input = props => {
  switch (props.type) {
    case 'text':
      return (
        <StyledInputBox width={props.width} margin={props.margin}>
          <InputLabel>{props.inputLabel}</InputLabel>
          <Text
            type='text'
            id={props.id}
            onChange={props.changed}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            autoFocus={props.autoFocus}
            autoComplete={props.autoComplete}
            height={props.height}
            fontSize={props.fontSize}
            gridArea={props.gridArea}
            required={props.required}
            color={props.color}
            readOnly={props.readOnly}
            selectable={props.selectable}
            onKeyDown={props.onKeyDown}
          />
        </StyledInputBox>
      )
    case 'search':
      return (
        <StyledInputBox width={props.width} margin={props.margin}>
          <InputLabel>{props.inputLabel}</InputLabel>
          <Search
            type='search'
            id={props.id}
            onChange={props.changed}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            autoFocus={props.autoFocus}
            autoComplete={props.autoComplete}
            height={props.height}
            fontSize={props.fontSize}
            gridArea={props.gridArea}
            required={props.required}
            color={props.color}
            hideSearchBar={props.hideSearchBar}
          />
        </StyledInputBox>
      )

    case 'email':
      return (
        <StyledInputBox width={props.width} margin={props.margin}>
          <InputLabel>{props.inputLabel}</InputLabel>
          <Text
            onChange={props.changed}
            type='email'
            id={props.id}
            multiple={props.multiple}
            name={props.name}
            value={props.value}
            autoComplete='email'
            autoFocus={props.autoFocus}
            height={props.height}
            fontSize={props.fontSize}
            gridArea={props.gridArea}
            required={props.required}
            placeholder={props.placeholder}
          />
        </StyledInputBox>
      )

    case 'officePhoneExtension':
      return (
        <StyledInputBox width={props.width} margin={props.margin}>
          <InputLabel>{props.inputLabel}</InputLabel>
          <Text
            onChange={props.changed}
            type='tel'
            id={props.id}
            name={props.name}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            value={props.value}
            autoFocus={props.autoFocus}
            minLength='4'
            maxLength='4'
            height={props.height}
            fontSize={props.fontSize}
            gridArea={props.gridArea}
            required={props.required}
            placeholder={props.placeholder}
            autoComplete='offhack'
          />
        </StyledInputBox>
      )

    case 'cellPhone':
      return (
        <StyledInputBox width={props.width} margin={props.margin}>
          <InputLabel>{props.inputLabel}</InputLabel>
          <Text
            onChange={props.changed}
            type='tel'
            id={props.id}
            name={props.name}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            value={props.value}
            autoFocus={props.autoFocus}
            minLength='12'
            maxLength='12'
            pattern={'[0-9]{3} [0-9]{3} [0-9]{4}'}
            placeholder='000 000 0000'
            height={props.height}
            fontSize={props.fontSize}
            gridArea={props.gridArea}
            required={props.required}
            autoComplete='offhack'
          />
        </StyledInputBox>
      )

    case 'password':
      return (
        <StyledInputBox width={props.width} margin={props.margin}>
          <InputLabel>{props.inputLabel}</InputLabel>
          <Text
            onChange={props.changed}
            type='password'
            name={props.name}
            id={props.id}
            value={props.value}
            autoComplete={props.autoComplete || 'current-password'}
            autoFocus={props.autoFocus}
            height={props.height}
            fontSize={props.fontSize}
            gridArea={props.gridArea}
            required={props.required}
            borderColor={props.borderColor}
          />
        </StyledInputBox>
      )

    case 'textarea':
      return (
        <StyledTextArea margin={props.margin}>
          <InputLabel>{props.inputLabel}</InputLabel>
          <TextArea
            error={props.error}
            onChange={props.changed}
            autoFocus={props.autoFocus}
            onFocus={props.onFocus}
            autoComplete='false'
            placeholder={props.placeholder}
            cols={props.cols}
            padding={props.padding}
            width={props.width}
            height={props.height}
            fontSize={props.fontSize}
            required={props.required}
            value={props.value}
            id={props.id}
          />
        </StyledTextArea>
      )

    case 'checkbox':
      return (
        <Label htmlFor={props.id} fontSize={props.fontSize} tabIndex={props.tabIndex}>
          <input
            type='checkbox'
            className='checkbox'
            style={{ width: '16px', height: '16px' }}
            id={props.id}
            name={props.name}
            checked={props.checked}
            onChange={props.changed}
            required={props.required}
            fontSize={props.fontSize}
            onFocus={props.onFocus}
          />
          {` ${props.label}`}
        </Label>
      )
      
    case 'select':
      return (
        <StyledSelect width={props.width} margin={props.margin}>
          {props.inputLabel ? <InputLabel>{props.inputLabel}</InputLabel> : null}
          <Select
            id={props.id}
            onChange={props.changed}
            width={props.width}
            height={props.height}
            fontSize={props.fontSize}
            required={props.required}
            autoFocus={props.autoFocus}
            value={props.value}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
            backgroundColor={props.backgroundColor}
          >
            {props.children}
          </Select>
        </StyledSelect>
      )

    case 'datalist':
      return (
        <StyledDataList width={props.width}>
          <InputLabel>{props.inputLabel}</InputLabel>
          <DataListInput
            list={props.list}
            id={props.id}
            onChange={props.changed}
            value={props.value}
            autoFocus={props.autoFocus}
            height={props.height}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            fontSize={props.fontSize}
            required={props.required}
          />
          <datalist id={props.list}>{props.children}</datalist>
        </StyledDataList>
      )
    default:
      return null
  }
}

const StyledInputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${props => props.width || '100%'};
  margin: ${props => props.margin || '10px 10px'};
`

const Text = styled.input`
  outline: none;
  color: ${props => props.theme[props.color] || 'initial'};
  box-sizing: border-box;
  border: 1px solid ${props => (props.borderColor ? props.borderColor : props.theme.dark)};
  border-radius: 5px;
  background-color: white;
  padding: 12px 10px;
  height: ${props => props.height || '16px'};
  width: 100%;
  font-size: ${props => props.fontSize || '14px'};
  grid-area: ${props => props.gridArea || null};

  :focus {
    border: 2px solid ${props => (props.borderColor ? props.borderColor : props.theme.dark)};
    margin: -1px -1px;
  }
`

const Search = styled.input`
  outline: none;
  color: ${props => props.theme[props.color] || 'initial'};
  box-sizing: border-box;
  border: ${props => (props.hideSearchBar ? '0px' : '1px')} solid ${props => (props.borderColor ? props.borderColor : props.theme.dark)};
  border-radius: 5px;
  background-color: white;
  padding: ${props => (props.hideSearchBar ? '0px 0px' : props.padding || '12px 10px')};
  height: ${props => props.height || '16px'};
  width: ${props => (props.hideSearchBar ? '0px' : '100%')};
  font-size: ${props => props.fontSize || '14px'};
  grid-area: ${props => props.gridArea || null};

  :focus {
    border: 2px solid ${props => (props.borderColor ? props.borderColor : props.theme.dark)};
    margin: -1px -1px;
  }
`

const StyledDataList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${props => props.width || '100%'};
  margin: ${props => props.margin || '10px 10px'};
`

const DataListInput = styled.input`
  outline: none;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  background-color: white;
  padding: 12px 10px;
  height: ${props => props.height || '16px'};
  width: 100%;

  :focus {
    border: 2px solid ${props => props.theme.dark};
    margin: -1px -1px;
  }
`

const InputLabel = styled.p`
  font-size: 12px;
  padding: 0px 0px;
  margin: 0px 0px 4px 0px;
  color: ${props => props.theme.dark};
`

const StyledTextArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: ${props => props.margin || '10px 10px'};
`

const TextArea = styled.textarea`
  resize: none;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};
  padding: ${props => props.padding || '10px 10px'};
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  font-family: inherit;
  background-color: white;

  :invalid {
    box-shadow: ${props => (props.error ? null : 'none !important')};
  }

  :focus {
    border: 2px solid ${props => props.theme.dark};
    outline: none;
  }
`

const Label = styled.label`
  font-size: ${props => props.fontSize || '16px'};
  color: ${props => props.theme.dark};
  cursor: ${props => props.cursor || null};
  margin: 0px 15px;
  :hover {
    cursor: pointer;
  }
  :focus {
    outline: 1px solid ${props => props.theme.dark};
  }
`

const StyledSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${props => props.width || 'auto'};
  margin: ${props => props.margin || '10px 10px'};
`

const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid ${props => props.theme.dark};
  background-color: ${props => (props.backgroundColor ? props.theme[props.backgroundColor] : props.theme.bg)};
  border-radius: 5px;
  padding: 0px 6px;
  height: ${props => props.height || '40px'};
  width: ${props => props.width || '300px'};
  font-size: ${props => props.fontSize || '20px'};
  color: ${props => props.theme.dark};
  text-shadow: 0 0 0 ${props => props.theme.dark};

  :focus {
    border: 2px solid ${props => props.theme.dark};
  }
`

export default Input
