import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from 'store/actions'
import { auth } from 'utils/firebase'
import styled from 'styled-components'
import Button from 'components/Button'
import TextLink from '../TextLink'
import SignInModal from 'components/auth/SignInModal'
import CreateAccountModal from 'components/auth/CreateAccountModal'
import Loader from '../loader/Loader'

class AuthModal extends Component {
  state = {
    userType: null,
    windowMode: this.props.currentUser
      ? 'staff'
      : 'unknownUser',
    transition: false,
    termsAccepted: false,
    loading: false
  }

  componentDidMount() {
    this.pathname = window.location.pathname
    this.checkPageValidity()
  }

  componentDidUpdate() {
    if (this.pathname !== window.location.pathname) {
      this.pathname = window.location.pathname
      this.checkPageValidity()
    }
  }

  checkPageValidity = () => {
    this.setState(
      {
        validPage: this.pathname.match(/^(?:\/albums\/|\/photos\/|\/collections\/)/gi)
      },
      () => {
        if (!this.state.validPage) {
          this.setState({ windowMode: 'staff' })
        }
      }
    )
  }

  changeUserType = event => {
    if (event.type === 'click' || (event.type === 'keydown' && event.keyCode === 32)) {
      if (event.target.id === this.state.userType) this.setState({ userType: null })
      else this.setState({ userType: event.target.id })
    }
  }

  changeWindowMode = event => {
    if (event.type === 'click' || (event.type === 'keydown' && event.keyCode === 32)) {
      let transition = !(this.state.windowMode === 'unknownUser' && event.target.id === 'staff') && !(this.state.windowMode === 'staff' && event.target.id === 'unknownUser')
      this.setState({
        windowMode: event.target.id,
        transition
      })
    }
    if (event.target.id === 'visitor') {
      this.initiateRecaptchaScript()
    }
  }

  transitionEnd = () => {
    this.setState({ transition: false })
  }

  initiateRecaptchaScript = () => {
    this.setState({ loading: true })
    let script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js`
    script.onload = this.recaptchaScriptLoaded
    document.head.appendChild(script)
    window.recaptchaCallback = this.recaptchaTestPassed
  }

  recaptchaScriptLoaded = () => {
    this.setState({ loading: false })
  }

  runReCaptchaTest = () => {
    if (window.grecaptcha) window.grecaptcha.execute()
    else this.recaptchaTestPassed()
  }

  recaptchaTestPassed = () => {
    this.setState({ termsAccepted: true })
  }

  signInAnonymousUser = event => {
    event.stopPropagation()
    auth.setPersistence(true).then(() => {
      auth.signInAnonymously().then(async () => {
        await this.waitForUserUpdate()
        window.location.reload()
      })
      .catch(error => this.props.setError(error, false, 'Failed to sign in anonymously within the authModal component.'))
    })
  }

  // Refresh page once userUpdate has occured in order to flush recaptcha script
  waitForUserUpdate = async () => {
    let count = 0
    setTimeout(() => {
      count++
      if (this.props.currentUser) return
      else {
        if (count > 20) return
        else this.waitForUserUpdate()
      }
    }, 100)
  }

  hideAuthModal = () => {
    if (this.props.currentUser) this.props.showAuthModal(false)
  }

  render() {
    let smallDevice = this.props.smallDevice
    return (
      <React.Fragment>
        <Backdrop onClick={this.hideAuthModal} smallDevice={smallDevice}/>
        <Container>
          {this.state.windowMode === 'unknownUser' ? (
            <Modal windowMode={this.state.windowMode} onTransitionEnd={this.transitionEnd} smallDevice={smallDevice}>
              <Title transition={this.state.transition} smallDevice={smallDevice}>
                {`Welcome to the ${smallDevice ? 'UJ' : "University of Johannesburg's"} Digital Media Library`}
              </Title>
              <Body transition={this.state.transition} smallDevice={smallDevice}>
                <InputLine onClick={this.changeUserType} onKeyDown={this.changeUserType} id='staff' tabIndex='0' clickable>
                  <RadioButton id='staff'>
                    <i className='material-icons' id='staff'>
                      {this.state.userType === 'staff' ? 'radio_button_checked' : 'radio_button_unchecked'}
                    </i>
                  </RadioButton>
                  I am a UJ staff member
                </InputLine>
                <InputLine onClick={this.changeUserType} onKeyDown={this.changeUserType} id='visitor' tabIndex='0' clickable>
                  <RadioButton id='visitor'>
                    <i className='material-icons' id='visitor'>
                      {this.state.userType === 'visitor' ? 'radio_button_checked' : 'radio_button_unchecked'}
                    </i>
                  </RadioButton>
                  I am a visitor
                </InputLine>
                <ContinueButton smallDevice={smallDevice}>
                  <Button disabled={!this.state.userType} clicked={this.changeWindowMode} id={this.state.userType} tabIndex='0'>
                    Continue
                  </Button>
                </ContinueButton>
              </Body>
            </Modal>
          ) : this.state.windowMode === 'staff' ? (
            <Modal
              windowMode={this.state.windowMode}
              onTransitionEnd={this.transitionEnd}
              signedIn={this.props.currentUser}
              invalidPage={!this.state.validPage}
              smallDevice={smallDevice}
            >
              <Title transition={this.state.transition} smallDevice={smallDevice}>
                {`Welcome to the ${smallDevice ? 'UJ' : "University of Johannesburg's"} Digital Media Library`}
              </Title>
              <Body transition={this.state.transition} smallDevice={smallDevice}>
                <StaffActions smallDevice={smallDevice}>
                  Please{' '}
                  <TextLink fontWeight='bold' fontSize='17px' clicked={this.changeWindowMode} onKeyDown={this.changeWindowMode} id='signIn' tabIndex='0' autoFocus underline>
                    sign in
                  </TextLink>{' '}
                  or{' '}
                  <TextLink fontWeight='bold' fontSize='17px' clicked={this.changeWindowMode} onKeyDown={this.changeWindowMode} id='createAccount' tabIndex='0' underline>
                    create an account
                  </TextLink>{' '}
                  by using your UJ email address.
                </StaffActions>
              </Body>
              <GoBack
                onClick={this.changeWindowMode}
                onKeyDown={this.changeWindowMode}
                id='unknownUser'
                transition={this.state.transition}
                tabIndex='0'
                hide={!this.state.validPage || this.props.currentUser}
                smallDevice={smallDevice}
              >
                GO BACK
              </GoBack>
            </Modal>
          ) : this.state.windowMode === 'signIn' ? (
            <Modal windowMode={this.state.windowMode} onTransitionEnd={this.transitionEnd} smallDevice={smallDevice}>
              <Title transition={this.state.transition} smallDevice={smallDevice}>
                Sign in
              </Title>
              <Body transition={this.state.transition} smallDevice={smallDevice}>
                <SignInModal changeWindowMode={this.changeWindowMode} />
              </Body>
            </Modal>
          ) : this.state.windowMode === 'createAccount' ? (
            <Modal windowMode={this.state.windowMode} onTransitionEnd={this.transitionEnd} smallDevice={smallDevice}>
              <Title transition={this.state.transition} smallDevice={smallDevice}>
                Create account
              </Title>
              <Body transition={this.state.transition} smallDevice={smallDevice}>
                <CreateAccountModal changeWindowMode={this.changeWindowMode} />
              </Body>
            </Modal>
          ) : this.state.windowMode === 'visitor' ? (
            <Modal onTransitionEnd={this.transitionEnd} windowMode={this.state.windowMode} smallDevice={smallDevice}>
              {this.state.loading ? (
                <Loader />
              ) : (
                <React.Fragment>
                  <Title transition={this.state.transition} smallDevice={smallDevice}>
                    {`Welcome to the ${smallDevice ? 'UJ' : "University of Johannesburg's"} Digital Media Library`}
                  </Title>
                  <Body transition={this.state.transition} smallDevice={smallDevice} visitor id='terms'>
                    <span>
                      Please note that, as a visitor to this website, you must agree to the following terms and conditions for accessing and using any digitial content that may be
                      stored on this platform:
                    </span>
                    <Term>
                      <TermNumber>1.</TermNumber>
                      <TermText>All digital media, including photographs and videos, are the property of the University of Johannesburg.</TermText>
                    </Term>
                    <Term>
                      <TermNumber>2.</TermNumber>
                      <TermText>
                        Digital media may be republished in any digital or print media as long as the University of Johannesburg is mentioned by name together with the published
                        media.
                      </TermText>
                    </Term>
                    <Term>
                      <TermNumber>3.</TermNumber>
                      <TermText>No digital media, whether photographs or video, may under any circumstances be sold for financial gain.</TermText>
                    </Term>
                    <Term />
                    <span>
                      Visitor access is limited.{' '}
                      <TextLink clicked={this.changeWindowMode} id='staff' fontWeight='bold' underline tabIndex='0'>
                        UJ Staff
                      </TextLink>{' '}
                      should sign in or create an account{' '}
                      <TextLink clicked={this.changeWindowMode} id='staff' fontWeight='bold' underline tabIndex='0'>
                        here
                      </TextLink>
                      .
                    </span>
                    <Term />
                    <div
                      className='g-recaptcha'
                      data-sitekey='6LdOfLIZAAAAAHXZQoI4SMQuFVZVrzoO0t-kReVW'
                      data-size='invisible'
                      data-callback='recaptchaCallback'
                      data-badge='bottomleft'
                    />
                    <InputLine onClick={this.runReCaptchaTest} onKeyDown={this.runReCaptchaTest} tabIndex='0' clickable center>
                      <RadioButton>
                        <i className='material-icons'>
                          radio_button_
                          {this.state.termsAccepted ? 'checked' : 'unchecked'}
                        </i>
                      </RadioButton>
                      Agree
                    </InputLine>

                    <ContinueButton visitor={true} smallDevice={smallDevice}>
                      <Button disabled={!this.state.termsAccepted} clicked={this.signInAnonymousUser} tabIndex='0'>
                        Continue
                      </Button>
                    </ContinueButton>
                  </Body>
                  <GoBack
                    onClick={this.changeWindowMode}
                    onKeyDown={this.changeWindowMode}
                    id='unknownUser'
                    transition={this.state.transition}
                    tabIndex='0'
                    smallDevice={smallDevice}
                  >
                    GO BACK
                  </GoBack>
                </React.Fragment>
              )}
            </Modal>
          ) : null}
        </Container>
      </React.Fragment>
    )
  }
}

const Backdrop = styled.div`
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.2;
  z-index: ${props => (props.smallDevice ? 1 : 3)};
`

const Container = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
`

const Modal = styled.div`
  position: absolute;
  align-self: center;
  justify-self: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${props =>
    props.smallDevice
      ? 'calc(100% - 60px)'
      : props.windowMode === 'signIn'
      ? '380px'
      : props.windowMode === 'createAccount'
      ? '750px'
      : props.windowMode === 'visitor'
      ? '700px'
      : '530px'};
  height: ${props =>
    props.windowMode === 'signIn'
      ? props.smallDevice
        ? '320px'
        : '360px'
      : props.windowMode === 'createAccount'
      ? props.smallDevice
        ? '470px'
        : '470px'
      : props.windowMode === 'visitor'
      ? props.smallDevice
        ? '600px'
        : '460px'
      : props.signedIn || props.invalidPage
      ? props.smallDevice
        ? '130px'
        : '140px'
      : props.smallDevice
      ? '150px'
      : '170px'};
  background-color: ${props => props.theme.bg};
  border: 2px solid ${props => props.theme.dark};
  border-radius: 5px;
  overflow: hidden;
  z-index: ${props => (props.smallDevice ? 3 : 4)};
  transition: all 0.3s linear;
`

const Title = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: ${props => (props.smallDevice ? '30px' : '40px')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px;
  background-color: ${props => props.theme.dark};
  color: ${props => (props.transition ? props.theme.dark : props.theme.bg)};
  font-size: ${props => (props.smallDevice ? '15px' : '17px')};
`

const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 40px);
  box-sizing: border-box;
  display: ${props => (props.transition ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${props => (props.visitor ? (props.smallDevice ? '20px 40px' : '20px 50px') : props.smallDevice ? '8px 10px' : '8px 20px')};
  margin-bottom: ${props => (props.smallDevice && props.visitor ? '70px' : null)};
  color: ${props => props.theme.dark};
  text-align: ${props => (props.smallDevice && props.visitor ? 'justify' : null)};
`

const GoBack = styled.div`
  display: ${props => (props.transition || props.hide ? 'none' : 'block')};
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.dark};
  padding: ${props => (props.smallDevice ? '10px 10px' : null)};
  z-index: ${props => (props.smallDevice ? 5 : null)};

  :hover {
    cursor: ${props => (props.smallDevice ? null : 'pointer')};
    font-size: ${props => (props.smallDevice ? null : '15px')};
    left: ${props => (props.smallDevice ? null : '14px')};
    bottom: ${props => (props.smallDevice ? null : '14px')};
  }
`

const InputLine = styled.div`
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  align-items: center;
  min-height: ${props => props.height || null};
  margin: 10px 0px 0px ${props => (props.center ? '0px' : '30px')};
  align-self: ${props => (props.center ? 'center' : null)};

  :hover {
    cursor: ${props => (props.clickable ? 'pointer' : 'default')};
  }

  :focus {
    outline: 1px dotted ${props => (props.clickable ? props.theme.main : null)};
  }
`

const RadioButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 10px;
  font-size: 18px;
`

const ContinueButton = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.visitor ? '6px' : null};
`

const StaffActions = styled.div`
  position: relative;
  margin: ${props => (props.smallDevice ? '10px 10px' : '10px 100px')};
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
`

const Term = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
`

const TermNumber = styled.span`
  margin-left: 10px;
`

const TermText = styled.span`
  margin-left: 10px;
`

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    smallDevice: state.ui.smallDevice
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showAuthModal: value => dispatch(actions.showAuthModal(value)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal)
