import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from 'store/actions'
import styled from 'styled-components'
import Input from 'components/Input'
import Loader from 'components/loader/Loader'
import Text from 'components/Text'

class CollectionsModal extends Component {
  state = {
    loading: true,
    createNew: false,
    name: ''
  }

  options = []
  componentDidMount() {
    if (!this.props.collections) {
      this.props.getCollections(this.props.currentUser.email)
      this.getCollectionsPending = true
    } else this.populateOptions()
  }

  componentDidUpdate() {
    if (this.getCollectionsPending && this.props.collections) {
      this.getCollectionsPending = false
      this.populateOptions()
    }
    if (this.createCollectionPending) {
      this.props.loadPhotos(null)
    }
  }

  populateOptions = () => {
    let collections = this.props.collections
    if (collections) {
      collections.sort((a, b) => {
        if (a.modified > b.modified) return -1
        else if (b.modified > a.modified) return 1
        else return 0
      })
      this.lastUsed = collections[0]
      collections.forEach(collection => {
        this.options.push(
          <option value={collection.id} key={collection.id}>
            {collection.name}
          </option>
        )
      })
    }
    this.setState({
      loading: false,
      selected: this.lastUsed ? this.lastUsed.id : null
    })
  }

  select = event => {
    this.setState({ selected: event.target.value })
  }

  createButtonPressed = () => {
    this.setState({ createNew: true }, () => {
      document.getElementById('name').focus()
    })
  }

  nameChanged = event => {
    this.setState({ name: event.target.value })
  }

  createNewCollection = event => {
    if (
      (event.target.id === 'createNewCollectionButton' &&
        (event.type === 'click' || event.keyCode === 32)) ||
      (event.target.id === 'name' && event.keyCode === 13)
    ) {
      this.setState({ loading: true })
      let collection = {
        name: this.state.name,
        owner: this.props.currentUser.email,
        photos: this.props.photos,
        modified: new Date()
      }
      this.props.createCollection(collection)
      this.createCollectionPending = true
    }
  }

  addToCollection = () => {
    this.setState({ loading: true })
    let index = this.props.collections.findIndex(
      collection => collection.id === this.state.selected
    )
    let photos = this.props.collections[index].photos
    let modified = false
    this.props.photos.forEach(photo => {
      if (!photos.find(exisitingPhoto => exisitingPhoto.id === photo.id)) {
        photos.push(photo)
        modified = true
      }
    })
    if (modified) {
      this.props.addToCollection(this.state.selected, index, photos)
      this.props.loadPhotos(null)
    } else {
      this.props.loadPhotos(null)
    }
  }

  goBack = () => {
    this.setState({ createNew: false })
  }

  hideCollectionsWindow = () => {
    this.props.loadPhotos(null)
  }

  render() {
    return (
      <React.Fragment>
        <Backdrop onClick={this.hideCollectionsWindow} />
        <Container>
          <StyledCollectionsModal smallDevice={this.props.smallDevice}>
            <Title>Add photo to collection</Title>
            <Body loading={this.state.loading.toString()}>
              {this.state.loading ? (
                <Loader />
              ) : (
                <MyCollections>
                  {this.state.createNew ? (
                    <React.Fragment>
                      <Input
                        type="text"
                        placeholder="Please enter a name for your collection"
                        value={this.state.name}
                        changed={this.nameChanged}
                        width="100%"
                        id="name"
                        onKeyDown={this.createNewCollection}
                      />
                      <CreateButton smallDevice={this.props.smallDevice}>
                        <i
                          className="material-icons"
                          onClick={this.createNewCollection}
                          onKeyDown={this.createNewCollection}
                          id="createNewCollectionButton"
                          tabIndex="0"
                        >
                          create_new_folder
                        </i>
                      </CreateButton>
                    </React.Fragment>
                  ) : this.options.length > 0 ? (
                    <React.Fragment>
                      <Input
                        type="select"
                        id="myCollections"
                        value={this.state.selected}
                        changed={this.select}
                        width="100%"
                        height="28px"
                        fontSize="16px"
                      >
                        {this.options}
                      </Input>
                      <AddButton smallDevice={this.props.smallDevice}>
                        <i
                          className="material-icons"
                          id="Add"
                          onClick={this.addToCollection}
                        >
                          add_circle_outline
                        </i>
                      </AddButton>
                    </React.Fragment>
                  ) : (
                    <Text>
                      You do not yet have any collections. Please create one
                      using the button below.
                    </Text>
                  )}
                </MyCollections>
              )}
              {this.state.createNew ? (
                <React.Fragment>
                  <GoBack onClick={this.goBack} tabIndex="0">
                    GO BACK
                  </GoBack>
                </React.Fragment>
              ) : (
                <CreateNewCollection
                  smallDevice={this.props.smallDevice}
                  onClick={this.createButtonPressed}
                >
                  CREATE NEW
                </CreateNewCollection>
              )}
            </Body>
          </StyledCollectionsModal>
        </Container>
      </React.Fragment>
    )
  }
}

const Backdrop = styled.div`
  position: fixed;
  top: 48px;
  left: 0px;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 48px);
  background-color: black;
  opacity: 0.1;
  z-index: 1;
`

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledCollectionsModal = styled.div`
  position: absolute;
  justify-self: center;
  align-self: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${props => (props.smallDevice ? '90%' : '400px')};
  height: 160px;
  font-size: ${props => (props.smallDevice ? '12px' : '14px')};
  background-color: ${props => props.theme.bg};
  border: 2px solid ${props => props.theme.dark};
  border-radius: 5px;
  overflow: hidden;
  z-index: 3;
`

const Title = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px 10px;
  background-color: ${props => props.theme.dark};
  color: ${props => props.theme.bg};
  font-size: 16px;
`

const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 40px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.loading === 'true' ? 'center' : 'flex-start'};
  align-items: center;
  padding: 10px 20px;
  color: ${props => props.theme.dark};
`

const MyCollections = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const CreateNewCollection = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 16px;
  font-weight: bold;

  :hover {
    cursor: pointer;
    font-size: ${props => (props.smallDevice ? 'initial' : '16.3px')};
    left: ${props => (props.smallDevice ? '10px' : '9px')};
  }
`

const CreateButton = styled.div`
  position: relative;
  font-size: 34px;
  margin: ${props =>
    props.smallDevice ? '12px 10px 0px 6px' : '10px 2px 0px 2px'};

  :hover {
    cursor: pointer;
    font-size: 36px;
    margin: 9px 1px 0px 1px;
  }
`

const AddButton = styled.div`
  position: relative;
  font-size: 34px;
  margin: ${props =>
    props.smallDevice ? '8px 10px 0px 6px' : '6px 2px 0px 2px'};

  :hover {
    cursor: pointer;
    font-size: 36px;
    margin: 5px 1px 0px 1px;
  }
`

const GoBack = styled.div`
  display: ${props => (props.transition || props.hide ? 'none' : 'block')};
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 13px;
  font-weight: bold;
  color: ${props => props.theme.dark};

  :hover {
    cursor: pointer;
    font-size: 14px;
    left: 9px;
  }
`

const mapStateToProps = state => {
  return {
    smallDevice: state.ui.smallDevice,
    inputMethod: state.ui.inputMethod,
    currentUser: state.auth.currentUser,
    photos: state.collections.photos,
    collections: state.collections.collections
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadPhotos: photos => dispatch(actions.loadPhotos(photos)),
    getCollections: owner => dispatch(actions.getCollections(owner)),
    createCollection: collection =>
      dispatch(actions.createCollection(collection)),
    addToCollection: (collection, index, photos) =>
      dispatch(actions.addToCollection(collection, index, photos))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsModal)
