import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

class Search extends Component {
  state = {
    value: ''
  }

  componentDidMount() {
    this.props.history.listen(this.locationListener)
  }

  changeHandler = event => {
    this.setState({ value: event.target.value.toLowerCase() }, () => {
      if (this.props.onChange) this.props.onChange(event)
    })
  }

  locationListener = event => {
    if (event.pathname === '/') this.setState({value: ''})
  }

  render() {
    return (
      <StyledSearch
        type="search"
        id={this.props.id}
        value={this.state.value}
        list={this.props.list}
        onChange={this.changeHandler}
        placeholder='search'
        hidden={this.props.hidden}
      />
    )
  }
}

const StyledSearch = styled.input`
  box-sizing: border-box;
  position: relative;
  height: ${props => (props.smallDevice ? '18px' : '26px')};
  width: ${props => (props.hidden ? '0px' : '100%')};
  padding: ${props => (props.hidden ? null : '4px 6px')};
  border: 1px solid ${props => props.theme.dark};
  border: ${props => (props.hidden ? 'none' : null)};
  border-radius: 5px;
  outline: none;
  background-color: white;

  :focus {
    border: 2px solid ${props => props.theme.dark};
  }
`

export default withRouter(Search)
