import { Component } from 'react'
import styled from 'styled-components'

class Radio extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value || null
    }

    this.validateProps()
  }

  componentDidMount() {
    this.initializeMultipleValues()
  }

  validateProps = () => {
    if (!this.props.options || !typeof this.props.options === 'array' || this.props.options.length < 2)
      throw new Error("<Input type='radio' /> must have two or more options, passed in an array")
  }

  initializeMultipleValues = () => {
    if (!this.props.multiple) return
    let value = {}
    this.props.options.forEach(option => {
      value[option] = false
    })
    this.setState({ value }, () => (document.getElementById(this.props.id).value = value))
  }

  onFocusHandler = event => {
    document.addEventListener('keydown', this.radioOptionClickedHandler)
    if (this.props.onFocus) this.props.onFocus(event)
  }

  onBlurHandler = event => {
    document.removeEventListener('keydown', this.radioOptionClickedHandler)
    if (this.props.onBlur) this.props.onBlur(event)
  }

  radioOptionClickedHandler = event => {
    if (event.type === 'click' || event.keyCode === 32) {
      let valuesObject = this.state.value
      let newValue = event.target.getAttribute('value')
      if (this.props.multiple) {
        valuesObject[newValue] = !valuesObject[newValue]
      } 
      this.setState({ value: this.props.multiple ? valuesObject : newValue }, () => {
        document.getElementById(this.props.id).value = this.state.value
        if (this.props.onChange) this.props.onChange(event)
      })
    }
  }

  render() {
    return (
      <Container id={this.props.id} value={this.state.value} vertical={this.props.vertical}>
        {this.props.options.map(option => {
          return (
            <Option
              key={option}
              value={option}
              onClick={this.radioOptionClickedHandler}
              onChange={this.radioOptionClickedHandler}
              onFocus={this.onFocusHandler}
              onBlur={this.onBlurHandler}
              tabIndex='0'
            >
              <RadioIcon value={option} onClick={this.radioOptionClickedHandler}>
                {
                  <i className='material-icons' value={option} onClick={this.radioOptionClickedHandler}>
                    {this.props.multiple && this.state.value
                      ? this.state.value[option]
                        ? 'radio_button_checked'
                        : 'radio_button_unchecked'
                      : this.state.value === option
                      ? 'radio_button_checked'
                      : 'radio_button_unchecked'}
                  </i>
                }
              </RadioIcon>
              {option}
            </Option>
          )
        })}
      </Container>
    )
  }
}

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  width: auto;
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  justify-content: flex-start;
  align-items: ${props => (props.vertical ? 'flex-start' : 'center')};
  user-select: none;
  padding: 0px 5px;
  font-size: 14px;
`
const Option = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 30px;
  padding: 2px 6px;

  :focus {
    outline: none;
    border: 1px dotted ${props => props.theme.dark};
    padding: 1px 5px;
  }
`

const RadioIcon = styled.div`
  font-size: 20px;
  height: ${props => (props.smallDevice ? '18px' : '26px')};
  margin-right: 4px;
  margin-top: 6px;
`

export default Radio
