import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { db } from 'utils/firebase'
import * as actions from 'store/actions'
import Text from 'components/Text'
import Loader from 'components/loader/Loader'
import PreRenderPhotos from 'utils/PreRenderPhotos'
import Photo from 'components/Photo'
import Share from 'components/Share'
import Alert from 'components/Alert'
import sendEmail from './photoView/sendEmail'
import Input, { getInputValue } from 'components/NewInput'
import { collection, doc, getDoc, getDocs, query, updateDoc, where, arrayUnion, increment, writeBatch } from 'firebase/firestore'
import store from 'store/store'

class PhotoView extends Component {
  state = {
    loading: true,
    showLoadingPercentage: true,
    photoHover: false,
    windowBusy: false,
    showInfo: this.props.smallDevice ? false : true,
    transitionBusy: false,
    enableTransition: true,
    keyboardVisible: false,
    displayTagSuggestionNotification: false
  }

  loadingPercentage = 0
  smallDeviceLandscape = this.props.smallDeviceLandscape
  photosViewed = []
  maxWidthWide = 0
  maxHeightWide = 0
  maxWidthTall = 0
  maxHeightTall = 0

  componentDidMount() {
    this.populateCurrentPhotoCollection()
    this.setAvailableWidth()
    this.listenForKeyboardInput()
    this.listenForScreenChange()
  }

  componentDidUpdate() {
    this.handleOrientationChange()
  }

  componentWillUnmount() {
    this.removeListeners()
    this.props.populatePhotoCollection(null)
  }

  listenForKeyboardInput = () => {
    window.addEventListener('keyup', this.keyPressedHandler)
  }

  listenForScreenChange = () => {
    window.addEventListener('resize', this.photoLoaded)
  }

  removeListeners = () => {
    window.removeEventListener('keyup', this.keyPressedHandler)
    window.removeEventListener('resize', this.photoLoaded)
    if (this.tagSuggestNotificationTimeout) clearTimeout(this.tagSuggestNotificationTimeout)
  }

  handleOrientationChange = () => {
    if (this.smallDeviceLandscape === this.props.smallDeviceLandscape) return
    this.smallDeviceLandscape = this.props.smallDeviceLandscape
    this.setAvailableWidth()
    this.setState({ enableTransition: false })
    setTimeout(() => {
      this.setState({ enableTransition: true })
    }, 300)
  }

  populateCurrentPhotoCollection = async () => {
    const photoId = this.props.match.params.photoId

    if (this.props.currentPhotoCollection) {
      await this.populateCurrentPhotoData()
      this.setState({ loading: false })
      return
    }

    let photoArray = []
    getDoc(doc(db, 'photos', photoId))
      .then(async photo => {
        if (photo.data().stock) photoArray = [photo.data()]
        else {
          photoArray = await getDocs(query(collection(db, 'photos'), where('album', '==', photo.data().album)))
            .then(photos => photos.docs.map(doc => doc.data()))
            .catch(error => this.props.setError(error, true, 'Failed to get photos in PhotoView component.'))

          photoArray.sort((a, b) => {
            if (a.originalFileName < b.originalFileName) return -1
            if (a.originalFileName > b.originalFileName) return 1
            return 0
          })
        }
        this.props.populatePhotoCollection(photoArray)
        await this.populateCurrentPhotoData()
        this.setState({ loading: false })
      })
      .catch(error => this.props.setError(error, true, 'Failed to get photo data in PhotoView component.'))
  }

  populateCurrentPhotoData = async () => {
    const photoId = this.props.match.params.photoId
    this.findScrollPosition(photoId)
    this.currentPhotoData = this.props.currentPhotoCollection[this.scrollPosition]
    this.currentPhotoData.albumData = await this.getAlbumData()
    this.populateTags()
    this.updatePhotoViews()
    return
  }

  findScrollPosition = photoId => {
    if (this.scrollPosition !== undefined) return
    this.scrollPosition = this.props.currentPhotoCollection.findIndex(photo => photo.id === photoId)
  }

  getAlbumData = async () => {
    if (this.albumData && this.currentPhotoData.album === this.albumData.id) return this.albumData
    if (this.currentPhotoData.stock) return { name: 'Stock' }
    return getDoc(doc(db, 'albums', this.currentPhotoData.album))
      .then(album => {
        this.albumData = album.data()
        return this.albumData
      })
      .catch(error => this.props.setError(error, true, 'Failed to get album data in PhotoView component.'))
  }

  populateTags = () => {
    this.tags = this.currentPhotoData.tags.map(tag => {
      return (
        <Tag onClick={this.descriptionLinkClicked} id={tag} key={tag} clickable={this.props.currentUser && this.props.currentUser.staff}>
          {tag}
        </Tag>
      )
    })
  }

  setAvailableWidth = () => {
    this.availableWidth = `${window.innerWidth - 20}px`
  }

  updatePhotoViews = () => {
    if (!this.props.currentUser) return
    const id = this.currentPhotoData.id
    if (this.photosViewed.includes(id)) return
    this.photosViewed.push(id)
    updateDoc(doc(db, 'photos', id), {
      views: arrayUnion(this.props.currentUser.uid)
    }).catch(error => this.props.setError(error, false, 'Failed to update photo views in PhotoView component.'))
  }

  updatePhotoDownloads = id => {
    updateDoc(doc(db, 'photos', id), {
      downloads: increment(1)
    }).catch(error => this.props.setError(error, false, 'Failed to update photo downloads in PhotoView component.'))
  }

  keyPressedHandler = event => {
    if (document.activeElement.id === 'suggestTagsInput') return
    switch (event.key) {
      case 'ArrowLeft':
        this.previous()
        break
      case 'ArrowRight':
        this.next()
        break
      default:
        break
    }
  }

  next = () => {
    this.scrollPosition += 1
    if (this.scrollPosition === this.props.currentPhotoCollection.length) this.scrollPosition = 0
    this.scrollPhoto()
  }

  previous = () => {
    this.scrollPosition -= 1
    if (this.scrollPosition < 0) this.scrollPosition = this.props.currentPhotoCollection.length - 1
    this.scrollPhoto()
  }

  scrollPhoto = async () => {
    this.setState({ photoLoading: true, loading: true })
    this.props.history.replace('/photos/' + this.props.currentPhotoCollection[this.scrollPosition].id)
    await this.populateCurrentPhotoData()
    this.resetSuggestTagsInputValue()
    this.setState({ loading: false })
  }

  touchStart = event => {
    this.tagScrollPosition = document.getElementById('tags').scrollTop
    if (event.touches.length > 1) return
    this.startX = event.touches[0].screenX
    this.currentX = event.touches[0].screenX
    this.startY = event.touches[0].screenY
    this.currentY = event.touches[0].screenY
  }

  touchMove = event => {
    if (event.touches.length > 1) return
    this.currentX = event.touches[0].screenX
    this.currentY = event.touches[0].screenY
  }

  touchEnd = () => {
    if (this.tagScrollPosition !== document.getElementById('tags').scrollTop) return
    if (Math.abs(this.currentX - this.startX) < 5) return
    if (Math.abs(this.currentY - this.startY) > 50) return
    if (this.startX > this.currentX) this.next()
    if (this.startX < this.currentX) this.previous()
  }

  preRenderProgress = (percentCompleted, event) => {
    this.getMaximumDimensions(event.target)
    this.loadingPercentage = Math.round(percentCompleted)
    this.forceUpdate()
    if (this.loadingPercentage === 100 && this.currentPhotoData.albumData) {
      this.setState({ loading: false })
      setTimeout(() => {
        if (!this.startX) {
          this.setState({ photoHover: false })
        }
      }, 2000)
    }
  }

  getMaximumDimensions = photo => {
    if (photo.naturalWidth > photo.naturalHeight) {
      //Wide photo
      if (photo.naturalWidth > this.maxWidthWide) this.maxWidthWide = photo.naturalWidth
      if (photo.naturalHeight > this.maxHeightWide) this.maxHeightWide = photo.naturalHeight
    } else {
      //Tall photo
      if (photo.naturalWidth > this.maxWidthTall) this.maxWidthTall = photo.naturalWidth
      if (photo.naturalHeight > this.maxHeightTall) this.maxHeightTall = photo.naturalHeight
    }
  }

  descriptionLinkClicked = event => {
    if (event.target.id === 'album') this.props.history.push(`/albums/${this.currentPhotoData.album}`)
    else if (this.props.currentUser.staff) this.props.history.push(`/search/${event.target.innerHTML}`)
  }

  downloadHandler = event => {
    event.stopPropagation()
    this.props.populatePhotoCollection([this.currentPhotoData])
    this.props.download(this.currentPhotoData.id)
  }

  showHideInfo = () => {
    this.setAvailableWidth()
    this.photoHover = this.state.photoHover
    this.infoWidth = `${document.getElementById('info').clientWidth}px`

    if (!this.state.showInfo) {
      this.setState({
        transitionBusy: true,
        showInfo: true,
        photoHover: false
      })
      document.getElementById('info').scrollTo(0, 0)
      document.getElementById('tags').scrollTo(0, 0)
    } else {
      this.setState({
        transitionBusy: true,
        showInfo: false,
        photoHover: false
      })
    }
  }

  transitionEnd = () => {
    this.transitionListenerAdded = true
    this.photoLoaded()
    this.setState({ transitionBusy: false, photoHover: this.photoHover })
  }

  suggestTagsInputFocusToggle = type => {
    if (this.props.inputMethod === 'mouse') return
    if (type === 'focus') {
      this.setState({ keyboardVisible: true })
    } else {
      setTimeout(() => {
        if (document.activeElement.id !== 'suggestTagsInput') this.setState({ keyboardVisible: false })
      }, 10)
    }
  }

  submitTagSuggestion = async () => {
    let suggestedTags = document
      .getElementById('suggestTagsInput')
      .value.split(',')
      .map(tag => {
        if (!this.currentPhotoData.tags.includes(tag.trim())) {
          store.dispatch(actions.addPendingFormItems({ tags: tag.trim() }))
          return tag.trim()
        } else return null
      })
      .filter(tag => tag !== null)

    let batch = writeBatch(db)
    batch.set(
      doc(db, 'tagSuggestions', this.currentPhotoData.id),
      {
        album: this.currentPhotoData.album || 'Stock',
        id: this.currentPhotoData.id,
        webURL: this.currentPhotoData.webURL,
        currentTags: this.currentPhotoData.tags,
        suggestedTags: []
      },
      { merge: true }
    )
    batch.update(doc(db, 'tagSuggestions', this.currentPhotoData.id), {
      suggestedTags: arrayUnion({
        suggestedBy: this.props.currentUser.email,
        suggestedTags: suggestedTags
      })
    })
    batch
      .commit()
      .then(() => {
        this.tagSuggestNotification = (
          <Text fade margin='10px 0px'>
            Tag suggestion submitted successfully
          </Text>
        )
        this.forceUpdate()
        this.tagSuggestNotificationTimeout = setTimeout(() => {
          this.tagSuggestNotification = null
          this.forceUpdate()
        }, 3000)
      })
      .catch(error => this.props.setError(error, false, 'Failed to submit tag suggestion in PhotoView component.'))
  }

  createTagOptions = () => {
    this.tagOptions = []
    this.props.approved.tags.forEach(item => {
      this.tagOptions.push(item.key)
    })
  }

  resetCounter = 0
  resetSuggestTagsInputValue = () => {
    this.resetCounter += 1
    this.forceUpdate()
  }

  toggleOverlay = event => {
    if (!['next', 'previous'].includes(event.target.id)) this.setState({ photoHover: !this.state.photoHover })
  }

  photoHoverEnter = () => {
    this.setState({ photoHover: true })
  }

  photoHoverLeave = event => {
    if (!['Show info', 'Download photo'].includes(event.target.id)) this.setState({ photoHover: false })
  }

  photoLoaded = () => {
    let photo = document.getElementById('photo')
    if (photo) {
      this.overlayWidth = `${photo.clientWidth}px`
      this.overlayHeight = `${photo.clientHeight}px`
      this.overlayTop = `${photo.offsetTop}px`
      this.overlayLeft = `${photo.offsetLeft}px`
      this.forceUpdate()
    }
    if (
      document.querySelectorAll(':hover')[document.querySelectorAll(':hover').length - 1] &&
      document.querySelectorAll(':hover')[document.querySelectorAll(':hover').length - 1].id === 'overlay'
    )
      this.setState({ photoHover: true })
  }

  showShare = () => {
    this.shareWindow = <Share hideShare={this.hideShare} />
    this.forceUpdate()
  }

  hideShare = event => {
    if (!['container', 'backdrop'].includes(event.target.id)) return
    this.shareWindow = null
    this.forceUpdate()
  }

  addToCollection = () => {
    this.props.loadPhotos([this.currentPhotoData])
  }

  reportPhotoHandler = async () => {
    this.alert = (
      <Alert
        alertType='Report photo'
        alertButtonClicked={this.reportPhoto}
        backdropClicked={this.hideAlert}
        anonymousUser={this.props.currentUser && this.props.currentUser.email ? false : true}
      />
    )
    this.forceUpdate()
  }

  reportPhoto = async event => {
    if (event.target.id === 'cancel') return this.hideAlert(null, true)
    const reason = getInputValue('reportPhotoReason')
    const email = getInputValue('reportPhotoEmail')

    if (reason === '' || email === '') return
    this.setState({ loading: true, showLoadingPercentage: false })
    await updateDoc(doc(db, 'photos', this.currentPhotoData.id), {
      reported: true,
      reportedData: arrayUnion({
        reportedBy: this.props.currentUser.staff ? this.props.currentUser.fullName : 'anonymous',
        email: this.props.currentUser.anonymous ? email : this.props.currentUser.email,
        reason
      })
    }).catch(error => this.props.setError(error, false, 'Failed to report photo in PhotoView component.'))

    await sendEmail()
    this.alert = <Alert alertType='Photo reported successfully' alertButtonClicked={() => this.hideAlert(null, true)} backdropClicked={this.hideAlert} />
    this.setState({ loading: false, showLoadingPercentage: true })
  }

  hideAlert = (event, cancel) => {
    if (
      (event && event.target.id !== 'modalBackdrop') ||
      (getInputValue('reportPhotoReason') !== '' && !cancel) ||
      (!this.props.currentUser.staff && getInputValue('reportPhotoEmail') !== '' && !cancel)
    )
      return
    this.alert = null
    this.forceUpdate()
  }

  render() {
    let smallDevice = this.props.smallDevice
    let smallDeviceLandscape = this.props.smallDeviceLandscape
    if ((this.state.loading && this.state.showLoadingPercentage) || !this.props.currentPhotoCollection)
      return (
        <StyledLoader>
          <PreRenderPhotos preRenderProgress={this.preRenderProgress} />
          <Loader percent={this.loadingPercentage} />
        </StyledLoader>
      )
    else
      return (
        <React.Fragment>
          <StyledPhotoView
            showInfo={this.state.showInfo}
            smallDevice={smallDevice}
            smallDeviceLandscape={smallDeviceLandscape}
            onTouchStart={this.touchStart}
            onTouchMove={this.touchMove}
            onTouchEnd={this.touchEnd}
          >
            <Info
              id='info'
              showInfo={this.state.showInfo}
              onTransitionEnd={this.transitionEnd}
              transitionBusy={this.state.transitionBusy}
              enableTransition={this.state.enableTransition}
              availableWidth={this.availableWidth}
              smallDeviceLandscape={smallDeviceLandscape}
              smallDevice={smallDevice}
            >
              <Title
                width={this.infoWidth}
                transitionBusy={this.state.transitionBusy}
                availableWidth={this.availableWidth}
                smallDeviceLandscape={smallDeviceLandscape}
                smallDevice={smallDevice}
              >{`Photo ${this.scrollPosition + 1}/${this.props.currentPhotoCollection.length}`}</Title>
              {this.currentPhotoData.stock ? null : (
                <PhotoDetails
                  transitionBusy={this.state.transitionBusy}
                  width={this.infoWidth}
                  availableWidth={this.availableWidth}
                  keyboardVisible={this.state.keyboardVisible}
                  smallDeviceLandscape={smallDeviceLandscape}
                  smallDevice={smallDevice}
                >
                  <PhotoDetailTitle smallDevice={smallDevice}>Album</PhotoDetailTitle>
                  <PhotoDetail
                    clickable
                    onClick={this.descriptionLinkClicked}
                    id='album'
                    name={this.currentPhotoData.id.slice(0, this.currentPhotoData.id.length - 4)}
                    smallDevice={smallDevice}
                  >
                    {this.currentPhotoData.albumData.name}
                  </PhotoDetail>

                  <PhotoDetailTitle smallDevice={smallDevice}>Date</PhotoDetailTitle>
                  <PhotoDetail id='date' smallDevice={smallDevice}>
                    {new Date(this.currentPhotoData.albumData.date.toMillis()).toLocaleDateString('en-ZA', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </PhotoDetail>

                  <PhotoDetailTitle smallDevice={smallDevice}>Venue</PhotoDetailTitle>
                  <PhotoDetail smallDevice={smallDevice} id='venue'>
                    {this.currentPhotoData.albumData.venue}
                  </PhotoDetail>

                  {this.props.currentUser && this.props.currentUser.staff ? (
                    <React.Fragment>
                      <PhotoDetailTitle smallDevice={smallDevice}>Faculty/Division</PhotoDetailTitle>
                      <PhotoDetail smallDevice={smallDevice} id='faculty'>
                        {this.currentPhotoData.albumData.faculty}
                      </PhotoDetail>
                    </React.Fragment>
                  ) : null}

                  {this.props.currentUser && this.props.currentUser.staff ? (
                    <React.Fragment>
                      <PhotoDetailTitle smallDevice={smallDevice}>Department</PhotoDetailTitle>
                      <PhotoDetail smallDevice={smallDevice} id='department'>
                        {this.currentPhotoData.albumData.department}
                      </PhotoDetail>
                    </React.Fragment>
                  ) : null}

                  {this.props.currentUser && this.props.currentUser.staff ? (
                    <React.Fragment>
                      <PhotoDetailTitle smallDevice={smallDevice}>Contact person</PhotoDetailTitle>
                      <PhotoDetail smallDevice={smallDevice} id='contactPerson'>
                        {this.currentPhotoData.albumData.contact}
                      </PhotoDetail>
                    </React.Fragment>
                  ) : null}

                  <PhotoDetailTitle smallDevice={smallDevice}>Photographer</PhotoDetailTitle>
                  <PhotoDetail id='photographer' smallDevice={smallDevice}>
                    {this.currentPhotoData.albumData.photographer}
                  </PhotoDetail>
                </PhotoDetails>
              )}

              <TagsSection
                width={this.infoWidth}
                transitionBusy={this.state.transitionBusy}
                availableWidth={this.availableWidth}
                smallDeviceLandscape={smallDeviceLandscape}
                anonymousUser={this.props.currentUser && this.props.currentUser.anonymous}
                smallDevice={smallDevice}
              >
                <PhotoDetailTitle smallDevice={smallDevice}>Tags</PhotoDetailTitle>
                <Tags id='tags' availableWidth={this.availableWidth} smallDeviceLandscape={smallDeviceLandscape} smallDevice={smallDevice}>
                  {this.currentPhotoData.tags.length > 0 ? this.tags : 'None'}
                </Tags>
              </TagsSection>
              {this.props.currentUser && this.props.currentUser.staff ? (
                <SuggestTags smallDevice={smallDevice}>
                  {this.tagSuggestNotification || (
                    <React.Fragment>
                      <Input
                        id='suggestTagsInput'
                        type='autocomplete'
                        onFocus={this.suggestTagsInputFocusToggle}
                        onBlur={this.suggestTagsInputFocusToggle}
                        resetCounter={this.resetCounter}
                        optionsArray={this.props.approved.tags.map(item => item.key) || <option />}
                        placeholder='Suggest tags'
                        width='300px'
                        height='25px'
                        margin='0px 0px 0px 10px'
                        multiple
                      />
                      <i className='material-icons' id='Submit' onClick={this.submitTagSuggestion} title='Submit'>
                        playlist_add_check_circle
                      </i>
                    </React.Fragment>
                  )}
                </SuggestTags>
              ) : null}
            </Info>

            <Explorer smallDevice={smallDevice}>
              {smallDevice ? null : (
                <Previous title='Previous'>
                  <i className='material-icons' onClick={this.previous}>
                    navigate_before
                  </i>
                </Previous>
              )}

              <PhotoArea
                id='photoArea'
                maxHeightWide={this.maxHeightWide}
                keyboardVisible={this.state.keyboardVisible}
                inputMethod={this.props.inputMethod}
                smallDevice={smallDevice}
              >
                <Photo src={this.currentPhotoData.webURL} alt={this.currentPhotoData.id} onLoad={this.photoLoaded} id='photo' />

                <Overlay
                  photoHover={this.state.photoHover}
                  onMouseEnter={this.photoHoverEnter}
                  onMouseLeave={this.photoHoverLeave}
                  onClick={smallDevice ? this.toggleOverlay : null}
                  width={this.overlayWidth}
                  height={this.overlayHeight}
                  top={this.overlayTop}
                  left={this.overlayLeft}
                  id='overlay'
                  hidden={this.state.transitionBusy}
                  keyboardVisible={this.state.keyboardVisible}
                  smallDevice={smallDevice}
                >
                  {smallDevice ? null : (
                    <React.Fragment>
                      <PhotoToolbarLeft id='toolbar'>
                        <PhotoDownload title='Download photo'>
                          <i className='material-icons' onClick={this.downloadHandler}>
                            save_alt
                          </i>
                        </PhotoDownload>
                        {this.props.currentUser && this.props.currentUser.staff ? (
                          <PhotoShare title='Share photo'>
                            <i className='material-icons' onClick={this.showShare} id='Share'>
                              share
                            </i>
                          </PhotoShare>
                        ) : null}
                        {this.props.currentUser && this.props.currentUser.staff ? (
                          <PhotoAdd title='Add to collection'>
                            <i className='material-icons' onClick={this.addToCollection} id='Add to collection'>
                              add_circle_outline
                            </i>
                          </PhotoAdd>
                        ) : null}
                        <PhotoReport title='Report photo'>
                          <i className='material-icons-outlined' onClick={this.reportPhotoHandler} id='Report photo'>
                            report
                          </i>
                        </PhotoReport>
                      </PhotoToolbarLeft>
                      <PhotoToolbarRight>
                        <PhotoInfo title={this.state.showInfo ? 'Hide info' : 'Show info'}>
                          <i className='material-icons-outlined' onClick={this.showHideInfo} id={this.state.showInfo ? 'Hide info' : 'Show info'}>
                            info
                          </i>
                        </PhotoInfo>
                      </PhotoToolbarRight>
                    </React.Fragment>
                  )}
                </Overlay>
              </PhotoArea>
              {smallDevice ? null : (
                <Next smallDevice={smallDevice} onClick={this.next} title='Next'>
                  <i className='material-icons'>navigate_next</i>
                </Next>
              )}
            </Explorer>
          </StyledPhotoView>

          <Footer smallDevice={smallDevice}>
            {this.props.currentUser ? (
              <ShowHideInfo smallDevice={smallDevice} onClick={this.showHideInfo} title={this.state.showInfo ? 'Hide info' : 'Show info'}>
                {this.state.showInfo ? 'hide info' : 'show info'}
              </ShowHideInfo>
            ) : null}
            <Buttons>
              <ReportButton onClick={this.reportPhotoHandler} smallDevice={smallDevice} title='Report photo'>
                <i className='material-icons-outlined' onClick={this.reportPhotoHandler}>
                  report
                </i>
              </ReportButton>
              <AddButton
                onClick={this.addToCollection}
                hidden={!this.props.currentUser || !this.props.currentUser.staff}
                smallDevice={smallDevice}
                title='Add to collection'
              >
                <i className='material-icons' onClick={this.addToCollection}>
                  add_circle_outline
                </i>
              </AddButton>
              <ShareButton
                smallDevice={smallDevice}
                disabled={this.state.nothingFound}
                onClick={this.showShare}
                hidden={!this.props.currentUser || !this.props.currentUser.staff}
                title='Share'
              >
                <i className='material-icons' onClick={this.showShare}>
                  share
                </i>
              </ShareButton>
              <DownloadButton smallDevice={smallDevice} title='Download' onClick={this.downloadHandler}>
                <i className='material-icons'>save_alt</i>
              </DownloadButton>
            </Buttons>
          </Footer>
          {this.shareWindow}
          {this.alert ? this.state.loading ? <Loader /> : this.alert : null}
        </React.Fragment>
      )
  }
}

const StyledPhotoView = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  grid-area: main;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledLoader = styled.div`
  grid-area: main;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Info = styled.div`
  position: ${props => (props.smallDevice ? 'absolute' : 'relative')};
  box-sizing: border-box;
  height: 100%;
  width: ${props => (props.showInfo ? (props.smallDevice ? props.availableWidth : '700px') : '0px')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  overflow-y: ${props => (props.smallDeviceLandscape ? 'scroll' : null)};
  color: ${props => props.theme.main};
  background-color: ${props => props.theme.bg};
  border-right: ${props => (props.smallDevice ? '0px' : '1px')} solid ${props => (props.showInfo ? props.theme.main : props.theme.bg)};
  transition: ${props => (props.smallDevice ? (props.enableTransition ? 'width 0.4s linear' : null) : 'width 0.5s ease-out')};
  z-index: ${props => (props.smallDevice ? 2 : null)};
`

const Title = styled.div`
  position: relative;
  box-sizing: border-box;
  width: ${props => (props.smallDevice ? props.availableWidth : props.transitionBusy ? props.width : '100%')};
  flex-shrink: 0;
  font-size: ${props => (props.smallDevice ? (props.smallDeviceLandscape ? '14px' : '18px') : '20px')};
  font-weight: bold;
  color: ${props => props.theme.dark};
  padding-bottom: ${props => (props.smallDeviceLandscape ? '6px' : '20px')};
  overflow: hidden;
`

const PhotoDetails = styled.div`
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  width: ${props => (props.smallDevice ? props.availableWidth : props.transitionBusy ? props.width : '100%')};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${props => props.theme.dark};
  font-size: ${props => (props.smallDevice ? 'initial' : '16px')};
  opacity: ${props => (props.smallDevice && props.keyboardVisible ? 0 : 1)};
  transition: ${props => (props.smallDevice ? 'opacity 0.5s linear' : null)};
`

const PhotoDetailTitle = styled.div`
  font-weight: bold;
  padding-left: 10px;
  font-size: ${props => (props.smallDevice ? '12px' : 'initial')};
`

const PhotoDetail = styled.div`
  padding-left: 20px;
  font-size: ${props => (props.smallDevice ? '14px' : 'initial')};
  padding-top: ${props => (props.smallDevice ? '2px' : null)};
  padding-bottom: ${props => (props.smallDevice ? '10px' : null)};
  overflow: hidden;
  margin: 4px 0px 15px 0px;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-weight: ${props => (props.clickable ? 'bold' : null)};
      font-size: ${props => (props.clickable ? '15.7px' : null)};
      cursor: ${props => (props.clickable ? 'pointer' : 'select')};
    }
  }
`

const TagsSection = styled.div`
  position: ${props => (props.smallDevice ? (props.smallDevice || props.anonymousUser ? 'relative' : 'absolute') : 'initial')};
  bottom: 0px;
  box-sizing: border-box;
  width: ${props => (props.smallDevice ? props.availableWidth : props.transitionBusy ? props.width : '100%')};
  height: ${props => (props.smallDevice ? (props.smallDeviceLandscape ? '80px' : '160px') : '100%')};
  display: flex;
  flex-shrink: ${props => (props.smallDeviceLandscape ? 0 : 1)};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${props => props.theme.dark};
  background-color: ${props => props.theme.bg};
  overflow: hidden;
  padding-bottom: ${props => (props.smallDeviceLandscape ? '0px' : '4px')};
`

const Tags = styled.div`
  position: relative;
  box-sizing: border-box;
  width: ${props => (props.smallDevice ? `calc(${props.availableWidth} - 40px)` : '100%')};
  height: auto;
  max-height: ${props => (props.smallDevice ? '85px' : null)};
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: ${props => (props.smallDevice ? null : '10px 20px')};
  margin: ${props => (props.smallDevice ? '10px 0px 0px 20px' : null)};
  font-size: ${props => (props.smallDevice ? '14px' : 'initial')};
`

const Tag = styled.div`
  width: auto;
  white-space: nowrap;
  overflow-y: hidden;
  border: 1px solid ${props => props.theme.dark};
  border-radius: 5px;
  padding: ${props => (props.smallDevice ? '2px 6px' : '2px 10px')};
  margin: ${props => (props.smallDevice ? '0px 6px 6px 0px' : '0px 8px 8px 0px')};
  background-color: ${props => props.theme.light};
  color: ${props => props.theme.dark};
  font-size: ${props => (props.smallDevice ? '14px' : 'initial')};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      border-width: ${props => (props.clickable ? '2px' : '1px')};
      margin: ${props => (props.clickable ? '0px 6px 6px 0px' : '0px 8px 8px 0px')};
      cursor: ${props => (props.clickable ? 'pointer' : 'default')};
    }
  }
`

const SuggestTags = styled.div`
  position: ${props => (props.smallDevice ? 'absolute' : 'relative')};
  bottom: 10px;
  height: auto;
  box-sizing: border-box;
  display: ${props => (props.smallDeviceLandscape ? 'none' : 'flex')};
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  width: ${props => (props.smallDevice ? props.availableWidth : '100%')};
  color: ${props => props.theme.dark};
  padding-top: ${props => (props.smallDevice ? (props.smallDeviceLandscape ? '0px' : '10px') : '20px')};
  padding-bottom: ${props => (props.smallDevice ? '10px' : '20px')};

  i {
    font-size: 30px;
    margin: 1px 1px 1px 8px;
    @media (hover: hover) and (pointer: fine) {
      :hover {
        cursor: pointer;
        font-size: 32px;
        margin: 0px 0px 0px 7px;
      }
    }
  }
`

const Explorer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: ${props => (props.smallDevice ? 'flex' : 'grid')};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-template-rows: 50px 1fr 50px;
  grid-template-columns: 100px 1fr 100px;
  grid-template-areas:
    'previous . next'
    'previous photo next'
    'previous . next';
  color: ${props => props.theme.main};
  overflow: hidden;
`

const PhotoArea = styled.div`
  position: ${props => (props.smallDevice ? 'relative' : 'absolute')};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-width: ${props => (props.smallDevice ? '100%' : null)};
  max-height: ${props => (props.smallDevice ? '100%' : null)};
  grid-area: ${props => (props.smallDevice ? null : 'photo')};
  display: ${props => (props.keyboardVisible ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  transition: ${props => (props.smallDevice ? 'height 1s ease' : null)};
`

const Overlay = styled.div`
  position: absolute;
  opacity: ${props => (props.photoHover ? (props.hidden ? '0' : '1') : '0')};
  width: ${props => props.width};
  height: ${props => props.height};
  top: ${props => props.top};
  left: ${props => props.left};
  display: ${props => (props.keyboardVisible ? 'none' : 'flex')};
  justify-content: space-between; //from mobile
  align-items: center; //from mobile
`

const Previous = styled.div`
  position: ${props => (props.smallDevice ? 'absolute' : 'initial')};
  top: calc(50% - 20px);
  left: 10px;
  font-size: 60px;
  grid-area: ${props => (props.smallDevice ? null : 'previous')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme[props.smallDevice ? 'main' : 'dark']};
  text-shadow: ${props => (props.smallDevice ? `1px 1px 1px ${props.theme.dark}` : null)};
  opacity: ${props => (props.smallDevice ? (props.photoHover ? 1 : 0) : 1)};

  @media (hover: hover) and (pointer: fine) {
    i:hover {
      font-size: 64px;
      cursor: pointer;
    }
  }
`

const Next = styled(Previous)`
  grid-area: ${props => (props.smallDevice ? null : 'next')};
  right: 10px;
  left: unset;
`

const PhotoToolbarLeft = styled.div`
  position: absolute;
  bottom: 0px;
  left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-shadow: 1px 1px ${props => props.theme.dark};
  color: ${props => props.theme.bg};
`

const PhotoToolbarRight = styled.div`
  position: absolute;
  bottom: 0px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-shadow: 1px 1px ${props => props.theme.dark};
  color: ${props => props.theme.bg};
`

const PhotoDownload = styled.div`
  font-size: 34px;
  margin: 1px 6px 1px 6px;
  :hover {
    font-size: 36px;
    margin: 0px 5px 0px 5px;
    cursor: pointer;
  }
`

const PhotoShare = styled.div`
  display: ${props => (props.hidden ? 'none' : 'block')};
  font-size: 34px;
  margin: 1px 6px;
  :hover {
    font-size: 36px;
    margin: 0px 5px;
    cursor: pointer;
  }
`

const PhotoAdd = styled.div`
  display: ${props => (props.hidden ? 'none' : 'block')};
  font-size: 34px;
  margin: 1px 6px;
  :hover {
    font-size: 36px;
    margin: 0px 5px;
    cursor: pointer;
  }
`

const PhotoReport = styled.div`
  display: ${props => (props.hidden ? 'none' : 'block')};
  font-size: 34px;
  margin: 1px 6px;
  :hover {
    font-size: 36px;
    margin: 0px 5px;
    cursor: pointer;
  }
`

const PhotoInfo = styled.div`
  font-size: 32px;
  margin: 1px 6px;
  :hover {
    font-size: 34px;
    margin: 0px 5px;
    cursor: pointer;
  }
`

const Footer = styled.div`
  grid-area: footer;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.light};
  color: ${props => props.theme.dark};
`

const ShowHideInfo = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? '80px' : '100px')};
  bottom: 0px;
  margin-top: 20px;
  left: 0px;
  font-size: ${props => (props.smallDevice ? '14px' : '16px')};
  font-weight: bold;
  color: ${props => props.theme.dark};
  border-right: 1px solid ${props => props.theme.dark};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: 17px;
      cursor: pointer;
    }
  }
`

const Buttons = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const ReportButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? '60px' : '80px')};
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '30px' : '40px')};
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: pointer;
      font-size: 43px;
    }
  }
`

const AddButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? '60px' : '80px')};
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '30px' : '40px')};
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: pointer;
      font-size: 43px;
    }
  }
`

const ShareButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? '60px' : '80px')};
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '30px' : '40px')};
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: pointer;
      font-size: 43px;
    }
  }
`

const DownloadButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? '60px' : '80px')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '40px' : '50px')};
  color: ${props => props.theme.dark};
  border-left: 1px solid ${props => props.theme.dark};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: 53px;
      cursor: pointer;
    }
  }
`

const mapStateToProps = state => {
  return {
    currentPhotoCollection: state.browse.currentPhotoCollection,
    searchQuery: state.browse.searchQuery,
    currentUser: state.auth.currentUser,
    approved: state.forms.approved,
    smallDeviceLandscape: state.ui.smallDeviceLandscape,
    smallDevice: state.ui.smallDevice,
    inputMethod: state.ui.inputMethod
  }
}

const mapDispatchToProps = dispatch => {
  return {
    populatePhotoCollection: photoArray => dispatch(actions.populatePhotoCollection(photoArray)),
    loadPhotos: photos => dispatch(actions.loadPhotos(photos)),
    download: (files, folderName) => dispatch(actions.download(files, folderName)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PhotoView))
