import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as actions from 'store/actions'
import styled from 'styled-components'
import { db } from 'utils/firebase'
import PhotoCard from 'components/PhotoCard'
import Alert from 'components/Alert'
import Loader from 'components/loader/Loader'
import Share from 'components/Share'
import Input from 'components/Input'
import Text from 'components/Text'
import { doc, getDoc } from 'firebase/firestore'

class Collections extends Component {
  state = {
    loading: true
  }

  sharedCollections = []
  loggedIn = this.props.currentUser && this.props.currentUser.staff
  sharedCollectionId = this.props.match.params.collectionId
  currentCollection = null
  numOfCollections = null

  componentDidMount() {
    this.props.populatePhotoCollection(null) // NTS: change name to avoid confusion with collections
    if (this.props.currentUser && this.props.currentUser.staff) {
      if (this.props.collections) {
        this.processCollections()
      } else {
        this.collectionsPending = true
        this.props.getCollections(this.props.currentUser.email)
      }
    } else if (this.sharedCollectionId) this.populatePhotos(this.props.match.params.collectionId)
    else this.props.history.replace('/')
  }

  componentDidUpdate() {
    if (this.collectionsPending && this.props.collections) {
      this.collectionsPending = false
      this.processCollections()
    }

    if (this.numOfCollections !== null && this.props.collections && this.numOfCollections !== this.props.collections.length) {
      this.numOfCollections = null
      this.processCollections()
    }

    if (!this.loggedIn && this.props.currentUser && this.props.currentUser.staff) {
      this.loggedIn = true
      this.collectionsPending = true
      this.props.getCollections(this.props.currentUser.email)
    }

    if (this.deletionPending && !this.props.collections.some(collection => collection.id === this.currentCollection.id)) {
      this.deletionPending = false
      this.currentCollection = null
      this.photoArray = []
      this.props.history.replace('/collections')
      if (this.props.collections.length > 0) this.processCollections()
      else this.setState({ loading: false })
    }

    if (this.removePhotosPending) {
      let newCurrentCollection = this.props.collections.find(collection => collection.id === this.currentCollection.id)
      if (newCurrentCollection.photos.length !== this.currentCollection.photos.length) {
        this.currentCollection = newCurrentCollection
        this.removePhotosPending = false
        this.populatePhotos()
      }
    }
  }

  testSharedCollection = () => {
    if (this.sharedCollectionId) return !this.props.collections.some(collection => collection.id === this.sharedCollectionId)
    return false
  }

  processCollections = () => {
    if (this.testSharedCollection()) this.populatePhotos(this.sharedCollectionId)
    else if (this.props.collections.length > 0) {
      this.currentCollection = this.props.collections[0]
      this.props.collections.forEach(collection => {
        if (collection.modified > this.currentCollection.modified || collection.id === this.sharedCollectionId) this.currentCollection = collection
        if (this.sharedCollections.length > 0 && this.sharedCollections[0].id === collection.id) this.sharedCollections = []
      })
      this.props.history.replace(`/collections/${this.currentCollection.id}`)
      this.populatePhotos()
    } else this.setState({ loading: false })
  }

  collectionChanged = event => {
    this.currentCollection =
      this.props.collections.find(collection => collection.id === event.target.value) ||
      this.sharedCollections.find(collection => collection.id === event.target.value)
    this.props.history.replace(`/collections/${this.currentCollection.id}`)
    if (this.props.smallDevice) document.getElementById('selectCollection').blur()
    this.populatePhotos()
  }

  // Populate the photoArray with all images from collection based on collectionId
  populatePhotos = async collectionId => {
    if (collectionId) {
      await getDoc(doc(db, 'collections', collectionId))
        .then(collection => {
          if (collection.data()) {
            this.currentCollection = {
              ...collection.data(),
              id: collection.id,
              shared: true
            }
            this.sharedCollections = [this.currentCollection]
          } else {
            this.props.history.replace('/collections')
            this.sharedCollectionId = null
            this.processCollections()
          }
        })
        .catch(error => {
          this.props.setError(error, true, 'Failed to load collection with photos from database in Collections component.')
        })
    }
    if (this.currentCollection) {
      this.photoArray = this.currentCollection.photos
      this.photoArray.sort((a, b) => {
        if (a.id < b.id) return -1
        else if (a.id > b.id) return 1
        else return 0
      })
    }
    this.calculateToolbarButtons()
    this.state.loading ? this.setState({ loading: false }) : this.forceUpdate()
  }

  copyCollection = event => {
    event.stopPropagation()
    this.setState({ loading: true })
    let collection = {
      name: this.currentCollection.name,
      owner: this.props.currentUser.email,
      photos: this.photoArray,
      modified: new Date()
    }
    this.numOfCollections = this.props.collections.length
    this.sharedCollectionId = null
    this.sharedCollections = []
    this.props.history.replace('/collections')
    this.props.createCollection(collection)
  }

  // Open the photo view for the clicked photo (unless ctrl is pressed, which selects the photo instead)
  photoClickedHandler = event => {
    if (event.target.id.includes('button')) return
    let id = event.target.id.slice(event.target.id.lastIndexOf('-') + 1)
    if (event.ctrlKey || this.selection.length > 0) this.selectPhoto(null, id)
    else {
      this.props.populatePhotoCollection(this.photoArray)
      this.props.history.push('/photos/' + id)
    }
  }

  // Add photo to selection (for downloading a specific set of images)
  selection = []
  selectPhoto = (event, passedId) => {
    let id = passedId || event.target.id.slice(event.target.id.lastIndexOf('-') + 1)
    if (this.selection.includes(id)) this.selection.splice(this.selection.indexOf(id), 1)
    else this.selection.splice(0, 0, id)
    this.forceUpdate()
  }

  selectAll = () => {
    this.selection = this.photoArray.map(photo => photo.id)
    this.forceUpdate()
  }

  clearSelection = event => {
    if (!event || ['explorer', 'Clear selection'].includes(event.target.id)) {
      this.selection = []
      this.forceUpdate()
    }
  }

  downloadHandler = event => {
    event.stopPropagation()
    this.props.populatePhotoCollection(this.photoArray)
    if (event.target.id.includes('button')) this.props.download(event.target.id.slice(event.target.id.lastIndexOf('-') + 1))
    else if (this.selection.length > 0) this.props.download(this.selection, this.currentCollection.name)
    else this.props.download('all', this.currentCollection.name)
  }

  alertButtonClicked = event => {
    if (['continue', 'modalBackdrop'].includes(event.target.id)) {
      this.alert = null
      this.forceUpdate()
    }
  }

  showShare = () => {
    this.shareWindow = <Share hideShare={this.hideShare} />
    this.forceUpdate()
  }

  hideShare = (event) => {
    const id = event.target.id
    if (['url', 'title', 'shareWindow', 'shareBody', 'copyLink', 'shareInputLine', 'shareButton'].includes(id)) return
    this.shareWindow = null
    this.forceUpdate()
  }

  removeFromCollection = event => {
    event.stopPropagation()
    let photosToRemove = []
    event.target.id.includes('button') ? photosToRemove.push(event.target.id.slice(event.target.id.lastIndexOf('-') + 1)) : (photosToRemove = this.selection)
    let index = this.props.collections.findIndex(collection => collection.id === this.currentCollection.id)
    let newPhotoArray = this.currentCollection.photos.filter(photo => !photosToRemove.includes(photo.id))
    this.props.removeFromCollection(this.currentCollection.id, index, newPhotoArray)
    this.removePhotosPending = true
  }

  deleteCollectionWarning = event => {
    event.stopPropagation()
    this.alert = (
      <Alert
        alertType='Delete collection'
        alertButtonClicked={this.deleteCollection}
        width={this.props.smallDevice ? '80%' : null}
        backdropClicked={this.deleteCollection}
      />
    )
    this.forceUpdate()
  }

  deleteCollection = event => {
    if (event) {
      event.stopPropagation()
      if (['cancel', 'modalBackdrop'].includes(event.target.id)) {
        this.alert = null
        this.forceUpdate()
      } else if (event.target.id === 'continue') {
        this.alert = null
        this.deletionPending = true
        this.setState({ loading: true })
        this.props.deleteCollection(this.currentCollection.id)
      }
    } else {
      this.alert = null
      this.forceUpdate()
    }
  }

  calculateToolbarButtons = () => {
    if (!this.props.smallDevice) return
    this.toolbarButtons = 3
    if (this.props.currentUser && this.props.currentUser.staff && this.currentCollection) {
      this.toolbarButtons += 1 //share
      if (this.props.currentUser.email === this.currentCollection.owner) this.toolbarButtons += 2 // delete + remove
      if (this.currentCollection.shared) this.toolbarButtons += 1 //copy
    }
  }

  render() {
    let smallDevice = this.props.smallDevice
    if (this.photoArray) {
      this.photos = this.photoArray.map(photo => {
        return (
          <PhotoCard
            key={photo.id}
            id={photo.id}
            src={photo.thumbURL}
            alt={photo.id}
            onClick={this.photoClickedHandler}
            select={this.selectPhoto}
            selected={this.selection.includes(photo.id)}
            download={this.downloadHandler}
            hideButtons={this.selection.length > 0}
            removeFromCollection={this.removeFromCollection}
            owner={this.props.currentUser && this.props.currentUser.email === this.currentCollection.owner}
          />
        )
      })
    }

    return this.state.loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        {!this.currentCollection ? (
          <NoCollections smallDevice={smallDevice}>You don't have any collections. Please add one or more photos to a collection.</NoCollections>
        ) : (
          <StyledCollections anonymousUser={!this.props.currentUser || this.props.currentUser.anonymous} smallDevice={smallDevice}>
            {this.photos.length > 0 ? (
              <Explorer tabIndex='-1' onClick={this.clearSelection} id='explorer' smallDevice={smallDevice}>
                {this.photos}
              </Explorer>
            ) : (
              <EmptyCollection smallDevice={smallDevice}>This collection is empty.</EmptyCollection>
            )}
            {smallDevice ? (
              <SelectCollection smallDevice={smallDevice}>
                {this.props.collections && (this.props.collections.length > 1 || (this.props.collections.length === 1 && this.sharedCollections.length > 0)) ? (
                  <React.Fragment>
                    <Input
                      type='select'
                      value={this.currentCollection.id}
                      id='selectCollection'
                      changed={this.collectionChanged}
                      width='auto'
                      height='24px'
                      fontSize='18px'
                      backgroundColor='light'
                    >
                      {this.props.collections.map(collection => {
                        return (
                          <option value={collection.id} key={collection.id}>
                            {collection.name}
                          </option>
                        )
                      })}
                      {this.sharedCollections.map(collection => {
                        return (
                          <option value={collection.id} key={collection.id}>
                            {`${collection.name} (shared)`}
                          </option>
                        )
                      })}
                    </Input>
                  </React.Fragment>
                ) : (
                  <Text margin='0px 0px 0px 15px'>{`Collection: ${this.currentCollection.name} ${this.currentCollection.shared ? '(shared)' : ''}`}</Text>
                )}
              </SelectCollection>
            ) : null}
          </StyledCollections>
        )}

        <Footer smallDevice={smallDevice}>
          {this.props.collections && this.props.collections.length > 0 && !smallDevice ? (
            <SelectCollection smallDevice={smallDevice}>
              <Text fontSize='22px'>Collection:</Text>
              <Input type='select' value={this.currentCollection.id} id='selectCollection' changed={this.collectionChanged} width='200px' height='30px'>
                {this.props.collections.map(collection => {
                  return (
                    <option value={collection.id} key={collection.id}>
                      {collection.name}
                    </option>
                  )
                })}
                {this.sharedCollections.map(collection => {
                  return (
                    <option value={collection.id} key={collection.id}>
                      {`${collection.name} (shared)`}
                    </option>
                  )
                })}
              </Input>
            </SelectCollection>
          ) : null}
          <Toolbar smallDevice={smallDevice}>
            {smallDevice ? (
              <React.Fragment>
                <SelectAll
                  onClick={!this.currentCollection ? null : this.selectAll}
                  id='Select all'
                  disabled={!this.currentCollection}
                  toolbarButtons={this.toolbarButtons}
                  smallDevice={smallDevice}
                >
                  <i className='material-icons' id='Select all'>
                    check_box
                  </i>
                </SelectAll>
                <ClearSelection
                  onClick={!this.currentCollection ? null : this.clearSelection}
                  id='Clear selection'
                  disabled={!this.currentCollection}
                  toolbarButtons={this.toolbarButtons}
                  smallDevice={smallDevice}
                >
                  <i className='material-icons' id='Clear selection'>
                    check_box_outline_blank
                  </i>
                </ClearSelection>
              </React.Fragment>
            ) : (
              <SelectionPanel
                keepRight={!this.props.currentUser || !this.props.currentUser.staff}
                disabled={!this.currentCollection}
                sharedCollection={this.currentCollection && this.currentCollection.shared}
              >
                <SelectAll onClick={this.currentCollection ? this.selectAll : null} title='Select all' disabled={!this.currentCollection}>
                  <i className='material-icons' id='Select all'>
                    check_box
                  </i>
                </SelectAll>
                <ClearSelection onClick={this.currentCollection ? this.clearSelection : null} title='Clear selection' disabled={!this.currentCollection}>
                  <i className='material-icons' id='Clear selection'>
                    check_box_outline_blank
                  </i>
                </ClearSelection>
              </SelectionPanel>
            )}

            {this.props.currentUser && this.props.currentUser.staff && this.currentCollection && this.currentCollection.shared ? (
              <CopyButton title='Copy collection' onClick={this.copyCollection} toolbarButtons={this.toolbarButtons} smallDevice={smallDevice}>
                <i className='material-icons' onClick={this.copyCollection}>
                  content_copy
                </i>
              </CopyButton>
            ) : null}
            <RemoveButton
              title='Remove from collection'
              onClick={
                !this.props.collections || this.props.collections.length === 0 || this.currentCollection.shared
                  ? null
                  : this.selection.length > 0
                  ? this.removeFromCollection
                  : null
              }
              hidden={!this.props.currentUser || !this.props.currentUser.staff || (this.currentCollection && this.currentCollection.shared)}
              disabled={!this.props.collections || this.props.collections.length === 0 || this.currentCollection.shared}
              noSelection={this.selection.length < 1}
              toolbarButtons={this.toolbarButtons}
              smallDevice={smallDevice}
            >
              <i
                className='material-icons'
                title='Remove from collection'
                onClick={
                  !this.props.collections || this.props.collections.length === 0 || this.currentCollection.shared
                    ? null
                    : this.selection.length > 0
                    ? this.removeFromCollection
                    : null
                }
              >
                remove_circle_outline
              </i>
            </RemoveButton>
            <DeleteCollection
              title='Delete collection'
              onClick={!this.props.collections || this.props.collections.length === 0 || this.currentCollection.shared ? null : this.deleteCollectionWarning}
              disabled={!this.props.collections || this.props.collections.length === 0 || this.currentCollection.shared}
              hidden={!this.props.currentUser || !this.props.currentUser.staff || (this.currentCollection && this.currentCollection.shared)}
              toolbarButtons={this.toolbarButtons}
              smallDevice={smallDevice}
            >
              <i
                className='material-icons'
                onClick={!this.props.collections || this.props.collections.length === 0 || this.currentCollection.shared ? null : this.deleteCollectionWarning}
              >
                delete
              </i>
            </DeleteCollection>
            <ShareButton
              title='Share collection'
              onClick={!this.props.collections || this.props.collections.length === 0 ? null : this.showShare}
              disabled={!this.currentCollection}
              hidden={!this.props.currentUser || !this.props.currentUser.staff}
              toolbarButtons={this.toolbarButtons}
              smallDevice={smallDevice}
            >
              <i className='material-icons' onClick={!this.props.collections || this.props.collections.length === 0 ? null : this.showShare}>
                share
              </i>
            </ShareButton>
            <DownloadButton
              title={this.selection.length > 0 ? 'Download selection' : 'Download collection'}
              onClick={this.currentCollection ? this.downloadHandler : null}
              disabled={!this.currentCollection}
              toolbarButtons={this.toolbarButtons}
              smallDevice={smallDevice}
            >
              <i className='material-icons'>save_alt</i>
            </DownloadButton>
          </Toolbar>
        </Footer>
        {this.shareWindow}
        {this.alert}
      </React.Fragment>
    )
  }
}

const NoCollections = styled.div`
  position: absolute;
  box-sizing: border-box;
  grid-area: main; //from mobile
  width: 100%;
  margin-top: ${props => (props.smallDevice ? '100px' : null)};
  top: ${props => (props.smallDevice ? null : '30%')};
  color: ${props => props.theme.dark};
  display: flex;
  justify-content: center;
`

const StyledCollections = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Explorer = styled.div`
  position: relative;
  display: grid;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-around;
  grid-gap: 30px;
  padding: 10px 40px 40px 40px;
  margin-bottom: ${props => (props.smallDevice ? '10px' : null)};
  overflow-y: auto;
  background-color: ${props => props.theme.bg};
  :focus {
    outline: none;
  }
`

const EmptyCollection = styled.div`
  position: absolute;
  margin-top: ${props => (props.smallDevice ? '100px' : null)};
  top: ${props => (props.smallDevice ? null : '30%')};
  width: 100%;
  color: ${props => props.theme.dark};
  display: flex;
  justify-content: center;
`

const Footer = styled.div`
  grid-area: footer;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : null)};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.light};
  color: ${props => props.theme.dark};
`

const SelectCollection = styled.div`
  position: absolute;
  left: ${props => (props.smallDevice ? '-10px' : '10px')};
  bottom: 0px;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '40px' : '60px')};
  width: ${props => (props.smallDevice ? 'calc(100% + 20px)' : 'auto')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '20px' : null)};
  color: ${props => props.theme.dark};
  background-color: ${props => props.theme.light};
  margin-top: ${props => (props.smallDevice ? '10px' : null)};
  margin-bottom: ${props => (props.smallDevice ? '-12px' : null)};
  border-bottom: ${props => (props.smallDevice ? `1px solid ${props.theme.dark}` : null)};
  z-index: 4;
`

const Toolbar = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

const SelectionPanel = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};
`

const SelectAll = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '30px')};
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : null)};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  font-size: ${props => (props.smallDevice ? '30px' : '24px')};
  padding-top: ${props => (props.smallDevice ? null : '4px')};
  border-left: ${props => (props.smallDevice ? `1px solid ${props.disabled ? props.theme.main : props.theme.dark}` : null)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: ${props => (props.disabled ? null : '27px')};
      padding-top: ${props => (props.disabled ? null : '3px')};
      cursor: ${props => (props.disabled ? null : 'pointer')};
    }
  }
`

const ClearSelection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.smallDevice ? '50px' : '30px')};
  box-sizing: border-box;
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : null)};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  font-size: ${props => (props.smallDevice ? '30px' : '24px')};
  border-left: ${props => (props.smallDevice ? `1px solid ${props.disabled ? props.theme.main : props.theme.dark}` : null)};
  padding-bottom: ${props => (props.smallDevice ? null : '4px')};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: ${props => (props.disabled ? null : '27px')};
      padding-bottom: ${props => (props.disabled ? null : '3px')};
      cursor: ${props => (props.disabled ? null : 'pointer')};
    }
  }
`

const CopyButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : '80px')};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '30px' : '40px')};
  border-left: 1px solid ${props => (props.disabled && props.smallDevice ? props.theme.main : props.theme.dark)};
  color: ${props => (props.disabled && props.smallDevice ? props.theme.main : props.theme.dark)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: pointer;
      font-size: 43px;
    }
  }
`

const RemoveButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : '80px')};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  color: ${props => props.theme[props.noSelection || props.disabled ? 'main' : 'dark']};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: ${props => (props.noSelection || props.disabled ? null : 'pointer')};
      font-size: ${props => (props.noSelection || props.disabled ? null : '43px')};
    }
  }
`

const DeleteCollection = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : '80px')};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  color: ${props => props.theme[props.disabled ? 'main' : 'dark']};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: ${props => (props.disabled ? null : 'pointer')};
      font-size: ${props => (props.disabled ? null : '43px')};
    }
  }
`
const ShareButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : '80px')};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '30px' : '40px')};
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      cursor: ${props => (props.disabled ? null : 'pointer')};
      font-size: ${props => (props.disabled ? null : '43px')};
    }
  }
`

const DownloadButton = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '50px' : '60px')};
  width: ${props => (props.smallDevice ? `calc(100% / ${props.toolbarButtons})` : '80px')};
  max-width: ${props => (props.smallDevice ? '80px' : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.smallDevice ? '40px' : '50px')};
  color: ${props => (props.disabled ? props.theme.main : props.theme.dark)};
  border-left: 1px solid ${props => (props.disabled ? props.theme.main : props.theme.dark)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      font-size: ${props => (props.disabled ? null : '53px')};
      cursor: ${props => (props.disabled ? null : 'pointer')};
    }
  }
`

const mapStateToProps = state => {
  return {
    currentPhotoCollection: state.browse.currentPhotoCollection,
    smallDevice: state.ui.smallDevice,
    currentUser: state.auth.currentUser,
    collections: state.collections.collections
  }
}

const mapDispatchToProps = dispatch => {
  return {
    populatePhotoCollection: photoArray => dispatch(actions.populatePhotoCollection(photoArray)),
    updateCurrentUser: userObject => dispatch(actions.updateCurrentUser(userObject)),
    getCollections: owner => dispatch(actions.getCollections(owner)),
    removeFromCollection: (collection, index, photos) => dispatch(actions.removeFromCollection(collection, index, photos)),
    deleteCollection: collectionId => dispatch(actions.deleteCollection(collectionId)),
    createCollection: collection => dispatch(actions.createCollection(collection)),
    download: (files, folderName) => dispatch(actions.download(files, folderName)),
    setError: (error, breaking, customMessage) => dispatch(actions.setError(error, breaking, customMessage))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Collections))
