import React from 'react'
import styled from 'styled-components'

const Thumbnail = props => {
  return (
    <React.Fragment>
      <StyledThumbnail src={props.src} alt={props.alt} id={props.id} draggable={false} onLoad={() => positionOverlay(props.id)} />
      <Overlay name={`overlay-${props.id}`} id={props.id}/>
    </React.Fragment>
  )
}

function positionOverlay(id) {
  let overlay = document.getElementsByName(`overlay-${id}`)[0]
  let img = document.getElementById(id)
  overlay.style.height = `${img.clientHeight}px`
  overlay.style.width = `${img.clientWidth}px`
}

const Overlay = styled.div`
  position: absolute;
`

const StyledThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export default Thumbnail
