import * as actions from 'store/actions/actionTypes'
import { updateObject } from 'utils/shared'

const initialState = {
  files: null,
  progress: null
}

const updateProgress = (state, action) => {
  // let progress = { ...state.progress }
  // if (action.progress) {
  //   Object.keys(action.progress).forEach(item => {
  //     progress[item] = action.progress[item]
  //   })
  // } else progress = null
  return updateObject(state, { progress: action.progress })
}

const addFiles = (state, action) => {
  if (state.files !== null) {
    const newFileArray = state.files.slice()
    action.files.forEach(newFile => {
      let fileExists = false
      state.files.forEach(oldFile => {
        if (newFile.name === oldFile.name) {
          fileExists = true
        }
      })
      if (!fileExists) {
        newFileArray.push(newFile)
      }
    })
    return updateObject(state, { files: newFileArray })
  } else return updateObject(state, { files: action.files })
}

const clearFiles = state => {
  return updateObject(state, { files: null })
}

const removeFile = (state, action) => {
  const newFileArray = state.files.filter(file => file.name !== action.fileName)
  if (newFileArray.length > 0) {
    return updateObject(state, { files: newFileArray })
  } else return updateObject(state, { files: null })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_PROGRESS:
      return updateProgress(state, action)
    case actions.ADD_FILES:
      return addFiles(state, action)
    case actions.CLEAR_FILES:
      return clearFiles(state)
    case actions.REMOVE_FILE:
      return removeFile(state, action)
    default:
      return state
  }
}

export default reducer
