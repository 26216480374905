import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from 'store/actions'
import styled from 'styled-components'
import Input from 'components/NewInput'
import Alert from 'components/Alert'

class File extends Component {
  constructor(props) {
    super(props)
    
    if (this.props.existingValue) this.populateExistingFiles()
    else {
      this.files = []
      this.renderList()
    }
  }

  populateExistingFiles = () => {
    this.files = this.props.existingValue
    this.setValue()
    this.renderList()
  }

  inputClickedHandler = () => {
    if (this.props.disabled) return
    document.getElementById(this.props.id).click()
  }

  addFiles = event => {
    if (this.props.onChange) this.props.onChange(event)
    this.files = [...this.files, ...Object.values(event.target.files)]
    this.removeDuplicates()
    this.validateFileType()
    this.validateFileSize()
    this.setValue()
    this.renderList()
  }

  removeDuplicates = () => {
    let fileNames = []
    this.files = this.files.filter(file => {
      if (fileNames.includes(file.name)) return false
      fileNames.push(file.name)
      return true
    })
  }

  validateFileType = () => {
    let fileTypes = this.props.accept.split(',')
    let invalidFile = false
    this.files = this.files.filter(file => {
      let extension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase()
      if (fileTypes.includes(extension)) return true
      invalidFile = true
      return false
    })
    if (invalidFile) this.alert = <Alert alertType='File type error' alertButtonClicked={this.clearAlert} />
  }

  validateFileSize = () => {
    let maxSize = this.props.maxSize
    if (!maxSize) return
    let tooLarge = false
    this.files = this.files.filter(file => {
      if (!file.size || file.size < maxSize) return true
      tooLarge = true
      return false
    })
    if (tooLarge) this.alert = <Alert alertType='File error' alertButtonClicked={this.clearAlert} />
  }

  setValue = () => {
    this.props.setFiles(this.props.id, this.files)
  }

  renderList = () => {
    if (this.files && this.files.length > 0) {
      this.list = this.files.map(file => {
        return (
          <ListItem key={file.name} title={file.name}>
            {`${file.name.slice(0, 16)}${file.name.length > 16 ? '...' : ''}`}
            <RemoveFile id={file.name} onClick={this.removeFile}>
              <i className='material-icons' id={file.name}>
                delete
              </i>
            </RemoveFile>
          </ListItem>
        )
      })
    } else this.list = null
    this.forceUpdate()
  }

  removeFile = event => {
    this.files = this.files.filter(file => file.name !== event.target.id)
    this.setValue()
    this.renderList()
  }

  clearAlert = () => {
    this.alert = null
    this.forceUpdate()
  }

  render() {
    return (
      <StyledFile>
        <Input
          type='button'
          id={`${this.props.id}_button`}
          onClick={this.inputClickedHandler}
          value='Add files'
          width='80px'
          disabled={this.props.disabled}
        >
          Add files
        </Input>
        <FileInput
          type='file'
          id={this.props.id}
          accept={this.props.accept}
          multiple={this.props.multiple || true}
          required={this.props.required}
          onChange={this.addFiles}
          tabIndex='-1'
        />
        <List invalid={this.props.invalid && !this.list}>{this.list || 'No files selected'}</List>
        {this.alert}
      </StyledFile>
    )
  }
}

const StyledFile = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;
  align-items: center;

  :focus {
    outline: none;
    border-width: 2px;
  }
`

const List = styled.div`
  box-sizing: border-box;
  height: ${props => (props.smallDevice ? '18px' : '40px')};
  width: 80%;
  flex-wrap: wrap;
  overflow-y: auto;
  padding: 2px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${props => props.invalid ? 'red' : props.theme.dark};
  font-size: 14px;
  :hover {
    cursor: default;
  }

  :disabled {
    border-color: ${props => props.theme.main};
    color: ${props => props.theme.main};
  }
`

const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  text-overflow: clip;
  white-space: nowrap;
`

const RemoveFile = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0px 1px;
  :hover {
    font-size: 16px;
    margin: 0px 0px;
    cursor: pointer;
  }
`

const FileInput = styled.input`
  position: relative;
  width: 0.1px;
  opacity: 0;
`

const mapDispatchToProps = dispatch => {
  return {
    setFiles: (id, files) => dispatch(actions.setFiles(id, files))
  }
}

export default connect(null, mapDispatchToProps)(File)
